import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../Const";
import swal from "sweetalert";

export const GetClientCategoryList = (api) => (dispatch) => {
  const url = api;
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_CATEGORY_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
};
export const NextClientCategoryList = (nextUrl) => (dispatch) => {
  const url = nextUrl;
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_CATEGORY_LIST,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
  }
};

export const PreviousClientCategoryList = (prevUrl) => (dispatch) => {
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_CATEGORY_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
};
//   export const GetClientDetails = (id) => (dispatch) => {
//     const url = `${BASE_URL}api/v1/inventory/admin/category/${id}/`;
//     try {
//       Axios.get(url).then((res) => {
//         if (res.data.status) {
//           dispatch({
//             type: Types.GET_CLIENT_DETAILS,
//             payload: res.data.data,
//           });
//         }
//       });
//     } catch (error) {}
//   };

export const SetClientCategoryUpdatedData = (data) => (dispatch) => {
  const url = `${BASE_URL}api/v1/inventory/admin/category/${data}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_CATEGORY_UODATED,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
};

export const GetClientCategoryInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_CLIENT_CATEGORY_INPUT, payload: formData });
  // dispatch({ type: Types.ADD_NEW_DOC, payload: {name:'restaurant_types', value:, } });
};
export const SetClientCategoryStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_CLIENT_CATEGORY_FALSE, payload: false });
};
export const SubmitClientCategorydData = (data) => async (dispatch) => {
  console.log("data.branch", data.branches);
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (data.branches) {
    data.branches = [userData.client_id];
  }

  // data.restaurant_types.push()
  // if (data.name === "") {
  //   showToast("error", "Name shouldn't be empty !");
  //   return 0;
  // } else if (data.address === "") {
  //   showToast("error", "Adress shouldn't be empty !");
  //   return 0;
  // } else if (data.email === "") {
  //   showToast("error", "Email shouldn't be empty !");
  //   return 0;
  // }

  const url = `${BASE_URL}api/v1/inventory/admin/category/`;
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
          showToast("success", "Category added successfully");

          dispatch({ type: Types.AFTER_CLIENT_CATEGORY_DATA, payload: true });
          dispatch(
            GetClientCategoryList(
              `${BASE_URL}api/v1/inventory/admin/category/?branches=${userData.client_id}`
            )
          );
        } else {
          dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const UpdateClientCategorydData = (data, api) => async (dispatch) => {
  const client_id = localStorage.getItem("client_id");
  if (data.branches) {
    data.branches = [client_id];
  }
  // if (data.name === "") {
  //   showToast("error", "Name shouldn't be empty !");
  //   return 0;
  // } else if (data.address === "") {
  //   showToast("error", "Adress shouldn't be empty !");
  //   return 0;
  // } else if (data.email === "") {
  //   showToast("error", "Email shouldn't be empty !");
  //   return 0;
  // }

  const url = `${BASE_URL}api/v1/inventory/admin/category/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });

  try {
    await Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
          showToast("success", "Category Updated successfully");
          dispatch(GetClientCategoryList(api));
          dispatch({ type: Types.AFTER_CLIENT_CATEGORY_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const ClientCategorydelete = (id, api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/inventory/admin/category/${id}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT_CATEGORY, payload: true });
  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("error", res.data.message);
        dispatch({ type: Types.IS_CLIENT_CATEGORY_DELETED, payload: true });
        dispatch(GetClientCategoryList(api));
      }
    });
  } catch (error) {}
};
export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_CLIENT_CATEGORY_DELETED, payload: false });
};
export const GetClientCategoryOption = (data) => {
 
  const option = [];
  if (data) {
    data.forEach((item) => {
      const obj = {
        label: item.name,
        value: item.id,
        id: item.id,
      };
      //  item.id
      option.push(obj);
    });
  }
  return option;
};
