import React, { useEffect, useState } from "react";

import { Search } from "@material-ui/icons";

import { useHistory } from "react-router-dom";
import ClientList from "./ClientList";
import { SetClientStatusFalse } from "../_redux/action/ClientAction";
import { useDispatch, useSelector } from "react-redux";

import { GetCategoryList } from "../../../Seetings/category_redux/action/CategoryAcion";
import { GetUserNotification } from "../../../User/component/verifieduser/_redux/action/VerifiedUserAction";
import { GetClientBulkInput, SubmitClientBulkData } from "../../../image_upload/_redux/action/Imageaction";

const Clients = () => {
  const dispatch = useDispatch();
  // const api = `${BASE_URL}api/v1/inventory/admin/category/`;
  const [search, setSearch] = useState("");
  const [selectedName, setSelectedName] = useState(undefined);
  const clientBulkInput = useSelector((state) => state.imageInfo.clientBulkInput);
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);
  const afterClientdata = useSelector(
    (state) => state.clientInfo.afterClientdata
  );
  const categoryList = useSelector((state) => state.categoryInfo.categoryList);
  useEffect(() => {
    dispatch(GetCategoryList());
    dispatch(GetUserNotification());
  }, []);
  useEffect(() => {
  
    dispatch(SetClientStatusFalse());
  }, [afterClientdata]);
  const handleSelectChange = (e) => {
   
    setSelectedName(e.target.value);
  };
  const handleChangeInput = (name, value) => {
    dispatch(GetClientBulkInput(name, value));
    console.log(value, 'value')
  };
  const handleSubmit = (data) => {
    dispatch(SubmitClientBulkData(data));
  };
  return (
    <>
      <div className="parent text-capitalize">
        <div className="main-div">
          <div className="fw-bold">
            <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
            <span
              style={{ fontSize: "14px", color: "#818181", fontWeight: "400" }}
            >
              Offers
            </span>
          </div>

          <div className="filter_search row mt-4 ">
            <div className="col-lg-3 col-md-6 mb-3 d-flex align-items-center">
              {/* <span className="me-2" style={{ fontSize: "14px" }}>
                Business Type :{" "}
              </span> */}
               <input
                type="file"
                name="file"
                id="fileSelect"
                // value={bulkInput.file}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) =>
                  handleChangeInput("file", e.target.files[0])
                }
              />
              {/* <select name="" id="" className="me-2">
                <option value="bulk_action">Bulk action</option>
                <option value="Food">Action</option>
                <option value="None">Action</option>
              </select> */}
              <span className="me-2">
                <button className="orange-btn" style={{ padding: "5px 20px" }}    onClick={()=>handleSubmit(clientBulkInput)}>
                  Apply
                </button>
              </span>
            </div>
          
            <div className="col-lg-4 col-md-6 mb-3 d-flex align-items-center">
              <span className="me-2" style={{ fontSize: "14px", width: "70%" }}>
                Filter by :{" "}
              </span>
              <select
                name=""
                id=""
                className="me-2"
                onChange={handleSelectChange}
              >
                <option value="">--</option>

                {categoryList?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-5 col-md-6 mb-3 ">
              <span className="search">
                <Search className="search_icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search comments, users.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </span>
            </div>
          </div>

          <div className="table_div mt-4">
            <ClientList search={search} selectedName={selectedName}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
