import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";

export const GetCategoryList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/settings/admin/business-category/`;
  dispatch({ type: Types.IS_LOAD_CATEGORY, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CATEGORY_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CATEGORY, payload: true });
};
export const GetBillingTypeList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/settings/admin/billing-Type/`;
  dispatch({ type: Types.IS_LOAD_CATEGORY, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_BILLING_TYPE_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CATEGORY, payload: true });
};

export const GetCategorylInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_CATEGORY_INPUT, payload: formData });
};

export const SetCategoryStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_CATEGORY_FALSE, payload: false });
};
export const SubmitCategoryldData = (data) => async (dispatch) => {
  if (data.name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  }
  //  else if (data.address === "") {
  //     showToast("error", "Adress shouldn't be empty !");
  //     return 0;
  //   } else if (data.email === "") {
  //     showToast("error", "Email shouldn't be empty !");
  //     return 0;
  //   }

  const url = `${BASE_URL}api/v1/settings/admin/business-category/`;
  dispatch({ type: Types.IS_LOAD_CATEGORY, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
          showToast("success", "Business Cateory  added successfully");
          dispatch(GetCategoryList());
          dispatch({ type: Types.AFTER_CATEGORY_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};


export const GetCategoryBillinglInput = (name, value, i, billingInput = undefined) => (dispatch) => {
  console.log('value', value)
 if(billingInput !==undefined){
   const formData = {
     name: name,
     value: value,
     i
   };
   dispatch({ type: Types.ADD_NEW_BILLING_INPUT, payload: formData });
 }
  
 };

 export const SubmitCategoryBillingData = (data) => async (dispatch) => {
 

  const url = `${BASE_URL}/api/v1/settings/admin/billing-Type/activation/`;
  dispatch({ type: Types.IS_LOAD_CATEGORY, payload: true });

  try {
    await Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
          showToast("success", "Billing type updated successfully");
          dispatch(GetCategoryList());
          dispatch({ type: Types.AFTER_CATEGORY_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CATEGORY, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

// --// --------------delete------------
export const categorydelete = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/settings/admin/business-category/${id}/`;
  
    try {
      Axios.delete(url).then((res) => {
        if (res.data.status) {
          showToast("error", res.data.message);
          dispatch({ type: Types.IS_CATEGORY_DELETED, payload: true });
          dispatch(GetCategoryList())
        }
      });
    } catch (error) {}
  };
  export const SetFalseObjectDelete = () => (dispatch) => {
    dispatch({ type: Types.IS_CATEGORY_DELETED, payload: false });
  };
  
  export const GetCategoryOption = (data) => {
    const option = [];
    if (data) {
      data.forEach((item) => {
        const obj = {
          label: item.name,
          value: item.id,
          id: item.id,
        };
        //  item.id
        option.push(obj);
      });
    }
    return option;
  };