import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../../Const";
import swal from "sweetalert";



export const GetUserNotification = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/users/admin/dashboard/notifications/`;
  // const url = api;
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_USER_NOTIFICATION,
      
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });
};
export const GetVerifiedUserList = (api) => (dispatch) => {
  // const url = `${BASE_URL}api/v1/users/admin/?is_verified=true`;
  const url = api;
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_VERIFIED_USER_LIST,
      
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });
};

export const NextUserListList = (nextUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const url = nextUrl
  // dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_VERIFIED_USER_LIST,
          payload: res.data.data,
        });

        
      }
    });
  } catch (error) {

  }

};

export const PreviousUserList = (prevUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const url = `${BASE_URL}api/v1/products/admin/order/?offset=${}&limit=100`;
  const url = prevUrl
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_VERIFIED_USER_LIST,
          payload: res.data.data,
        });

        
        
        dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });
};
export const GetVerifiedUserlInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_VERIFIED_USER_INPUT, payload: formData });
};
export const SetVerifiedUserStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_VERIFIED_USER_FALSE, payload: false });
};
export const SubmitVerifiedUserldData = (data) => async (dispatch) => {
  if(parseInt(data.membership_level) === 1){
    data.is_verified = true;
    data.is_requested = false
  }
  else if(parseInt(data.membership_level) === 0){
    data.is_verified = false;
    data.is_requested = true
  }
  if (data.first_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
  if (data.last_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
 
   else if (data.membership_level === "") {
      showToast("error", "Membership level shouldn't be empty !");
      return 0;
    } 
    else if (data.role === "") {
      showToast("error", "Role shouldn't be empty !");
      return 0;
    }
   else if (data.image === "") {
      showToast("error", "Image shouldn't be empty !");
      return 0;
    } 
    else if (data.role === "") {
      showToast("error", "Role shouldn't be empty !");
      return 0;
    }


  const url = `${BASE_URL}api/v1/users/admin/`;
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
          showToast("success", "App User  added successfully");
          dispatch(GetVerifiedUserList());
          dispatch({ type: Types.AFTER_VERIFIED_USER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const UpdateVerifiedldData = (data) => async (dispatch) => {
 
  if(parseInt(data.membership_level) === 1){
    data.is_verified = true;
    data.is_requested = false
  }
  else if(parseInt(data.membership_level) === 0){
    data.is_verified = false;
    data.is_requested = true
  }
  if (data.first_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
  if (data.last_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
 
   else if (data.membership_level === "") {
      showToast("error", "Membership level shouldn't be empty !");
      return 0;
    } 
    else if (data.role === "") {
      showToast("error", "Role shouldn't be empty !");
      return 0;
    }
   else if (data.image === "") {
      showToast("error", "Image shouldn't be empty !");
      return 0;
    } 
    else if (data.role === "") {
      showToast("error", "Role shouldn't be empty !");
      return 0;
    }


  const url = `${BASE_URL}api/v1/users/admin//${data.id}/`;
  dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: true });

  try {
    await Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
          showToast("success", "Verified user updated successfully");
          dispatch(GetVerifiedUserList());
          dispatch({ type: Types.AFTER_VERIFIED_USER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_VERIFIED_USER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const SetVerifiedUpdatedData = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/users/admin/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_VERIFIED_USER_UODATED,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };
export const GetVerifiedDetails = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/users/admin/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_VERIFIED_USER_DETAILS,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };
// // --// --------------delete------------
// export const categorydelete = (id) => (dispatch) => {
//     const url = `${BASE_URL}/api/v1/settings/admin/business-category/${id}/`;
  
//     try {
//       Axios.delete(url).then((res) => {
//         if (res.data.status) {
//           showToast("error", res.data.message);
//           dispatch({ type: Types.IS_CATEGORY_DELETED, payload: true });
//           dispatch(GetCategoryList())
//         }
//       });
//     } catch (error) {}
//   };
//   export const SetFalseObjectDelete = () => (dispatch) => {
//     dispatch({ type: Types.IS_CATEGORY_DELETED, payload: false });
//   };
  
