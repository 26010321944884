import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { useHistory } from "react-router-dom";
import { GetBillingTypeList } from "../Seetings/category_redux/action/CategoryAcion";
import { GetClientInput } from "./all_clients/_redux/action/ClientAction";
const Details2 = ({ value }) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState([]);
  const clientInput = useSelector((state) => state.clientInfo.clientInput);
  const isLoadClient = useSelector((state) => state.clientInfo.isLoadClient);
  const handleChangeInput = (name, value) => {
    dispatch(GetClientInput(name, value));
  };
  const billingTypeList = useSelector(
    (state) => state.categoryInfo.billingTypeList
  );
  useEffect(() => {
    dispatch(GetBillingTypeList());
  }, []);
  const handleChangeTextInput = (name, value) => {
    
    if (name === "phone_numbers") {
      if (value && value.length > 3) {
       
        value = value.slice(0, 3);
      }
 
      value = value.map((option) => option.value);
    }
    dispatch(GetClientInput(name, value));
  };


  useEffect(() => {
    const options = clientInput?.phone_numbers?.map((item) => ({
      value: item,
      label: item,
    }));
    
    setPhoneNumber(options); 
  }, [clientInput.phone_numbers]);
  return (
    <div className="parent">
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Add New Client /
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>
            Client Name
          </span>
        </div>

        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-8 col-lg-7 input-head">
            <p>Email Address*</p>
            <input
              type="text"
              placeholder="example@gmail.com"
              name="email"
              value={clientInput.email}
              onChange={(e) => handleChangeInput("email", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-8 col-lg-7 input-head">
            <p>Create a password*</p>
            <input
              type="password"
              placeholder="******"
              name="password"
              value={clientInput.password}
              onChange={(e) => handleChangeInput("password", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-8 col-lg-7 input-head2">
            <p>Phone Number*</p>
            <input
              type="text"
              placeholder="+880179480985"
              name="phone"
              value={clientInput.phone}
              onChange={(e) => handleChangeInput("phone", e.target.value)}
            />
         
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-8 col-lg-7 input-head2">
            <p>Optional Phone Numbers</p>
            
            <CreatableSelect
              type="number"
              isClearable={true}
              isMulti
              name="restaurant_types"
              value={phoneNumber}
              onChange={(newValue) => {
                // handleChangeResInput("restaurant_types", newValue);
                handleChangeTextInput("phone_numbers", newValue);
                setPhoneNumber(newValue);
              }}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-8 col-lg-7 input-head">
            <p>Payment Amount</p>
            <input
              type="text"
              placeholder="4500"
              name="paid_amount"
              value={clientInput.paid_amount}
              onChange={(e) => handleChangeInput("paid_amount", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-12 col-md-8 col-lg-7 row input-head2">
            <div className="col-12">
              <p>Payment Duration</p>
            </div>
            <div
              className="ms-2"
              style={{
                border: "1px solid  #C2C2C2",
                borderRadius: "12px",
                background: "white",
              }}
            >
              <div className="row monthly">
                <div className="col-7">
                  <input
                    type="text"
                    placeholder="2 Months"
                    name="payment_duration"
                    value={clientInput.payment_duration}
                    onChange={(e) =>
                      handleChangeInput("payment_duration", e.target.value)
                    }
                  />
                </div>

                <div className="col-1">
                  <Divider
                    color="dark"
                    bgcolor="dark"
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                </div>
                <div className="col-4">
                  <div style={{ position: "relative" }}>
                    <select
                      id="inputState"
                      className="form-select "
                      disabled
                      name="payment_billing_type"
                      value={clientInput.payment_billing_type}
                      onChange={(e) =>
                        handleChangeInput(
                          "payment_billing_type",
                          e.target.value
                        )
                      }
                    >
                      <option value={""}>Payment Type</option>
                      {billingTypeList?.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="arrow-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 256 256"
                        stroke="currentColor"
                      >
                        <rect width="20" height="20" fill="none"></rect>
                        <polyline
                          points="208 96 128 176 48 96"
                          fill="none"
                          stroke="#000000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="24"
                        ></polyline>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" justify-content-start" style={{ marginTop: "25px" }}>
          <button onClick={() => value("1")} className="back-btn me-3">
            Back
          </button>
          <button onClick={() => value("3")} className="next-btn ">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Details2;
