import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import noData2 from "../../../assets/jsonFile/noData2.json";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Lottie from "react-lottie";
import UserPagination from "../../Common/UserPagination";

import { BASE_URL } from "../../../Const";
import { GetUserNotification } from "../../User/component/verifieduser/_redux/action/VerifiedUserAction";
import {
  GetSupportUserList,
  NextSupportList,
  PreviousSupportList,
} from "../_redux/action/SupportAction";
const SupportList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const api = `${BASE_URL}api/v1/users/admin/contact/?limit=20&search=${search}`;
  const support_List = useSelector((state) => state.supportInfo.supportList);
  const isLoadSupport = useSelector((state) => state.supportInfo.isLoadSupport);

  const [selected, setSelected] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // ========checkbox---------------

  React.useEffect(() => {
    dispatch(GetSupportUserList(api));
    dispatch(GetUserNotification());
  }, []);

  React.useEffect(() => {
    dispatch(GetSupportUserList(api));
  }, [search]);


  React.useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  
  }, []);

  // --------------pagination----------------

  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextSupportList(support_List?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousSupportList(support_List?.previous));
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // ---------------limit text---
  const [expanded, setExpanded] = React.useState({});
  const handleExpand = (id) => {
    setExpanded((prevExpanded) => ({ ...prevExpanded, [id]: true }));
  };

  const handleCollapse = (id) => {
    setExpanded((prevExpanded) => ({ ...prevExpanded, [id]: false }));
  };
  // ================================fixed sticky navbar===============
  const [navbar, setNavbar] = React.useState(false);
  const [color, setColor] = React.useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
      setColor("black");
    } else {
      setNavbar(false);
      setColor("white");
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", changeBackground, true);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);
  return (
    <div>
      {isLoadSupport && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadSupport &&
        support_List?.results &&
        support_List?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}
      {!isLoadSupport &&
        support_List?.results &&
        support_List?.results !== null &&
        support_List?.results?.length > 0 && (
          <TableContainer
            component={Paper}
            className="text-capitalize"
            sx={{ maxHeight: 670 }}
          >
            <Table stickyHeader aria-label="sticky table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "white" }}>
                <TableRow>
                  <TableCell align="left">
                    <div style={{ marginLeft: "15px" }}>Name</div>
                  </TableCell>
                  <TableCell align="left"> Reason for Contact</TableCell>
                  <TableCell align="left">Contact Details</TableCell>
                  <TableCell align="left"> Date</TableCell>

                  {/* <TableCell align="left">Phone Number</TableCell> */}
                  <TableCell align="left">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "white" }}>
                {support_List?.results?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <div style={{ textAlign: "left", marginLeft: "15px" }}>
                          {/* <Avatar alt="User1" src="/static/images/avatar/1.jpg" />{" "} */}

                          <p style={{ color: "#EF5B0C", fontWeight: "bold" }}>
                            {row.user_name}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          <p>{row.title}</p>
                        </div>
                      </TableCell>

                      <TableCell align="center">
                        <div style={{ textAlign: "left", lineHeight: "20px" }}>
                          <p
                            style={{
                              color: "#7A7A7A",
                              fontSize: "14px",
                              fontWeight: "500",
                              marginBottom: "0",
                            }}
                          >
                            {row.contact_details}
                          </p>{" "}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          <p>
                            Requested:{" "}
                            {new Date(row.created_at)
                              .toISOString()
                              .slice(0, 10)}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell
                      // align="center"
                      >
                        <div style={{ textAlign: "left", lineHeight: "20px" }}>
                          <p
                            style={{
                              color: "#7A7A7A",
                              fontSize: "14px",
                              fontWeight: "500",
                              marginBottom: "0",
                            }}
                          >
                            {row.details}
                          </p>{" "}
                        </div>
                        {/* <p
                            style={{
                              textAlign: "justify",
                              color: "#7A7A7A",
                              fontSize: "14px",
                              fontWeight: "500",
                              marginBottom: "0",
                            }}
                          >
                            {expanded[row.id]
                              ? row.details
                              : `${row.details.split(" ").slice(0, 6)}...`}
                            {expanded[row.id] ? (
                              <button onClick={() => handleCollapse(row.id)}>
                                See less
                              </button>
                            ) : (
                              <button onClick={() => handleExpand(row.id)}>
                                See more
                              </button>
                            )}
                          </p> */}

                        {/* {row.desc} */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {support_List?.count}</p>
        <UserPagination
          prev_next={support_List}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default SupportList;
