import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Edit, Star, ViewDayOutlined } from "@material-ui/icons";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import Avatar from "@mui/material/Avatar";

import Checkbox from "@mui/material/Checkbox";

import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { BsImage } from "react-icons/bs";
import img1 from "../../../../assets/img/2.jpg";
import UserPagination from "../../../Common/UserPagination";
import { Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RowMenu } from "../../../Common/RowMenu";
import {
  Getofferdelete,
  GetOfferList,
  NextOfferListList,
  PreviousOfferList,
} from "../_redux/action/OfferAction";
import { GetClientCategoryList } from "../../../Clients/category/_redux/action/ClientCategoryAction";
import { BASE_URL } from "../../../../Const";
import { GetClientofferdelete, SetClientofferUpdatedData } from "../../../Clients/ClientOffers/_redux/action/ClientOfferAction";
import { GetClientProductList } from "../../../Clients/products/_redux/action/ClientProductAction";
import { status_list } from "../../../Common/Dropdown";
const OfferList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  const offer_List = useSelector((state) => state.offerInfo.OfferList);
  const isLoadOffer = useSelector((state) => state.offerInfo.isLoadOffer);
  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );
  const ClientProductList = useSelector(
    (state) => state.clientProductInfo.ClientProductList
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    setUsers(offer_List?.results);
    dispatch(
      GetOfferList(`${BASE_URL}api/v1/promotions/admin/offer/?limit=20`)
    );
    dispatch(
      GetClientCategoryList(`${BASE_URL}api/v1/inventory/admin/category/`)
    );
    dispatch(
      GetClientProductList(`${BASE_URL}api/v1/inventory/admin/product/`)
    );
    // localStorage.removeItem("client_id");
  }, []);
  React.useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  // ====================filter===========
  React.useEffect(() => {
    if (selectedName) {
      dispatch(
        GetOfferList(
          `${BASE_URL}api/v1/promotions/admin/offer/?discount_type=${selectedName}&&limit=20`
        )
      );
    } else {
      dispatch(
        GetOfferList(`${BASE_URL}api/v1/promotions/admin/offer/?limit=20`)
      );
    }
  }, [selectedName]);

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextOfferListList(offer_List?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousOfferList(offer_List?.previous));
  };

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  const handleeEdit = (id) => {
   
    dispatch(SetClientofferUpdatedData(id));
    history.push(`/add-client-offer-2/${id}`);
  };
  const handleDelete = (id) => {
    dispatch(Getofferdelete(id, `${BASE_URL}api/v1/promotions/admin/offer/?limit=20`));
    // handleClose()
    setAnchorEl(null);
  };
  return (
    <div>
      {isLoadOffer && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadOffer &&
        offer_List?.results &&
        offer_List?.results !== null &&
        offer_List?.results?.length > 0 && (
          <TableContainer component={Paper} className="text-capitalize">
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "white" }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      // className="form-check-input"
                      name="allSelect"
                      checked={selectedUser?.length === users?.length}
                      onChange={(e) => handleChange(e, users)}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="left">Name of Offer</TableCell>
                  <TableCell align="left">Product</TableCell>
                  <TableCell align="left">Client Name</TableCell>
                  <TableCell align="left">Location</TableCell>
                  <TableCell align="center">Branch</TableCell>
                  <TableCell align="center">MBBD Rating </TableCell>
                  <TableCell align="center">View</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableCell align="left">
                    {" "}
                    <IconButton aria-label="more">
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "white" }}>
                {offer_List?.results
                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val?.client_category
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.client_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.name}
                            checked={selectedUser.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange(e, row)}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className="image-td"
                          style={{ fontWeight: "bolder" }}
                        >
                          
                          {row.offer_id}
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          <div className="d-flex align-items-center gap-2">
                            <img src={row.banner_url} alt="" />
                            <div>
                              <h6
                                // onClick={() => history.push("/profile")}
                                style={{ color: "Black" }}
                              >
                                {row.name}
                              </h6>
                              <p>
                                Joining Date:{" "}
                                {new Date(row.client_joining_date)
                                  .toISOString()
                                  .slice(0, 10)}
                              </p>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell align="left" className="client_td">
                          <div className="d-flex gap-2 mb-3">
                            {row.product.map((item) => {
                              return ClientProductList?.results?.map(
                                (product) => {
                                  return (
                                    product.id === item && (
                                      <div className="menu_name">
                                        {product.name}{" "}
                                      </div>
                                    )
                                  );
                                }
                              );
                            })}
                          
                          </div>
                          
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          <h6 style={{ color: "#7A7A7A" }}>
                            {row.client_name}
                          </h6>
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          <h6 style={{ color: "#7A7A7A" }}>
                            {row.client_location}
                          </h6>
                        </TableCell>
                        <TableCell align="center">
                          {row.client_branch}
                        </TableCell>
                        <TableCell align="center" className="rating_td">
                          <span className="d-flex align-items-center">
                            <Star /> {row.client_rating}
                          </span>
                        </TableCell>
                        <TableCell align="center" className="table_icon">
                          <span
                            onClick={() => history.push(`/clients-dashboard`)}
                            style={{ cursor: "pointer" }}
                          >
                            <FaEye
                              className="me-2"
                              style={{ fontSize: "22px", color: "D8D8D8 " }}
                            />
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          {status_list?.map((item) => {
                            return (
                              item.value === row.status && (
                                <button className="green-btn">
                                  {item.label}
                                </button>
                              )
                            );
                          })}
                          {/* {row.status === "Scheduled" ? (
                      <button className="yellow-btn">Scheduled</button>
                    ) : (
                      <button className="green-btn">Published</button>
                    )} */}
                        </TableCell>

                        <TableCell align="left">
                          {" "}
                          <RowMenu
                            row={row}
                            handleeEdit={handleeEdit}
                            handleDelete={handleDelete}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {offer_List?.count}</p>
        <UserPagination
          prev_next={offer_List}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default OfferList;
