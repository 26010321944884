import React from "react";
import { useState } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import user1 from "../../assets/img/user1.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClientInput,
  GetClientInputImage,
} from "./all_clients/_redux/action/ClientAction";
const BrowseFile = ({
  title,
  name,
  onImageChange,
  url,
  file_name,
  imageUrl,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const dispatch = useDispatch();
  const clientInput = useSelector((state) => state.clientInfo.clientInput);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };
  // const [images, setImages] = useState(clientInput?.gallery);

  // function deleteHandler(image) {

  //   setImages(images.filter((item, i) => image !== i));

  //   dispatch(GetClientInput("gallery", images));
  // }

  const [data, setData] = useState(clientInput?.gallery);

  const handleRemove = (index, item) => {
    const updatedItems = [...clientInput.gallery_image];
    updatedItems.splice(index, 1);
    // setItems(updatedItems);
    dispatch(GetClientInput("gallery_image", updatedItems));

    clientInput.gallery.map((image) => {
      const updatedCver = [...clientInput.gallery];
      if (image === item.id) {
        updatedCver.splice(index, 1);
        dispatch(GetClientInput("gallery", updatedCver));
      }
    });
  };
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("index", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.effectAllowed = "move";
  };

  // const handleDrop = (event, index) => {
  //   event.preventDefault();
  //   const oldIndex = event.dataTransfer.getData("index");
  //   const newImages = [...clientInput.gallery_image];
  //   const draggedImage = newImages.splice(oldIndex, 1)[0];
  //   newImages.splice(index, 0, draggedImage);
  //   newImages.forEach((image, i) => {
  //     image.order = i + 1;
  //   });
  //   dispatch(GetClientInput("gallery_image", newImages));
  // };

  const handleDrop = (event, index, item) => {
    event.preventDefault();
    const oldIndex = event.dataTransfer.getData("index");
    const newImages = [...clientInput.gallery_image];
    const draggedImage = newImages.splice(oldIndex, 1)[0];
    console.log("index", index, draggedImage);
    newImages.splice(index, 0, draggedImage);
    newImages.forEach((image, i) => {
      image.order = i + 1;
    });

    try {
      const newImages2 = [...clientInput.gallery];
      console.log("newImages2", newImages2);
      const draggedImage2 = newImages2.splice(oldIndex, 1)[0];
      // const draggedImage2 = newImages2.splice(oldIndex2, 1)[0];
      console.log("draggedImage2", draggedImage2);
      newImages2.splice(index, 0, draggedImage2);

      console.log("Red Alert :", newImages, newImages2);
      dispatch(GetClientInput("gallery_image", newImages));
      dispatch(GetClientInputImage("gallery", newImages2));
    } catch (error) {
      console.log("newImages2", error);
    }
  };

  return (
    <div>
      <div className="row input-title" style={{ marginTop: "25px" }}>
        <div className="col-11 col-md-6 input-head">
          <p>{title} :</p>
          <p className="image_size">Resolution: 300 x 300</p>
          <div
            className="w-100 browse-main d-flex align-items-center"
            style={{
              height: "55px",
              border: "1px solid #E0E0E0",
              borderRadius: "12px",
              overflowX: "hidden",
              background: "white",
            }}
          >
            <p
              className="my-2 ms-2"
              style={{
                color: "#A1A1A1",
                fontSize: "15px",
                overflow: "hidden",
              }}
            >
              {file_name}
            </p>

            <div className="file-up">
              <input
                multiple
                type="file"
                accept="image/*"
                name={name}
                onChange={onImageChange}
              />
              <p>Browse Files</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-11 col-md-6 row justify-content-between">
          {/* {selectedImages &&
            selectedImages.map((image, index) => {
              return ( */}
          <div key={clientInput?.id} className="col-10 col-md-12 my-2 my-md-0">
            <div className=" row">
              {clientInput.gallery_image &&
                clientInput.gallery_image.map((item, index) => {
                  return (
                    <>
                      {item.id && (
                        <div
                          className="col-md-4 mb-3"
                          style={{ position: "relative" }}
                        >
                          <img
                            className="img-fluid"
                            style={{
                              width: "100%",
                              height: "180px",
                              borderRadius: "8px",
                              objectFit: "cover",
                              cursor: "move",
                            }}
                            src={item?.document}
                            alt="Menubook"
                            draggable="true"
                            onDragStart={(event) =>
                              handleDragStart(event, index)
                            }
                            onDragOver={handleDragOver}
                            onDrop={(event) => handleDrop(event, index)}
                          />

                          <div
                            className="ms-2 remove_img"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemove(index, item)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="#000000"
                              viewBox="0 0 256 256"
                            >
                              <rect width="256" height="256" fill="none"></rect>
                              <circle
                                cx="128"
                                cy="128"
                                r="96"
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="24"
                              ></circle>
                              <line
                                x1="160"
                                y1="96"
                                x2="96"
                                y2="160"
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="24"
                              ></line>
                              <line
                                x1="160"
                                y1="160"
                                x2="96"
                                y2="96"
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="24"
                              ></line>
                            </svg>
                          </div>
                          {/* <div className="d-flex gap-3 justify-content-end">
                            {index < clientInput.gallery_image.length - 1 && (
                              <div
                                onClick={() => swapElements(index, index + 1)}
                                style={{ cursor: "pointer", color: "#ef5b0c" }}
                              >
                                <AiOutlineArrowDown />
                              </div>
                            )}

                            {index > 0 && (
                              <div
                                onClick={() => swapElements(index, index - 1)}
                                style={{ cursor: "pointer", color: "#ef5b0c" }}
                              >
                                <AiOutlineArrowUp />
                              </div>
                            )}
                          </div> */}
                        </div>
                      )}
                    </>
                  );
                })}
              {/* {url &&
                url.map((item, index) => (
                  <>
                    {item.id && (
                      <div
                        className="col-md-4 mb-3"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "180px",
                            borderRadius: "8px",
                            objectFit: "gallery",
                          }}
                          src={item.value}
                          alt=""
                        />
                      
                      </div>
                    )}
                  </>
                ))} */}

              {/* <div className="ms-2 mt-2" style={{ color: "#979FB8" }}>
               
                <p style={{ fontSize: "12px", marginTop: "-10px" }}>
                  {size}
                </p>
              </div> */}
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </div>
    </div>
  );
};

export default BrowseFile;
