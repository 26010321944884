import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  GetReviewlInput,
  SetReviewStatusFalse,
  SubmitReviewldData,
} from "../../review/_redux/action/ReviewAction";
import { review_status } from "../../Common/Dropdown";
import { BASE_URL } from "../../../Const";

const AddReportReview = (props) => {
 
  const dispatch = useDispatch();
  const reviewInput = useSelector((state) => state.reviewInfo.reviewInput);
  const afterReviewData = useSelector(
    (state) => state.reviewInfo.afterReviewData
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  let  api = `${BASE_URL}api/v1/users/admin/dashboard/client/`
  const handleChangeInput = (name, value) => {
    dispatch(GetReviewlInput(name, value));
    if (userData.client_id) {
      console.log(userData.client_id)
      dispatch(GetReviewlInput("status", 1));
    }
  };
  const SubmitRepoprt = (data) => {
    dispatch(SubmitReviewldData(data,api));
  };

  useEffect(() => {
    if (userData.client_id) {
      dispatch(GetReviewlInput("status", 1));
    }
  }, []);
  useEffect(() => {
    if (afterReviewData) {
      props.onHide();
      localStorage.removeItem("Advertise_id");
    }
    dispatch(SetReviewStatusFalse());
  }, [afterReviewData]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="mx-2 mx-md-3 mx-lg-4">
        <div>
          <h4 className="text-center" style={{ color: "#515151" }}>
            Add Report
          </h4>
        </div>
        {/* {!userData.client_id && (
          <div className="inputs-modal my-3">
            <p>Status</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="status"
                value={reviewInput.status}
                onChange={(e) => handleChangeInput("status", e.target.value)}
              >
                <option value="">--</option>
                {review_status?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
          
            </div>
          </div>
        )} */}

        <div>
          <div className="inputs-modal my-3">
            <p>Description</p>
            <textarea
              rows={4}
              type="text"
              name="report_text"
              value={reviewInput.report_text}
              onChange={(e) => handleChangeInput("report_text", e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="mx-2 mx-md-3 mx-lg-4">
        <Button
          className="save-btn "
          style={{ color: "white", background: "#EF5B0C" }}
          onClick={() => SubmitRepoprt(reviewInput)}
          // {props.onHide}
        >
          {" "}
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddReportReview;
