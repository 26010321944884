import React from "react";
import ArrowSvg from "../../Common/ArrowSvg";
import SingleBrowseFile from "../SingleBrowseFile";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Select, { components } from "react-select";
import { useState, useEffect } from "react";
import FoodCategory from "./FoodCategory";
import {
  GetClientOfferInput,
  SetClientOfferStatusFalse,
  SubmitClientOfferdData,
  UpdateClientofferdData,
} from "./_redux/action/ClientOfferAction";
import { GetClientCategoryList } from "../category/_redux/action/ClientCategoryAction";
import { GetClientList } from "../all_clients/_redux/action/ClientAction";
import { SubmitImage } from "../../Seetings/_redux/action/SeetingsAction";
import { GetClientProductList } from "../products/_redux/action/ClientProductAction";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../Const";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(true);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        className="me-2"
        checked={isSelected}
        style={{ color: "greenyellow", background: "orange" }}
      />
      {children}
    </components.Option>
  );
};



const AddClientOffer2 = () => {
  const history =useHistory()
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [foodcatgery, setFoodcatgery] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const ClientProductList = useSelector(
    (state) => state.clientProductInfo.ClientProductList
  );
  const clientOfferInput = useSelector(
    (state) => state.clientOfferInfo.clientOfferInput
  );
  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );
  const afterClientOfferData = useSelector(
    (state) => state.clientOfferInfo.afterClientOfferData
  );
  const client_List = useSelector((state) => state.clientInfo.clientList);
  useEffect(() => {
    if (afterClientOfferData) {
      history.push(`/manage_offer`)
      dispatch(SetClientOfferStatusFalse());
    }
  }, [afterClientOfferData]);
  const [modalShow, setModalShow] = useState(false);
  const handleChangeInput = (name, value) => {
    dispatch(GetClientOfferInput(name, value));
    dispatch(GetClientOfferInput("category", foodcatgery));
    dispatch(GetClientOfferInput("product", selectedValue));
  };
  const handleaddSubmit = (data) => {
    dispatch(SubmitClientOfferdData(data));
  };
  const handleUpdateSubmit = (data) => {
    dispatch(UpdateClientofferdData(data));
  };
  const handleChangeBranch = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
    dispatch(GetClientOfferInput("product", selectedValue));
  };
  const handleChangefoodCategory = (e) => {
    setFoodcatgery(Array.isArray(e) ? e.map((x) => x.id) : []);
    dispatch(GetClientOfferInput("category", foodcatgery));
  };
  useEffect(() => {
    dispatch(GetClientList(`${BASE_URL}api/v1/restaurant/admin/clients/`));
    dispatch(GetClientCategoryList(`${BASE_URL}api/v1/inventory/admin/category/`));
    dispatch(GetClientProductList(`${BASE_URL}api/v1/inventory/admin/product/`));
  }, []);

  return (
    <div className="parent">
      <div className="main-div">
        <h4 className="my-3" style={{ fontSize: "22px", color: "#2D2D2D" }}>
          Offer Request
        </h4>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Offer name</p>
            <input
              type="text"
              placeholder="Menubook BD"
              name="name"
              value={clientOfferInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
        </div>
        <div className="inputs-modal food-cat f2 col-11 col-md-6">
            <p>Select terget client (Restaurent or services)</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="client"
                value={clientOfferInput.client}
                onChange={(e) => handleChangeInput("client", e.target.value)}
              >
                {client_List?.results?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.company_name}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        <div className="row " style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 food-cat f2">
            <p>Select Category and food:</p>
            <div style={{ position: "relative" }}>
              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
              
                onSelect={handleChangefoodCategory}
                className="my-2"
                options={ClientCategoryList?.results}
                showCheckbox
                placeholder="Select"
                closeIcon={true}
                // onChange={}
              />
              <div className="arrow-icon" style={{ marginTop: "-5px" }}>
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Select Category and food:</p>
            <div style={{ position: "relative" }}>
              <div onClick={() => setModalShow(true)}>
                <input type="text" placeholder="food" />
              </div>
              <div className="arrow-icon" style={{ rotate: "270deg" }}>
                <ArrowSvg />
              </div>
            </div>
           
          </div>
        </div>
        <FoodCategory show={modalShow} onHide={() => setModalShow(false)} /> */}

        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Discount Type:</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="discount_type"
                value={clientOfferInput.discount_type}
                onChange={(e) =>
                  handleChangeInput("discount_type", e.target.value)
                }
              >
                <option selected value={0}>
                  Percentage
                </option>
                <option value={1}>Flat</option>
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>

        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Amount (%)</p>
            <input
              type="text"
              placeholder="0"
              name="discount_value"
              value={clientOfferInput.discount_value}
              onChange={(e) =>
                handleChangeInput("discount_value", e.target.value)
              }
            />
          </div>
        </div>

        <div className="row " style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 food-cat f2">
            <p>Add this product to multiple branches:</p>
            <div style={{ position: "relative" }}>
              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
               
                onSelect={handleChangeBranch}
                className="my-2"
                options={ClientProductList?.results}
                showCheckbox
                placeholder="Select"
                closeIcon={true}
                // onChange={}
              />
              <div className="arrow-icon" style={{ marginTop: "-5px" }}>
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head2">
            <p>Add this product to multiple branches:</p>
            <Select
              defaultValue={[]}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setSelectedOptions(options.map((opt) => opt.value));
                }
              }}
              options={allOptions}
              components={{
                Option: InputOption,
              }}
            />
            
          </div>
        </div> */}
        <div className="row input-title" style={{ marginTop: "20px" }}>
          <div className="col-11 col-md-6 input-head">
            <div className="mt-4 row  justify-content-between  time-notification">
              <div className="col-md-5 col-12">
                <span style={{ fontSize: "15px", color: "#515151" }}>
                  Starting time:
                </span>
                <div className=" time-to-date w-100 px-2 mt-4">
                  {/* <input
                    type="datetime-local"
                    name="start_date"
                    value={clientOfferInput.start_date}
                    onChange={(e) =>
                      handleChangeInput("start_date", e.target.value)
                    }
                  />
                  <div className="divider">
                    <div></div>
                  </div> */}
                  <input
                    type="datetime-local"
                    id=""
                    name="start_date"
                    value={clientOfferInput.start_date}
                    onChange={(e) =>
                      handleChangeInput("start_date", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="col-md-5 col-12 ">
                <span style={{ fontSize: "15px", color: "#515151" }}>
                  Ending time:
                </span>
                <div className=" time-to-date w-100 px-2 mt-4">
                  {/* <input
                    type="time"
                    name="expiry_date"
                    value={clientOfferInput.expiry_date}
                    onChange={(e) =>
                      handleChangeInput("expiry_date", e.target.value)
                    }
                  /> */}
                  {/* <div className="divider">
                    <div></div>
                  </div> */}
                  <input
                    type="datetime-local"
                    id=""
                    name="expiry_date"
                    value={clientOfferInput.expiry_date}
                    onChange={(e) =>
                      handleChangeInput("expiry_date", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SingleBrowseFile
          title={"Set Banner Image:"}
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "offer_banner"));
            handleChangeInput("menuName1", e.target.files[0].name);
            handleChangeInput("size1", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              handleChangeInput("menu_url", e.target.result);
            };
          }}
          name="menu"
          url={clientOfferInput?.menu_url}
          file_name={clientOfferInput.menuName1}
          size={clientOfferInput.size1 / 1000}
          // image_url={clientOfferInput.menu[0]?.document}
        />

        <div className="mt-4">
          <button
            className="orange-btn"
            onClick={() =>{
              clientOfferInput.id ? handleUpdateSubmit(clientOfferInput) :
               handleaddSubmit(clientOfferInput)}}
          >
            Publish Offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClientOffer2;
