/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Form, Input, message, Select } from "antd";

import { useDispatch, useSelector } from "react-redux";

import CheckBox from "./Checkbox";
import Map from "./Map";
import Spin from "./Spinner";

import API from "./Api";
import {
  getDataManager,
  handleGaForAddingShippingAdress,
} from "./HelperFunction";

import "./styles.css";
import { GetNotificationInput } from "../../_redux/action/NotificationAction";

const { TextArea } = Input;
const { Option } = Select;

const NotificationAddres = ({
  setActiveKey,
  shippingAddressList,
  fetchShippingAddressList,
  handleShippingAddressSelect,
  checkoutData,
}) => {
  const address = new API.Address();

  // const { userData } = useSelector((state) => {
  //   return state?.app;
  // });

  const [showShipForm, setShowShipForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [shippingDestination, setShippingDestination] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState("");
  const [timer, setTimer] = useState();
  const [townsList, setTownsList] = useState([]);

  useEffect(() => {
    fetchSellerLocation();
    fetchTownsList();
  }, []);

  const fetchTownsList = async (value) => {
    setLoader("area");
    getDataManager(address?.getTownsList, null).then((x) => {
      if (x?.status === "success") {
        setTownsList(x?.data?.results);
      }
      // else {
      //   message.error({ content: 'Process failed', duration: 3 });
      // }
    });
  };

  const handleEdit = (address) => {
    setSelectedAddress(address);
    setShowShipForm(true);
  };

  const fetchSellerLocation = async (value) => {
    setLoader("area");
    getDataManager(address?.shippingDestinationList, null, {
      name: value,
    }).then((x) => {
      if (x?.status === "success") {
        const locations = x?.data?.results.map((l) => ({
          ...l,
          name: l?.city_name,
        }));
        setShippingDestination(locations);
        setLoader("");
      }
      // else {
      //   setLoader('');
      //   message.error({ content: 'Process failed', duration: 3 });
      // }
    });
  };

  const handleLocationSearch = (value) => {};

  const addShippinAddress = (payload) => {};

  const handleCancel = () => {};

  return (
    <>
      <section className="shipping-address-section">
        {loading && <Spin title="Saving..." />}

        <AddressForm
          selectedAddress={selectedAddress}
          onCancel={handleCancel}
          addShippinAddress={addShippinAddress}
          // editShippingAddress={editShippingAddress}
          shippingDestination={shippingDestination}
          // userData={userData}
          loader={loader}
          townsList={townsList}
          handleLocationSearch={handleLocationSearch}
        />
      </section>
    </>
  );
};

export default NotificationAddres;

const AddressCard = ({
  address,

  handleShippingAddressSelect,
  checkoutData,
}) => {
  return (
    <Card className="address-card">
      <div className="upper">
        {handleShippingAddressSelect && (
          <CheckBox
            checked={checkoutData?.shipping_id === address?.id}
            onChange={(e) =>
              handleShippingAddressSelect &&
              handleShippingAddressSelect(e.target.checked, address?.id)
            }
          />
        )}
      </div>
    </Card>
  );
};

const AddressForm = ({
  selectedAddress,
  onCancel,
  shippingDestination,
  editShippingAddress,
  addShippinAddress,
  // userData,
  loader,
  townsList,
  handleLocationSearch,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const notificationInput = useSelector(
    (state) => state.notificationInfo.notificationInput
  );
  const isEdit = !!selectedAddress;

  const [location, setLocation] = useState({});
  const [placeObject, setPlaceObject] = useState({});

  const onPlaceSelected = (place) => {
    const lat = place?.geometry?.location?.lat();
    const lon = place?.geometry?.location?.lng();
    setLocation({ lat, lon });
    setPlaceObject(place);
    dispatch(GetNotificationInput("area_latitude", location.lat));
    dispatch(GetNotificationInput("area_longitude", location.lon));
    dispatch(GetNotificationInput("area_name", location.name));
  };
  console.log("location", location, placeObject.name);
  notificationInput.area_latitude = location.lat;
  notificationInput.area_longitude = location.lon;
  notificationInput.area_name = placeObject.name;

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({ first_name: selectedAddress?.first_name });
      form.setFieldsValue({ last_name: selectedAddress?.last_name });
      form.setFieldsValue({ address: selectedAddress?.address });
      form.setFieldsValue({ area_name: selectedAddress?.area_name });
      form.setFieldsValue({ details: selectedAddress?.details });
      form.setFieldsValue({
        shipping_destination: selectedAddress?.shipping_destination,
      });
      setLocation({
        lat: selectedAddress?.latitude,
        lon: selectedAddress?.longitude,
      });
      setPlaceObject(JSON.parse(selectedAddress?.place_data));
    }
  }, [selectedAddress]);

  const onSubmit = () => {
    const values = form.getFieldsValue(true);
    if (location?.lon && location?.lat) {
      if (isEdit) {
        editShippingAddress({
          ...selectedAddress,
          ...values,
          // customer: userData?.id,
          latitude: location?.lat,
          longitude: location?.lon,
          place_data: JSON.stringify(placeObject),
        });
      } else {
        addShippinAddress({
          ...values,
          // customer: userData?.id,
          latitude: location?.lat,
          longitude: location?.lon,
          place_data: JSON.stringify(placeObject),
        });
      }
    } else {
      message.error({
        content: "Longitude and latitude are required",
        duration: 3,
      });
    }
  };

  return (
    <Form form={form} onFinish={onSubmit}>
      <Map
        onPlaceSelected={onPlaceSelected}
        lat={Number(location?.lat)}
        lon={Number(location?.lon)}
        placeObject={placeObject}
      />
    </Form>
  );
};
