import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import Checkbox from "@mui/material/Checkbox";
import UserPagination from "../../../Common/UserPagination";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RowMenu } from "../../../Common/RowMenu";
import { BsImage } from "react-icons/bs";

import "./advertisement.css";
// import { buttonBaseClasses } from "@mui/material";
// import { GetNewAddList } from "../../new_add/_redux/action/NewAddAction";
import { placement_list, status_list } from "../../../Common/Dropdown";
import { GetClientList } from "../../../Clients/all_clients/_redux/action/ClientAction";
import {
  GetAdvertisementdelete,
  GetAdvertisementList,
  NextAddListList,
  PreviousAddList,
  SetAdvertisementUpdatedData,
} from "../../../Clients/ClientAdvertisement/_redux/action/AddvertisementAction";

import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { BASE_URL } from "../../../../Const";
import Lottie from "react-lottie";
import noData from "../../../../assets/jsonFile/noData2.json";
import AddTotalAdvertisement from "../../../Clients/ClientAdvertisement/AddTotalAdvertisement";
const AdvertisementList = ({ search, selectedName }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const api = `${BASE_URL}api/v1/promotions/admin/advertisement/?limit=20`;
  const [modalShow, setModalShow] = React.useState(false);
  const client_List = useSelector((state) => state.clientInfo.clientList);
  const addvertisementList = useSelector(
    (state) => state.addvertisementInfo.addvertisementList
  );
  const isLoadAdd = useSelector((state) => state.addvertisementInfo.isLoadAdd);
  const [selected, setSelected] = React.useState([]);
  const options = ["None", "Atria", "Callisto"];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    setUsers(addvertisementList?.results);
    dispatch(GetAdvertisementList(api));

    dispatch(GetClientList(`${BASE_URL}api/v1/restaurant/admin/clients/`));
  }, []);

  React.useEffect(() => {
    if (selectedName) {
      dispatch(
        GetAdvertisementList(
          `${BASE_URL}api/v1/promotions/admin/advertisement/?status=${selectedName}&&limit=20`
        )
      );
    } else {
      dispatch(
        GetAdvertisementList(
          `${BASE_URL}api/v1/promotions/admin/advertisement/?limit=20`
        )
      );
    }
  }, [selectedName]);

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  const handleeEdit = (id) => {
    setModalShow(true);
    dispatch(SetAdvertisementUpdatedData(id));
    localStorage.setItem("Advertise_id", id);
    setAnchorEl(null);
  };
  const Advertise_id = localStorage.getItem("Advertise_id");
  const handleDelete = (id) => {
    dispatch(GetAdvertisementdelete(id, api));
    // handleClose()
    setAnchorEl(null);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  ////////// -rederect login page--------------

  React.useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextAddListList(addvertisementList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousAddList(addvertisementList?.previous));
  };

  return (
    <div>
      {isLoadAdd && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadAdd &&
        addvertisementList?.results &&
        addvertisementList?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}
      {!isLoadAdd &&
        addvertisementList?.results &&
        addvertisementList?.results !== null &&
        addvertisementList?.results?.length > 0 && (
          <TableContainer component={Paper} className="text-capitalize">
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "white" }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      // className="form-check-input"
                      name="allSelect"
                      checked={selectedUser?.length === users?.length}
                      onChange={(e) => handleChange(e, users)}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "24px" }}>
                    <BsImage />
                  </TableCell>
                  <TableCell align="left">Client Name</TableCell>
                  <TableCell align="left">Name</TableCell>
                  {/* <TableCell align="left">View</TableCell> */}
                  <TableCell align="left">Click</TableCell>
                  <TableCell align="center">Placement </TableCell>
                  <TableCell align="center">Status</TableCell>
                  {/* <TableCell align="left">View</TableCell> */}
                  <TableCell align="left">
                    {" "}
                    <IconButton aria-label="more">
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "white" }}>
                {addvertisementList?.results
                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val?.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }

                    // else if (
                    //   val?.location.toLowerCase().includes(search.toLowerCase())
                    // ) {
                    //   return val;
                    // }
                  })
                  .map((row) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.name}
                            checked={selectedUser.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange(e, row)}
                          />
                        </TableCell>
                        <TableCell align="center" className="image-td">
                          <img src={row.banner_url} alt="" />
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          {client_List?.results?.map((item) => {
                            return (
                              item.id === row.ad_for && (
                                <h6>{item.company_name}</h6>
                              )
                            );
                          })}

                          <p>
                            {" "}
                            {new Date(row.created_at)
                              .toISOString()
                              .slice(0, 10)}
                          </p>
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          <h6>{row.name}</h6>
                          <p>{row.no_of_days}</p>
                        </TableCell>
                        {/* <TableCell align="left" className="">
                  <h6>{row.views}</h6>
                </TableCell> */}
                        <TableCell align="left">{row.clicks}</TableCell>
                        <TableCell align="center" className="placement_td">
                          {" "}
                          <select
                            name=""
                            id=""
                            className="me-2"
                            value={row.placement}
                          >
                            {placement_list?.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </TableCell>
                     
                          <TableCell align="center">
                            {row.status === 1 ? (
                              <button className="yellow-btn">
                                Unpublished
                              </button>
                            ) : row.status === 0 ? (
                              <button className="green-btn">Published</button>
                            ) : (
                              <button
                                className="green-btn"
                                style={{ background: "red" }}
                              >
                                Expired
                              </button>
                            )}
                      
                          {/* {row.status === "Draft" ? (
                    <button className="yellow-btn">Draft</button>
                  ) : (
                    <button className="green-btn">Published</button>
                  )} */}
                        </TableCell>

                        {/* <TableCell align="left" className="table_icon">
                  <span style={{ cursor: "pointer" }}>
                    <FaEye
                      className="me-2"
                      style={{ fontSize: "22px", color: "D8D8D8 " }}
                    />
                  </span>
                </TableCell> */}

                        <TableCell align="left">
                          <RowMenu
                            row={row}
                            handleeEdit={handleeEdit}
                            handleDelete={handleDelete}
                            // show={show}
                            // hide={hide}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {addvertisementList?.count}</p>
        <UserPagination
          prev_next={addvertisementList}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
      <AddTotalAdvertisement
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={Advertise_id}
      />
    </div>
  );
};

export default AdvertisementList;
