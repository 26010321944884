import React from "react";
import ArrowSvg from "../../Common/ArrowSvg";
import SingleBrowseFile from "../SingleBrowseFile";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Select, { components } from "react-select";
import { useState, useEffect } from "react";
import FoodCategory from "./FoodCategory";
import {
  GetClientOfferInput,
  SetClientOfferStatusFalse,
  SubmitClientOfferdData,
  UpdateClientofferdData,
} from "./_redux/action/ClientOfferAction";
import { GetClientCategoryList,GetClientCategoryOption } from "../category/_redux/action/ClientCategoryAction";
import { GetClientList } from "../all_clients/_redux/action/ClientAction";
import { SubmitImage } from "../../Seetings/_redux/action/SeetingsAction";
import { GetClientProductList,GetProductOption } from "../products/_redux/action/ClientProductAction";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../Const";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import OfferImage from "./OfferImage";

const AddClientOffer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = React.useState([]);
 
  const [foodcatgery, setFoodcatgery] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);
  const ClientProductList = useSelector(
    (state) => state.clientProductInfo.ClientProductList
  );
  const clientOfferInput = useSelector(
    (state) => state.clientOfferInfo.clientOfferInput
  );
  const CategoryOption = GetClientCategoryOption(
    useSelector(
      (state) => state.clientCategoryInfo?.ClientCategoryList?.results
    )
  );
  const productOption = GetProductOption(
    useSelector((state) => state.clientProductInfo.ClientProductList?.results)
  );
  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );
  const afterClientOfferData = useSelector(
    (state) => state.clientOfferInfo.afterClientOfferData
  );

  useEffect(() => {
    if (afterClientOfferData) {
      history.push(`/client-offer`);
      dispatch(SetClientOfferStatusFalse());
    }
  }, [afterClientOfferData]);
  const [modalShow, setModalShow] = useState(false);
  const handleChangeInput = (name, value) => {
    dispatch(GetClientOfferInput(name, value));
    // dispatch(GetClientOfferInput("category", foodcatgery));
    dispatch(GetClientOfferInput("product", selectedValue));
    if(name === "expiry_date_2"){
      dispatch(GetClientOfferInput("expiry_date", value));
    }
    if(name === "start_date_2"){
      dispatch(GetClientOfferInput("start_date", value));
    }
  };
  const handleaddSubmit = (data) => {
    dispatch(SubmitClientOfferdData(data));
  };
  const handleUpdateSubmit = (data) => {
    dispatch(UpdateClientofferdData(data));
  };
  const handleChangeBranch = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
    dispatch(GetClientOfferInput("product", selectedValue));
  };
  
  useEffect(() => {
    dispatch(GetClientList(`${BASE_URL}api/v1/restaurant/admin/clients/`));
    dispatch(
      GetClientCategoryList(`${BASE_URL}api/v1/inventory/admin/category/?branches=${userData.client_id}`)
    );
    dispatch(
      GetClientProductList(`${BASE_URL}api/v1/inventory/admin/product/?client=${userData.client_id}`)
    );
  }, []);

  useEffect(() => {
    dispatch(
      GetClientProductList(
        // `${BASE_URL}api/v1/inventory/admin/product/?client=${client_id}`
        `${BASE_URL}api/v1/inventory/admin/product/?client=${userData.client_id}&except_category=${clientOfferInput.category}`
      )
    );
  }, [clientOfferInput.category]);


   // ---select multiple product-------------
   const [products, setProducts] = React.useState([]);
   const handleChangeProduct = (name, value) => {
    
    if (name === "product") {
      value = Array.from(value, (option) => option.id);
      // setRestaurant_types(value);
    }

    dispatch(GetClientOfferInput(name, value));
  };
  useEffect(() => {
    const productValue = productOption.filter(({ id }) => {
      return clientOfferInput?.product.find((id1) => id1 === id) !== undefined;
    });

    setProducts((branch) => {
      return productValue;
    });
  }, [clientOfferInput.product]);

  // ---select multiple category-------------

  const [category, setCategory] = React.useState([]);
  const handleChangeCategory = (name, value) => {
    if (name === "category") {
      value = Array.from(value, (option) => option.id);
      // setRestaurant_types(value);
    }

    dispatch(GetClientOfferInput(name, value));
  };
  useEffect(() => {
    const categoryValue = CategoryOption?.filter(({ id }) => {
      return clientOfferInput?.category.find((id1) => id1 === id) !== undefined;
    });

    setCategory((branch) => {
      return categoryValue;
    });
  }, [clientOfferInput.category]);
  return (
    <div className="parent">
      {isLoadImage && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <h4 className="my-3" style={{ fontSize: "22px", color: "#2D2D2D" }}>
          Offer Request
        </h4>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Offer name</p>
            <input
              type="text"
              placeholder="Menubook BD"
              name="name"
              value={clientOfferInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
        </div>
        <div className="row " style={{ marginTop: "25px" }}>
          <div className="row input-title" style={{ marginTop: "25px" }}>
            <div className="col-11 col-md-6 input-head2">
              <p>Add Category:</p>
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={CategoryOption}
                className=" mb-3"
                name="category"
                value={category}
                onChange={(e) => handleChangeCategory("category", e)}
              />
            </div>
          </div>
        </div>
        <div className="row " style={{ marginTop: "25px" }}>
          <div className="row input-title" style={{ marginTop: "25px" }}>
            <div className="col-11 col-md-6 input-head2">
              <p>Add Products:</p>
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={productOption}
                className=" mb-3"
                name="product"
                value={products}
                onChange={(e) => handleChangeProduct("product", e)}
              />
            </div>
          </div>
        </div>
        {/* <div className="row " style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 food-cat f2">
     
            <p>Select Category and food:</p>
            <div style={{ position: "relative" }}>
              <Multiselect
                displayValue="name"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={handleChangefoodCategory}
                className="my-2"
                options={ClientCategoryList?.results}
                showCheckbox
                placeholder="Select"
                closeIcon={true}
                // onChange={}
              />
              <div className="arrow-icon" style={{ marginTop: "-5px" }}>
                <ArrowSvg />
              </div>
            </div>
           
          </div>
        </div> */}

        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Discount Type:</p>
            <div style={{ position: "relative" }}>
              <select
              
                id="inputState"
                className="form-select "
                name="discount_type"
                value={clientOfferInput.discount_type}
                onChange={(e) =>
                  handleChangeInput("discount_type", e.target.value)
                }
              >
                <option selected value={''}>
                --
                </option>
                <option selected value={0}>
                  Percentage
                </option>
                <option value={1}>Flat</option>
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>

        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Amount {clientOfferInput.discount_type === 0 && "(%)"}</p>
            <input
              type="number"
              placeholder="0.00"
              name="discount_value"
              value={clientOfferInput.discount_value}
              onChange={(e) =>
                handleChangeInput("discount_value", e.target.value)
              }
            />
          </div>
        </div>

        {/* <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head2">
            <p>Add this product to multiple branches:</p>
            <Select
              defaultValue={[]}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setSelectedOptions(options.map((opt) => opt.value));
                }
              }}
              options={allOptions}
              components={{
                Option: InputOption,
              }}
            />
            
          </div>
        </div> */}
        <div className="row input-title" style={{ marginTop: "20px" }}>
          <div className="col-11 col-md-6 input-head">
            <div className="mt-4 row  justify-content-between  time-notification">
              <div className="col-md-5 col-12">
                <span style={{ fontSize: "15px", color: "#515151" }}>
                  Starting time:
                </span>
                <div className=" time-to-date w-100 px-2 mt-4">
                  {/* <input
                    type="datetime-local"
                    name="start_date"
                    value={clientOfferInput.start_date}
                    onChange={(e) =>
                      handleChangeInput("start_date", e.target.value)
                    }
                  />
                  <div className="divider">
                    <div></div>
                  </div> */}
                  <input
                    type="datetime-local"
                    id=""
                    name="start_date_2"
                    value={clientOfferInput.start_date_2}
                    onChange={(e) =>
                      handleChangeInput("start_date_2", e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="col-md-5 col-12 ">
                <span style={{ fontSize: "15px", color: "#515151" }}>
                  Ending time:
                </span>
                <div className=" time-to-date w-100 px-2 mt-4">
                  {/* <input
                    type="time"
                    name="expiry_date"
                    value={clientOfferInput.expiry_date}
                    onChange={(e) =>
                      handleChangeInput("expiry_date", e.target.value)
                    }
                  /> */}
                  {/* <div className="divider">
                    <div></div>
                  </div> */}
                  <input
                    type="datetime-local"
                    id=""
                    name="expiry_date_2"
                    value={clientOfferInput.expiry_date_2}
                    onChange={(e) =>
                      handleChangeInput("expiry_date_2", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <OfferImage
          title={"Set Banner Image:"}
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "offer_banner"));
            handleChangeInput("menuName1", e.target.files[0].name);
            handleChangeInput("size1", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              handleChangeInput("menu_image", e.target.result);
            
            };
          }}
          name="menu"
          url={clientOfferInput?.menu_image}
          file_name={clientOfferInput.menuName1}
          size={clientOfferInput.size1 / 1000}
          // image_url={clientOfferInput.menu[0]?.document}
        />

        <div className="mt-4">
          <button
            className="orange-btn"
            onClick={() => {
              clientOfferInput.id
                ? handleUpdateSubmit(clientOfferInput)
                : handleaddSubmit(clientOfferInput);
            }}
          >
            Publish Offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClientOffer;
