
import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../Const";
import swal from "sweetalert";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

export const GetClientProductList = (api) => (dispatch) => {
    const url = api
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_CLIENT_PRODUCT_LIST,
            payload: res.data.data,
          });
  
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
    }
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
  };

  export const NextProductListList = (nextUrl) => (dispatch) => {
    const url = nextUrl;
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_CLIENT_PRODUCT_LIST,
            payload: res.data.data,
          });
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
    }
  };

  
  export const PreviousProductList = (prevUrl) => (dispatch) => {
    const url = prevUrl;
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_CLIENT_PRODUCT_LIST,
            payload: res.data.data,
          });
  
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
    }
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
  };
  
 
 
  export const GetClientProductInput = (name, value) => (dispatch) => {
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.GET_CLIENT_PRODUCT_INPUT, payload: formData });
    // dispatch({ type: Types.ADD_NEW_DOC, payload: {name:'restaurant_types', value:, } });
  };
  export const SetClientProductStatusFalse = () => (dispatch) => {
    dispatch({ type: Types.SET_CLIENT_PRODUCT_FALSE, payload: false });
  };
  export const SubmitClientProductdData = (data) => async (dispatch) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    data.client =userData.client_id
    data.desc = draftToHtml(
      convertToRaw(data?.desc?.getCurrentContent())
    );

    if (data.name === "") {
      showToast("error", "Name shouldn't be empty !");
      return 0;
    } 
    else if (data.category === "") {
      showToast("error", "Category shouldn't be empty !");
      return 0;
    } 
    // else if (data.desc === "") {
    //   showToast("error", "Product desc shouldn't be empty !");
    //   return 0;
    // }
    else if (data.regular_price === "") {
      showToast("error", "Regular price shouldn't be empty !");
      return 0;
    } 
    else if (data.thumb === null) {
      showToast("error", "Product image shouldn't be empty !");
      return 0;
    } else if (data.vat_type === "") {
      showToast("error", "Vat type shouldn't be empty !");
      return 0;
    }
  
    const url = `${BASE_URL}api/v1/inventory/admin/product/`;
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
  
    try {
      await Axios.post(url, data)
        .then((res) => {
          if (res.data.status) {
            dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
            showToast("success", "Product added successfully");
  
            dispatch({ type: Types.AFTER_CLIENT_PRODUCT_DATA, payload: true });
          } else {
            dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
          }
        })
        .catch((err) => {
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
  
          const erroeMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(erroeMsg)) {
            // showToast("error", value[0]);
            swal(value[0], {
              icon: "error",
            });
          }
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
          swal(erroeMsg, {
            icon: "error",
          });
        });
    } catch (error) {}
  };


//   export const GetClientDetails = (id) => (dispatch) => {
//     const url = `${BASE_URL}api/v1/inventory/admin/product/${id}/`;
//     try {
//       Axios.get(url).then((res) => {
//         if (res.data.status) {
//           dispatch({
//             type: Types.GET_CLIENT_DETAILS,
//             payload: res.data.data,
//           });
//         }
//       });
//     } catch (error) {}
//   };

  export const SetClientproductUpdatedData = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/inventory/admin/product/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_CLIENT_PRODUCT_UODATED,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };

  export const UpdateClientProductdData = (data) => async (dispatch) => {
       const userData = JSON.parse(localStorage.getItem("userData"));

    data.client =userData.client_id
    data.desc = draftToHtml(
      convertToRaw(data?.desc?.getCurrentContent())
    );

    if (data.name === "") {
      showToast("error", "Name shouldn't be empty !");
      return 0;
    } 
    else if (data.category === "") {
      showToast("error", "Category shouldn't be empty !");
      return 0;
    } 
   
    else if (data.regular_price === "") {
      showToast("error", "Regular price shouldn't be empty !");
      return 0;
    } 
    else if (data.thumb === null) {
      showToast("error", "Product image shouldn't be empty !");
      return 0;
    } else if (data.vat_type === "") {
      showToast("error", "Vat type shouldn't be empty !");
      return 0;
    }
  
    const url = `${BASE_URL}api/v1/inventory/admin/product/${data.id}/`;
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
  
    try {
      await Axios.patch(url, data)
        .then((res) => {
          if (res.data.status) {
            dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
            showToast("success", "Product update successfully");
  
            dispatch({ type: Types.AFTER_CLIENT_PRODUCT_DATA, payload: true });
          } else {
            dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
          }
        })
        .catch((err) => {
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
  
          const erroeMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(erroeMsg)) {
            // showToast("error", value[0]);
            swal(value[0], {
              icon: "error",
            });
          }
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
          swal(erroeMsg, {
            icon: "error",
          });
        });
    } catch (error) {}
  };
  export const UpdateProductTodaysSpecialdData = (data, id) => async (dispatch) => {
       const userData = JSON.parse(localStorage.getItem("userData"));

    data.client =userData.client_id
    // if (data.name === "") {
    //   showToast("error", "Name shouldn't be empty !");
    //   return 0;
    // } else if (data.address === "") {
    //   showToast("error", "Adress shouldn't be empty !");
    //   return 0;
    // } else if (data.email === "") {
    //   showToast("error", "Email shouldn't be empty !");
    //   return 0;
    // }
  
    const url = `${BASE_URL}api/v1/inventory/admin/product/${id}/`;
    dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: true });
  
    try {
      await Axios.patch(url, data)
        .then((res) => {
          if (res.data.status) {
            dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
            showToast("success", "Product update successfully");
  
            dispatch({ type: Types.AFTER_CLIENT_PRODUCT_DATA, payload: true });
          } else {
            dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
          }
        })
        .catch((err) => {
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
  
          const erroeMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(erroeMsg)) {
            // showToast("error", value[0]);
            swal(value[0], {
              icon: "error",
            });
          }
          dispatch({ type: Types.IS_LOAD_CLIENT_PRODUCT, payload: false });
          swal(erroeMsg, {
            icon: "error",
          });
        });
    } catch (error) {}
  };


  export const GetClientProductdelete = (id, api) => (dispatch) => {
    const url = `${BASE_URL}api/v1/inventory/admin/product/${id}/`;
  
    try {
      Axios.delete(url).then((res) => {
        if (res.data.status) {
          showToast("error", res.data.message);
          dispatch({ type: Types.IS_CLIENT_PRODUCT_DELETED, payload: true });
          dispatch(GetClientProductList(api))
        }
      });
    } catch (error) {}
  };
  export const SetFalseObjectDelete = () => (dispatch) => {
    dispatch({ type: Types.IS_CLIENT_PRODUCT_DELETED, payload: false });
  };
  export const GetProductOption = (data) => {
    console.log(data,'data')
    const option = [];
    if (data) {
      data.forEach((item) => {
        const obj = {
          label: item.name,
          value: item.id,
          id: item.id,
        };
        //  item.id
        option.push(obj);
      });
    }
    return option;
  };