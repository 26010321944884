import React from 'react'
import PushNotification from '../component/PushNotification'

const AddMenuBookNotification = () => {
  return (
    <div>
      <PushNotification/>
    </div>
  )
}

export default AddMenuBookNotification