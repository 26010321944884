import { Switch } from "@material-ui/core";
import {
  FacebookLogo,
  InstagramLogo,
  ShareNetwork,
  WhatsappLogo,
} from "phosphor-react";
import React from "react";
import {  useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";


import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import BrowsePhotosProfile from "../../BrowsePhotosProfile";

import { useSelector, useDispatch } from "react-redux";

import { GetClientDetails, SetClientUpdatedData } from "../_redux/action/ClientAction";
import { GetCountryList } from "../../../User/component/appUser/_redux/action/AppUserAction";
const ClientProfile = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const clientDetails = useSelector(
    (state) => state.clientInfo.clientDetails
  );
  const isLoadclient = useSelector((state) => state.appUserInfo.isLoadclient);
  const countryList = useSelector((state) => state.appUserInfo.countryList);
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
    dispatch(GetClientDetails(userData?.client_id));
    dispatch(GetCountryList());
  }, []);

  const handleEditUser = () => {
    dispatch(SetClientUpdatedData(userData?.client_id));
    history.push(`/edit_clients/${userData?.client_id}`);
  };

  const PhotoItem = ({ image, thumb, group }) => (
    <div
      style={{
        maxWidth: "250px",
        width: "200px",
        padding: "5px",
        // border: "1px solid #34425a ",
        borderRadius: "8px",
        margin: "8px",
        boxShadow:"rgb(22 17 17 / 20%) 0px 4px 8px 0px"
      }}
    >
      <LightgalleryItem group={group} src={image} thumb={thumb}>
        <img
          src={image}
          alt="menubook"
          style={{
            width: "100%",
            cursor: "pointer",
            height: "250px",
            objectFit: "cover",
            borderRadius: "8px",
            
          }}
        />
      </LightgalleryItem>
    </div>
  );
  return (
    <div className="parent min-vh-100">
      {isLoadclient && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>Profile</span>
        </div>

        <div className=" border bg-white mt-4">
          <div className="px-4 py-3 row justify-content-between align-items-center">
            <div className="col-md-4 d-flex align-items-center">
              <img
                // src="https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png"
                src={clientDetails?.logo_image}
                width={50}
                height={50}
                className="rounded-circle"
                alt=""
              />
              <h5
                className="ms-2 ms-md-4"
                style={{ fontSize: "22px", color: "#000000" }}
              >
               {clientDetails?.company_name}
              </h5>
            </div>
            <div className="col-md-3 d-flex justify-content-end">
             
              <span
                style={{ cursor: "pointer", color: "#03438C" }}
                onClick={() => handleEditUser()}
              >
                <FaEdit
                  color="#03438C"
                  className="me-2"
                  style={{ fontSize: "22px" }}
                />
                Edit
              </span>
            </div>
          </div>
          <div className="px-4 py-3 row">
            <div className="col-12 my-3">
              <h6 color="#000000" style={{ fontSize: "20px" }}>
                GENERALS
              </h6>
            </div>
            <div className="col-12 col-md-9 row justify-content-between">
              <div
                className="col-12 col-md-6 col-lg-4"
                style={{ color: "#BBBBBB" }}
              >
                <p>Company Name:</p>
                <p style={{ marginTop: "-7px" }}>{clientDetails?.company_name}</p>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}>Company Address:</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                  {clientDetails?.location} 
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}>Select Country</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                Bangladesh
                </p>
                {isLoadclient && countryList !== null && 
               
                {/* {countryList?.map((option) => {
                  return (
                    <>
                      {option.id === clientDetails?.country && (
                        <span key={option.id} value={option.id}>
                          {option.name}
                        </span>
                      )}
                    </>
                  );
                })} */}
              // </p>
                }
                
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}>Company category:</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                 {clientDetails?.company_category_name}
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}>Work Phone Number:</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                  {clientDetails?.phone}
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}>Email Address:</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                  {clientDetails?.email}
                </p>
              </div>
            </div>
            <div className="col-10 col-md-3">
              <p>About:</p>
              <p style={{ color: "#BBBBBB", fontSize: "13px" }}>
              {clientDetails?.about?.replace(/<[^>]+>/g, '')}
              </p>
            </div>
          </div>
          <hr />

          <>
            <div className="my-5">
              <div className="col-12 mt-3 ">
                <p
                  className="fw-bold ms-3"
                  style={{ color: "#515151", fontSize: "22px" }}
                >
                  Logo :
                </p>
              </div>
              <LightgalleryProvider
                style={{ height: "210px" }}
                className="client_img"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {/* {clientDetails?.cover_image.map((p) => ( */}
                  <PhotoItem
                    key={"%210"}
                    image={clientDetails?.logo_image}
                    group="group2"
                  />
                  {/* ))} */}
                </div>
              </LightgalleryProvider>
            </div>
            <div className="my-5">
              <div className="col-12 mt-3 ">
                <p
                  className="fw-bold ms-3"
                  style={{ color: "#515151", fontSize: "22px" }}
                >
                  Cover Photo :
                </p>
              </div>
              <LightgalleryProvider>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {clientDetails?.cover_image.map((p) => (
                    <PhotoItem
                      key={clientDetails.id}
                      image={p.document}
                      group="group2"
                    />
                  ))}
                </div>
              </LightgalleryProvider>
            </div>
            <div className="my-5">
              <div className="col-12 mt-3 ">
                <p
                  className="fw-bold ms-3"
                  style={{ color: "#515151", fontSize: "22px" }}
                >
                  Menu Photos :
                </p>
              </div>
              <LightgalleryProvider>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {clientDetails?.menu_image.map((p) => (
                    <PhotoItem
                      key={clientDetails.id}
                      image={p.document}
                      group="group2"
                    />
                  ))}
                </div>
              </LightgalleryProvider>
            </div>
            <div className="my-5">
              <div className="col-12 mt-3 ">
                <p
                  className="fw-bold ms-3"
                  style={{ color: "#515151", fontSize: "22px" }}
                >
                  Gallery Photos :
                </p>
              </div>
              <LightgalleryProvider>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  {clientDetails?.gallery_image.map((p) => (
                    <PhotoItem
                      key={clientDetails.id}
                      image={p.document}
                      group="group2"
                    />
                  ))}
                 
                </div>
              </LightgalleryProvider>
            </div>
          </>
          
            {/* <>
              <BrowsePhotosProfile title="Change or upload Cover Photos:" image_arr={clientDetails?.cover_image}/>
              <BrowsePhotosProfile title="Change or upload Menu Photos:" image_arr={clientDetails?.menu_image}/>
              <BrowsePhotosProfile title="Gallery"image_arr={clientDetails?.gallery_image} />
            </> */}
         
        </div>
       
          <div className="row mt-4">
            <div className="col-12 mt-3 ">
              <p
                className="fw-bold ms-3"
                style={{ color: "#515151", fontSize: "15px" }}
              >
                Your QR Code:
              </p>
            </div>
            <div className="col-12 mb-2">
              <img src={clientDetails?.qr_code} alt="" />
            </div>
            <div className="col-12 d-flex align-items-center">
              <p
                className="my-auto"
                style={{ color: "#7A7A7A", fontSize: "15px" }}
              >
                Share :
              </p>
              <div className="d-flex gap-2 align-items-center ms-2 ">
                <ShareNetwork cursor={"pointer"} size={30} weight="bold" />
                <FacebookLogo cursor={"pointer"} size={30} weight="fill" />
                <InstagramLogo cursor={"pointer"} size={30} weight="light" />
                <WhatsappLogo cursor={"pointer"} size={32} weight="light" />
              </div>
            </div>
          </div>
      
      </div>
    </div>
  );
};

export default ClientProfile;
