import * as Types from "../types/Types";

import { MyhtmlToDraft } from "../../../Clients/all_clients/_redux/reducer/ClientsReducer";
const initialState = {
  notificationInput: {
    id: "",
    server_key: "",
    notification_name: null,
    traffic: null,
    radius: null,
    push_notification_name: null,
    push_notification_description: "",
    status: 1,
    image: null,
    is_all_client: false,
    is_all_user: false,
    area_name: "Dhaka, Bangladesh",
    area_latitude: "23.777176",
    area_longitude: "90.399452",
    clients: [],
  },

  sendNotification: {
    id: "",
    status: 2,
  },

  notificationList: [],
  OfferDeleted: null,

  isLoadNotification: false,
  afterNotificationData: false,
};
const NotificationReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_NOTIFICATION_SEND:
      const sendNotification = { ...state.sendNotification };
      sendNotification[action.payload.name] = action.payload.value;
      return {
        ...state,
        sendNotification: sendNotification,
      };
    case Types.GET_NOTIFICATION_INPUT:
      const notificationInput = { ...state.notificationInput };
      notificationInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        notificationInput: notificationInput,
      };
    case Types.IS_LOAD_NOTIFICATION:
      return {
        ...state,
        isLoadNotification: action.payload,
      };

    case Types.GET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case Types.AFTER_NOTIFICATION_DATA:
      return {
        ...state,
        afterNotificationData: action.payload,
      };
    case Types.SET_NOTIFICATION_FALSE:
      return {
        ...state,
        afterNotificationData: action.payload,
        notificationInput: initialState.notificationInput,
      };
    case Types.SET_NOTIFICATION_UPDATED:
      let setnotificationInput = { ...state.notificationInput };
      setnotificationInput = action.payload;
      setnotificationInput.server_key = action.payload.server_key || [];
      setnotificationInput.id = action.payload.id;
      setnotificationInput.notification_name = action.payload.notification_name;
      setnotificationInput.traffic = action.payload.traffic;
      setnotificationInput.clients = action.payload.clients || [];
      setnotificationInput.area_longitude = action.payload.area_longitude;
      setnotificationInput.area_latitude = action.payload.area_latitude;
      setnotificationInput.area_name = action.payload.area_name;
      setnotificationInput.is_all_user = action.payload.is_all_user;
      setnotificationInput.is_all_client = action.payload.is_all_client;
      setnotificationInput.push_notification_name =
        action.payload.push_notification_name;
      // setnotificationInput.push_notification_description =
      //   action.payload.push_notification_description;
      setnotificationInput.status = action.payload.status;
      setnotificationInput.image_url = action.payload.image_url;
      setnotificationInput.image = action.payload.image;
      setnotificationInput.image = action.payload.image;

      setnotificationInput.push_notification_description = MyhtmlToDraft(
        setnotificationInput.push_notification_description
      );

      return {
        ...state,
        notificationInput: setnotificationInput,
      };

    default:
      break;
  }
  return newState;
};

export default NotificationReducer;
