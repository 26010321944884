import * as Types from "../types/Types";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
const initialState = {
  ClientProductInput: {
    name: "",
    regular_price: "",
    sale_price: 0,
    desc: "",
    status: 0,
    is_today_special: false,
    is_grand_offer: false,
    is_active: true,
    title: "",
    meta_desc: null,
    category: '',
    thumb: null,
    thumb_url: "",
    client: '',
    vat_type: '',
  },

  ClientProductList: null,

  clientDetails: null,
  isClientDeleted: null,

  isLoadProduct: false,
  afterClientcategoryData: false,
};
const ClientProductReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_CLIENT_PRODUCT_INPUT:
      const ClientProductInput = { ...state.ClientProductInput };
      ClientProductInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        ClientProductInput: ClientProductInput,
      };
    case Types.IS_LOAD_CLIENT_PRODUCT:
      return {
        ...state,
        isLoadProduct: action.payload,
      };
    case Types.AFTER_CLIENT_PRODUCT_DATA:
      return {
        ...state,
        afterClientcategoryData: action.payload,
      };
    case Types.GET_CLIENT_PRODUCT_LIST:
      return {
        ...state,
        ClientProductList: action.payload,
      };

    case Types.GET_CLIENT_PRODUCT_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      };
    case Types.SET_CLIENT_PRODUCT_FALSE:
      return {
        ...state,
        afterClientcategoryData: action.payload,
        ClientProductInput: initialState.ClientProductInput,
      };
    case Types.IS_CLIENT_PRODUCT_DELETED:
      return {
        ...state,
        isClientDeleted: action.payload,
      };

    case Types.GET_CLIENT_PRODUCT_UODATED:
      const setClientProductInput = { ...state.ClientProductInput };
      setClientProductInput.regular_price = action.payload.regular_price || [];
      setClientProductInput.id = action.payload.id;
      setClientProductInput.name = action.payload.name;
      setClientProductInput.sale_price = action.payload.sale_price;
      setClientProductInput.desc = action.payload.desc;
      setClientProductInput.status = action.payload.status;
      setClientProductInput.is_today_special = action.payload.is_today_special;
      setClientProductInput.is_grand_offer = action.payload.is_grand_offer;
      setClientProductInput.is_active = action.payload.is_active;
      setClientProductInput.title = action.payload.title;
      setClientProductInput.meta_desc = action.payload.meta_desc;
      setClientProductInput.category = action.payload.category;
      setClientProductInput.thumb = action.payload.thumb;
      setClientProductInput.thumb_url = action.payload.thumb_url;
      setClientProductInput.client = action.payload.client;
      setClientProductInput.vat_type = action.payload.vat_type;
      setClientProductInput.desc = MyhtmlToDraft(
        setClientProductInput.desc
      );

      return {
        ...state,
        ClientProductInput: setClientProductInput,
      };

    default:
      break;
  }
  return newState;
};

export default ClientProductReducer;
export const MyhtmlToDraft = (data) => {
  const blocksFromHtml = htmlToDraft(data);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};
