
export const GET_VERIFIED_USER_LIST ="GET_VERIFIED_USER_LIST"
export const GET_USER_LIST ="GET_USER_LIST"
export const GET_VERIFIED_USER_INPUT ="GET_VERIFIED_USER_INPUT"
export const AFTER_VERIFIED_USER_DATA ="AFTER_VERIFIED_USER_DATA"
export const IS_LOAD_VERIFIED_USER ="IS_LOAD_VERIFIED_USER"
// export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const SET_VERIFIED_USER_FALSE ="SET_VERIFIED_USER_FALSE"
export const IS_VERIFIED_USER_DELETED ="IS_VERIFIED_USER_DELETED"
export const GET_VERIFIED_USER_UODATED ="GET_VERIFIED_USER_UODATED"
export const GET_VERIFIED_USER_DETAILS ="GET_VERIFIED_USER_DETAILS"


export const GET_USER_NOTIFICATION ="GET_USER_NOTIFICATION"