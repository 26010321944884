import * as Types from "../types/Types";
const initialState = {
  invoiceInput: {
    invoice_id: "",
    reference: "",
    id: undefined,
    price: "",
    status: 1,
    client: "",
  },
  invoiceList: [],
  afterInvoiceData: false,
  //   clientDetails: null,
  OfferDeleted: null,

  isLoadInvoice: false,
};
const InvoiceReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_LOAD_INVOICE:
      return {
        ...state,
        isLoadInvoice: action.payload,
      };

    case Types.GET_INVOICE_LIST:
      return {
        ...state,
        invoiceList: action.payload,
      };
    case Types.AFTER_INVOICE_DATA:
      return {
        ...state,
        afterInvoiceData: action.payload,
      };
    case Types.INVOICE_STATUS_STATUS_FALSE:
      return {
        ...state,
        afterInvoiceData: action.payload,
        invoiceInput: initialState.invoiceInput,
      };
    case Types.GET_INVOICE_INPUT:
      const invoiceInput = { ...state.invoiceInput };
      invoiceInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        invoiceInput: invoiceInput,
      };

    case Types.GET_INVOICE_UPDATE_DATA:
      const setinvoiceInput = { ...state.invoiceInput };
      setinvoiceInput.id = action.payload.id;
      setinvoiceInput.client = action.payload.client;
      setinvoiceInput.status = action.payload.status;
      setinvoiceInput.reference = action.payload.reference;
      setinvoiceInput.price = action.payload.price;
      setinvoiceInput.invoice_id = action.payload.invoice_id;
      // setinvoiceInput.image_url = action.payload.image_url;
      // setinvoiceInput.mobile = action.payload.mobile;
      // setinvoiceInput.password = action.payload.password;
      return {
        ...state,
        invoiceInput: setinvoiceInput,
      };

    default:
      break;
  }
  return newState;
};

export default InvoiceReducer;
