import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
const OfferImage = ({
  title,
  name,
  onImageChange,
  url,
  file_name,
  size,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  const clientOfferInput = useSelector(
    (state) => state.clientOfferInfo.clientOfferInput
  );

  const appUserInput = useSelector((state) => state.appUserInfo.appUserInput);
  return (
    <div>
      <div className="row input-title" style={{ marginTop: "25px" }}>
        <div className="col-11 col-md-6 input-head">
          <p>{title} :</p>
          <p className="image_size">Resolution: 300 x 300</p>
          <div
            className="w-100 browse-main d-flex align-items-center"
            style={{
              height: "55px",
              border: "1px solid #E0E0E0",
              borderRadius: "12px",
              overflowX: "hidden",
              background: "white",
            }}
          >
            <p
              className="my-2 ms-2"
              style={{
                color: "#A1A1A1",
                fontSize: "15px",
                overflow: "hidden",
              }}
            >
              {file_name}
            </p>

            <div className="file-up">
              <input
                type="file"
                accept="image/*"
                name={name}
                onChange={onImageChange}
              />
              <p>Browse Files</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-11 col-md-6 row justify-content-between">
          {/* {selectedImages &&
            selectedImages.map((image, index) => {
              return ( */}
          <div key={appUserInput?.id} className="col-10 col-md-6 my-2 my-md-0">
            <div
              className="d-flex align-items-center  px-2"
              style={{ position: "relative" }}
            >
              {appUserInput.image_url ? (
                <img
                  src={appUserInput?.image_url}
                  height={36}
                  width={36}
                  alt=""
                />
              ) : clientOfferInput.banner_url ? (
                <img
                  src={clientOfferInput?.banner_url}
                  height={36}
                  width={36}
                  alt=""
                />
              ) : (
                url && <img src={url} height={36} width={36} alt="" />
              )}

            
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </div>
    </div>
  );
};

export default OfferImage;
