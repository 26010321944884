import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";

export const GetWebsiteInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_WEBSITE_INPUT, payload: formData });
};
export const SetWebsiteStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_WEBSITE_FALSE, payload: false });
};
export const SubmitWebsitedData = (data) => async (dispatch) => {
  // if (data.name === "") {
  //   showToast("error", "Name shouldn't be empty !");
  //   return 0;
  // }
  //  else if (data.speech === "") {
  //     showToast("error", "Testimonial shouldn't be empty !");
  //     return 0;
  //   } else if (data.designation === "") {
  //     showToast("error", "Designation shouldn't be empty !");
  //     return 0;
  //   }

  const url = `${BASE_URL}api/v1/settings/admin/homepage/`;
  dispatch({ type: Types.IS_LOAD_WEBSITE, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
          showToast("success", "Business Website  added successfully");

          dispatch({ type: Types.AFTER_WEBSITE_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

// -------menu---------------
export const GetMenuList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/settings/admin/menu/`;
  dispatch({ type: Types.IS_LOAD_WEBSITE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_MENU_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_WEBSITE, payload: true });
};
export const GetMenuInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_HOME_PAGE_MENU_INPUT, payload: formData });
};
export const SubmitMenudData = (data) => async (dispatch) => {
  // if (data.name === "") {
  //   showToast("error", "Name shouldn't be empty !");
  //   return 0;
  // }
  //  else if (data.speech === "") {
  //     showToast("error", "Testimonial shouldn't be empty !");
  //     return 0;
  //   } else if (data.designation === "") {
  //     showToast("error", "Designation shouldn't be empty !");
  //     return 0;
  //   }

  const url = `${BASE_URL}api/v1/settings/admin/menu/`;
  dispatch({ type: Types.IS_LOAD_WEBSITE, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
          showToast("success", "Business Website  added successfully");
          dispatch(GetMenuList());
          dispatch({ type: Types.AFTER_WEBSITE_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_WEBSITE, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
