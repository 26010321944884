import * as Types from "../types/Types";
const initialState = {
  clientOfferInput: {
    id: "",
    offer_id: "",
    name: "",
    description: "df",
    discount_type: 0,
    discount_value: "",
    start_date: "",
    expiry_date_2: "",
    start_date_2: "",
    expiry_date: "",
    status: 0,
    is_active: false,
    banner: null,
    banner_url: "",
    category: [],
    product: [0],
    client: "",
  },

  clientOfferList: null,

  //   clientDetails: null,
    isClientOfferDeleted: null,

  isLoadOffer: false,
  afterClientOfferData: false,
};
const ClientOfferReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_CLIENT_OFFER_INPUT:
      const clientOfferInput = { ...state.clientOfferInput };
      clientOfferInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        clientOfferInput: clientOfferInput,
      };
    case Types.IS_LOAD_CLIENT_OFFER:
      return {
        ...state,
        isLoadOffer: action.payload,
      };
    case Types.AFTER_CLIENT_OFFER_DATA:
      return {
        ...state,
        afterClientOfferData: action.payload,
      };
    case Types.GET_CLIENT_OFFER_LIST:
      return {
        ...state,
        clientOfferList: action.payload,
      };
    case Types.SET_CLIENT_OFFER_FALSE:
      return {
        ...state,
        afterClientOfferData: action.payload,
        clientOfferInput: initialState.clientOfferInput,
      };

    case Types.GET_CLIENT_OFFER_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      };

    case Types.IS_CLIENT_OFFER_DELETED:
      return {
        ...state,
        isClientOfferDeleted: action.payload,
      };
      case Types.GET_CLIENT_OFFER_UPDATED:
      const setclientOfferInput = { ...state.clientOfferInput };
      setclientOfferInput.offer_id  = action.payload.offer_id ;
      setclientOfferInput.id  = action.payload.id ;
      setclientOfferInput.name = action.payload.name;
      setclientOfferInput.description = action.payload.description;
      setclientOfferInput.discount_type = action.payload.discount_type;
      setclientOfferInput.discount_value = action.payload.discount_value;
      setclientOfferInput.start_date = action.payload.start_date;
      setclientOfferInput.expiry_date = action.payload.expiry_date;
      setclientOfferInput.status = action.payload.status;
      setclientOfferInput.is_active = action.payload.is_active;
      setclientOfferInput.banner = action.payload.banner;
      setclientOfferInput.expiry_date_2 = action.payload.expiry_date_2;
      setclientOfferInput.start_date_2 = action.payload.start_date_2;
      setclientOfferInput.banner_url = action.payload.banner_url;
      setclientOfferInput.category = action.payload.category || [];
      setclientOfferInput.product = action.payload.product || [];
      setclientOfferInput.client  = action.payload.client || [];
      
      // setclientOfferInput.password = action.payload.password;
      return {
        ...state,
        clientOfferInput: setclientOfferInput,
      };

    default:
      break;
  }
  return newState;
};

export default ClientOfferReducer;
