import { Search } from "@material-ui/icons";
import { ArrowLineDown } from "phosphor-react";
import React, { useState, useEffect } from "react";
import bCss from "../../Business/Buisness.module.css";
import { GetCategoryList } from "../../Seetings/category_redux/action/CategoryAcion";
import InvoiceList from "./InvoiceList";
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import 'jspdf-autotable';
const Invoice = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [selectedName, setSelectedName] = useState(undefined);
  const [selectedStardDate, setSelectedStardDate] = useState(undefined);
  const categoryList = useSelector((state) => state.categoryInfo.categoryList);
  useEffect(() => {
    dispatch(GetCategoryList());
  }, []);
  const handleSelectStartDate = (e) => {
    setSelectedStardDate(e.target.value);
    setSelectedStardDate(new Date(e.target.value).toISOString().split("T")[0]);
  };
  const handleSelectChange = (e) => {
    setSelectedName(e.target.value);
  };
  const HandlePdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: "#invoice-table", theme: "grid" });
    doc.text("MB Invoice", 20, 10);

    doc.save("Menubook Invoice.pdf");
  };
  return (
    <div className="parent ">
      <div className="main-div">
        <div
          className={`row d-flex justify-content-lg-end justify-content-start  ${bCss.upper_select} `}
        >
          <div
            className={`col-12 col-md-8 col-lg-10 row d-flex justify-content-first align-items-center   ${bCss.main_text}`}
          >
            <div className={` col-12 col-md-12 col-lg-6  ${bCss.first}`}>
              <span className="me-0 me-md-2" style={{ fontSize: "14px" }}>
                Business Type :{" "}
              </span>
              <select name="" id="" onChange={handleSelectChange}>
                <option value="">--</option>

                {categoryList?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option?.name}
                  </option>
                ))}
              </select>
            </div>
            {/* <div className={` col-12 col-md-12 col-lg-3  ${bCss.first}`}>
              <span className="me-0 me-md-2" style={{ fontSize: "14px" }}>
                Payment :{" "}
              </span>
              <select name="" id="">
                <option value="All">All</option>
                <option value="Food">Food</option>
                <option value="None">None</option>
              </select>
            </div> */}
            <div
              className={`col-12 col-md-10 col-lg-6 mt-2 justify-content-end  ${bCss.second}`}
            >
              <span className="me-2" style={{ fontSize: "14px" }}>
                Date Range :{" "}
              </span>
              <input
                style={{
                  border: "1px solid #d8d8d8",
                  padding: "2px 4px",
                  fontSize: "14px",
                  color: "#3f3f44",
                  borderRadius: "4px",
                }}
                className="date_input"
                type="date"
                name=""
                id=""
                onChange={handleSelectStartDate}
              />
              {/* <select name="" id="">
                <option value="Month to Date(1-27Oct ,2022)">
                  Month to Date(1-27Oct ,2022)
                </option>
                <option value="Month to Date(1-27Jan ,2022)">
                  Month to Date(1-27Jan ,2022)
                </option>
                <option value="Month to Date(1-27May ,2022)">
                  Month to Date(1-27May ,2022)
                </option>
              </select> */}
            </div>
          </div>
        </div>
        <div
          // className="row mt-4 rounded-2 bg-white"
          className="row mt-4 rounded-2 "
          style={{ minHeight: "400px" }}
        >
          <div className="col-12">
            <div className="row my-3 mx-2">
              <div className="col-5 col-md-3">
                <h5 className="">Invoices</h5>
              </div>
              <div className="col-12 col-md-7 my-2 my-md-0">
                <span className="search">
                  <Search className="search_icon" />
                  <input
                    type="search"
                    name=""
                    id=""
                    placeholder="Search comments, users.."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </span>
              </div>
              <div
                className="col-5 col-md-2 d-flex justify-content-md-end align-items-center justify-content-start"
                onClick={HandlePdf}
                style={{ cursor: "pointer" }}
              >
                <span className="me-2 mt-2">Export</span>
                <ArrowLineDown size={22} weight="fill" color="#F52D56" />
              </div>
            </div>
          </div>
          <div className="col-12">
            <InvoiceList
              selectedStardDate={selectedStardDate}
              search={search}
              export_id={"invoice-table"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
