import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Edit, ViewDayOutlined } from "@material-ui/icons";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import Avatar from "@mui/material/Avatar";
// import UserPagination from "../../verifieduser/UserPagination";
import UserPagination from "../../../../Common/UserPagination";
import Checkbox from "@mui/material/Checkbox";

import { AiFillExclamationCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  GetAppUserDetails,
  GetAppuserList,
  GetRequesteduserList,
  NextRequestedUserList,
  PreviousRequestedUserList,
  SetAppUserUpdatedData,
  SetRequestedUserUpdatedData,
} from "../../appUser/_redux/action/AppUserAction";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Lottie from "react-lottie";
import noData2 from "../../../../../assets/jsonFile/noData2.json";
import { BASE_URL } from "../../../../../Const";
import { GetUserNotification } from "../../verifieduser/_redux/action/VerifiedUserAction";

const VerificationList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  const options = ["None", "Atria", "Callisto"];
  const api = `${BASE_URL}api/v1/users/admin/?is_requested=true&&limit=20`;
  const requestedUserList = useSelector(
    (state) => state.appUserInfo.requestedUserList
  );
  const userList = useSelector((state) => state.appUserInfo.userList);
  const isLoadAppUser = useSelector((state) => state.appUserInfo.isLoadAppUser);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setUsers(requestedUserList?.results);
    dispatch(GetRequesteduserList(api));
    dispatch(GetUserNotification());
  }, []);

  React.useEffect(() => {
    if (selectedName === "is_app_user") {
      dispatch(GetRequesteduserList(`${BASE_URL}api/v1/users/admin/?limit=20`));
    } else if (selectedName === "is_verified") {
      dispatch(
        GetRequesteduserList(
          `${BASE_URL}api/v1/users/admin/?is_verified=true&&limit=20`
        )
      );
    } else if (selectedName === "is_requested") {
      dispatch(
        GetRequesteduserList(
          `${BASE_URL}api/v1/users/admin/?is_requested=true&&limit=20`
        )
      );
    }
  }, [selectedName]);
  // --checkbox-------------
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  // here you can see checked data
  // all checked data are there in selectedUser

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  // ----------------------api calll--------------------

  const handleEditUser = (id) => {
    dispatch(SetRequestedUserUpdatedData(id));
    history.push(`/edit-requested-user/${id}`);
  };
  const handleViewUser = (id) => {
    dispatch(GetAppUserDetails(id));
    history.push(`/profile/${id}`);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextRequestedUserList(requestedUserList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousRequestedUserList(requestedUserList?.previous));
  };
  return (
    <div>
      {isLoadAppUser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadAppUser &&
        requestedUserList?.results &&
        requestedUserList?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}

      {!isLoadAppUser &&
        requestedUserList?.results &&
        requestedUserList?.results !== null &&
        requestedUserList?.results?.length > 0 && (
          <TableContainer component={Paper} className="text-capitalize">
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "#FFF5EF" }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      // className="form-check-input"
                      name="allSelect"
                      checked={selectedUser?.length === users?.length}
                      onChange={(e) => handleChange(e, users)}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">User</TableCell>
                  <TableCell align="center">User Role</TableCell>
                  <TableCell align="left">Contact Info</TableCell>
                  <TableCell align="center">Review Count</TableCell>
                  <TableCell align="center">Followers</TableCell>
                  <TableCell align="left">Membership Level</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "#FFF5EF" }}>
                {requestedUserList?.results
                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val?.username
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.mobile?.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.email?.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((row) => {
                    // const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.username}
                            // checked when selectedUser contains checked object/filed/row
                            checked={selectedUser.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange(e, row)}
                            //  checked={isItemSelected}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <div className="d-flex user_td  gap-2">
                            <Avatar
                              alt="User1"
                              src="/static/images/avatar/1.jpg"
                            />{" "}
                            <div>
                              <h6>
                                {row.first_name} {""} {row.last_name}
                              </h6>

                              <p>
                                Joined :
                                {new Date(row.created_at)
                                  .toISOString()
                                  .slice(0, 10)}
                              </p>
                              <p>
                                {" "}
                                Verification request:{" "}
                                {new Date(row.verification_requested_date)
                              .toISOString()
                              .slice(0, 10)}
                              
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "16px",
                            color: "#EF5B0C",
                            fontWeight: "bold",
                          }}
                        >
                          <p> {row.role === 0 && "Food Blogger"}</p>
                        </TableCell>
                        <TableCell align="left" className="membership_td">
                          <div
                            style={{ textAlign: "left", lineHeight: "20px" }}
                          >
                            <p
                              style={{
                                color: "#7A7A7A",
                                fontSize: "14px",
                                fontWeight: "500",
                                marginBottom: "0",
                              }}
                            >
                              {row.email}
                            </p>{" "}
                            <p
                              style={{
                                color: "#7A7A7A",
                                fontSize: "14px",
                                fontWeight: "500",
                                marginBottom: "0",
                              }}
                            >
                              {row.mobile}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "#7A7A7A",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          <p> {row.posts}</p>
                        </TableCell>
                        <TableCell align="center" className="membership_td">
                          {" "}
                          <p>{row.followers}</p>
                        </TableCell>
                        <TableCell align="left" className="me-2 membership_td">
                          <div className="d-flex gap-2 requ">
                            {row.membership_level === 0 && (
                              <h5>
                                {" "}
                                <AiFillExclamationCircle />
                              </h5>
                            )}{" "}
                            <div>
                              {row.membership_level === 0 && (
                                <h6>Requested for verification</h6>
                              )}{" "}
                              <p
                                style={{
                                  color: "#7A7A7A",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  marginBottom: "0",
                                }}
                              >
                                {" "}
                                {row.membership_level === 0
                                  ? "Not Verified"
                                  : row.membership_level === 1 && " Verified"}
                                {row.membership_level === 1 && (
                                  <MdVerified style={{ color: "#EF5B0C" }} />
                                )}{" "}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="center" className="table_icon">
                          <span
                            className="d-flex justify-content-center"
                            onClick={() => handleViewUser(row.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <FaEye
                              className="me-2"
                              style={{ fontSize: "20px" }}
                            />
                            view
                          </span>
                          <br />
                          <span
                            className="d-flex justify-content-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditUser(row.id)}
                          >
                            <FaEdit
                              className="me-2"
                              style={{ fontSize: "20px" }}
                            />
                            Edit
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {requestedUserList?.count}</p>
        <UserPagination
          prev_next={requestedUserList}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default VerificationList;
