import * as Types from "../types/Types";
const initialState = {
 

  businessUserList: null,

  businessUserDetails: null,
  isVerifiedUserDeleted: null,
  
  isLoadBusinessuser: false,
 
};
const BusinessUserReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
   
    case Types.IS_LOAD_BUSINESS_USER:
      return {
        ...state,
        isLoadBusinessuser: action.payload,
      };
   
    case Types.GET_BUSINESS_USER_LIST:
      return {
        ...state,
        businessUserList: action.payload,
      };
      
   
      
      case Types.GET_BUSINESS_USER_DETAILS:
      return {
        ...state,
        businessUserDetails: action.payload,
      };
   
  
    


    default:
      break;
  }
  return newState;
};

export default BusinessUserReducer;
