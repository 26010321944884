import React from "react";
import { AiFillExclamationCircle } from "react-icons/ai";

import ArrowSvg from "../../../Common/ArrowSvg";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  GetVerifiedUserlInput,
  SetVerifiedUserStatusFalse,
  SubmitVerifiedUserldData,
  UpdateVerifiedldData,
} from "./_redux/action/VerifiedUserAction";
import {
  user_role,
  district_list,
  verification_status,
} from "../../../Common/Dropdown";
import { SubmitImage } from "../../../Seetings/_redux/action/SeetingsAction";
import SingleBrowseFile from "../../../Clients/SingleBrowseFile";
import { GetCountryList } from "../appUser/_redux/action/AppUserAction";
const EditVerifiedUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const verifiedUserInput = useSelector(
    (state) => state.verifiedUserInfo.verifiedUserInput
  );
  const countryList = useSelector((state) => state.appUserInfo.countryList);
  const isLoadVerifiedUser = useSelector(
    (state) => state.verifiedUserInfo.isLoadVerifiedUser
  );
  const afterVerifiedUserData = useSelector(
    (state) => state.verifiedUserInfo.afterVerifiedUserData
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const handleChangeInput = (name, value) => {
    dispatch(GetVerifiedUserlInput(name, value));
    if(verifiedUserInput.membership_level === 1 ){
      dispatch(GetVerifiedUserlInput("is_requested", false));
      dispatch(GetVerifiedUserlInput("is_verified", true));
    }
    // else
    //  if(verifiedUserInput.membership_level === 0){
    //   dispatch(GetVerifiedUserlInput("is_requested", true));
    //   dispatch(GetVerifiedUserlInput("is_verified", false));
    // }
    console.log("tru4e", verifiedUserInput.membership_level , verifiedUserInput.is_verified,verifiedUserInput.is_requested)
  };
  const handleSubmitAppUser = (data) => {
    dispatch(SubmitVerifiedUserldData(data));
  };
  const handleUpdateAppUser = (data) => {
    dispatch(UpdateVerifiedldData(data));
  };
  useEffect(() => {
    if (afterVerifiedUserData) {
      history.push(`/verified_user`);
    }
    dispatch(SetVerifiedUserStatusFalse());
  }, [afterVerifiedUserData]);

  const testimonial_id = localStorage.getItem("testimonial_id");
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }

    dispatch(GetCountryList());
  }, []);
  return (
    <div className="parent">
      {isLoadVerifiedUser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>
            {verifiedUserInput?.id ? "Edit User" : "Add User"}
          </span>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>First name</p>
            <input
              type="text"
              placeholder="user@123"
              name="first_name"
              value={verifiedUserInput.first_name}
              onChange={(e) => handleChangeInput("first_name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Last name</p>
            <input
              type="text"
              placeholder="user@123"
              name="last_name"
              value={verifiedUserInput.last_name}
              onChange={(e) => handleChangeInput("last_name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <p> Role</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="role"
                value={verifiedUserInput.role}
                onChange={(e) => handleChangeInput("role", e.target.value)}
              >
                  <option value={""}>--</option>
                {user_role.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              style={{ flexWrap: "wrap " }}
            >
              <p className="my-2 my-md-0">Varification Status</p>
              <div style={{ color: "#FF0000" }}>
                <span>
                  <AiFillExclamationCircle />
                </span>
                <span className="ms-2 fw-bold">Requested for verification</span>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="membership_level"
                value={verifiedUserInput.membership_level}
                onChange={(e) =>
                  handleChangeInput("membership_level", e.target.value)
                }
              >
                  <option value={""}>--</option>
                {verification_status.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Full Name</p>
            <input
              type="text"
              placeholder="Shafin Ahmed"
              name="first_name"
              value={verifiedUserInput.first_name}
              onChange={(e) => handleChangeInput("first_name", e.target.value)}
            />
          </div>
        </div> */}
        {/* <SingleBrowseFile
          title={"Profile Picture "}
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "Ver_image"));
            handleChangeInput("fileName1", e.target.files[0].name);
            handleChangeInput("size1", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              // setPdfFile(e.target.result);
              handleChangeInput("image_url", e.target.result);
            };
          }}
          name="image"
          url={verifiedUserInput?.image_url}
          file_name={verifiedUserInput.fileName1}
          size={verifiedUserInput.size1 / 1000}
        />
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Address Line 1</p>
            <input
              type="text"
              placeholder="Dhaka"
              name="address1"
              value={verifiedUserInput.address1}
              onChange={(e) => handleChangeInput("address1", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Address Line 2</p>
            <input
              type="text"
              placeholder="Sylhet"
              name="address2"
              value={verifiedUserInput.address2}
              onChange={(e) => handleChangeInput("address2", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <p> Country</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="country"
                value={verifiedUserInput.country}
                onChange={(e) => handleChangeInput("country", e.target.value)}
              >
                {countryList?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>District</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="district"
                value={verifiedUserInput.district}
                onChange={(e) => handleChangeInput("district", e.target.value)}
              >
                {district_list.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Phone</p>
            <input
              type="text"
              placeholder="123456"
              name="mobile"
              value={verifiedUserInput.mobile}
              onChange={(e) => handleChangeInput("mobile", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Email</p>
            <input
              type="text"
              placeholder="example@gmail.com"
              name="email"
              value={verifiedUserInput.email}
              onChange={(e) => handleChangeInput("email", e.target.value)}
            />
          </div>
        </div>
        {/* <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Password</p>
            <input
              type="password"
              placeholder="example@gmail.com"
              name="password"
              value={verifiedUserInput.password}
              onChange={(e) => handleChangeInput("password", e.target.value)}
            />
          </div>
        </div> */}
        {/* <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head d-flex align-items-center">
            <p className="my-auto me-3">Password:</p>
            <button
              className="reset-btn px-4 py-2 rounded-2 "
              style={{ color: "#EF5B0C", border: "1px solid #EF5B0C" }}
            >
              Sent Reset Link
            </button>
          </div>
        </div> */}
        <div className="my-4">
          {verifiedUserInput?.id ? (
            <button
              className="save-btn"
              onClick={() => handleUpdateAppUser(verifiedUserInput)}
            >
              Update User
            </button>
          ) : (
            <button
              className="save-btn"
              onClick={() => handleSubmitAppUser(verifiedUserInput)}
            >
              Add User
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditVerifiedUser;
