
import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";
export const InputTextValue = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.INPUT_LOGIN_VALUE, payload: formData });
};

export const SetSigninStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_LOGIN_FALSE, payload: false });
};

export const SignInData = (postData) => async (dispatch) => {
  if (postData.mobile.length === 0) {
    showToast("error", "Mobile shouldn't be empty !");
    return 0;
  } else if (postData.password.length === 0) {
    showToast("error", "Password shouldn't be empty");
    return 0;
  }

  const url = `${BASE_URL}api/v1/auth/admin/login/`;
  dispatch({ type: Types.IS_LOAD_LOGIN, payload: true });

  try {
    await Axios.post(url, postData)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
          showToast("success", res.data.message);
          if (postData.remember === true) {
            localStorage.setItem("is_remember_me", true);
          }
          // Store data's to local storage
          localStorage.setItem("is_menubook_cient_logged_in", true);
          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
        
          dispatch({ type: Types.AFTER_LOGIN_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        const message = JSON.parse(err.request.response).message;
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        swal(message, {
          icon: "error",
        });
      });
  } catch (error) {}
};





export const GetForgetPassInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_FORGET_PASSWORD, payload: formData });
};
export const SubmitForgetpasswordData = (postData) => async (dispatch) => {
  if (postData.mobile === "") {
    showToast("error", "Mobile shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/auth/admin/forget/password/`;
  dispatch({ type: Types.IS_LOAD_LOGIN, payload: true });

  try {
    await Axios.post(url, postData)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
          showToast("success", res.data.message);

          dispatch({ type: Types.AFTER_FORGET_PASSWORD, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
   
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
// ========verification----------------
export const GetVarificationInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_VERIFICATION_CHECK, payload: formData });
};
// export const SetVerificationStatusFalse = () => (dispatch) => {
//   dispatch({ type: Types.SET_VERIFICATION_FALSE, payload: false });
// };
export const SubmitVarificationdData = (postData) => async (dispatch) => {
  // postData.email= email
  if (postData.mobile === "") {
    showToast("error", "Mobile shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/auth/admin/verification/check/`;
  dispatch({ type: Types.IS_LOAD_LOGIN, payload: true });

  try {
    await Axios.post(url, postData)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
          showToast("success", res.data.message);

          dispatch({ type: Types.AFTER_VERIFICATION_CHECK, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        const message = JSON.parse(err.request.response).message;
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        swal(message, {
          icon: "error",
        });
      });
  } catch (error) {}
};
// ========resend verification----------------
export const GetResendVarificationInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_RESEND_VERIFICATION_INPUT, payload: formData });
};
// export const SetVerificationStatusFalse = () => (dispatch) => {
//   dispatch({ type: Types.SET_VERIFICATION_FALSE, payload: false });
// };
export const SubmitResendVarificationdData = (postData) => async (dispatch) => {
 
  if (postData.mobile === "") {
    showToast("error", "Mobile shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/auth/admin/verification/resend/`;
  dispatch({ type: Types.IS_LOAD_LOGIN, payload: true });

  try {
    await Axios.post(url, postData)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
          showToast("success", res.data.message);

          dispatch({ type: Types.AFTER_RESEND_INPUT, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        const message = JSON.parse(err.request.response).message;
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        swal(message, {
          icon: "error",
        });
      });
  } catch (error) {}
};

// ======confirm passowrd====
export const GetConfirmPassInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_CONFIRM_PASS_INPUT, payload: formData });
};
export const SetConfirmpassStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_VERIFICATION_FALSE, payload: false });
};
export const SubmitConfirmpassdData = (postData) => async (dispatch) => {
  // postData.email= email
  if (postData.password === "") {
    showToast("error", "Password shouldn't be empty !");
    return 0;
  }
  if (postData.password.length < 8 ) {
    showToast("error", "Must be at least 8 characters. !");
    return 0;
  }
  else if (postData.confirm_password === "") {
    showToast("error", "Confirm Password shouldn't be empty !");
    return 0;
  }
  // else if (postData && postData.confirm_password !== postData.password) {
  //   showToast("error", " Password doesn't match !");
  //   return 0;
  // }

  const url = `${BASE_URL}api/v1/auth/admin/forget/password/confirm/`;
  dispatch({ type: Types.IS_LOAD_LOGIN, payload: true });

  try {
    await Axios.post(url, postData)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
          showToast("success", res.data.message);

          dispatch({ type: Types.AFTER_CONFIRM_PASSWORD, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        const message = JSON.parse(err.request.response).message;
        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_LOGIN, payload: false });
        swal(message, {
          icon: "error",
        });
      });
  } catch (error) {}
};
