import React from "react";
import { useState } from "react";

import { useSelector } from "react-redux";
const SingleImageUpload = ({
  title,
  name,
  onImageChange,
  url,
  file_name,
  imageUrl,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const clientInput = useSelector((state) => state.clientInfo.clientInput);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  return (
    <div>
      <div className="row input-title" style={{ marginTop: "25px" }}>
        <div className="col-11 col-md-6 input-head">
          <p>{title} :</p>
          <p className="image_size">Resolution: 200 x 40</p>
          <div
            className="w-100 browse-main d-flex align-items-center"
            style={{
              height: "55px",
              border: "1px solid #E0E0E0",
              borderRadius: "12px",
              overflowX: "hidden",
              background: "white",
            }}
          >
            <p
              className="my-2 ms-2"
              style={{
                color: "#A1A1A1",
                fontSize: "15px",
                overflow: "hidden",
              }}
            >
              {file_name}
            </p>

            <div className="file-up">
              <input
                // multiple
                type="file"
                accept="image/*"
                name={name}
                onChange={onImageChange}
              />
              <p>Browse Files</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-11 col-md-6 row justify-content-between">
          {/* {selectedImages &&
            selectedImages.map((image, index) => {
              return ( */}
          <div key={clientInput?.id} className="col-10 col-md-6 my-2 my-md-0">
            <div
            
            >
              {clientInput.logo_image ? (
                <div className=" mb-3">
                  <img
                    className="img-fluid"
                    style={{
                      width: "100%",
                      height: "180px",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                    src={clientInput?.logo_image}
                    alt=""
                  />
                </div>
              ) : (
                url && (
                  <div className="mb-3">
                    <img
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "180px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                      src={url}
                      alt=""
                    />
                  </div>
                )
              )}
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </div>
    </div>
  );
};

export default SingleImageUpload;
