import * as Types from "../types/Types";
const initialState = {
  ClientCategoryInput: {
    id: "",
    name: "",
    slug: "",
    branch_count: null,
    rank: 0,
    is_active: true,
    parent: null,
    thumb: null,
    branches: [0],
  },

  ClientCategoryList: null,

  clientDetails: null,
  isClientDeleted: null,

  isLoadClientCategory: false,
  afterClientcategoryData: false,
};
const ClientCategoryReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_CLIENT_CATEGORY_INPUT:
      const ClientCategoryInput = { ...state.ClientCategoryInput };
      ClientCategoryInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        ClientCategoryInput: ClientCategoryInput,
      };
    case Types.IS_LOAD_CLIENT_CATEGORY:
      return {
        ...state,
        isLoadClientCategory: action.payload,
      };
    case Types.AFTER_CLIENT_CATEGORY_DATA:
      return {
        ...state,
        afterClientcategoryData: action.payload,
      };
    case Types.GET_CLIENT_CATEGORY_LIST:
      return {
        ...state,
        ClientCategoryList: action.payload,
      };

    case Types.GET_CLIENT_CATEGORY_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      };
    case Types.SET_CLIENT_CATEGORY_FALSE:
      return {
        ...state,
        afterClientcategoryData: action.payload,
        ClientCategoryInput: initialState.ClientCategoryInput,
      };
    case Types.IS_CLIENT_CATEGORY_DELETED:
      return {
        ...state,
        isClientDeleted: action.payload,
      };

    case Types.GET_CLIENT_CATEGORY_UODATED:
      const setClientCategoryInput = { ...state.ClientCategoryInput };
      setClientCategoryInput.branches = action.payload.branches || [];
      setClientCategoryInput.id = action.payload.id;
      setClientCategoryInput.name = action.payload.name;
      setClientCategoryInput.slug = action.payload.slug;
      setClientCategoryInput.count = action.payload.count;
      setClientCategoryInput.rank = action.payload.rank;
      setClientCategoryInput.parent = action.payload.parent;
      setClientCategoryInput.thumb = action.payload.thumb;

      return {
        ...state,
        ClientCategoryInput: setClientCategoryInput,
      };

    default:
      break;
  }
  return newState;
};

export default ClientCategoryReducer;
