import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../Const";
import swal from "sweetalert";

export const GetOfferList = (api) => (dispatch) => {
  const url = api;
  dispatch({ type: Types.IS_LOAD_OFFER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_OFFER_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_OFFER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_OFFER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_OFFER, payload: true });
};


export const NextOfferListList = (nextUrl) => (dispatch) => {
  const url = nextUrl;
  dispatch({ type: Types.IS_LOAD_OFFER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_OFFER_LIST,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_OFFER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_OFFER, payload: false });
  }
};

export const PreviousOfferList = (prevUrl) => (dispatch) => {
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_OFFER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_OFFER_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_OFFER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_OFFER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_OFFER, payload: true });
};



export const Getofferdelete = (id, api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/promotions/admin/offer/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("error", res.data.message);
        dispatch({ type: Types.IS_OFFER_DELETED, payload: true });
        dispatch(GetOfferList(api))
      }
    });
  } catch (error) {}
};
export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_OFFER_DELETED, payload: false });
};
