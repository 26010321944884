import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
const SingleBrowseFile = ({
  title,
  name,
  onImageChange,
  url,
  file_name,
  size,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  const notificationInput = useSelector(
    (state) => state.notificationInfo.notificationInput
  );
  const appUserInput = useSelector((state) => state.appUserInfo.appUserInput);
  return (
    <div>
      <div className="row input-title" style={{ marginTop: "25px" }}>
        <div className="col-11 col-md-6 input-head">
          <p>{title} :</p>
          <p className="image_size">Resolution: 110 x 130</p>
          <div
            className="w-100 browse-main d-flex align-items-center"
            style={{
              height: "55px",
              border: "1px solid #E0E0E0",
              borderRadius: "12px",
              overflowX: "hidden",
              background: "white",
            }}
          >
            <p
              className="my-2 ms-2"
              style={{
                color: "#A1A1A1",
                fontSize: "15px",
                overflow: "hidden",
              }}
            >
              {file_name}
            </p>

            <div className="file-up">
              <input
                type="file"
                accept="image/*"
                name={name}
                onChange={onImageChange}
              />
              <p>Browse Files</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-11 col-md-6 row justify-content-between">
          {/* {selectedImages &&
            selectedImages.map((image, index) => {
              return ( */}
          <div key={appUserInput?.id} className="col-10 col-md-6 my-2 my-md-0">
            <div
              className="d-flex align-items-center  px-2"
              style={{ position: "relative" }}
            >
              {appUserInput.image_url ? (
                <div
                  style={{ height: "200px", width: "100%", objectFit: "cover" }}
                >
                  <img src={appUserInput?.image_url} alt="" style={{ height: "200px", width: "100%", objectFit: "cover" }}/>
                </div>
              ) : notificationInput.image_url ? (
                <div
                  style={{ height: "200px", width: "100%", objectFit: "cover" }}
                >
                  <img src={notificationInput?.image_url} alt="MB" style={{ height: "100%", width: "100%", objectFit: "cover" }}/>
                </div>
              ) : (
                // <img
                //   src={notificationInput?.image_url}
                //   height={36}
                //   width={36}
                //   alt=""
                // />
                url && (
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img src={url} alt="mb" style={{ height: "200px", width: "100%", objectFit: "cover" }}/>
                  </div>
                )
              )}

              {/* <div className="ms-2 mt-2" style={{ color: "#979FB8" }}>
               
                <p style={{ fontSize: "12px", marginTop: "-10px" }}>
                  {size}
                </p>
              </div> */}
              {/* <div
                className="ms-2"
                style={{ marginTop: "-34px", cursor: "pointer" }}
                onClick={() => deleteHandler(generalInput?.default_role)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <rect width="256" height="256" fill="none"></rect>
                  <circle
                    cx="128"
                    cy="128"
                    r="96"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></circle>
                  <line
                    x1="160"
                    y1="96"
                    x2="96"
                    y2="160"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></line>
                  <line
                    x1="160"
                    y1="160"
                    x2="96"
                    y2="96"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></line>
                </svg>
              </div> */}
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </div>
    </div>
  );
};

export default SingleBrowseFile;
