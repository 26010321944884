import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import ArrowSvg from "../../Common/ArrowSvg";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClientCategoryInput,
  GetClientCategoryList,
  SubmitClientCategorydData,
  SetClientCategoryStatusFalse,
} from "../category/_redux/action/ClientCategoryAction";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { GetClientList } from "../all_clients/_redux/action/ClientAction";
import Multiselect from "multiselect-react-dropdown";
import ModalImageUpload from "../../Seetings/ModalImageUpload";
import { SubmitImage } from "../../Seetings/_redux/action/SeetingsAction";
import { BASE_URL } from "../../../Const";
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const AddCatagory = (props) => {

  const dispatch = useDispatch();

  const client_List = useSelector((state) => state.clientInfo.clientList);
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);
  const ClientCategoryInput = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryInput
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const isLoadClientCategory = useSelector(
    (state) => state.clientCategoryInfo.isLoadClientCategory
  );
  const afterClientcategoryData = useSelector(
    (state) => state.clientCategoryInfo.afterClientcategoryData
  );
  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );

  const [selectedValue, setSelectedValue] = useState([]);
  const handleChangeBranch = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
    dispatch(GetClientCategoryInput("branches", selectedValue));
    dispatch(GetClientCategoryInput("branch_count", selectedValue?.length));
  };
  const handleChangeInput = (name, value) => {
    dispatch(GetClientCategoryInput(name, value));

    dispatch(GetClientCategoryInput("branches", selectedValue));
   
  };
  const HandleCategorySubmit = (data) => {
    dispatch(GetClientCategoryInput("branches", selectedValue));
    dispatch(SubmitClientCategorydData(data));
  };

  useEffect(() => {
    dispatch(GetClientList(`${BASE_URL}api/v1/restaurant/admin/clients/${userData.client_id}/branches/`));
  }, []);
 

  useEffect(() => {
    if (afterClientcategoryData) {
      props.onHide();
      dispatch(SetClientCategoryStatusFalse);
    }
  }, [afterClientcategoryData]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {isLoadImage && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {isLoadClientCategory && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="mx-2 mx-md-3 mx-lg-4">
        <div>
          <h4 className="text-center" style={{ color: "#515151" }}>
            Add new category
          </h4>
        </div>

        <div>
          <div className="inputs-modal my-3">
            <p>Name</p>
            <input
              type="text"
              name="name"
              value={ClientCategoryInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
          {/* <div className="inputs-modal my-3">
            <p>Slug</p>
            <input
              type="text"
              name="slug"
              value={ClientCategoryInput.slug}
              onChange={(e) => handleChangeInput("slug", e.target.value)}
            />
          </div> */}
          <div className="inputs-modal my-3">
            <p>Parent category</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="parent"
                value={ClientCategoryInput.parent}
                onChange={(e) => handleChangeInput("parent", e.target.value)}
              >
                <option value={""}>--</option>
                {ClientCategoryList?.results?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>

          <div className="row " style={{ marginTop: "25px" }}>
            <div className="col-11 col-md-12 food-cat f2">
              <p>Add this product to multiple branches:</p>
              <div style={{ position: "relative" }}>
                <Multiselect
                  displayValue="company_name"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  // onSelect={function noRefCheck() {}}
                  // onSelect={client_List.filter(obj => selectedValue.includes(obj.id))}
                  onSelect={handleChangeBranch}
                  className="my-2"
                  options={client_List}
                  showCheckbox
                  placeholder="Select"
                  closeIcon={true}
                  // onChange={}
                />
                <div className="arrow-icon" style={{ marginTop: "-5px" }}>
                  <ArrowSvg />
                </div>
              </div>
            </div>
          </div>

          <ModalImageUpload
            title="Choose a photo:"
            onImageChange={(e) => {
              imageInput.owner = userData.id;
              imageInput.doc_type = 0;
              imageInput.document = e.target.files[0];
              dispatch(SubmitImage(imageInput, "thumb"));
              handleChangeInput("fileName6", e.target.files[0].name);
              handleChangeInput("size3", e.target.files[0].size);
              let reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);
              reader.onloadend = (e) => {
                // setPdfFile(e.target.result);
                handleChangeInput("thumb_url", e.target.result);
              };
            }}
            name="image"
            url={ClientCategoryInput?.thumb_url}
            file_name={ClientCategoryInput.fileName6}
            size={ClientCategoryInput.size3 / 1000}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="mx-2 mx-md-3 mx-lg-4">
        <Button
          className="w-100 save-btn "
          style={{ color: "white", background: "#EF5B0C" }}
          onClick={() => HandleCategorySubmit(ClientCategoryInput)}
          // {props.onHide}
        >
          {" "}
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCatagory;
