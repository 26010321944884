import React from "react";

import { Search } from "@material-ui/icons";

import AppUserList from "./AppUserList";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetClientCategoryList } from "../../../../Clients/category/_redux/action/ClientCategoryAction";
import { BASE_URL } from "../../../../../Const";

import { GetUserNotification } from "../../verifieduser/_redux/action/VerifiedUserAction";



const AppUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedName, setSelectedName] = useState(undefined);
  const api = `${BASE_URL}api/v1/inventory/admin/category/`;


  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(GetClientCategoryList(api));
    dispatch(GetUserNotification());
  }, []);

  const handleSelectChange = (e) => {
    setSelectedName(e.target.value);
  };

  return (
    <>
     
      {/* <CustomerBulk/> */}
      <div className="parent text-capitalize">
        <div className="main-div">
          <div className="fw-bold">
            <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
            <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
              Client Name /
            </span>{" "}
            <span
              style={{ fontSize: "14px", color: "#818181", fontWeight: "400" }}
            >
              Users
            </span>
          </div>

          <div className="new-user mt-4">
            <div className="d-flex gap-3 align-items-center">
              <p>User</p>
              <button
                className="orange-btn"
                onClick={() => history.push(`/add-user`)}
              >
                Add New
              </button>
            </div>
          </div>
          {/* <div className="count mt-4">
            <p>
              All (12464) | Admin (30) | Restaurant (300) | Customer (2343245) |{" "}
              <span>Verified (2343245)</span>{" "}
            </p>
          </div> */}

          <div className="filter_search d-flex align-items-center justify-content-between mt-4 ">
           
           
            <div className="mb-3 d-flex align-items-center">
              <span className="me-2" style={{ fontSize: "14px", width: "70%" }}>
                Filter by :{" "}
              </span>
              <select
                name=""
                id=""
                className="me-2"
                onChange={handleSelectChange}
              >
                <option value="">--</option>
                <option value="is_app_user">All User</option>
                <option value="is_verified">Verified User</option>
                <option value="is_requested">Verification Request</option>

                {/* {ClientCategoryList?.results?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))} */}
              </select>
            </div>
            <div className=" mb-3 ">
              <span className="search">
                <Search className="search_icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search comments, users.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </span>
            </div>
          </div>

          <div className="table_div mt-4">
            <AppUserList search={search} selectedName={selectedName} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppUser;
