import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import user1 from "../../assets/img/user1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { GetMenuInput,SetWebsiteStatusFalse,SubmitMenudData } from "./website_redux/action/HomepageAction";
const AddMenu = (props) => {
  const dispatch = useDispatch();
  const MenuInput = useSelector((state) => state.websiteInfo.MenuInput);
  const menuList = useSelector((state) => state.websiteInfo.menuList);
  const afterWebsiteData = useSelector(
    (state) => state.websiteInfo.afterWebsiteData
  );
  const handleChangeInput = (name, value) => {
    dispatch(GetMenuInput(name, value));
  };
  const handleSubmitMenu =(data)=>{
    dispatch(SubmitMenudData(data))
  }
  React.useEffect(() => {
    if(afterWebsiteData){
      props.onHide()
    }
    dispatch(SetWebsiteStatusFalse());
  }, [afterWebsiteData]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="mx-2 mx-md-3 mx-lg-4">
        <div>
          <h4 className="text-left" style={{ color: "#515151" }}>
            Add New Menu
          </h4>
        </div>

        <div>
          <div className="inputs-modal my-3">
            <p>Name</p>
            <input
              type="text"
              name="name"
              value={MenuInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
          <div className="inputs-modal my-3">
            <p>Slug</p>
            <input
              type="text"
              name="slug"
              value={MenuInput.slug}
              onChange={(e) => handleChangeInput("slug", e.target.value)}
            />
          </div>
          <div className="inputs-modal my-3">
            <p>Parent Menu</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="parent_menu"
                value={MenuInput.parent_menu}
                onChange={(e) => handleChangeInput("parent_menu", e.target.value)}
              >
                {menuList?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                
              </select>
              <div className="arrow-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 256 256"
                >
                  <rect width="20" height="20" fill="none"></rect>
                  <polyline
                    points="208 96 128 176 48 96"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></polyline>
                </svg>
              </div>
            </div>
          </div>
          <div className="inputs-modal my-3">
            <p>Select Destination</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="destination"
                // value={MenuInput.destination}
                // onChange={(e) =>
                //   handleChangeInput("destination", e.target.value)
                // }
              >
                {/* {categoryList?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))} */}
                <option selected value={1}>
                  Customer
                </option>
                <option value={2}>Owner</option>
                <option value={3}>Shop</option>
              </select>
              <div className="arrow-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 256 256"
                >
                  <rect width="20" height="20" fill="none"></rect>
                  <polyline
                    points="208 96 128 176 48 96"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></polyline>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="mx-2 mx-md-3 mx-lg-4">
        <Button
          className="w-100 save-btn "
          style={{ color: "white", background: "#EF5B0C" }}
          onClick={()=> handleSubmitMenu(MenuInput)}
        >
          {" "}
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMenu;
