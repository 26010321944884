import React, { useEffect } from "react";
import bCss from "../Business/Buisness.module.css";
import { BsHandbag } from "react-icons/bs";
import { MdShowChart } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Bar from "../Svgs/Bar";
import img1 from "../../assets/img/1).jpg";
import { Add, Search } from "@material-ui/icons";
import { Backpack, CaretUp, Plus, Star } from "phosphor-react";
import { useSelector, useDispatch } from "react-redux";
import { GetBusinessDetails } from "../Business/_redux/action/MyBusinessAction";
import { BASE_URL } from "../../Const";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { review_status } from "../Common/Dropdown";
import { SetReviewUpdatedData } from "../review/_redux/action/ReviewAction";
import AddReportReview from "./ClientAdvertisement/AddReportReview";

import { BsQuestionCircleFill } from "react-icons/bs";
const ClientDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const myBusinessDetails = useSelector(
    (state) => state.dashboardInfo.myBusinessDetails
  );
  const isLoadDashboard = useSelector(
    (state) => state.dashboardInfo.isLoadDashboard
  );

  useEffect(() => {
    dispatch(
      GetBusinessDetails(`${BASE_URL}api/v1/users/admin/dashboard/client/`)
    );
  }, []);
  console.log(myBusinessDetails?.recent_reviews);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [modalShow, setModalShow] = React.useState(false);
  const handleeReport = (id) => {
    setModalShow(true);
    dispatch(SetReviewUpdatedData(id));
  };

  return (
    <div className={`${bCss.buisness_main} ${bCss.main_bg}`}>
      <AddReportReview show={modalShow} onHide={() => setModalShow(false)} />
      {isLoadDashboard && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="fw-bold">
        <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
        <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
          {" "}
          Client Name /{" "}
        </span>{" "}
        <span style={{ fontSize: "14px", color: "#818181" }}>Dashboard</span>
      </div>
      <div className={`${bCss.card} row  justify-content-between mt-3`}>
        <div className="col-lg-2  col-md-3 col-12 my-2 d-flex justify-content-between">
          <div>
            <BsHandbag size={29} color={"#3F3F44"} />
            <p className="mt-4">Total Category</p>
          </div>
          <div style={{ color: "#6FC141" }}>
            <p style={{ fontSize: "20px" }}>
              {myBusinessDetails?.total_categories}
            </p>
            <MdShowChart size={35} color={"#6FC141"} />
          </div>
        </div>
        <div className="col-lg-2  col-md-3 col-12 my-2 d-flex justify-content-between">
          <div>
            <BsHandbag size={29} color={"#3F3F44"} />
            <p className="mt-4">Total Review</p>
          </div>
          <div style={{ color: "#6FC141" }}>
            <p style={{ fontSize: "20px" }}>
              {myBusinessDetails?.total_reviews}
            </p>
            <MdShowChart size={35} color={"#6FC141"} />
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-12 my-2 d-flex justify-content-between">
          <div>
            <BsHandbag size={29} color={"#3F3F44"} />
            <p className="mt-4">Total Offer</p>
          </div>
          <div style={{ color: "#6FC141" }}>
            <p style={{ fontSize: "20px" }}>
              {myBusinessDetails?.total_offers}
            </p>
            <MdShowChart size={35} color={"#6FC141"} />
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-12 my-2 d-flex justify-content-between">
          <div>
            <BsHandbag size={29} color={"#3F3F44"} />
            <p className="mt-4">Total Advertisement</p>
          </div>
          <div style={{ color: "#6FC141" }}>
            <p style={{ fontSize: "20px" }}>
              {myBusinessDetails?.total_advertisements}
            </p>
            <MdShowChart size={35} color={"#6FC141"} />
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-12 my-2 d-flex justify-content-between">
          <div>
            <BsHandbag size={29} color={"#3F3F44"} />
            <p className="mt-4">Total Invoice</p>
          </div>
          <div style={{ color: "#6FC141" }}>
            <p style={{ fontSize: "20px" }}>
              {myBusinessDetails?.total_invoices}
            </p>
            <MdShowChart size={35} color={"#6FC141"} />
          </div>
        </div>
      </div>
      <div
        className={`row gx-4 my-4 justify-content-between ${bCss.client_box2} `}
      >
        <div
          className={`col-lg-7 col-12 col-md-12 row gx-lg-3 py-3 px-2 rounded  gx-0 my-2   `}
        >
          <div className="col-12 row justify-content-between">
            <div className="col-12 py-3  col-md-5 col-lg-5 d-flex justify-content-between align-items-center  bg-white">
              <div className="py-4 px-3">
                <Backpack size={40} weight="light" color="#C2D4D9" />
              </div>
              <div className="fw-bold me-2">
                <h5 style={{ color: "#EF5B0C", fontSize: "26px" }}>
                  {myBusinessDetails?.total_products}
                </h5>
                <h6 style={{ color: "#1E3A56", fontSize: "18px" }}>
                  Total Products
                </h6>
              </div>
            </div>
            <div
              onClick={() => history.push(`/add-products`)}
              className=" py-4 mt-2 mt-md-0 px-3 d-flex justify-content-center align-items-center rounded-1 col-lg-5 bg-transparent"
              style={{
                border: "1px solid #EF5B0C",
                color: "#EF5B0C",
                cursor: "pointer",
              }}
            >
              <Plus size={22} weight="bold" className="me-2" color="#EF5B0C" />{" "}
              Add Products
            </div>
          </div>
          <div className={`${bCss.white2} mt-3`} style={{ display: "block" }}>
            <div className="mt-4">
              <div
                className="d-flex justify-content-between me-1 me-md-4 fw-bold"
                style={{ color: "#6B6B6B" }}
              >
                <p>Recent Customer Feedback</p>
                <p>Ratings</p>
              </div>
              <TableContainer
                style={{ border: "none" }}
                component={Paper}
                className="text-capitalize"
              >
                <Table aria-label="caption table">
                  <TableHead style={{ background: "white" }}>
                    <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="left">Name & Category</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Rating</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myBusinessDetails?.recent_reviews?.map((row) => {
                      const isItemSelected = isSelected(row.name);
                      return (
                        <TableRow
                          key={row.id}
                          style={{
                            margin: "10px 0px 10px 0px",
                          }}
                        >
                          <TableCell
                            padding="checkbox"
                            style={{ minWidth: "150px" }}
                          >
                            <div className="my-2 d-flex flex-column align-items-center">
                              <div className="d-flex gap-3">
                                <img
                                  src={row?.photos?.document}
                                  width={30}
                                  height={30}
                                  className="rounded-full my-1 mx-auto"
                                  alt="MB"
                                />
                              </div>

                              {/* <p
                                className="my-auto ms-1"
                                style={{ fontSize: "12px", color: "#939393" }}
                              >
                                10 Min Ago
                              </p> */}
                              <p
                                className="my-auto ms-1"
                                style={{ fontSize: "12px", color: "#939393" }}
                              >
                                {new Date(row.created_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}{" "}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell align="center" className="slug ">
                            <div>
                              <div className="d-flex align-items-center" style={{textAlign:"left"}}>
                                <p
                                  style={{
                                    color: "#1E3A56",
                                    fontSize: "16px",
                                  }}
                                >
                                  {row.client_name}
                                </p>
                                <div className="d-flex gap-2 mx-2 mb-3">
                                  {row?.review_info?.slice(0.3).map((item) => {
                                    return (
                                      <div className="menu_name">
                                        {item?.product_info?.category_name}
                                      </div>
                                    );
                                  })}

                                  {row?.review_info?.length > 3 ? (
                                    <div
                                      className="menu_name"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        history.push(`/clinet-reviews`)
                                      }
                                    >
                                      {" "}
                                      + {row?.review_info?.length - 3}
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>

                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#7A7A7A",
                                  textAlign: "left",
                                }}
                              >
                                {row.descriptions}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {review_status?.map((item) => {
                              return (
                                item.value === row.status && (
                                  <button
                                    className={`${
                                      row.status === 0
                                        ? "green-btn"
                                        : row.status === 1
                                        ? "red-btn"
                                        : "yellow-btn"
                                    }`}
                                  >
                                    {item.label}
                                  </button>
                                )
                              );
                            })}
                          </TableCell>
                          <TableCell align="center">
                            <span
                              className="d-flex justify-content-center"
                              style={{ marginTop: "" }}
                            >
                              <Star size={24} weight="fill" color="orange" />{" "}
                              {row?.review_info[0]?.star}
                            </span>
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => handleeReport(row.id)}
                          >
                            {" "}
                            <div
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              Report <BsQuestionCircleFill />{" "}
                            </div>{" "}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div className="d-flex justify-content-center my-3 align-items-end">
            <button
              style={{
                border: "1px solid #F1F1F1",
                outline: "none",
                color: "#1E3A56",
              }}
              className="bg-white w-100  py-2"
              onClick={() => history.push(`/clinet-reviews`)}
            >
              View All
            </button>
          </div>
        </div>
        <div
          className={`col-lg-5 col-md-12 rounded py-3 px-4  col-12 my-2 ${bCss.white3}`}
        >
          <div
            className="d-flex my-3 fw-bold  justify-content-between"
            style={{ fontSize: "16px", color: "#7A7A7A" }}
          >
            <p>Your Top Items</p>
            <p>Ratings</p>
          </div>
          {myBusinessDetails?.top_products_list?.map((item) => (
            <div
              key={item.id}
              style={{ borderTop: "1px solid #F1F1F1" }}
              className="d-flex py-3  justify-content-between align-items-center"
            >
              <p className="fw-semibold my-auto" style={{ color: "#7A7A7A" }}>
                {item.name}
              </p>
              <p
                className="my-auto"
                style={{ color: "orange", fontSize: "14px" }}
              >
                <Star size={20} weight="fill" color="orange" /> {item.rating} (
                {item.all_rating})
              </p>
            </div>
          ))}

          {/* table */}
          <div className="d-flex justify-content-center my-3 align-items-end">
            <button
              style={{
                border: "1px solid #F1F1F1",
                outline: "none",
                color: "#1E3A56",
              }}
              className="bg-white w-100  py-2"
              onClick={() => history.push(`/products`)}
            >
              View All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
