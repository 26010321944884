import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";
import Bar from "../Svgs/Bar";
import UserPagination from "../Common/UserPagination";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  ClientCategorydelete,
  GetClientCategoryList,
  NextClientCategoryList,
  PreviousClientCategoryList,
  SetClientCategoryUpdatedData,
  UpdateClientCategorydData,
} from "./category/_redux/action/ClientCategoryAction";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { BASE_URL } from "../../Const";
import Lottie from "react-lottie";
import noData2 from "../../assets/jsonFile/noData2.json";
import { GetUserNotification } from "../User/component/verifieduser/_redux/action/VerifiedUserAction";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { RowMenu } from "../Common/RowMenu";
import Button from "react-bootstrap/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Search } from "@material-ui/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import { Catalogues } from "../Common/Dropdown";
const CategoryList = ({ show }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const api = `${BASE_URL}api/v1/inventory/admin/category/?branches=${userData.client_id}&&limit=20&&search=${search}`;
  const [selected, setSelected] = React.useState([]);

  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );
  const isLoadClientCategory = useSelector(
    (state) => state.clientCategoryInfo.isLoadClientCategory
  );

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    dispatch(GetClientCategoryList(api));
    dispatch(GetUserNotification());
  }, []);
  React.useEffect(() => {
    dispatch(GetClientCategoryList(api));
  }, [search]);
  const handleEditUser = (data) => {
    dispatch(SetClientCategoryUpdatedData(data));
    show();
  };
  const handleRemoveUser = (id) => {
    dispatch(
      ClientCategorydelete(
        id,
        `${BASE_URL}api/v1/inventory/admin/category/?branches=${userData.client_id}&&limit=20&&search=${search}`
      )
    );
  };

  // here you can see checked data
  // all checked data are there in selectedUser

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(ClientCategoryList?.results);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextClientCategoryList(ClientCategoryList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousClientCategoryList(ClientCategoryList?.previous));
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteAll = () => {
    if (selectedUser) {
      selectedUser.map((item) => {
        return dispatch(
          ClientCategorydelete(
            item.id,
            `${BASE_URL}api/v1/inventory/admin/category/?branches=${userData.client_id}&&limit=20&&search=${search}`
          )
        );
      });
    }
  };

  return (
    <>
      {isLoadClientCategory && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadClientCategory &&
        ClientCategoryList?.results &&
        ClientCategoryList?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}

      {ClientCategoryList?.results?.length > 0 && (
        <div className="d-flex justify-content-between align-items-center">
          <span className="search">
            <Search className="search_icon2" />
            <input
              type="search"
              name=""
              id=""
              placeholder="Search comments, users.."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
          <Button onClick={() => handleDeleteAll()} variant="outline-danger">
            <DeleteIcon /> Multiple Delete
          </Button>{" "}
        </div>
      )}

      {!isLoadClientCategory &&
        ClientCategoryList?.results &&
        ClientCategoryList?.results !== null &&
        ClientCategoryList?.results?.length > 0 && (
          <TableContainer
            component={Paper}
            className="text-capitalize"
            sx={{ maxHeight: 670 }}
          >
            <Table stickyHeader aria-label="sticky table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "white" }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      // className="form-check-input"
                      name="allSelect"
                      checked={
                        selectedUser &&
                        selectedUser?.length ===
                          ClientCategoryList?.results?.length
                      }
                      onChange={(e) =>
                        handleChange(e, ClientCategoryList?.results)
                      }
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">Name</TableCell>
                  {/* <TableCell align="center">Slug</TableCell> */}
                  <TableCell align="center">Count</TableCell>
                  <TableCell align="center">
                    {" "}
                    <IconButton aria-label="more" onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <MenuItem onClick={() => handleDeleteAll()}>
                        Multiple Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "white" }}>
                {/* {Catalogues */}
                {ClientCategoryList?.results

                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.name}
                            checked={
                              selectedUser &&
                              selectedUser?.some((item) => item?.id === row.id)
                            }
                            onChange={(e) => handleChange(e, row)}
                          />
                        </TableCell>
                        <TableCell align="center" className="name">
                          <div
                            className="d-flex align-items-center gap-2 fw-bold"
                            style={{ color: "#EF5B0C" }}
                          >
                            <p>{row.name}</p>
                          </div>
                        </TableCell>
                        {/* <TableCell
                          align="center"
                          className="slug fw-bold"
                          style={{ color: "#7A7A7A" }}
                        >
                          <p>{row.slug}</p>
                        </TableCell> */}
                        <TableCell align="center" style={{ color: "#7A7A7A" }}>
                          <p>{row.count}</p>
                        </TableCell>

                        <TableCell align="center">
                          <RowMenu
                            row={row}
                            handleeEdit={handleEditUser}
                            handleDelete={handleRemoveUser}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3 ">
        <p>Total : {ClientCategoryList?.count}</p>

        <UserPagination
          prev_next={ClientCategoryList}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default CategoryList;
