import * as Types from "../types/Types";
const initialState = {
  appUserInput: {
    password: "",
    first_name: "",
    last_name: "string",
    username: "",
    email: "",
    mobile: "",
    dob: null,
    gender: 0,
    address1: "",
    address2: "",
    district: "",
    wallet: 0,
    membership_level: 1,
    role: 0,
    posts: 0,
    followers: 0,
    is_app_user: true,
    is_verified: true,
    is_approved: true,
    is_active: true,
    is_staff: true,
    is_superuser: true,
    bio: "",
    image: null,
    country: 20,
  },
  requestedUserInput: {
    password: "",
    first_name: "",
    last_name: "string",
    username: "",
    email: "",
    mobile: "",
    dob: null,
    gender: 0,
    address1: "",
    address2: "",
    district: "",
    wallet: 0,
    membership_level: 1,
    role: 0,
    posts: 0,
    followers: 0,
    is_app_user: true,
    is_verified: false,
    is_approved: false,
    is_active: true,
    is_staff: true,
    is_superuser: false,
    is_requested:true,
    bio: "",
    // image: 0,
    country: 20,
    
  },

  appUserList: null,
  requestedUserList: null,
  userPagination:null,
  userList: { app_user_arr: [], userList: [] },
  appUserDetails: null,
  isAppUserDeleted: null,
  countryList: null,
  isLoadAppUser: false,
  afterAppUserData: false,
};
const AppUserReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_APP_USER_INPUT:
      const appUserInput = { ...state.appUserInput };
      appUserInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        appUserInput: appUserInput,
      };
    case Types.GET_REQUESTED_USER_INPUT:
      const requestedUserInput = { ...state.requestedUserInput };
      requestedUserInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        requestedUserInput: requestedUserInput,
      };
    case Types.IS_LOAD_APP_USER:
      return {
        ...state,
        isLoadAppUser: action.payload,
      };
    case Types.AFTER_APP_USER_DATA:
      return {
        ...state,
        afterAppUserData: action.payload,
      };
    case Types.GET_APP_USER_LIST:
      return {
        ...state,
        appUserList: action.payload,
      };
    case Types.GET_REQUESTED_USER_LIST:
      return {
        ...state,
        requestedUserList: action.payload,
      };

  
    case Types.GET_APP_USER_DETAILS:
      return {
        ...state,
        appUserDetails: action.payload,
      };
    case Types.SET_APP_USER_FALSE:
      return {
        ...state,
        afterAppUserData: action.payload,
        appUserInput: initialState.appUserInput,
      };
    case Types.IS_APP_USER_DELETED:
      return {
        ...state,
        isAppUserDeleted: action.payload,
      };
    case Types.GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case Types.GET_USER_PAGINATION:
      return {
        ...state,
        userPagination: action.payload,
      };

    case Types.GET_REQUESTED_USER_UODATED:
      const setrequestedUserInput = { ...state.requestedUserInput };
      setrequestedUserInput.id = action.payload.id;
      setrequestedUserInput.first_name = action.payload.first_name;
      setrequestedUserInput.last_name = action.payload.last_name;
      setrequestedUserInput.username = action.payload.username;
      setrequestedUserInput.email = action.payload.email;
      setrequestedUserInput.dob = action.payload.dob;
      setrequestedUserInput.gender = action.payload.gender;
      setrequestedUserInput.address1 = action.payload.address1;
      setrequestedUserInput.address2 = action.payload.address2;
      setrequestedUserInput.district = action.payload.district;
      setrequestedUserInput.wallet = action.payload.wallet;
      setrequestedUserInput.membership_level = action.payload.membership_level;
      setrequestedUserInput.role = action.payload.role;
      setrequestedUserInput.posts = action.payload.posts;
      setrequestedUserInput.followers = action.payload.followers;
      setrequestedUserInput.is_app_user = action.payload.is_app_user;
      setrequestedUserInput.is_verified = action.payload.is_verified;
      setrequestedUserInput.is_approved = action.payload.is_approved;
      setrequestedUserInput.role = action.payload.role;
      setrequestedUserInput.is_active = action.payload.is_active;
      setrequestedUserInput.is_staff = action.payload.is_staff;
      setrequestedUserInput.is_superuser = action.payload.is_superuser;
      setrequestedUserInput.bio = action.payload.bio;
      setrequestedUserInput.country = action.payload.country;
      setrequestedUserInput.image = action.payload.image;
      setrequestedUserInput.image_url = action.payload.image_url;
      setrequestedUserInput.mobile = action.payload.mobile;
      // setrequestedUserInput.password = action.payload.password;
      return {
        ...state,
        requestedUserInput: setrequestedUserInput,
      };
    case Types.GET_APP_USER_UODATED:
      const setappUserInput = { ...state.appUserInput };
      setappUserInput.id = action.payload.id;
      setappUserInput.first_name = action.payload.first_name;
      setappUserInput.last_name = action.payload.last_name;
      setappUserInput.username = action.payload.username;
      setappUserInput.email = action.payload.email;
      setappUserInput.dob = action.payload.dob;
      setappUserInput.gender = action.payload.gender;
      setappUserInput.address1 = action.payload.address1;
      setappUserInput.address2 = action.payload.address2;
      setappUserInput.district = action.payload.district;
      setappUserInput.wallet = action.payload.wallet;
      setappUserInput.membership_level = action.payload.membership_level;
      setappUserInput.role = action.payload.role;
      setappUserInput.posts = action.payload.posts;
      setappUserInput.followers = action.payload.followers;
      setappUserInput.is_app_user = action.payload.is_app_user;
      setappUserInput.is_verified = action.payload.is_verified;
      setappUserInput.is_approved = action.payload.is_approved;
      setappUserInput.role = action.payload.role;
      setappUserInput.is_active = action.payload.is_active;
      setappUserInput.is_staff = action.payload.is_staff;
      setappUserInput.is_superuser = action.payload.is_superuser;
      setappUserInput.bio = action.payload.bio;
      setappUserInput.country = action.payload.country;
      setappUserInput.image = action.payload.image;
      setappUserInput.image_url = action.payload.image_url;
      setappUserInput.mobile = action.payload.mobile;
      // setappUserInput.password = action.payload.password;
      return {
        ...state,
        appUserInput: setappUserInput,
      };

    default:
      break;
  }
  return newState;
};

export default AppUserReducer;
