import React, { useEffect, useState } from "react";
import "./Settings.css";
import user1 from "../../assets/img/user1.jpg";
import UploadImage from "./UploadImage";
import ArrowSvg from "../Common/ArrowSvg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  GetGeneralInput,
  SetGeneralStatusFalse,
  SubmitGeneraldData,
  SubmitImage,
} from "./_redux/action/SeetingsAction";
import { user_role } from "../Common/Dropdown";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
const General = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const generalInput = useSelector((state) => state.seetingInfo.generalInput);
  const isLoadSeetings = useSelector(
    (state) => state.seetingInfo.isLoadSeetings
  );
  const afterGeneralData = useSelector(
    (state) => state.seetingInfo.afterGeneralData
  );
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const handleChangeInput = (name, value, e) => {
    dispatch(GetGeneralInput(name, value, e));
  };
  const handleSubmitGeneral = (data) => {
    dispatch(SubmitGeneraldData(data));
  };
  useEffect(() => {
    if (afterGeneralData) dispatch(SetGeneralStatusFalse());
  }, [afterGeneralData]);

  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);
  return (
    <div className="parent">
      {isLoadSeetings && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Seetings /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>General</span>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Your company's name*</p>
            <input
              type="text"
              placeholder="Menubook BD"
              name="name"
              value={generalInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Your company Address*</p>
            <input
              type="text"
              placeholder="Menubook BD"
              name="address"
              value={generalInput.address}
              onChange={(e) => handleChangeInput("address", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Administration Email Address</p>

            <input
              type="email"
              placeholder="Menubook@gmail.com"
              name="email"
              value={generalInput.email}
              onChange={(e) => handleChangeInput("email", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
          <p>Contact Number</p>

            <input
              type="email"
              placeholder="Menubook@gmail.com"
              name="mobile"
              value={generalInput.mobile}
              onChange={(e) => handleChangeInput("mobile", e.target.value)}
            />
          </div>
        </div>
     

        {/*logo image */}
        <UploadImage
          title="Choose a logo file:"
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "logo"));
            handleChangeInput("fileName1", e.target.files[0].name);
            handleChangeInput("size1", e.target.files[0].size);
           
            
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              // setPdfFile(e.target.result);
              handleChangeInput("logo", e.target.result);
            };
          }}
          name="logo"
          url={generalInput?.logo}
          file_name={generalInput.fileName1}
          size={generalInput.size1 / 1000}
        />
        {/*fabicon */}
        <UploadImage
          title="Choose a fav icon:"
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "fav_icon"));
            handleChangeInput("fileName", e.target.files[0].name);
            handleChangeInput("size", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              // setPdfFile(e.target.result);
              handleChangeInput("fav_icon", e.target.result);
              // handleChangeInput("fav_icon", e.target.files[0]);
            };
          }}
          name="fav_icon"
          url={generalInput?.fav_icon}
          file_name={generalInput.fileName}
          size={generalInput.size / 1000}
        />
        <div>
          <button
            className="save-btn"
            onClick={() => handleSubmitGeneral(generalInput)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default General;
