import * as React from "react";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { FaEdit, FaEye } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import Avatar from "@mui/material/Avatar";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
// import UserPagination from "../../verifieduser/UserPagination";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Alluserdelete,
  GetAppUserDetails,
  GetAppuserList,
  NextUserListList,
  PreviousOrderList,
  SetAppUserUpdatedData,
} from "../_redux/action/AppUserAction";
import { BASE_URL } from "../../../../../Const";
import UserPagination from "../../../../Common/UserPagination";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "react-bootstrap/Button";

const AppUserList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userapi = `${BASE_URL}api/v1/users/admin/?limit=20&&search=${search}&&client=${userData.client_id}`;
  const [selected, setSelected] = React.useState([]);
  const options = ["None", "Atria", "Callisto"];
  const appUserList = useSelector((state) => state.appUserInfo.appUserList);
  const userPagination = useSelector(
    (state) => state.appUserInfo.userPagination
  );
  const userList = useSelector((state) => state.appUserInfo.userList);
  const isLoadAppUser = useSelector((state) => state.appUserInfo.isLoadAppUser);

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);
  console.log("selectedUser", selectedUser, users);
  React.useEffect(() => {
    dispatch(GetAppuserList(userapi));
    setUsers(appUserList?.results);
    
  }, []);

  React.useEffect(() => {
    if (selectedName === "is_app_user") {
      dispatch(GetAppuserList(`${BASE_URL}api/v1/users/admin/?limit=20&&search=${search}&&client=${userData.client_id}`));
    } else if (selectedName === "is_verified") {
      dispatch(
        GetAppuserList(
          `${BASE_URL}api/v1/users/admin/?is_verified=true&&limit=20&&search=${search}&&client=${userData.client_id}`
        )
      );
    } else if (selectedName === "is_requested") {
      dispatch(
        GetAppuserList(
          `${BASE_URL}api/v1/users/admin/?is_requested=true&&limit=20&&client=${userData.client_id}`
        )
      );
    }
  }, [selectedName, search]);

  // here you can see checked data
  // all checked data are there in selectedUser

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(appUserList?.results);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleDeleteAll = () => {
    if (selectedUser) {
      selectedUser.map((item) => {
        return dispatch(
          Alluserdelete(
            item.id,
            userapi
          )
        );
      });
    }
  };

  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  // ----------------------api calll--------------------
  const handleEditUser = (id) => {
    dispatch(SetAppUserUpdatedData(id));
    history.push(`/edit-user/${id}`);
  };
  const handleViewUser = (id) => {
    dispatch(GetAppUserDetails(id));
    history.push(`/profile/${id}`);
  };

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextUserListList(appUserList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousOrderList(appUserList?.previous));
  };

  return (
    <div>
      {isLoadAppUser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadAppUser &&
        appUserList?.results &&
        appUserList?.results.length === 0 && (
          <>
            <div class="alert alert-success text-center mt-150" role="alert">
              Sorry ! No order found.
            </div>
          </>
        )}
      {appUserList?.results?.length > 0 && (
        <div className="d-flex justify-content-end mb-3">
            <Button onClick={() => handleDeleteAll()} variant="outline-danger">
            <DeleteIcon /> Multiple Delete
          </Button>{" "}
        </div>
      )}
      {!isLoadAppUser &&
        appUserList?.results &&
        appUserList?.results !== null &&
        appUserList?.results.length > 0 && (
          <TableContainer
            component={Paper}
            className="text-capitalize"
            sx={{ maxHeight: 670 }}
          >
            <Table stickyHeader aria-label="sticky table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "white" }}>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    // style={{ paddingBottom: 10, paddingTop: 10 }}
                    // colSpan={2}
                  >
                    <Checkbox
                      color="primary"
                      // className="form-check-input"
                      name="allSelect"
                      checked={  selectedUser && selectedUser?.length === appUserList?.results?.length}
                      onChange={(e) => handleChange(e, appUserList?.results)}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">User</TableCell>
                  <TableCell align="center">User Role</TableCell>
                  <TableCell align="center">Contact Info</TableCell>
                  <TableCell align="center">Review Count</TableCell>
                  <TableCell align="center">Followers</TableCell>
                  <TableCell align="center">Membership Level</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "white" }}>
                {/* {userList?.app_user_arr?.map((row) => { */}
                {appUserList?.results
                  ?.filter((val) => {
                    if (search === "") {
                      return val;
                    } else if (
                      val?.username
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.email?.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })

                  .map((row) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.username}
                            // checked when selectedUser contains checked object/filed/row
                            checked={selectedUser?.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange(e, row)}
                            //  checked={isItemSelected}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <div className="d-flex  gap-2">
                            <Avatar
                              alt={row.first_name}
                              src="/static/images/avatar/1.jpg"
                            />{" "}
                            <div
                              style={{ textAlign: "left", lineHeight: "20px" }}
                            >
                              <p
                                style={{
                                  color: "#2D2D2D",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  marginBottom: "0",
                                }}
                              >
                                {row.first_name} {row.last_name}
                              </p>
                              {/* <p
                              style={{
                                color: "#2D2D2D",
                                fontSize: "12px",
                                margin: "0",
                              }}
                            >
                              45 Approved
                            </p> */}
                              <p
                                style={{
                                  color: "#7A7A7A",
                                  fontSize: "12px",
                                  margin: "0",
                                }}
                              >
                                Joined:{" "}
                                {new Date(row.created_at)
                                  .toISOString()
                                  .slice(0, 10)}
                                {/* {row.created_at} */}
                              </p>
                              <p
                                onClick={() => history.push(`/review`)}
                                style={{
                                  color: "#EF5B0C",
                                  fontSize: "12px",
                                  margin: "0",
                                  cursor: "pointer",
                                }}
                              >
                                {/* View All Reviews */}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ color: "#EF5B0C", fontWeight: "bold" }}
                        >
                          <p>
                            {" "}
                            {
                              row.role === 0 && "Food Blogger"
                              // : row.role === 1
                              // ? "Client"
                              // : "User"
                            }
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{ textAlign: "center", lineHeight: "20px" }}
                          >
                            <p
                              style={{
                                color: "#7A7A7A",
                                fontSize: "14px",
                                fontWeight: "500",
                                marginBottom: "0",
                              }}
                            >
                              {row.email}
                            </p>{" "}
                            <p
                              style={{
                                color: "#7A7A7A",
                                fontSize: "14px",
                                fontWeight: "500",
                                marginBottom: "0",
                              }}
                            >
                              {row.mobile}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "#7A7A7A",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          <p> {row.posts}</p>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            color: "#7A7A7A",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          <p> {row.followers}</p>
                        </TableCell>
                        <TableCell align="center" className="me-2">
                          <p
                            style={{
                              color: "#7A7A7A",
                              fontSize: "14px",
                              fontWeight: "500",
                              marginBottom: "0",
                            }}
                          >
                            {" "}
                            {row.membership_level === 1
                              ? "Verified"
                              : row.membership_level === 0 && "Not Verified"}
                            {row.membership_level === 1 && (
                              <MdVerified
                                style={{ color: "#EF5B0C", fontSize: "20px" }}
                              />
                            )}
                            {/* <MdVerified style={{ color: "#EF5B0C" }} /> */}
                          </p>
                        </TableCell>
                        <TableCell align="center" className="table_icon">
                          <span
                            className="d-flex justify-content-center"
                            onClick={() => handleViewUser(row.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <FaEye
                              className="me-2"
                              style={{ fontSize: "20px" }}
                            />
                            view
                          </span>
                          <br />
                          <span
                            className="d-flex justify-content-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEditUser(row.id)}
                          >
                            <FaEdit
                              className="me-2"
                              style={{ fontSize: "20px" }}
                            />
                            Edit
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      <div className="mt-4 ">{/* <UserPagination /> */}</div>

      <div className="d-flex pagination justify-content-end align-items-center gap-3 ">
        <p>Total : {appUserList?.count}</p>

        <UserPagination
          prev_next={appUserList}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default AppUserList;
