export const INPUT_LOGIN_VALUE = "INPUT_LOGIN_VALUE"
export const AFTER_LOGIN_DATA = "AFTER_LOGIN_DATA"
export const SET_LOGIN_FALSE = "SET_LOGIN_FALSE"
export const LOGIN_MESSAGE = "LOGIN_MESSAGE"
export const IS_LOAD_LOGIN = "IS_LOAD_LOGIN"

export const GET_FORGET_PASSWORD = "GET_FORGET_PASSWORD"
export const AFTER_FORGET_PASSWORD = "AFTER_FORGET_PASSWORD"

export const GET_VERIFICATION_CHECK = "GET_VERIFICATION_CHECK"
export const AFTER_VERIFICATION_CHECK = "AFTER_VERIFICATION_CHECK"

export const GET_RESEND_VERIFICATION_INPUT = "GET_RESEND_VERIFICATION_INPUT"
export const AFTER_RESEND_INPUT = "AFTER_RESEND_INPUT"
export const SET_VERIFICATION_FALSE = "SET_VERIFICATION_FALSE"

export const GET_CONFIRM_PASS_INPUT = "GET_CONFIRM_PASS_INPUT"
export const AFTER_CONFIRM_PASSWORD = "AFTER_CONFIRM_PASSWORD"
export const SET_CONFIRM_PASS_FALSE= "SET_CONFIRM_PASS_FALSE"

