/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form } from "antd";

// import Map from "./Map1";
import Location from "./Location";
import Spin from "./Spinner";

import "./styles.css";

const AddressSearch = ({}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState("");

  const [townsList, setTownsList] = useState([]);

  const handleLocationSearch = (value) => {};

  const handleCancel = () => {};

  return (
    <>
      <section className="shipping-address-section">
        {loading && <Spin title="Saving..." />}

        <AddressForm
          selectedAddress={selectedAddress}
          onCancel={handleCancel}
          loader={loader}
          townsList={townsList}
          handleLocationSearch={handleLocationSearch}
        />
      </section>
    </>
  );
};

export default AddressSearch;

const AddressForm = ({}) => {
  const [form] = Form.useForm();

  const [location, setLocation] = useState({});
  const [placeObject, setPlaceObject] = useState({});

  const onPlaceSelected = (place) => {
    const lat = place?.geometry?.location?.lat();
    const lon = place?.geometry?.location?.lng();
    setLocation({ lat, lon });
    setPlaceObject(place);
    // dispatch(GetClientInput("latitude", location.lat));
    // dispatch(GetClientInput("latitude", location.lon));
    // dispatch(GetClientInput("location", location.name));
  };

  const onSubmit = () => {};

  return (
    <Form form={form} onFinish={onSubmit}>
      <Location
        onPlaceSelected={onPlaceSelected}
        lat={Number(location?.lat)}
        lon={Number(location?.lon)}
        placeObject={placeObject}
      />
    </Form>
  );
};
