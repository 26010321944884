import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";
export const GetImageInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });

  if (name === "doc_type") {
    let reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      formData.name = "doc_url";
      formData.value = reader.result;
      dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });
    };
    reader.readAsDataURL(file);
  }
  //  }
};
export const SubmitImage = (data) => (dispatch) => {
  const formData = new FormData();
  const urlImg = `${BASE_URL}api/v1/auth/upload/`;

  formData.append("document", data.document);
  formData.append("doc_type", data.doc_type);
  //   formData.append("owner", data.owner);

  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });
  try {
    Axios.post(urlImg, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((resImg) => {
        if (resImg.data.status) {
          //   dispatch({ type: Types.IS_SUCCESS_IMAGE, payload: true });
          showToast("success", "Image Uploaded successfully");

          dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        } else {
          showToast("error", resImg.data.message);
          dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        const message = JSON.parse(err.request.response).message;
        showToast("error", message);
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });
};
export const GetBulkInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({
    type: Types.GET_BULK_INPUT,
    payload: formData,
  });
};

export const SetBulkStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_BULK_FALSE, payload: false });
};
export const SubmitBulkData = (data) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  data.client =userData.client_id
  if (data.file === "") {
    showToast("error", "Add Valid Menu Book Excel File First !");
    return 0;
  }
  const formData = new FormData();
  const urlImg = `${BASE_URL}api/v1/inventory/admin/product/bulk-import/`;

  formData.append("file", data.file);
  formData.append("client", data.client);
  //   formData.append("owner", data.owner);

  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });
  try {
    Axios.post(urlImg, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((resImg) => {
        if (resImg.data.status) {
          dispatch({ type: Types.AFTER_BULK_DATA, payload: true });
          showToast("success", "Bulk Import successfully");

          dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        } else {
          showToast("error", resImg.data.message);
          dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        }
      })
      .catch((err) => {
        console.log(err, JSON.parse(err.request.response).errors.msg);
        dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        const message = JSON.parse(err.request.response).errors.msg;
        swal({
          icon: "error",
          // text: `${errorMsg[key] === 'File is not a zip file' ? 'Only Exel file supported!' : errorMsg[key]}`
          text: message,
        });
        // showToast("error", message);
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });
};

// =========================================client bulk========================
export const GetClientBulkInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({
    type: Types.GET_CLIENT_BULK_INPUT,
    payload: formData,
  });
};

export const SubmitClientBulkData = (data) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  data.client =userData.client_id
  if (data.file === "") {
    showToast("error", "Add Valid Menu book Excel File First !");
    return 0;
  }
  const formData = new FormData();
  const urlImg = `${BASE_URL}api/v1/restaurant/admin/bulk-import/`;

  formData.append("file", data.file);

  //   formData.append("owner", data.owner);

  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });
  try {
    Axios.post(urlImg, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((resImg) => {
        if (resImg.data.status) {
          dispatch({ type: Types.AFTER_BULK_DATA, payload: true });
          showToast("success", "Bulk Import successfully");

          dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        } else {
          showToast("error", resImg.data.message);
          dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        }
      })
      .catch((err) => {
        console.log(err, JSON.parse(err.request.response).errors.msg);
        dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
        const message = JSON.parse(err.request.response).errors.msg;
        swal({
          icon: "error",
          // text: `${errorMsg[key] === 'File is not a zip file' ? 'Only Exel file supported!' : errorMsg[key]}`
          text: message,
        });
        // showToast("error", message);
      });
  } catch (error) {
    showToast("error", "Something went wrong");
  }
  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });
};
