import React from "react";
import { useHistory } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CaretDown, CaretUp } from "phosphor-react";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Lottie from "react-lottie";
import noData2 from "../../../assets/jsonFile/noData2.json";
import { Edit } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { GetInvoiceList, NextInvoiceList, PreviousInvoiceList, SetInvoiceUpdatedData } from "./_redux/action/InvoiceAction";
import { BASE_URL } from "../../../Const";
import UserPagination from "../../Common/UserPagination";
import EditInvoice from "./EditInvoice";
const InvoiceList = ({selectedStardDate,selectedName, search, export_id}) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const invoice_List = useSelector((state) => state.inVoiceInfo.invoiceList);
  const isLoadInvoice = useSelector((state) => state.inVoiceInfo.isLoadInvoice);
  // React.useEffect(() => {
  //   dispatch(GetInvoiceList());
  // }, []);
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  React.useEffect(() => {
 
    dispatch(GetInvoiceList(`${BASE_URL}api/v1/transactions/admin/invoice/?client=${userData.client_id}&limit=20&&search=${search}`));
  }, []);
    // --------------pagination----------------
const [currentPage, setCurrentPage] = React.useState(1);

const handleNexteClick = (pageNo) => {
  setCurrentPage(pageNo);
  dispatch(NextInvoiceList(invoice_List?.next));
};
const handlePreviousClick = (pageNo) => {
  setCurrentPage(pageNo);
  dispatch(PreviousInvoiceList(invoice_List?.previous));
};

const handleUpdate =(data)=>{
  setModalShow(true);
    dispatch(SetInvoiceUpdatedData(data.id));
}

React.useEffect(() => {
  if (selectedName) {
    dispatch(
      GetInvoiceList(
        `${BASE_URL}api/v1/transactions/admin/invoice/?client=${userData.client_id}&category=${selectedName}&&limit=20&&search=${search}`
      )
    );
  }
  if( selectedStardDate ){
    dispatch(
      GetInvoiceList(
        `${BASE_URL}api/v1/transactions/admin/invoice/?client=${userData.client_id}&limit=20&invoice_date=${selectedStardDate}&&search=${search}`
      )
    );
  }
  else {
    dispatch(
      GetInvoiceList(`${BASE_URL}api/v1/transactions/admin/invoice/?client=${userData.client_id}&limit=20&&search=${search}`)
    );
  }
 
}, [selectedStardDate,selectedName]);
  return (
    <>
     <EditInvoice
        show={modalShow}
        onHide={() => setModalShow(false)}
        api={`${BASE_URL}api/v1/transactions/admin/invoice/?limit=20&&search=${search}`}
      />
      {isLoadInvoice && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!isLoadInvoice &&
        invoice_List?.results &&
        invoice_List?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}

      {!isLoadInvoice &&
        invoice_List?.results &&
        invoice_List?.results !== null &&
        invoice_List?.results?.length > 0 && (
          <TableContainer
          component={Paper}
          className="text-capitalize"
          sx={{ maxHeight: 670 }}
        >
          <Table stickyHeader aria-label="sticky table" id={export_id}>
          <TableHead style={{ background: "white" }}>
                <TableRow style={{ color: "#54617A" }}>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        INVOICE ID
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          size={10}
                          style={{ marginTop: "-3px" }}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        CLIENT ID
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          size={10}
                          style={{ marginTop: "-3px" }}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        COMPANY NAME
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          size={10}
                          style={{ marginTop: "-3px" }}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        BRANCH
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          size={10}
                          style={{ marginTop: "-3px" }}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        REFERENCE
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          style={{ marginTop: "-3px" }}
                          size={10}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        INVOICE TIME
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          style={{ marginTop: "-3px" }}
                          size={10}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        PRICE
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          style={{ marginTop: "-3px" }}
                          size={10}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        Transection ID
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          style={{ marginTop: "-3px" }}
                          size={10}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                      Payment date
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          style={{ marginTop: "-3px" }}
                          size={10}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        STATUS
                      </p>
                      <span className="d-flex ms-2 flex-column">
                        <CaretUp size={10} weight="fill" color="#54617A" />
                        <CaretDown
                          style={{ marginTop: "-3px" }}
                          size={10}
                          weight="fill"
                          color="#54617A"
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="d-flex  align-items-center">
                      <p className="my-auto" style={{ color: "#54617A" }}>
                        Action
                      </p>
                      
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "white" }}>
                {invoice_List?.results?.filter((val) => {
                if (search == "") {
                  return val;
                } else if (
                  val?.invoice_id.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                } else if (
                  val?.client_company_name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                }
                // else if (
                //   val?.location.toLowerCase().includes(search.toLowerCase())
                // ) {
                //   return val;
                // }
              }).map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell align="center" className="name">
                        <div
                          className="d-flex align-items-center gap-2 fw-bold"
                          style={{ color: "#7A7A7A" }}
                        >
                          {row.invoice_id}
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="slug fw-bold"
                        style={{ color: "#7A7A7A" }}
                      >
                        {row.client_id}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#7A7A7A" }}>
                        {row.client_company_name}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#7A7A7A" }}>
                        {row.branch_name}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#7A7A7A" }}>
                        {row.reference}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#7A7A7A" }}>
                        {new Date(row.invoice_date).toISOString().slice(0, 10)}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#7A7A7A" }}>
                        {row.price}
                      </TableCell>
                      <TableCell align="center" style={{ color: "#7A7A7A" }}>
                        {row.transaction_id }
                      </TableCell>
                      <TableCell align="left" style={{ color: "#7A7A7A" }}>
                      {/* {new Date(row.updated_at).toISOString().slice(0, 10)} */}
                      {new Date(row.updated_at).toLocaleDateString(
                            "en-US",
                            options)}
                      </TableCell>
                      <TableCell align="left" className="placement_td">
                        {" "}
                        {/* <select name="" id="" className="me-2">
                          <option value="Active">Active</option>
                          <option value="Pending">Pending</option>
                        </select> */}
                        {row.status === 0 ? (
                          <button className="yellow-btn">Pending</button>
                        ) : row.status === 1 ? (
                          <button className="green-btn">Paid</button>
                        ) : (
                          <button
                            className="green-btn"
                            style={{ background: "red" }}
                          >
                            Cancelled
                          </button>
                        )}
                      </TableCell>
                      <TableCell align="left" style={{ color: "#7A7A7A", cursor:"pointer" }} onClick={()=>handleUpdate(row)}>
                        <Edit/>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

<div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {invoice_List?.count}</p>
        <UserPagination
          prev_next={invoice_List}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default InvoiceList;
