import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BsQuestionCircleFill } from "react-icons/bs";
import Avatar from "@mui/material/Avatar";

import Checkbox from "@mui/material/Checkbox";
import UserPagination from "../../Common/UserPagination";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Star } from "@material-ui/icons";
import {
  GetRevieList,
  NextreviewListList,
  PreviousreviewList,
  SetReviewUpdatedData,
} from "../_redux/action/ReviewAction";
import { review_status, statusList } from "../../Common/Dropdown";
import { GetClientList } from "../../Clients/all_clients/_redux/action/ClientAction";
import { BASE_URL } from "../../../Const";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Lottie from "react-lottie";
import noData from "../../../assets/jsonFile/noData2.json";
import { ReportRowMenu } from "./ReportRowMenu";
import AddReport from "../../Clients/ClientAdvertisement/AddReport";
import AddReportReview from "../../Clients/ClientAdvertisement/AddReportReview";
const ReviewList = ({ search, selectedName }) => {
  let sum = 0;
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);

  const review_list = useSelector((state) => state.reviewInfo.reviewList);
  const isLoadReview = useSelector((state) => state.reviewInfo.isLoadReview);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    // localStorage.removeItem("client_id");
    setUsers(review_list?.results);
    dispatch(GetRevieList(`${BASE_URL}api/v1/feeds/admin/review/?limit=20`));
    // dispatch(GetClientList(`${BASE_URL}api/v1/restaurant/admin/clients/`));
  }, []);
  // ====================filter===========
  React.useEffect(() => {
    if (selectedName) {
      dispatch(
        GetRevieList(
          `${BASE_URL}api/v1/feeds/admin/review/?status=${selectedName}&&limit=20`
        )
      );
    } else {
      dispatch(GetRevieList(`${BASE_URL}api/v1/feeds/admin/review/?limit=20`));
    }
  }, [selectedName]);

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextreviewListList(review_list?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousreviewList(review_list?.previous));
  };
  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [modalShow, setModalShow] = React.useState(false);
  const handleeReport = (id) => {
    setModalShow(true);
    dispatch(SetReviewUpdatedData(id));
  };
  return (
    <>
      <AddReportReview show={modalShow} onHide={() => setModalShow(false)} />
      {isLoadReview && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadReview &&
        review_list?.results &&
        review_list?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}
      {!isLoadReview &&
        review_list?.results &&
        review_list?.results !== null &&
        review_list?.results.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "white" }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      // className="form-check-input"
                      name="allSelect"
                      checked={selectedUser?.length === users?.length}
                      onChange={(e) => handleChange(e, users)}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">User</TableCell>
                  <TableCell align="left">Comments</TableCell>
                  <TableCell align="left">Restaurant</TableCell>
                  <TableCell align="left">Rating</TableCell>
                  <TableCell align="left">Status</TableCell>

                  <TableCell align="center">
                    {" "}
                    <IconButton aria-label="more">
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ background: "white" }}>
                {review_list?.results
                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val?.reviewed_by_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.descriptions
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((row) => {
                    const isItemSelected = isSelected(row?.name);
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.id}
                            // checked when selectedUser contains checked object/filed/row
                            checked={selectedUser.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange(e, row)}
                            //  checked={isItemSelected}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <div className="d-flex align-items-center gap-2 td-name">
                            <Avatar alt="User1" src={row.reviewed_by_image} />{" "}
                            <p>
                              {" "}
                              {row?.reviewed_by_name}
                              <br />
                              <span style={{ color: "#7A7A7A" }}>
                                Joined:{" "}
                                {new Date(row.created_at)
                                  .toISOString()
                                  .slice(0, 10)}
                              </span>
                            </p>
                          </div>
                        </TableCell>
                        <TableCell align="left" className="comment_td">
                          <>
                            {/* <h6>Chicken Burger (4/5)</h6> */}
                            <p>{row.descriptions}</p>
                            <div className="d-flex gap-3 mb-3">
                              {row.photos.map((item) => {
                                return <img src={item.document} alt="" />;
                              })}
                            </div>
                            <div className="d-flex gap-2 mb-3">
                              {row.review_info.map((item) => {
                                return (
                                  <>
                                  {item?.product_info?.rating && 
                                  <div className="menu_name">
                                  {item?.product_info &&
                                    item?.product_info?.name}{" "}
                                  (
                                  {item?.product_info?.rating &&
                                    item?.product_info?.rating}
                                  )
                                </div>
                                  }
                                   
                                  </>
                                 
                                );
                              })}
                            </div>
                            {/* <div className="d-flex gap-2 mb-3">
                          <div className="menu_name">
                            Beef Burger Title (4/5)
                          </div>
                          <div className="menu_name">Beef Burger (4/5)</div>
                        </div> */}
                          </>
                        </TableCell>
                        <TableCell>
                          <h6
                            className="comment_td "
                            style={{ color: "#7A7A7A" }}
                          >
                            {row.client_name}
                            {/* {row.review_info.map((item) => {
                              return item.product_info.restaurant;
                            })} */}
                          </h6>
                        </TableCell>
                        <TableCell align="left" className="rating_td">
                          <span className="d-flex align-items-center">
                            <Star /> {row?.client_review}
                            {/* {row.review_info.forEach((value) => {
                              return (sum += value.star);
                            })} */}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          {review_status?.map((item) => {
                            return (
                              item.value === row.status && (
                                <button
                                  className={`${
                                    row.status === 0
                                      ? "green-btn"
                                      : row.status === 1
                                      ? "red-btn"
                                      : "yellow-btn"
                                  }`}
                                >
                                  {item.label}
                                </button>
                              )
                            );
                          })}
                        </TableCell>

                        <TableCell align="center">
                          <ReportRowMenu
                            row={row}
                            handleeReport={handleeReport}
                          />{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {review_list?.count}</p>
        <UserPagination
          prev_next={review_list}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default ReviewList;
