import * as Types from "../types/Types";

const initialState = {
  addvertisementInput: {
    id:"",
    name: "",
    placement: "",
    no_of_days: "",
    // views: "",
    // clicks: "",
    status: 1,
    ad_for: "",
    banner: null,
    banner_url: "",
    start_date:null,
    expiry_date:null,
  },

  addvertisementList: null,
 
//   clientDetails: null,
//   isAddvertisementDeleted: null,
 
  isLoadAdd: false,
  afterAddvertisementData: false,
};
const AddvertisementReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_ADDVERTISEMENT_INPUT:
      const addvertisementInput = { ...state.addvertisementInput };
      addvertisementInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        addvertisementInput: addvertisementInput,
      };
    case Types.IS_LOAD_ADDVERTISEMENT:
      return {
        ...state,
        isLoadAdd: action.payload,
      };
    case Types.AFTER_ADDVERTISEMENT_DATA:
      return {
        ...state,
        afterAddvertisementData: action.payload,
      };
    case Types.GET_ADDVERTISEMENT_LIST:
      return {
        ...state,
        addvertisementList: action.payload,
      };
      case Types.SET_ADDVERTISEMENT_FALSE:
      return {
        ...state,
        afterAddvertisementData: action.payload,
        addvertisementInput: initialState.addvertisementInput,
      };

    // case Types.GET_CLIENT_DETAILS:
    //   return {
    //     ...state,
    //     clientDetails: action.payload,
    //   };
    
    case Types.IS_ADDVERTISEMENT_DELETED:
      return {
        ...state,
        isAddvertisementDeleted: action.payload,
      };

      case Types.GET_ADDVERTISEMENT_UODATED:
      const setaddvertisementInput = { ...state.addvertisementInput };
      setaddvertisementInput.placement  = action.payload.placement || [];
      setaddvertisementInput.id  = action.payload.id ;
      setaddvertisementInput.name = action.payload.name;
      setaddvertisementInput.placement = action.payload.placement;
      setaddvertisementInput.status = action.payload.status;
      setaddvertisementInput.no_of_days = action.payload.no_of_days;
      setaddvertisementInput.ad_for = action.payload.ad_for;
      setaddvertisementInput.banner = action.payload.banner;
      setaddvertisementInput.banner_url = action.payload.banner_url;
      setaddvertisementInput.start_date = action.payload.start_date;
      setaddvertisementInput.expiry_date = action.payload.expiry_date;
    
      // setaddvertisementInput.password = action.payload.password;
      return {
        ...state,
        addvertisementInput: setaddvertisementInput,
      };

    default:
      break;
  }
  return newState;
};

export default AddvertisementReducer;
