import * as Types from "../types/Types";
const initialState = {
  websiteInput: {
    title: "",
    tag_line: "",
    meta_desc: "",
    is_active: true,
  },
  MenuInput: {
    name: "",
    slug: "",
    rank: "1",
    sort_by: "1",
    destination: 0,

    is_active: true,
  },

  websiteList: null,
  menuList: [],
  isWebsiteDeleted: null,
  isLoadwebsite: false,
  afterWebsiteData: false,
  isMenuDeleted: false,
};
const WebsiteReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_WEBSITE_INPUT:
      const websiteInput = { ...state.websiteInput };
      websiteInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        websiteInput: websiteInput,
      };
    case Types.GET_HOME_PAGE_MENU_INPUT:
      const MenuInput = { ...state.MenuInput };
      MenuInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        MenuInput: MenuInput,
      };
    case Types.IS_LOAD_WEBSITE:
      return {
        ...state,
        isLoadwebsite: action.payload,
      };
    case Types.AFTER_WEBSITE_DATA:
      return {
        ...state,
        afterWebsiteData: action.payload,
      };
    // case Types.GET_WEBSITE_LIST:
    //   return {
    //     ...state,
    //     websiteList: action.payload,
    //   };
    case Types.GET_MENU_LIST:
      return {
        ...state,
        menuList: action.payload,
      };
    case Types.SET_WEBSITE_FALSE:
      return {
        ...state,
        afterWebsiteData: action.payload,
        websiteInput: initialState.websiteInput,
        MenuInput: initialState.MenuInput,
      };
    // case Types.IS_WEBSITE_DELETED:
    //   return {
    //     ...state,
    //     isWebsiteDeleted: action.payload,
    //   };
    case Types.IS_MENU_DELETED:
      return {
        ...state,
        isMenuDeleted: action.payload,
      };

    case Types.WEBSITE_UPDATE_DATA:
      const setwebsiteInput = { ...state.websiteInput };
      setwebsiteInput.id = action.payload.id;
      setwebsiteInput.name = action.payload.name;
      setwebsiteInput.designation = action.payload.designation;
      setwebsiteInput.speech = action.payload.speech;
      setwebsiteInput.company = action.payload.company;
      setwebsiteInput.rank = action.payload.rank;
      setwebsiteInput.image_url = action.payload.image_url;
      return {
        ...state,
        websiteInput: setwebsiteInput,
      };

    default:
      break;
  }
  return newState;
};

export default WebsiteReducer;
