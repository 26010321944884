export const user_role = [
  {
    value: 0,
    label: "Food Blogger",
  },
  // {
  //   value: 1,
  //   label: "Client",
  // },
  // {
  //   value: 2,
  //   label: "User",
  // },
];
export const invoice_status = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Paid",
  },
  {
    value: 2,
    label: "Cancelled",
  },
  // {
  //   value: 3,
  //   label: "",
  // },
];
export const placement_list = [
  {
    value: 0,
    label: "Top-Left",
  },
  {
    value: 1,
    label: "Top-Right",
  },
  
];
export const review_status = [
  {
    value: 0,
    label: "Approved",
  },
  {
    value: 1,
    label: "Reported",
  },
  {
    value: 2,
    label: "Report rejected",
  },
  {
    value: 3,
    label: "Report approved",
  },
];
export const status_list = [
  {
    value: 0,
    label: "Published",
  },
  {
    value: 1,
    label: "UnPublish",
  },
  {
    value: 2,
    label: "Expired",
  },
  // {
  //   value: 3,
  //   label: "",
  // },
];
export const week_list = [
  {
    value: 0,
    label: "Satyrday",
  },
  {
    value: 1,
    label: "Sunday",
  },
  {
    value: 2,
    label: "Monday",
  },
  {
    value: 3,
    label: "Tuesday",
  },
  {
    value: 4,
    label: "Wednesday",
  },
  {
    value: 5,
    label: "Thursday",
  },
  {
    value: 6,
    label: "Friday",
  },
];
export const district_list = [
  {
    value: 0,
    label: "Dhaka",
  },
  {
    value: 1,
    label: "Khulna",
  },
  {
    value: 2,
    label: "Narail",
  },
];
export const sortList = [
  {
    value: 0,
    label: "Sorting",
  },
  {
    value: 1,
    label: "Close",
  },
  {
    value: 2,
    label: "Reported",
  },
];
export const statusList = [
  {
    value: 0,
    label: "Approved",
  },
  {
    value: 1,
    label: "Pending",
  },
  {
    value: 2,
    label: "Rejected",
  },
];
export const NotificationTrafficType = [
  {
    value: 1,
    label: "Client",
  },
  {
    value: 2,
    label: "User",
  },
  {
    value: 3,
    label: "All",
  },
];
export const NotificationPublishTyps = [
  {
    value: 1,
    label: "Pending",
  },
  {
    value: 2,
    label: "Published",
  },
  {
    value: 3,
    label: "Deactive",
  },
];
export const verification_status = [
  {
    value: 1,
    label: "Verified",
  },
  {
    value: 0,
    label: "Not Verified",
  },
];
export const discount_list = [
  {
    value: 0,
    label: "Discount Percentage (%)",
  },
  {
    value: 1,
    label: "Flat",
  },
];
