import * as Types from "../types/Types";
import Axios from "axios";

import { BASE_URL } from "../../../../Const";
import { showToast } from "../../../../utils/ToastHelper";
import swal from "sweetalert";

export const GetTestimonialList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/settings/admin/testimonial/`;
  dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_TESTIMONIAL_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: true });
};

export const GetTestimoniallInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_TESTIMONIAL_INPUT, payload: formData });
};
export const SetTestimonialStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_TESTIMONIAL_FALSE, payload: false });
};
export const SubmitTestimonialldData = (data) => async (dispatch) => {
  if (data.name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  }
   else if (data.speech === "") {
      showToast("error", "Testimonial shouldn't be empty !");
      return 0;
    } else if (data.designation === "") {
      showToast("error", "Designation shouldn't be empty !");
      return 0;
    }

  const url = `${BASE_URL}api/v1/settings/admin/testimonial/`;
  dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
          showToast("success", "Business Cateory  added successfully");
          dispatch(GetTestimonialList());
          dispatch({ type: Types.AFTER_TESTIMONIAL_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const UpdateTestimonialldData = (data) => async (dispatch) => {
  if (data.name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  }
   else if (data.speech === "") {
      showToast("error", "Testimonial shouldn't be empty !");
      return 0;
    } else if (data.designation === "") {
      showToast("error", "Designation shouldn't be empty !");
      return 0;
    }

  const url = `${BASE_URL}api/v1/settings/admin/testimonial/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: true });

  try {
    await Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
          showToast("success", "Business Cateory  added successfully");
          dispatch(GetTestimonialList());
          dispatch({ type: Types.AFTER_TESTIMONIAL_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_TESTIMONIAL, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const SetTestimonialUpdatedData = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/settings/admin/testimonial/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.TESTIMONIAL_UPDATE_DATA,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };
// // --// --------------delete------------
// export const categorydelete = (id) => (dispatch) => {
//     const url = `${BASE_URL}/api/v1/settings/admin/business-category/${id}/`;
  
//     try {
//       Axios.delete(url).then((res) => {
//         if (res.data.status) {
//           showToast("error", res.data.message);
//           dispatch({ type: Types.IS_CATEGORY_DELETED, payload: true });
//           dispatch(GetCategoryList())
//         }
//       });
//     } catch (error) {}
//   };
//   export const SetFalseObjectDelete = () => (dispatch) => {
//     dispatch({ type: Types.IS_CATEGORY_DELETED, payload: false });
//   };
  
