import * as Types from "../types/Types";
const initialState = {
  newAddList: [],

  //   clientDetails: null,
  newAddDelete: null,

  isLoadNewAdd: false,

};
const NewAddReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_LOAD_NEW_ADD:
      return {
        ...state,
        isLoadNewAdd: action.payload,
      };

    case Types.GET_NEW_ADD_LIST:
      return {
        ...state,
        newAddList: action.payload,
      };

    case Types.IS_NEW_ADD_DELETED:
      return {
        ...state,
        newAddDelete: action.payload,
      };

    default:
      break;
  }
  return newState;
};

export default NewAddReducer;
