import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Star } from "@material-ui/icons";
import { FaEye } from "react-icons/fa";

import Checkbox from "@mui/material/Checkbox";

import { useHistory } from "react-router-dom";

// import Userfrom "../../../User/component/verifieduser/UserPagination";

import UserPagination from "../../../Common/UserPagination";
import { useSelector, useDispatch } from "react-redux";
import {
  GetClientDetails,
  GetClientdelete,
  GetClientList,
  PreviousClientList,
  SetClientUpdatedData,
  NextClientListList,
} from "../_redux/action/ClientAction";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { RowMenu } from "../../../Common/RowMenu";
import { BASE_URL } from "../../../../Const";
import Lottie from "react-lottie";
import noData2 from '../../../../assets/jsonFile/noData2.json'
const ClientList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  const api = `${BASE_URL}api/v1/restaurant/admin/clients/?limit=20`;
  const client_List = useSelector((state) => state.clientInfo.clientList);

  const isLoadclient = useSelector((state) => state.clientInfo.isLoadclient);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    setUsers(client_List?.results);
    dispatch(GetClientList(api));
  }, []);
  React.useEffect(() => {
    if (selectedName) {
      dispatch(
        GetClientList(
          `${BASE_URL}api/v1/restaurant/admin/clients/?company_category=${selectedName}&&limit=20`
        )
      );
    }
    else{
      dispatch(
        GetClientList(
          `${BASE_URL}api/v1/restaurant/admin/clients/?limit=20`
        )
      );
    }
  }, [selectedName]);

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };

  //   ------api call--------------
  const handleProfileView = (id) => {
    dispatch(GetClientDetails(id));
    history.push(`/client_profile/${id}`);
    localStorage.setItem("client_id", id);
  };
  const handleProfileEdit = (id) => {
    dispatch(SetClientUpdatedData(id));
    history.push(`/edit_clients/${id}`);
  };
  const handleDelete = (id) => {
    dispatch(GetClientdelete(id, api));
    // handleClose()
    setAnchorEl(null);
  };

  ////////// -rederect login page--------------

  React.useEffect(() => {
    localStorage.removeItem("client_id");
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextClientListList(client_List?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousClientList(client_List?.previous));
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      {isLoadclient && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
       {!isLoadclient && client_List?.results && client_List?.results?.length === 0 && (
        <div style={{ display:"flex",  }} className="d-flex, justify-content-between">
          <Lottie options={defaultOptions} height='200' width='200'/>
        </div>
      )}
      {!isLoadclient &&
        client_List?.results &&
        client_List?.results !== null &&
        client_List?.results.length > 0 && (
      <TableContainer component={Paper} className="text-capitalize">
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          {/* <caption>A basic table example with a caption</caption> */}
          <TableHead style={{ background: "white" }}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  // className="form-check-input"
                  name="allSelect"
                  checked={selectedUser?.length === users?.length}
                  onChange={(e) => handleChange(e, users)}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="left">Name of Client</TableCell>
              <TableCell align="left">Client Category</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="center">Branch</TableCell>
              <TableCell align="center">MBBD Rating </TableCell>
              <TableCell align="center">View</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="left">
                {" "}
                <IconButton aria-label="more">
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: "white" }}>
            {client_List?.results
              ?.filter((val) => {
                if (search == "") {
                  return val;
                } else if (
                  val?.client_id.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                } else if (
                  val?.company_name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                }
                // else if (
                //   val?.location.toLowerCase().includes(search.toLowerCase())
                // ) {
                //   return val;
                // }
              })
              .map((row) => {
                const isItemSelected = isSelected(row.name);
                return (
                  <TableRow key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        name={row.client_id}
                        checked={selectedUser.some(
                          (item) => item?.id === row.id
                        )}
                        onChange={(e) => handleChange(e, row)}
                      />
                    </TableCell>
                    <TableCell align="center" className="image-td">
                      {row.client_id}
                    </TableCell>
                    <TableCell align="left" className="client_td">
                      <div className="d-flex align-items-center gap-2">
                        <img src={row.logo_url} alt="" />
                        <div>
                          <h6
                            onClick={() => handleProfileView(row.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {row.company_name}
                          </h6>
                          <p>
                            {" "}
                            {new Date(row.created_at)
                              .toISOString()
                              .slice(0, 10)}
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left" className="client_td">
                      <h6>{row.category_name}</h6>
                    </TableCell>
                    <TableCell align="left" className="">
                      <h6>{row.location}</h6>
                    </TableCell>
                    <TableCell align="center">{row.branches}</TableCell>
                    <TableCell align="center" className="rating_td">
                      <span className="d-flex align-items-center">
                        <Star /> {row.rating}
                      </span>
                    </TableCell>
                    <TableCell align="center" className="table_icon">
                      <span
                        onClick={() => history.push(`/clients-dashboard`)}
                        style={{ cursor: "pointer" }}
                      >
                        <FaEye
                          className="me-2"
                          style={{ fontSize: "22px", color: "D8D8D8 " }}
                        />
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <select name="" id="" className="status_td">
                        <option value="bulk_action">Active</option>
                        <option value="Food">Deactive</option>
                      </select>
                    </TableCell>

                    <TableCell align="left">
                      <RowMenu
                        row={row}
                        handleeEdit={handleProfileEdit}
                        handleDelete={handleDelete}
                      />{" "}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {client_List?.count}</p>
        <UserPagination
          prev_next={client_List}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default ClientList;
