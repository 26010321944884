import Multiselect from "multiselect-react-dropdown";
import React, { useEffect } from "react";
import ArrowSvg from "../Common/ArrowSvg";
import SingleBrowseFile from "./SingleBrowseFile";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClientProductInput,
  SetClientProductStatusFalse,
  SubmitClientProductdData,
  UpdateClientProductdData,
} from "./products/_redux/action/ClientProductAction";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { SubmitImage } from "../Seetings/_redux/action/SeetingsAction";
import { GetClientCategoryList } from "./category/_redux/action/ClientCategoryAction";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../Const";
import { GetClientList } from "./all_clients/_redux/action/ClientAction";
import { Editor } from "react-draft-wysiwyg";
const AddClientProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, SetisLoading] = React.useState(false);
  const ClientProductInput = useSelector(
    (state) => state.clientProductInfo.ClientProductInput
  );
  const isLoadProduct = useSelector(
    (state) => state.clientProductInfo.isLoadProduct
  );
  const [selectedValue, setSelectedValue] = React.useState(
    ClientProductInput.branches
  );

  const afterClientcategoryData = useSelector(
    (state) => state.clientProductInfo.afterClientcategoryData
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);
  const client_List = useSelector((state) => state.clientInfo.clientList);

  // const handleChangeBranch = (e) => {
  //   setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
  //   dispatch(GetClientProductInput("branches", selectedValue));
  // };
  const handleChangeInput = (name, value) => {
    dispatch(GetClientProductInput(name, value));

    dispatch(GetClientProductInput("branches", selectedValue));
    if (name === "regular_price") {
      dispatch(GetClientProductInput("sale_price", value));
    }
  };
  const handlesubmit = (data) => {
    SetisLoading(true);
    dispatch(SubmitClientProductdData(data));
  };
  const handleUpdateSubmit = (data) => {
    SetisLoading(true);
    dispatch(UpdateClientProductdData(data));
  };
  const client_id = localStorage.getItem("client_id");
  useEffect(() => {
    dispatch(
      GetClientCategoryList(
        `${BASE_URL}api/v1/inventory/admin/category/?branches=${userData.client_id}`
      )
    );
    dispatch(
      GetClientList(
        `${BASE_URL}api/v1/restaurant/admin/clients/${userData.client_id}/branches/`
      )
    );
  }, []);

  useEffect(() => {
    if (afterClientcategoryData) {
      history.push(`/products`);
      SetisLoading(false);
      dispatch(SetClientProductStatusFalse());
    }
    SetisLoading(false);
  }, [afterClientcategoryData]);
  if (isLoading) {
    return (
      <Backdrop
        open
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div className="parent min-vh-100">
      {/* {isLoadProduct && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
      {isLoadImage && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>Products</span>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Your Product or Service Name:</p>
            <input
              type="text"
              placeholder="Menubook BD"
              name="name"
              value={ClientProductInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Select Category:</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="category"
                value={ClientProductInput.category}
                onChange={(e) => handleChangeInput("category", e.target.value)}
              >
                <option value={""}>--</option>
                {ClientCategoryList?.results?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Regular Price:</p>
            <input
              type="text"
              placeholder="Menubook BD"
              name="regular_price"
              value={ClientProductInput.regular_price}
              onChange={(e) =>
                handleChangeInput("regular_price", e.target.value)
              }
            />
          </div>
        </div>

        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Discount Price:</p>
            <input
              type="text"
              placeholder="Menubook BD"
              name="sale_price"
              value={ClientProductInput.sale_price}
              onChange={(e) => handleChangeInput("sale_price", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Vat Type:</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="vat_type"
                value={ClientProductInput.vat_type}
                onChange={(e) => handleChangeInput("vat_type", e.target.value)}
              >
                <option value={""}>--</option>
                <option value={0}>Excluded</option>
                <option value={1}>Included</option>
                {/* {ClientCategoryList?.results?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))} */}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex align-item-center  input-title"
          style={{ marginTop: "25px" }}
        >
          <p>Is Today's Special?</p>
          <input
            className="mx-2"
            type="checkbox"
            name="is_today_special"
            value={ClientProductInput.is_today_special}
            checked={ClientProductInput.is_today_special}
            onChange={(e) =>
              handleChangeInput("is_today_special", e.target.checked)
            }
          />
          <p>Yes</p>
        </div>

        <SingleBrowseFile
          title={"Set Product Image"}
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "product_thumb"));
            handleChangeInput("fileName6", e.target.files[0].name);
            handleChangeInput("size3", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              // setPdfFile(e.target.result);
             
              handleChangeInput("thumb_url", e.target.result);
            };
          }}
          name="image"
          url={ClientProductInput?.thumb_url}
          file_name={ClientProductInput.fileName6}
          size={ClientProductInput.size3 / 1000}
        />

        <div className="row input-title" style={{ marginTop: "20px" }}>
          <div className="col-11 col-md-6 input-head">
            <span
              className="mt-4 mb-3"
              style={{ fontSize: "15px", color: "#515151" }}
            >
              Product Description:
            </span>
            {/* <textarea
            rows={4}
              className="mt-4"
              type="textarea"
              placeholder="Menubook BD"
              name="desc"
              value={ClientProductInput.desc}
              onChange={(e) => handleChangeInput("desc", e.target.value)}
            /> */}
            <Editor
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarClassName="toolbar"
              placeholder="Menubook BD"
              name="desc"
              editorState={ClientProductInput?.desc}
              // editorState={state}
              onEditorStateChange={(e) => handleChangeInput("desc", e)}
              // onEditorStateChange={(e) => Test(e)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "30px" }}>
          <div className="col-11 col-md-6 input-head">
            <p className="server-title">SEO Settings</p>
            <span
              className="mt-4 mb-3"
              style={{ fontSize: "15px", color: "#515151" }}
            >
              Product Title:
            </span>
            <input
              className="mt-4"
              type="text"
              placeholder="Menubook BD"
              name="title"
              value={ClientProductInput.title}
              onChange={(e) => handleChangeInput("title", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "20px" }}>
          <div className="col-11 col-md-6 input-head">
            <span
              className="mt-4 mb-3"
              style={{ fontSize: "15px", color: "#515151" }}
            >
              Meta Description:
            </span>
            <input
              className="mt-4"
              type="textarea"
              placeholder="Menubook BD"
              name="meta_desc"
              value={ClientProductInput.meta_desc}
              onChange={(e) => handleChangeInput("meta_desc", e.target.value)}
            />
          </div>
        </div>
        <div className="mt-4 d-flex ">
          <button
            className="back-btn me-3"
            onClick={() => history.push(`/products`)}
          >
            Cancel
          </button>
          <button
            className="done-btn"
            onClick={() => {
              ClientProductInput.id
                ? handleUpdateSubmit(ClientProductInput)
                : handlesubmit(ClientProductInput);
            }}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClientProducts;
