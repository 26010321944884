import { MenuItem } from "@material-ui/core";
import React, { useEffect,useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import Avatar from "@mui/material/Avatar";
import user from "../../assets/img/user1.jpg";
import UserPagination from "../User/component/verifieduser/UserPagination";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { GetTestimonialList, SetTestimonialUpdatedData } from "./testimonial_redux/action/TestimonialAction";
import AddTestiminial from "./AddTestiminial";
const TestimonialTable = () => {

  const options = ["None", "Atria", "Callisto"];
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const testimonialList = useSelector(
    (state) => state.testimonialInfo.testimonialList
  );
  const isLoadTestimonial = useSelector(
    (state) => state.testimonialInfo.isLoadTestimonial
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(GetTestimonialList());
  }, []);
const handleTestimonialEdit =(data)=>{
  setModalShow(true)
  // AddTestiminial(data)
  localStorage.setItem("testimonial_id", data);
  dispatch(SetTestimonialUpdatedData(data))
}

const testimonial_id = localStorage.getItem("testimonial_id")
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);
  return (
    <div className="testimonial">
      {isLoadTestimonial && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="caption table"
          className="testimonial"
        >
          {/* <caption>A basic table example with a caption</caption> */}
          <TableHead style={{ background: "white" }}>
            <TableRow>
              <TableCell align="center">Photos</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center"> Role</TableCell>

              <TableCell align="left">Testimonials</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: "white" }}>
            {testimonialList?.map((row) => (
              <TableRow key={row.id} style={{ minHeight: "150px" }}>
                <TableCell className="d-flex justify-content-center align-items-center gap-2">
                  <Avatar alt="User1" src={row.image_url} />{" "}
                  {/* <img className="rounded-full" src={user} alt="" /> */}
                </TableCell>
                <TableCell align="center" style={{ color: "#EF5B0C" }}>
                  <p> {row.name}</p>
                </TableCell>
                <TableCell align="center" style={{ color: "#EF5B0C" }}>
                  <p> {row.designation}</p>
                </TableCell>

                <TableCell
                  align="left"
                  style={{ maxWidth: "350px" }}
                  className="me-2"
                >
                  <p> {row.speech}</p>
                </TableCell>
                <TableCell align="center" className="table_icon">
                  {/* <span style={{cursor:"pointer"}}>
                    <FaEye className="me-2" />
                    view
                  </span> */}
                  <br />
                  <span onClick={()=> handleTestimonialEdit(row.id)} style={{cursor:"pointer"}}>
                    <FaEdit className="me-2" />
                    Edit
                  </span>
                </TableCell>
                {/* <AddTestiminial show={modalShow} onHide={() => setModalShow(false)} id={row.id}/> */}
              </TableRow>
              
            ))}

          </TableBody>
        </Table>
      </TableContainer>
      <div className="mt-4">
        <UserPagination />
      </div>
      <AddTestiminial show={modalShow} onHide={() => setModalShow(false)} id={testimonial_id} />
    </div>
  );
};

export default TestimonialTable;
