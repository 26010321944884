import * as Types from "../types/Types";
const initialState = {
  generalInput: {
    name: "",
    address: "",
    email: "",
    default_role: 0,
    logo: {},
    fav_icon: 0,
  },

  imageInput: {
    document: "",
    doc_type: 0,
    owner: 0,
  },

  categoryInput: {
    name: "",
    is_active: false,
  },

  categoryList: null,
  isLoadImage: false,

  isLoadSeetings: false,
  afterGeneralData: false,
};
const SeetingsReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_GENERAL_INPUT:
      const generalInput = { ...state.generalInput };
      generalInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        generalInput: generalInput,
      };
    case Types.IS_LOAD_SEETINGS:
      return {
        ...state,
        isLoadSeetings: action.payload,
      };
    case Types.AFTER_GENERAL_DATA:
      return {
        ...state,
        afterGeneralData: action.payload,
      };
    case Types.SET_GENERAL_FALSE:
      return {
        ...state,
        afterGeneralData: action.payload,
        generalInput: initialState.generalInput,
      };

    case Types.GET_IMAGE_INPUT:
      const imageInput = { ...state.imageInput };
      imageInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        imageInput: imageInput,
      };
    case Types.IS_LOAD_IMAGE:
      return {
        ...state,
        isLoadImage: action.payload,
      };

    default:
      break;
  }
  return newState;
};

export default SeetingsReducer;
