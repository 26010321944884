import React, { useEffect, useState } from "react";
import { GetClientInput } from "../Clients/all_clients/_redux/action/ClientAction";
import { useDispatch } from "react-redux";

const CurrentLocation = () => {
  const dispatch = useDispatch();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [location, setLocation] = useState(null);
  const api_key = "AIzaSyCueZ-BzQugLmj0J1h3ODLAtoeAlLUuogk";
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      // Make a request to the Google Maps Geocoding API to get the location information
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${api_key}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.results && data.results.length > 0) {
            const address = data.results[0].formatted_address;
            setLocation(data.results[0].formatted_address);
            console.log("Current Location:", address);
          }
        })
        .catch((error) => {
          console.error("Error retrieving location:", error);
        });
    }
  }, [latitude, longitude]);
  const handleCurrentLocation = () => {
    dispatch(GetClientInput("latitude", latitude));
    dispatch(GetClientInput("longitude", longitude));
    dispatch(GetClientInput("location", location));
  };

  return (
    <div>
      {latitude && longitude ? (
        <div>
          <button className="next-btn" onClick={handleCurrentLocation}>
             Location
          </button>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default CurrentLocation;
