import React from "react";
import user1 from "../../assets/img/user1.jpg";
import BrowseFile from "./BrowseFile";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useEffect } from "react";
import {
  GetClientInput,
  SetClientStatusFalse,
  SubmitClientdData,
  UpdateClientdData,
} from "./all_clients/_redux/action/ClientAction";
import { SubmitImage } from "../Seetings/_redux/action/SeetingsAction";
import SingleImageUpload from "./all_clients/pages/SingleImageUpload";
import CoverImageUpload from "./all_clients/pages/CoverImageUpload";
import MenuImageUpload from "./products/MenuImageUpload";
import { Editor } from "react-draft-wysiwyg";
const Details3 = ({ value }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, SetisLoading] = React.useState(false);
  const clientInput = useSelector((state) => state.clientInfo.clientInput);
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isLoadClient = useSelector((state) => state.clientInfo.isLoadClient);
  const afterClientdata = useSelector(
    (state) => state.clientInfo.afterClientdata
  );
  const handleChangeInput = (name, value) => {
    dispatch(GetClientInput(name, value));
  };
  const handleaddClient = (data) => {
    SetisLoading(true);
    dispatch(SubmitClientdData(data));
  };
  const handleEditClient = (data) => {
    SetisLoading(true);
    dispatch(UpdateClientdData(data));
  };
  useEffect(() => {
    if (afterClientdata) {
      SetisLoading(false);
      history.push(`/summary`);
      dispatch(SetClientStatusFalse());
    }
  }, [afterClientdata]);
  if (isLoading) {
    return (
      <Backdrop
        open
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div className="parent " style={{ minHeight: "100vh" }}>
      {isLoadClient && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {isLoadImage && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Add New Client /
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>
            Client Name
          </span>
        </div>

        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>About your business*</p>
            <Editor
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarClassName="toolbar"
              name="about"
              editorState={clientInput.about}
              // editorState={state}
              onEditorStateChange={(e) => handleChangeInput("about", e)}
              // onEditorStateChange={(e) => Test(e)}
            />
          </div>
        </div>
      {/* Logo photo */}
      <SingleImageUpload
          title="Logo"
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "client_logo"));
            handleChangeInput("menuName3", e.target.files[0].name);
            handleChangeInput("size1", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              handleChangeInput("logo_image", e.target.result);
            };
          }}
          name="logo"
          url={clientInput?.logo_image}
          file_name={clientInput.menuName3}
          size={clientInput.size1 / 1000}
          image_url={clientInput.logo}
        />

        <CoverImageUpload
          title="Cover Photo"
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files;
            dispatch(SubmitImage(imageInput, "cover"));

            handleChangeInput(
              "coverName1",
              Array.from(e.target.files).map((item) => item.name)
            );
            handleChangeInput(
              "size1",
              Array.from(e.target.files).map((item) => item.size)
            );
            Promise.all(
              Array.from(e.target.files).map((file) => {
                return new Promise((r) => {
                  //file creat,

                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = (e) => {
                    r(e.target.result);
                  };
                });
              })
            ).then((list) => {});
          }}
          name="cover"
          url={clientInput?.cover_image}
          file_name={clientInput.coverName1.toString()}
          size={clientInput.size1 / 1000}
          image_url={clientInput.cover[0]?.document}
        />
        {/* Cover Photo */}
        {/* menu photo */}
        {parseInt(clientInput.company_service_type) === 0 ||
        parseInt(clientInput.company_service_type) === 2 ? (
          <MenuImageUpload
            title="Menu"
            onImageChange={(e) => {
              imageInput.owner = userData.id;
              imageInput.doc_type = 0;
              imageInput.document = e.target.files;
              dispatch(SubmitImage(imageInput, "menu"));

              handleChangeInput(
                "menuName1",
                Array.from(e.target.files).map((item) => item.name)
              );
              handleChangeInput(
                "size1",
                Array.from(e.target.files).map((item) => item.size)
              );
              Promise.all(
                Array.from(e.target.files).map((file) => {
                  return new Promise((r) => {
                    //file creat,

                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = (e) => {
                      r(e.target.result);
                    };
                  });
                })
              ).then((list) => {});
            }}
            name="menu"
            url={clientInput?.menu_image}
            file_name={clientInput.menuName1.toString()}
            size={clientInput.size1 / 1000}
            image_url={clientInput.menu[0]?.document}
          />
        ) : (
          <></>
        )}

        {/* menu photo */}

        {/* menu photo */}

        {/* Gallery Photo */}
        <BrowseFile
          title="Gallery Photo"
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files;

            dispatch(SubmitImage(imageInput, "gallery", clientInput.id));
            handleChangeInput(
              "fgalleryName1",
              Array.from(e.target.files).map((item) => item.name)
            );
            handleChangeInput(
              "size1",
              Array.from(e.target.files).map((item) => item.size)
            );
            Promise.all(
              Array.from(e.target.files).map((file) => {
                return new Promise((r) => {
                  //file creat,

                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = (e) => {
                    r(e.target.result);
                  
                  };
                });
              })
            ).then((list) => {
              //handleChangeInput("gallery_image", list);
            });
          }}
          name="gallery"
          url={clientInput?.gallery_image}
          file_name={clientInput.fgalleryName1.toString()}
          size={clientInput.size1 / 1000}
          image_url={clientInput.gallery[0]?.document}
        />

        <div className="row mt-4">
          <div className="col-11 col-md-6 d-flex align-items-center row">
            <div className="col-12 col-md-6 condition d-flex align-align-items-center">
              <input style={{cursor:"pointer"}}
                className="mx-2"
                type="checkbox"
                name="is_agree"
                disabled={
                  userData.user_type !== 0 &&
                  clientInput.is_agree === true &&
                  true
                }
                value={clientInput.is_agree}
                checked={clientInput.is_agree}
                onChange={(e) =>
                  handleChangeInput("is_agree", e.target.checked)
                }
              />
              <span className="">I agree to terms and conditions</span>
            </div>
            <div
              className=" d-flex justify-content-end ms-4 ms-md-0 col-12 col-md-6 my-2 my-md-0"
              // style={{ marginTop: "25px" }}
            >
              <button onClick={() => value("3")} className="back-btn me-3">
                Back
              </button>

              <button
                className="next-btn "
                disabled={clientInput?.is_agree && false}
                // {clientInput.id ? }
                onClick={() => {
                  clientInput.id
                    ? handleEditClient(clientInput)
                    : handleaddClient(clientInput);
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details3;
