import * as Types from "../types/Types";
const initialState = {
  reviewList: null,

  isLoadReview: false,
  reviewInput:{
    report_text:"",
    id:"",
    status:""
  }
};
const ReviewReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_LOAD_REVIEW:
      return {
        ...state,
        isLoadReview: action.payload,
      };
      case Types.GET_REVIEW_INPUT:
        const reviewInput = { ...state.reviewInput };
        reviewInput[action.payload.name] = action.payload.value;
        return {
          ...state,
          reviewInput: reviewInput,
        };
        case Types.AFTER_REVIEW_DATA:
      return {
        ...state,
        afterReviewData: action.payload,
      };
      case Types.SET_REVIEW_FALSE:
        return {
          ...state,
          afterReviewData: action.payload,
          reviewInput: initialState.reviewInput,
        
        };
    case Types.GET_REVIEW_LIST:
      return {
        ...state,
        reviewList: action.payload,
      };
      case Types.GET_REVIEW_UODATED:
        const setreviewInput = { ...state.reviewInput };
      
        setreviewInput.id = action.payload.id;
        setreviewInput.report_text = action.payload.report_text;
        setreviewInput.status = action.payload.status;
       
       
        // setreviewInput.password = action.payload.password;
        return {
          ...state,
          reviewInput: setreviewInput,
        };
  
    default:
      break;
  }
  return newState;
};

export default ReviewReducer;
