import { combineReducers } from "redux";
import UserReducer from "../../modules/User/_redux/reducer/UserReducer";

import LogInReducer from "../../modules/authentication/_redux/reducer/LogInReducer"; 

import SeetingsReducer from "../../modules/Seetings/_redux/reducer/SeetingsReducer";
import ImageReducer from "../../modules/image_upload/_redux/reducer/ImageReducer";
import CategoryReducer from "../../modules/Seetings/category_redux/reducer/CategoryReducer";
import TestimonialReducer from "../../modules/Seetings/testimonial_redux/Reducer/TestimonialReducer";
import WebsiteReducer from "../../modules/Seetings/website_redux/reducer/WebsiteReducer";
import AppUserReducer from "../../modules/User/component/appUser/_redux/reducer/AppUserReducer";
import VerifiedUsrReducer from "../../modules/User/component/verifieduser/_redux/reducer/VerifiedUsrReducer";
import ClientsReducer from "../../modules/Clients/all_clients/_redux/reducer/ClientsReducer";
import ReviewReducer from "../../modules/review/_redux/reducer/ReviewReducer";
import ClientCategoryReducer from "../../modules/Clients/category/_redux/reducer/ClientCategoryReducer";
import ClientProductReducer from "../../modules/Clients/products/_redux/reducer/ClientProductReducer";
import AddvertisementReducer from "../../modules/Clients/ClientAdvertisement/_redux/reducer/AddvertisementReducer";
import ClientOfferReducer from "../../modules/Clients/ClientOffers/_redux/reducer/ClientOfferReducer";
import OfferReducer from "../../modules/add_request/manage_offers/_redux/reducer/OfferReducer";
import NewAddReducer from "../../modules/add_request/new_add/_redux/reducer/NewAddReducer";
import NotificationReducer from "../../modules/notification/_redux/reducer/NotificationReducer";
import InvoiceReducer from "../../modules/Clients/Invoice/_redux/reducer/InvoiceReducer";
import MyBusinessReducer from "../../modules/Business/_redux/reducer/MyBusinessReducer";
import BusinessUserReducer from "../../modules/User/component/businessRequest/_redux/reducer/BusinessUserReducer";
import SupportReducer from "../../modules/support/_redux/reducer/SupportReducer";
// combine all of the reducers here
const rootReducer = combineReducers({
  
  logInInfo:LogInReducer,
  seetingInfo:SeetingsReducer,
  imageInfo:ImageReducer,
  categoryInfo:CategoryReducer,
  testimonialInfo:TestimonialReducer,
  websiteInfo:WebsiteReducer,
  appUserInfo:AppUserReducer,
  verifiedUserInfo:VerifiedUsrReducer,
  clientInfo:ClientsReducer,
  reviewInfo:ReviewReducer,
  clientCategoryInfo:ClientCategoryReducer,
  clientProductInfo:ClientProductReducer,
  addvertisementInfo:AddvertisementReducer,
  clientOfferInfo:ClientOfferReducer,
  offerInfo:OfferReducer,
  newAddInfo:NewAddReducer,
  notificationInfo:NotificationReducer,
  inVoiceInfo:InvoiceReducer,
  dashboardInfo:MyBusinessReducer,
  businessUserInfo:BusinessUserReducer,
  supportInfo:SupportReducer,
 
});

export default rootReducer;
