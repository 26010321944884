import React from "react";
import "./Settings.css";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCategorylInput,
  GetCategoryList,
  SubmitCategoryldData,
  SetCategoryStatusFalse,
  categorydelete,
  SetFalseObjectDelete,
  GetBillingTypeList,
  GetCategoryBillinglInput,
  SubmitCategoryBillingData,
} from "./category_redux/action/CategoryAcion";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  DELETE_DOC,
  SET_NEW_BILLING_INPUT,
} from "./category_redux/types/Types";
const SettingsBusiness = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.categoryInfo.categoryList);
  const billingTypeList = useSelector(
    (state) => state.categoryInfo.billingTypeList
  );
  const iscategorydeleted = useSelector(
    (state) => state.categoryInfo.iscategorydeleted
  );
  const isLoadCategory = useSelector(
    (state) => state.categoryInfo.isLoadCategory
  );
  const afterGeneralData = useSelector(
    (state) => state.categoryInfo.afterGeneralData
  );
  const categoryInput = useSelector(
    (state) => state.categoryInfo.categoryInput
  );
  const billingInput = useSelector((state) => state.categoryInfo.billingInput);
  const handleChangeInput = (name, value) => {
    dispatch(GetCategorylInput(name, value));
  };

  const handleAddcategory = (data) => {
    dispatch(SubmitCategoryldData(data));
  };
  useEffect(() => {
    dispatch(GetCategoryList());
    dispatch(GetBillingTypeList());
  }, []);
  useEffect(() => {
    dispatch(SetCategoryStatusFalse());
  }, [afterGeneralData]);

  // =====delete============
  const handleDelete = (id) => {
    dispatch(categorydelete(id));
  };
  useEffect(() => {
    if (iscategorydeleted) {
      dispatch(SetFalseObjectDelete());
    }
  }, [iscategorydeleted]);

  ////////// -rederect login page--------------
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  const handlechangeCheckbox = (value, i) => {
    if (value === true) {
      dispatch({ type: SET_NEW_BILLING_INPUT, payload: false });
      dispatch(GetCategoryBillinglInput("is_active", true, i, "billingInput"));
      dispatch(GetCategoryBillinglInput("id", i, i,"billingInput"));
    } else if (value === false) {
      dispatch({ type: DELETE_DOC, payload: i });
      dispatch(GetCategoryBillinglInput("id", i, i,"billingInput"));
      dispatch(GetCategoryBillinglInput("is_active", false, i,"billingInput"));
      
    }
  };

  const handleSumbitBillingType =(data)=>{
    dispatch(SubmitCategoryBillingData(data));
  }
  return (
    <div className="parent" style={{ minHeight: "100vh" }}>
      {isLoadCategory && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Seetings /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>Business</span>
        </div>
        {/* business category */}
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-7 input-head">
            <p>Add Business Category:</p>
            <div className="row">
              <div className="col-7">
                <input
                  type="text"
                  placeholder="Category Name"
                  name="name"
                  value={categoryInput.name}
                  onChange={(e) => handleChangeInput("name", e.target.value)}
                />
              </div>
              <div className="col-3 category-add">
                <button
                  className="add"
                  onClick={() => handleAddcategory(categoryInput)}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* business category */}
        {/* category table */}
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div
            className="col-11 col-md-5 border  category-tab"
            style={{ color: "#A1A1A1", fontSize: "15px", borderRadius: "12px" }}
          >
            <div className="d-flex justify-content-between align-items-center px-3 pt-3">
              <div className="">
                <p>Category Name</p>
              </div>
              <div className="">
                <p>Action</p>
              </div>
            </div>
            {categoryList?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex div justify-content-between bg-white align-items-center  px-3 pt-3"
                  style={{ marginLeft: "-11px", marginRight: "-11px" }}
                >
                  <div className="ps-2">
                    <p>{item.name}</p>
                  </div>
                  <div
                    className="pe-4 pb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(item.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#000000"
                      viewBox="0 0 256 256"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <line
                        x1="200"
                        y1="56"
                        x2="56"
                        y2="200"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="24"
                      ></line>
                      <line
                        x1="200"
                        y1="200"
                        x2="56"
                        y2="56"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="24"
                      ></line>
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* category table */}
        {/* Client Billing Type Dropdown: */}
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-7 input-head">
            <p>Client Billing Type Dropdown:</p>
          </div>
          <div className="row input-title" style={{ marginTop: "25px" }}>
            <div
              className="col-11 col-md-5 border  category-tab"
              style={{
                color: "#A1A1A1",
                fontSize: "15px",
                borderRadius: "12px",
              }}
            >
              <div className="d-flex justify-content-between align-items-center px-3 pt-3">
                <div className="">
                  <p>Category Name</p>
                </div>
                <div className="">
                  <p>Select</p>
                </div>
              </div>

              {billingTypeList?.map((bill, index) => {
                return (
                  <div
                    className="d-flex div justify-content-between bg-white align-items-center  px-3 pt-3"
                    style={{ marginLeft: "-11px", marginRight: "-11px" }}
                  >
                    <div className="ps-2">
                      <p>{bill.name}</p>
                    </div>
                    <div className="pe-4 pb-3">
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        name="is_active"
                        id=""
                        onChange={(e) =>
                          handlechangeCheckbox(e.target.checked, index)
                        }
                        // checked={bill.is_active === true ? true : false}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Client Billing Type Dropdown: */}
        <div className="mt-3">
          <button className="save-btn" onClick={()=> handleSumbitBillingType(billingInput)}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default SettingsBusiness;
