import React from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import ArrowSvg from "../Common/ArrowSvg";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import SingleBrowseFile from "./SingleBrowseFile";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  GetAppuserlInput,
  UpdateAppUserldData,
  SubmitAppUserldData,
  SetAppUserStatusFalse,
  GetCountryList,
} from "../User/component/appUser/_redux/action/AppUserAction";
import {
  district_list,
  user_role,
  verification_status,
} from "../Common/Dropdown";
import { SubmitImage } from "../Seetings/_redux/action/SeetingsAction";
const EditUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appUserInput = useSelector((state) => state.appUserInfo.appUserInput);
  const countryList = useSelector((state) => state.appUserInfo.countryList);
  const isLoadAppUser = useSelector((state) => state.appUserInfo.isLoadAppUser);
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);
  const afterAppUserData = useSelector(
    (state) => state.appUserInfo.afterAppUserData
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const handleChangeInput = (name, value) => {
    dispatch(GetAppuserlInput(name, value));
    
     if(appUserInput.membership_level === 1 ){
        dispatch(GetAppuserlInput("is_requested", false));
        dispatch(GetAppuserlInput("is_verified", true));
      }
      else if(appUserInput.membership_level === 0){
        dispatch(GetAppuserlInput("is_requested", true));
        dispatch(GetAppuserlInput("is_verified", false));
      }
  };
  const handleSubmitAppUser = (data) => {
    dispatch(SubmitAppUserldData(data));
  };
  const handleUpdateAppUser = (data) => {
    dispatch(UpdateAppUserldData(data));
  };
  useEffect(() => {
    if (afterAppUserData) {
      history.push(`/appUser`);
    }
    dispatch(SetAppUserStatusFalse());
  }, [afterAppUserData]);

  const testimonial_id = localStorage.getItem("testimonial_id");
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }

    dispatch(GetCountryList());
  }, []);
  return (
    <div className="parent">
      {isLoadAppUser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
       {isLoadImage && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>
            {appUserInput?.id ? "Edit User" : "Add User"}
          </span>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>First name</p>
            <input
              type="text"
              placeholder="user@123"
              name="first_name"
              value={appUserInput.first_name}
              onChange={(e) => handleChangeInput("first_name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Last name</p>
            <input
              type="text"
              placeholder="user@123"
              name="last_name"
              value={appUserInput.last_name}
              onChange={(e) => handleChangeInput("last_name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>User Role</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="role"
                value={appUserInput.role}
                onChange={(e) => handleChangeInput("role", e.target.value)}
              >
                <option value={""}>--</option>
                {user_role.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              style={{ flexWrap: "wrap " }}
            >
              <p className="my-2 my-md-0">Membership level </p>
              <div style={{ color: "#FF0000" }}>
                <span>
                  <AiFillExclamationCircle />
                </span>
                <span className="ms-2 fw-bold">Requested for verification</span>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="membership_level"
                value={appUserInput.membership_level}
                onChange={(e) =>
                  handleChangeInput("membership_level", e.target.value)
                }
              >
                <option value={""}>--</option>
                {verification_status.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
      
        {/* <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>About</p>
            <input
              type="text"
              placeholder="Shafin Ahmed"
              name="bio"
              value={appUserInput.bio}
              onChange={(e) => handleChangeInput("bio", e.target.value)}
            />
          </div>
        </div>
        <SingleBrowseFile
          title={"Profile Picture "}
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "image"));
            handleChangeInput("fileName1", e.target.files[0].name);
            handleChangeInput("size1", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              // setPdfFile(e.target.result);
              handleChangeInput("image_url", e.target.result);
            };
          }}
          name="image"
          url={appUserInput?.image_url}
          file_name={appUserInput.fileName1}
          size={appUserInput.size1 / 1000}
        /> */}
       
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Phone</p>
            <input
              type="text"
              placeholder="123456"
              name="mobile"
              value={appUserInput.mobile}
              onChange={(e) => handleChangeInput("mobile", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Email</p>
            <input
              type="text"
              placeholder="example@gmail.com"
              name="email"
              value={appUserInput.email}
              onChange={(e) => handleChangeInput("email", e.target.value)}
            />
          </div>
        </div>
       
        {/* <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head d-flex align-items-center">
            <p className="my-auto me-3">Password:</p>
            <button
              className="reset-btn px-4 py-2 rounded-2 "
              style={{ color: "#EF5B0C", border: "1px solid #EF5B0C" }}
            >
              Sent Reset Link
            </button>
          </div>
        </div> */}
        <div className="my-4">
          {appUserInput?.id ? (
            <button
              className="save-btn"
              onClick={() => handleUpdateAppUser(appUserInput)}
            >
              Update User
            </button>
          ) : (
            <button
              className="save-btn"
              onClick={() => handleSubmitAppUser(appUserInput)}
            >
              Add User
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditUser;
