import * as Types from "../types/Types";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
const initialState = {
  clientInput: {
    id: "",
    restaurant_types: [],
    facilities_types: [],
    homemade_types: [],
    phone_numbers: [],
    email: "",
    // password: "",
    phone: "",
    company_name: "",
    company_website: "",
    company_service_type: 0,
    paid_amount: "",
    payment_duration: 1,
    about: "",
    facilities: "",
    open_time: "",
    close_time: "",
    latitude: 23.8103,
    longitude: 90.4125,
    location: "Dhaka, Bangladesh",
    facebook: "",
    whatsapp: "",
    linkedin: "",
    instagram: "",
    twitter: "",
    status: 0,
    has_multiple_branch: false,
    branch: [],
    is_agree: false,
    is_active: true,
    parent: null,
    user: "5",

    company_category: [],
    payment_billing_type: null,
    logo: null,
    logo_image: null,
    menu_list: [],
    menu: [],
    cover: [],
    gallery: [],

    fgalleryName1: [],
    gallery_image: [],
    menuName1: [],
    coverName1: [],
    menu_image: [],
    cover_image: [],
  },

  clientList: null,

  clientDetails: null,
  isClientDeleted: null,

  isLoadclient: false,
  afterClientdata: false,
};
const ClientsReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_CLIENT_INPUT:
      const clientInput = { ...state.clientInput };

      if (
        action.payload.name === "gallery" ||
        action.payload.name === "menu" ||
        action.payload.name === "menu_list" ||
        action.payload.name === "cover"
      ) {
        if (action.payload.value === null) {
          clientInput[action.payload.name] = [];
        } else {
          if (action.payload.name === "gallery") {
            const gurl = clientInput["gallery_image"];
            if (action.payload.id) {
              gurl.push({
                id: action.payload.id,
                document: action.payload.value,
                order: gurl.length + 1,
              });
            }

            clientInput["gallery_image"] = gurl;
            if (action.payload.id) {
              clientInput[action.payload.name].push(action.payload.id);
            } else {
              clientInput[action.payload.name] = action.payload.value;
            }
            // clientInput[action.payload.name].push(action.payload.id);
          } else if (action.payload.name === "menu") {
            const gurl = clientInput["menu_image"];
            if (action.payload.id) {
              gurl.push({
                id: action.payload.id,
                document: action.payload.value,
                order: gurl.length + 1,
              });
            }
            clientInput["menu_image"] = gurl;
            if (action.payload.id) {
              clientInput[action.payload.name].push(action.payload.id);
            } else {
              clientInput[action.payload.name] = action.payload.value;
            }
            // clientInput[action.payload.name].push(action.payload.id);
          } else if (action.payload.name === "cover") {
            const gurl = clientInput["cover_image"];
            if (action.payload.id) {
              gurl.push({
                id: action.payload.id,
                document: action.payload.value,
                order: gurl.length + 1,
              });
            }
            clientInput["cover_image"] = gurl;
            if (action.payload.id) {
              clientInput[action.payload.name].push(action.payload.id);
            } else {
              clientInput[action.payload.name] = action.payload.value;
            }
            // clientInput[action.payload.name].push(action.payload.id);
          } else {
            clientInput[action.payload.name].push(action.payload.value);
            const Gallery = clientInput[action.payload.name];
            clientInput[action.payload.name] = Gallery;
          }
        }
      } else {
        clientInput[action.payload.name] = action.payload.value;
      }

      return {
        ...state,
        clientInput: clientInput,
      };
    case Types.IS_LOAD_CLIENT:
      return {
        ...state,
        isLoadclient: action.payload,
      };
    case Types.AFTER_CLIENT_DATA:
      return {
        ...state,
        afterClientdata: action.payload,
      };
    case Types.GET_CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload,
      };

    case Types.GET_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      };
    case Types.SET_CLIENT_FALSE:
      return {
        ...state,
        afterClientdata: action.payload,
        clientInput: initialState.clientInput,
      };
    case Types.IS_CLIENT_DELETED:
      return {
        ...state,
        isClientDeleted: action.payload,
      };

      case Types.GET_CLIENT_UODATED:
        const setclientInput = { ...state.clientInput };
        setclientInput.restaurant_types = action.payload.restaurant_types || [];
        setclientInput.branch = action.payload.branch || [];
        setclientInput.phone_numbers = action.payload.phone_numbers || [];
        setclientInput.id = action.payload.id;
        setclientInput.email = action.payload.email;
        // setclientInput.password = action.payload.password;
        setclientInput.phone = action.payload.phone;
        setclientInput.company_name = action.payload.company_name;
        setclientInput.company_website = action.payload.company_website;
        setclientInput.company_service_type = action.payload.company_service_type;
        setclientInput.paid_amount = action.payload.paid_amount;
        setclientInput.payment_duration = action.payload.payment_duration;
  
        setclientInput.open_time = action.payload.open_time;
        setclientInput.close_time = action.payload.close_time;
        setclientInput.latitude = action.payload.latitude;
        setclientInput.longitude = action.payload.longitude;
        setclientInput.location = action.payload.location;
        setclientInput.status = action.payload.status;
        setclientInput.has_multiple_branch = action.payload.has_multiple_branch;
        setclientInput.is_agree = action.payload.is_agree;
        setclientInput.is_active = action.payload.is_active;
        setclientInput.parent = action.payload.parent;
        setclientInput.company_category = action.payload.company_category || [];
        setclientInput.payment_billing_type = action.payload.payment_billing_type;
        setclientInput.logo = action.payload.logo;
        setclientInput.logo_image = action.payload.logo_image;
        setclientInput.whatsapp = action.payload.whatsapp;
        setclientInput.twitter = action.payload.twitter;
        setclientInput.linkedin = action.payload.linkedin;
        setclientInput.instagram = action.payload.instagram;
        setclientInput.facebook = action.payload.facebook;
        setclientInput.menu = action.payload.menu || [];
        setclientInput.menu_image = action.payload.menu_image || [];
        setclientInput.menu_list = action.payload.menu_list || [];
        setclientInput.cover = action.payload.cover;
        setclientInput.cover_image = action.payload.cover_image || [];
        setclientInput.gallery = action.payload.gallery || [];
        setclientInput.gallery_image = action.payload.gallery_image || [];
        setclientInput.facilities = action.payload.facilities;
        setclientInput.facilities = MyhtmlToDraft(setclientInput?.facilities || "");
        setclientInput.about = action.payload.about;
        setclientInput.about = MyhtmlToDraft(setclientInput?.about|| "");
        // setclientInput.password = action.payload.password;
        return {
          ...state,
          clientInput: setclientInput,
        };

    default:
      break;
  }
  return newState;
};

export default ClientsReducer;
export const MyhtmlToDraft = (data) => {
  const blocksFromHtml = htmlToDraft(data);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};
