import * as Types from "../types/Types";
import * as client from "../../../Clients/all_clients/_redux/types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";

export const GetBusinessDetails = (api) => (dispatch) => {
  // const url = `${BASE_URL}api/v1/users/admin/dashboard/business/`;
  const url = api;
  dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_MY_BUSINESS_DETAILS,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: true });
};
export const GetMenuList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/settings/admin/menu/`;
  // const url = api;
  dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_MENU_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: true });
};

export const GetMenuInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_MENU_INPUT, payload: formData });
};

export const SetMenuStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_MENU_FALSE, payload: false });
};
export const SubmitMenudData = (data, name) => async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  data.user = userData.id;
  if (data.name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/settings/admin/menu/`;
  dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });
          showToast("success", "Menu  added successfully");
          dispatch(GetMenuList());
          if (name === "menu") {
            dispatch({
              type: client.GET_CLIENT_INPUT,
              payload: { name: "menu_list", value: res.data.data.id },
            });
          }
          dispatch({ type: Types.AFTER_MENU_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_MY_BUSINESS, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

// --// --------------delete------------
export const Menudelete = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/settings/admin/menu/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("error", res.data.message);
        dispatch({ type: Types.IS_MENU_DELETED, payload: true });
        dispatch(GetMenuList());
      }
    });
  } catch (error) {}
};
export const SetMenuDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_MENU_DELETED, payload: false });
};
