// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";

import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ArrowSvg from "../../Common/ArrowSvg";
import { useState, useEffect } from "react";
import {
  GetAdvertisementInput,
  SetAdvertisementStatusFalse,
  SetAdvertisementUpdatedData,
  SubmitAdvertisementdData,
  UpdateAdvertisementdData,
} from "./_redux/action/AddvertisementAction";
import { GetClientList } from "../all_clients/_redux/action/ClientAction";
import { SubmitImage } from "../../Seetings/_redux/action/SeetingsAction";
import { placement_list, status_list, week_list } from "../../Common/Dropdown";
import { BASE_URL } from "../../../Const";
const AddTotalAdvertisement = (props) => {
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState([]);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);

  const addvertisementInput = useSelector(
    (state) => state.addvertisementInfo.addvertisementInput
  );
  const client_List = useSelector((state) => state.clientInfo.clientList);
  const afterAddvertisementData = useSelector(
    (state) => state.addvertisementInfo.afterAddvertisementData
  );
  useEffect(() => {
    if (afterAddvertisementData) {
      props.onHide();
      localStorage.removeItem("Advertise_id");
    }
    dispatch(SetAdvertisementStatusFalse());
  }, [afterAddvertisementData]);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  const handleChangeInput = (name, value) => {
    dispatch(GetAdvertisementInput(name, value));
    
  };
  const handleaddSubmit = (data) => {
    dispatch(SubmitAdvertisementdData(data,  `${BASE_URL}api/v1/promotions/admin/advertisement/?limit=20`));
  };
  const handleUpdateSubmit = (data) => {
    dispatch(UpdateAdvertisementdData(data,  `${BASE_URL}api/v1/promotions/admin/advertisement/?limit=20`));
  };
  useEffect(() => {
    if (props.id) {
      dispatch(SetAdvertisementUpdatedData(props.id));
    }
    if (userData.client_id) {
      dispatch(GetAdvertisementInput("ad_for", userData.client_id));
    }
  }, []);
const handelCancel=()=>{
  dispatch(SetAdvertisementStatusFalse());
  props.onHide()
}
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {isLoadImage && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div>
          <h4
            className="text-center text-capitalize"
            style={{ color: "#515151" }}
          >
            Advertisement
          </h4>
        </div>

        <div className="mx-4 ">
          <div className=" input-title my-3">
            <p>Add Name :</p>

            <input
              type="text"
              name="name"
              value={addvertisementInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
          <div className="inputs-modal my-3">
            <p>Select terget client (Restaurent or services)</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="ad_for"
                value={addvertisementInput.ad_for}
                onChange={(e) => handleChangeInput("ad_for", e.target.value)}
              >
                 <option value={""}>--</option>
                {client_List?.results?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.company_name}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
          <div className="inputs-modal my-3">
            <p>Placement</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="placement"
                value={addvertisementInput.placement}
                onChange={(e) => handleChangeInput("placement", e.target.value)}
              >
                <option value={""}>--</option>
                {placement_list?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
          {addvertisementInput.id && (
            <div className="inputs-modal my-3">
              <p>Status</p>
              <div style={{ position: "relative" }}>
                <select
                  id="inputState"
                  className="form-select "
                  name="status"
                  value={addvertisementInput.status}
                  onChange={(e) => handleChangeInput("status", e.target.value)}
                >
                  <option value="">--</option>
                  {status_list?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="arrow-icon">
                  <ArrowSvg />
                </div>
              </div>
            </div>
          )}

          <div className="inputs-modal my-3">
            <p>Start Date:</p>
            <div style={{ position: "relative" }}>
              <input
                type="datetime-local"
                name="start_date"
                value={addvertisementInput.start_date}
                onChange={(e) =>
                  handleChangeInput("start_date", e.target.value)
                }
              />
            </div>
          </div>
          <div className="inputs-modal my-3">
            <p>End Date:</p>
            <div style={{ position: "relative" }}>
              <input
                type="datetime-local"
                name="expiry_date"
                value={addvertisementInput.expiry_date}
                onChange={(e) =>
                  handleChangeInput("expiry_date", e.target.value)
                }
              />
            </div>
          </div>
          <div className="inputs-modal my-3">
            <p>Banner Image:</p>
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="preview_img">
                  {selectedImages
                    ? selectedImages.map((image, index) => {
                        return (
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="col-11 col-md-9 row justify-content-between ">
                              <div className="col-10 col-md-6 my-2 my-md-0">
                                <div
                                  className="d-flex align-items-center  px-2"
                                  style={{ position: "relative" }}
                                >
                                  {addvertisementInput.thumb_url ? (
                                    <img
                                      src={addvertisementInput?.thumb_url}
                                      height={36}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={addvertisementInput?.banner_url}
                                      height={36}
                                      alt=""
                                    />
                                  )}

                                  {/* //{" "} */}
                                  {/* <img
                                  src={addvertisementInput?.thumb_url}
                                  height={36}
                                  alt=""
                                /> */}
                                  <div
                                    className="ms-2 mt-2"
                                    style={{ color: "#979FB8" }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        marginTop: "10px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {addvertisementInput.fileName8}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      {addvertisementInput.size3 / 1000}
                                    </p>
                                  </div>
                                  <div
                                    onClick={() => deleteHandler(image)}
                                    className="ms-2"
                                    style={{ marginTop: "-34px" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="#000000"
                                      viewBox="0 0 256 256"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <rect
                                        width="256"
                                        height="256"
                                        fill="none"
                                      ></rect>
                                      <circle
                                        cx="128"
                                        cy="128"
                                        r="96"
                                        fill="none"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="24"
                                      ></circle>
                                      <line
                                        x1="160"
                                        y1="96"
                                        x2="96"
                                        y2="160"
                                        fill="none"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="24"
                                      ></line>
                                      <line
                                        x1="160"
                                        y1="160"
                                        x2="96"
                                        y2="96"
                                        fill="none"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="24"
                                      ></line>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="view_icon"
                              style={{ cursor: "pointer" }}
                            >
                              <FaEye />
                            </div>
                          </div>
                        );
                      })
                    : addvertisementInput?.banner_url && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-11 col-md-9 row justify-content-between ">
                            <div className="col-10 col-md-6 my-2 my-md-0">
                              <div
                                className="d-flex align-items-center  px-2"
                                style={{ position: "relative" }}
                              >
                                {/* {addvertisementInput.thumb_url ? (
                                  <img
                                    src={addvertisementInput?.thumb_url}
                                    height={36}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={addvertisementInput?.banner_url}
                                    height={36}
                                    alt=""
                                  />
                                )} */}

                                {/* //{" "} */}
                                <img
                                  src={addvertisementInput?.banner_url}
                                  height={36}
                                  alt=""
                                />
                                <div
                                  className="ms-2 mt-2"
                                  style={{ color: "#979FB8" }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginTop: "10px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {addvertisementInput.fileName8}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {addvertisementInput.size3 / 1000}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="view_icon"
                            style={{ cursor: "pointer" }}
                          >
                            <FaEye />
                          </div>
                        </div>
                      )}
                </div>
              </div>
              <div className="col-lg-4">
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    width: "100%",
                    background: "#EF5B0C",
                    borderRadius: "12px",
                    padding: "15px 25px",
                    fontSize: "12px",
                  }}
                  onChange={onSelectFile}
                >
                  Browse File
                  <input
                    hidden
                    type={"file"}
                    name="banner"
                    accept="image/*"
                    onChange={(e) => {
                      imageInput.owner = userData.id;
                      imageInput.doc_type = 0;
                      imageInput.document = e.target.files[0];
                      dispatch(SubmitImage(imageInput, "banner"));
                      handleChangeInput("fileName8", e.target.files[0].name);
                      handleChangeInput("size3", e.target.files[0].size);
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onloadend = (e) => {
                        // setPdfFile(e.target.result);
                        handleChangeInput("thumb_url", e.target.result);
                      };
                    }}
                    url={addvertisementInput?.thumb_url}
                    file_name={addvertisementInput.fileName8}
                    size={addvertisementInput.size3 / 1000}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end gap-3">
          <button className="yellow-btn"onClick={()=>handelCancel()}>Cancel</button>
          <button
            className="green-btn"
            onClick={() => {
              addvertisementInput.id
                ? handleUpdateSubmit(addvertisementInput)
                : handleaddSubmit(addvertisementInput);
            }}
          >
            Published
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTotalAdvertisement;
