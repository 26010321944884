import React, { useState } from "react";
// import { SubmitImage } from "../image_upload/_redux/action/Imageaction";
import { useDispatch, useSelector } from "react-redux";
import { GetGeneralInput, SubmitImage } from "./_redux/action/SeetingsAction";
const UploadImage = ({ title, name, onImageChange, url, file_name, size }) => {
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState([]);
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  // ========image upload----------------
  // const userData = JSON.parse(localStorage.getItem("userData"));
  const generalInput = useSelector((state) => state.seetingInfo.generalInput);
  const imageInput = useSelector((state) => state.seetingInfo.imageInput);

  const handleChangeInput = (name, value) => {
    dispatch(GetGeneralInput(name, value));
  };
  return (
    <div>
      <div className="row input-title" style={{ marginTop: "35px" }}>
        <div className="col-11 col-md-6 input-head row">
          <div className="col-12 col-md-5 my-2 my-md-0">
            <p className="fw-bold">{title}</p>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex align-items-center">
              <div className="logo-file">
                <p style={{ fontSize: "15px", color: "#2D2D2D" }}>
                  Choose File
                </p>
                <input
                  type="file"
                  accept="image/*"
                  name={name}
                  
                  onChange={onImageChange}
                  
                />
              </div>
              {/* {name === "logo" ? (
                <span
                  className="ms-3"
                  style={{ color: "#ef5b0c", fontSize: "15px" }}
                >
                  File Selected
                </span>
              ) : name === "fav_icon" ? (
                <span
                  className="ms-3"
                  style={{ color: "#ef5b0c", fontSize: "15px" }}
                >
                  File Selected
                </span>
              ) :(
                <span
                  className="ms-3"
                  style={{ color: "#737373", fontSize: "15px" }}
                >
                  No file chosen
                </span>
              )} */}
            </div>
            <p className="mt-3">Maximum Size: 2 MB</p>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-11 col-md-6 row justify-content-between">
          {/* {selectedImages &&
            selectedImages.map((image, index) => {
              return ( */}
          <div
            key={generalInput?.default_role}
            className="col-10 col-md-6 my-2 my-md-0"
          >
            <div
              className="d-flex align-items-center  px-2"
              style={{ position: "relative" }}
            >
             
             {url &&   <img src={url} height={36} width={36} alt="" />} {/* {generalInput?.logo_url ?   <img src={generalInput?.logo_url} height={36} width={36} alt="" /> :generalInput?.fav_icon_url &&  <img src={generalInput?.fav_icon_url} height={36} width={36} alt="" /> } */}
            
              <div className="ms-2 mt-2" style={{ color: "#979FB8" }}>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "10px",
                    fontWeight: "500",
                  }}
                >
                  {file_name}
                </p>
                <p style={{ fontSize: "12px", marginTop: "-10px" }}>
                  {size}
                </p>
              </div>
              {/* <div
                className="ms-2"
                style={{ marginTop: "-34px", cursor: "pointer" }}
                onClick={() => deleteHandler(generalInput?.default_role)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <rect width="256" height="256" fill="none"></rect>
                  <circle
                    cx="128"
                    cy="128"
                    r="96"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></circle>
                  <line
                    x1="160"
                    y1="96"
                    x2="96"
                    y2="160"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></line>
                  <line
                    x1="160"
                    y1="160"
                    x2="96"
                    y2="96"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="24"
                  ></line>
                </svg>
              </div> */}
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
