import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import noData2 from '../../../../../assets/jsonFile/noData2.json'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Lottie from "react-lottie";
import UserPagination from "../../../../Common/UserPagination";
import {
  GetBusinesUserdDetails,
  GetBusinesUserdUserList,
  NextUserListList,
  PreviousUserList,
} from "../_redux/action/BusinessUserAction";
import { BASE_URL } from "../../../../../Const";
import { GetUserNotification } from "../../verifieduser/_redux/action/VerifiedUserAction";
const BusinessUserList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const api = `${BASE_URL}api/v1/restaurant/admin/business-requested/?limit=20`;
  const businessUserList = useSelector(
    (state) => state.businessUserInfo.businessUserList
  );
  const isLoadBusinessuser = useSelector(
    (state) => state.businessUserInfo.isLoadBusinessuser
  );

 
  const [selected, setSelected] = React.useState([]);
  const options = ["None", "Atria", "Callisto"];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    setUsers(businessUserList);
    dispatch(GetBusinesUserdUserList(api));
    dispatch(GetUserNotification());
  }, []);

  // React.useEffect(() => {
  //   if(selectedName === "is_app_user"){
  //     dispatch(GetBusinesUserdUserList(`${BASE_URL}api/v1/restaurant/admin/business-requested/?limit=20`));
  //   }
  //   else if(selectedName === "is_verified"){
  //     dispatch(GetBusinesUserdUserList(`${BASE_URL}api/v1/restaurant/admin/business-requested/?limit=20`));
  //   }
  //   else if(selectedName === "is_requested"){
  //     dispatch(GetBusinesUserdUserList(`${BASE_URL}api/v1/restaurant/admin/business-requested/?is_requested=true&&limit=20`));
  //   }
  // }, [selectedName]);

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  // ----------------------api calll--------------------
  const handleEditUser = (id) => {
    // dispatch(SetVerifiedUpdatedData(id));
    // history.push(`/edit_verified_user/${id}`);
  };
  const handleViewUser = (id) => {
    dispatch(GetBusinesUserdDetails(id));
    history.push(`/profile/${id}`);
  };
  React.useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  // --------------pagination----------------

  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextUserListList(businessUserList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousUserList(businessUserList?.previous));
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
       {isLoadBusinessuser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadBusinessuser &&
        businessUserList?.results &&
        businessUserList?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}
          {!isLoadBusinessuser &&
        businessUserList?.results &&
        businessUserList?.results !== null &&
        businessUserList?.results?.length > 0 && (
      <TableContainer component={Paper} className="text-capitalize">
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          {/* <caption>A basic table example with a caption</caption> */}
          <TableHead style={{ background: "white" }}>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center"> Date</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Phone Number</TableCell>
              <TableCell align="center">Description</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody style={{ background: "white" }}>
            {businessUserList?.results
              ?.filter((val) => {
                if (search === "") {
                  return val;
                } else if (
                  val.name?.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                } else if (
                  val.phone?.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                } else if (
                  val.email?.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                }
              })
              .map((row) => {
                const isItemSelected = isSelected(row.name);
                return (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      <div className="d-flex  gap-2">
                        {/* <Avatar alt="User1" src="/static/images/avatar/1.jpg" />{" "} */}
                        <div style={{ textAlign: "left", lineHeight: "20px" }}>
                          <p style={{ color: "#EF5B0C", fontWeight: "bold" }}>
                            {row.name}
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginBottom: "0",
                      }}
                    >
                      <p>
                        Requested:{" "}
                        {new Date(row.created_at).toISOString().slice(0, 10)}
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ textAlign: "center", lineHeight: "20px" }}>
                        <p
                          style={{
                            color: "#7A7A7A",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          {row.email}
                        </p>{" "}
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#7A7A7A",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginBottom: "0",
                      }}
                    >
                      {row.phone}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#7A7A7A",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginBottom: "0",
                      }}
                    >
                      {row.desc}
                    </TableCell>
                  
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
 )}

      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {businessUserList?.count}</p>
        <UserPagination
          prev_next={businessUserList}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default BusinessUserList;
