import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../Const";
import swal from "sweetalert";
import { GetNewAddList } from "../../../../add_request/new_add/_redux/action/NewAddAction";

export const GetAdvertisementList = (api) => (dispatch) => {
  const url = api;
  // const url = `${BASE_URL}api/v1/promotions/admin/advertisement/?client=${client_id}`;
  dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_ADDVERTISEMENT_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: true });
};
export const NextAddListList = (nextUrl) => (dispatch) => {
  const url = nextUrl;
  dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_ADDVERTISEMENT_LIST,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
  }
};

export const PreviousAddList = (prevUrl) => (dispatch) => {
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_ADDVERTISEMENT_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: true });
};

export const GetAdvertisementInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_ADDVERTISEMENT_INPUT, payload: formData });
  // dispatch({ type: Types.ADD_NEW_DOC, payload: {name:'restaurant_types', value:, } });
};
export const SetAdvertisementStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_ADDVERTISEMENT_FALSE, payload: false });
};
export const SubmitAdvertisementdData = (data, api) => async (dispatch) => {
  // if (data.name === client_id) {
  //     showToast("error", "Name shouldn't be empty !");

  //     return 0;
  //   }

  const url = `${BASE_URL}api/v1/promotions/admin/advertisement/`;
  dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
          showToast("success", "Advertisement added successfully");

          dispatch({ type: Types.AFTER_ADDVERTISEMENT_DATA, payload: true });
          dispatch(GetAdvertisementList(api));
          // dispatch(GetNewAddList());
        } else {
          dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const SetAdvertisementUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/promotions/admin/advertisement/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_ADDVERTISEMENT_UODATED,
          payload: res.data.data,
        });
      }
    });
  } catch (error) {}
};

export const UpdateAdvertisementdData = (data, api) => async (dispatch) => {
  // if (data.name === "") {
  //   showToast("error", "Name shouldn't be empty !");
  //   return 0;
  // } else if (data.address === "") {
  //   showToast("error", "Adress shouldn't be empty !");
  //   return 0;
  // } else if (data.email === "") {
  //   showToast("error", "Email shouldn't be empty !");
  //   return 0;
  // }

  const url = `${BASE_URL}api/v1/promotions/admin/advertisement/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: true });

  try {
    await Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
          showToast("success", "Advertisement updated successfully");
          dispatch(GetAdvertisementList(api));
          dispatch({ type: Types.AFTER_ADDVERTISEMENT_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_ADDVERTISEMENT, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const GetAdvertisementdelete = (id, api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/promotions/admin/advertisement/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("error", res.data.message);
        dispatch({ type: Types.IS_ADDVERTISEMENT_DELETED, payload: true });
        dispatch(GetAdvertisementList(api));
      }
    });
  } catch (error) {}
};
export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_ADDVERTISEMENT_DELETED, payload: false });
};
