import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../../Const";
import swal from "sweetalert";

export const GetCountryList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/auth/country/`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_COUNTRY_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};
export const GetAppuserList = (api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/users/admin/?is_app_user=true`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(api).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_APP_USER_LIST,
          // type: Types.GET_USER_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};
export const NextUserListList = (nextUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const url = nextUrl;
  // dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_APP_USER_LIST,
          payload: res.data.data,
        });
      }
    });
  } catch (error) {}
};

export const PreviousOrderList = (prevUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const url = `${BASE_URL}api/v1/products/admin/order/?offset=${}&limit=100`;
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_APP_USER_LIST,
          payload: res.data.data,
        });

        // dispatch({
        //   type: Types.CURRENT_ORDER_COUNT,
        //   payload: res.data.data.count,
        // });
        // dispatch({
        //   type: Types.CURRENT_ORDER_PAGINATION,
        //   payload: res.data.data,
        // });

        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};
export const GetAppuserlInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_APP_USER_INPUT, payload: formData });
};
export const SetAppUserStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_APP_USER_FALSE, payload: false });
};
export const SubmitAppUserldData = (data) => async (dispatch) => {
  if (parseInt(data.membership_level) === 1) {
    data.is_verified = true;
    data.is_requested = false;
  } else if (parseInt(data.membership_level) === 0) {
    data.is_verified = false;
    data.is_requested = true;
  }
  if (data.first_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
  if (data.last_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  } else if (data.membership_level === "") {
    showToast("error", "Membership level shouldn't be empty !");
    return 0;
  } else if (data.role === "") {
    showToast("error", "Role shouldn't be empty !");
    return 0;
  } else if (data.image === "") {
    showToast("error", "Image shouldn't be empty !");
    return 0;
  } else if (data.role === "") {
    showToast("error", "Role shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/users/admin/`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
          showToast("success", "App User  added successfully");
          dispatch(GetAppuserList());
          dispatch({ type: Types.AFTER_APP_USER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const UpdateAppUserldData = (data) => async (dispatch) => {
  if (parseInt(data.membership_level) === 1) {
    data.is_verified = true;
    data.is_requested = false;
  } else if (parseInt(data.membership_level) === 0) {
    data.is_verified = false;
    data.is_requested = true;
  }
  if (data.first_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  }
  if (data.last_name === "") {
    showToast("error", "First Name shouldn't be empty !");
    return 0;
  } else if (data.membership_level === "") {
    showToast("error", "Membership level shouldn't be empty !");
    return 0;
  } else if (data.role === "") {
    showToast("error", "Role shouldn't be empty !");
    return 0;
  } else if (data.image === "") {
    showToast("error", "Image shouldn't be empty !");
    return 0;
  } else if (data.role === "") {
    showToast("error", "Role shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/users/admin//${data.id}/`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });

  try {
    await Axios.put(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
          showToast("success", " User updated successfully");
          dispatch(GetAppuserList());
          dispatch({ type: Types.AFTER_APP_USER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const SetAppUserUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/users/admin/${id}/`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_APP_USER_UODATED,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};
export const SetRequestedUserUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/users/admin/${id}/`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_REQUESTED_USER_UODATED,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};
export const GetAppUserDetails = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/users/admin/${id}/`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_APP_USER_DETAILS,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};
// --// --------------delete------------
export const Alluserdelete = (id,api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/users/admin/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("error", res.data.message);
        dispatch({ type: Types.IS_APP_USER_DELETED, payload: true });
        dispatch(GetAppuserList(api))
      }
    });
  } catch (error) {}
};
export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_APP_USER_DELETED, payload: false });
};

// ----verified-----------

export const GetRequesteduserList = (api) => (dispatch) => {
  const url = api;
  // const url = `${BASE_URL}api/v1/users/admin/?is_requested=true`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_REQUESTED_USER_LIST,
          // type: Types.GET_USER_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};

export const NextRequestedUserList = (nextUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const url = nextUrl;
  // dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_REQUESTED_USER_LIST,
          payload: res.data.data,
        });
      }
    });
  } catch (error) {}
};

export const PreviousRequestedUserList = (prevUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const url = `${BASE_URL}api/v1/products/admin/order/?offset=${}&limit=100`;
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_REQUESTED_USER_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });
};

export const GetRequestedduserlInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_REQUESTED_USER_INPUT, payload: formData });
};
// export const SetAppUserStatusFalse = () => (dispatch) => {
//   dispatch({ type: Types.false, payload: false });
// };
export const SubmitRequesteddUserldData = (data) => async (dispatch) => {
  if (data.name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  } else if (data.speech === "") {
    showToast("error", "Testimonial shouldn't be empty !");
    return 0;
  } else if (data.designation === "") {
    showToast("error", "Designation shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/users/admin/`;
  dispatch({ type: Types.IS_LOAD_APP_USER, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
          showToast("success", "App User  added successfully");
          dispatch(GetAppuserList());
          dispatch({ type: Types.AFTER_APP_USER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_APP_USER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
