import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  GetInvoicelInput,
  GetInvoiceList,
  SetInvoiceStatusFalse,
  UpdateInvoiceldData,
} from "./_redux/action/InvoiceAction";
import { invoice_status } from "../../Common/Dropdown";
import ArrowSvg from "../../Common/ArrowSvg";

const EditInvoice = (props) => {
  const dispatch = useDispatch();
  const invoiceInput = useSelector((state) => state.inVoiceInfo.invoiceInput);

  const afterInvoiceData = useSelector(
    (state) => state.inVoiceInfo.afterInvoiceData
  );
  const client_id = localStorage.getItem("client_id");

  const handleChangeInput = (name, value) => {
    dispatch(GetInvoicelInput(name, value));
  };
  const SubmitRepoprt = (data) => {
  
    dispatch(UpdateInvoiceldData(data, props.api));
  };
  console.log("data", props.api,afterInvoiceData);
  useEffect(() => {
    if (afterInvoiceData) {
      props.onHide();
      dispatch(GetInvoiceList(props.api));
    }
    dispatch(SetInvoiceStatusFalse());
  }, [afterInvoiceData]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="mx-2 mx-md-3 mx-lg-4">
        <div>
          <h4 className="text-center" style={{ color: "#515151" }}>
            Add Report
          </h4>
        </div>

        <div className="inputs-modal my-3">
          <p>Status</p>
          <div style={{ position: "relative" }}>
            <select
              id="inputState"
              className="form-select "
              name="status"
              value={invoiceInput.status}
              onChange={(e) => handleChangeInput("status", e.target.value)}
            >
              <option value="">--</option>
              {invoice_status?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="arrow-icon">
              <ArrowSvg />
            </div>
          </div>
        </div>

        {/* <div>
          <div className="inputs-modal my-3">
            <p>Description</p>
            <textarea
              rows={4}
              type="text"
              name="report_text"
              value={invoiceInput.report_text}
              onChange={(e) => handleChangeInput("report_text", e.target.value)}
            />
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer className="mx-2 mx-md-3 mx-lg-4">
        <Button
          className="save-btn "
          style={{ color: "white", background: "#EF5B0C" }}
          onClick={() => SubmitRepoprt(invoiceInput)}
          // {props.onHide}
        >
          {" "}
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditInvoice;
