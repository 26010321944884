import * as Types from "../types/Types";
const initialState = {
  imageInput: {
    document: "",
    doc_type: 0,
    owner: 0,
  },

  testimonialInput: {
    name: "",
    designation: "",
    company: "string",
    speech: "",
    rank: 12345,
    is_active: true,
    image: null,
    image_url:"",
  },

  testimonialList: null,
  isTestimonialDeleted: null,
  isLoadTestimonial: false,
  afterTestimonialData: false,
};
const TestimonialReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_TESTIMONIAL_INPUT:
      const testimonialInput = { ...state.testimonialInput };
      testimonialInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        testimonialInput: testimonialInput,
      };
    case Types.IS_LOAD_TESTIMONIAL:
      return {
        ...state,
        isLoadTestimonial: action.payload,
      };
    case Types.AFTER_TESTIMONIAL_DATA:
      return {
        ...state,
        afterTestimonialData: action.payload,
      };
    case Types.GET_TESTIMONIAL_LIST:
      return {
        ...state,
        testimonialList: action.payload,
      };
    case Types.SET_TESTIMONIAL_FALSE:
      return {
        ...state,
        afterTestimonialData: action.payload,
        testimonialInput: initialState.testimonialInput,
      };
    case Types.IS_TESTIMONIAL_DELETED:
      return {
        ...state,
        isTestimonialDeleted: action.payload,
      };

      case Types.TESTIMONIAL_UPDATE_DATA:
        const setTestimonialInput = { ...state.testimonialInput };
        setTestimonialInput.id = action.payload.id;
        setTestimonialInput.name = action.payload.name;
        setTestimonialInput.designation = action.payload.designation;
        setTestimonialInput.speech = action.payload.speech;
        setTestimonialInput.company = action.payload.company;
        setTestimonialInput.rank = action.payload.rank;
        setTestimonialInput.image_url = action.payload.image_url;
        setTestimonialInput.image = action.payload.image;
        return {
            ...state,
            testimonialInput: setTestimonialInput,
          };

    default:
      break;
  }
  return newState;
};

export default TestimonialReducer;
