import { Switch } from "@material-ui/core";
import {
  FacebookLogo,
  InstagramLogo,
  ShareNetwork,
  WhatsappLogo,
} from "phosphor-react";
import React from "react";
import { useState, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import qr from "../../assets/img/QRcode.jpg";
import Delete from "../Svgs/Delete";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import BrowsePhotosProfile from "./BrowsePhotosProfile";
import "./Client.css";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCountryList,
  SetAppUserUpdatedData,
} from "../User/component/appUser/_redux/action/AppUserAction";
const Profile = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const appUserDetails = useSelector(
    (state) => state.appUserInfo.appUserDetails
  );
  const isLoadAppUser = useSelector((state) => state.appUserInfo.isLoadAppUser);
  const countryList = useSelector((state) => state.appUserInfo.countryList);
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }

    dispatch(GetCountryList());
  }, []);

  const handleEditUser = () => {
    dispatch(SetAppUserUpdatedData(id));
    history.push(`/edit-user/${id}`);
  };
  return (
    <div className="parent min-vh-100">
      {isLoadAppUser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>Profile</span>
        </div>

        <div className=" border bg-white mt-4">
          <div className="px-4 py-3 row justify-content-between align-items-center">
            <div className="col-md-4 d-flex align-items-center">
              <img
                src={appUserDetails?.image_url}
                width={50}
                height={50}
                className="rounded-circle"
                alt=""
              />
              <h5
                className="ms-2 ms-md-4"
                style={{ fontSize: "22px", color: "#000000" }}
              >
                {appUserDetails?.first_name} {appUserDetails?.last_name}
              </h5>
            </div>
            {/* <div className="col-md-3">
              <span style={{ color: "#7a7a7a" }}>Available</span>
              <Switch
                // checked={loading}
                // onChange={() => setLoading(!loading)}
                name="loading"
                color="primary"
              />
              <span
                style={{ cursor: "pointer", color: "#03438C" }}
                onClick={() => handleEditUser()}
              >
                <FaEdit
                  color="#03438C"
                  className="me-2"
                  style={{ fontSize: "22px" }}
                />
                Edit
              </span>
            </div> */}
          </div>
          <div className="px-4 py-3 row">
            <div className="col-12 my-3">
              <h6 color="#000000" style={{ fontSize: "20px" }}>
                GENERALS
              </h6>
            </div>
            {/* <div className="col-12 col-md-9 row justify-content-between"> */}
            <div className="col-12 col-md-9 ">
              {/* <div
                className="col-12 col-md-6 col-lg-4"
                style={{ color: "#BBBBBB" }}
              >
                <p>Company Name:</p>
                <p style={{ marginTop: "-7px" }}>{appUserDetails?.username}</p>
              </div> */}
              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}> Address:</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                  Bangladesh
                </p>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}>Work Phone Number:</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                  {appUserDetails?.mobile}
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p style={{ color: "#000000" }}>Email Address:</p>
                <p style={{ color: "#BBBBBB", marginTop: "-7px" }}>
                  {appUserDetails?.email}
                </p>
              </div>
            </div>
            <div className="col-10 col-md-3">
              <p>Verification :</p>
              <p style={{ color: "#BBBBBB", fontSize: "13px" }}>
                {appUserDetails?.verification_info}
              </p>
            </div>
          </div>
          <hr />
          {/* {appUserDetails?.is_superuser && (
            <>
              <BrowsePhotosProfile title="Change or upload Cover Photos:" />
              <BrowsePhotosProfile title="Change or upload Menu Photos:" />
              <BrowsePhotosProfile title="Gallery" />
            </>
          )} */}
        </div>
        {/* {appUserDetails?.is_superuser && (
          <div className="row mt-4">
            <div className="col-12 mt-3 ">
              <p
                className="fw-bold ms-3"
                style={{ color: "#515151", fontSize: "15px" }}
              >
                Your QR Code:
              </p>
            </div>
            <div className="col-12 mb-2">
              <img src={qr} alt="" />
            </div>
            <div className="col-12 d-flex align-items-center">
              <p
                className="my-auto"
                style={{ color: "#7A7A7A", fontSize: "15px" }}
              >
                Share :
              </p>
              <div className="d-flex gap-2 align-items-center ms-2 ">
                <ShareNetwork cursor={"pointer"} size={30} weight="bold" />
                <FacebookLogo cursor={"pointer"} size={30} weight="fill" />
                <InstagramLogo cursor={"pointer"} size={30} weight="light" />
                <WhatsappLogo cursor={"pointer"} size={32} weight="light" />
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Profile;
