import * as Types from "../types/Types";
import Axios from "axios";



import swal from "sweetalert";
import { BASE_URL } from "../../../../Const";



export const GetSupportUserList = (api) => (dispatch) => {
  // const url = `${BASE_URL}api/v1/users/admin/?is_Support=true`;
  const url = api;
  dispatch({ type: Types.IS_LOAD_SUPPORT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_SUPPORT_LIST,
      
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_SUPPORT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_SUPPORT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_SUPPORT, payload: true });
};

export const NextSupportList = (nextUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const url = nextUrl
  // dispatch({ type: Types.IS_LOAD_SUPPORT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_SUPPORT_LIST,
          payload: res.data.data,
        });

        
      }
    });
  } catch (error) {

  }

};

export const PreviousSupportList = (prevUrl) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const url = `${BASE_URL}api/v1/products/admin/order/?offset=${}&limit=100`;
  const url = prevUrl
  dispatch({ type: Types.IS_LOAD_SUPPORT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_SUPPORT_LIST,
          payload: res.data.data,
        });

        
        
        dispatch({ type: Types.IS_LOAD_SUPPORT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_SUPPORT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_SUPPORT, payload: true });
};

export const GetSupportDetails = (id) => (dispatch) => {
    const url = `${BASE_URL}api/v1/users/admin/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_SUPPORT_DETAILS,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };
// // --// --------------delete------------
// export const categorydelete = (id) => (dispatch) => {
//     const url = `${BASE_URL}/api/v1/settings/admin/business-category/${id}/`;
  
//     try {
//       Axios.delete(url).then((res) => {
//         if (res.data.status) {
//           showToast("error", res.data.message);
//           dispatch({ type: Types.IS_CATEGORY_DELETED, payload: true });
//           dispatch(GetCategoryList())
//         }
//       });
//     } catch (error) {}
//   };
//   export const SetFalseObjectDelete = () => (dispatch) => {
//     dispatch({ type: Types.IS_CATEGORY_DELETED, payload: false });
//   };
  
