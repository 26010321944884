import React from "react";
import Pagination from "react-bootstrap/Pagination";
const UserPagination = ({prev_next,handlePreviousClick,handleNexteClick,currentPage }) => {
  // const [currentPage, setCurrentPage] = React.useState(1);
  return (
    <div>
      <div className="d-flex pagination justify-content-end align-items-center gap-3">
        {/* <p>{prev_next.count}</p> */}
        {/* <Pagination>
        <Pagination.First />
        <Pagination.Prev />
      
        <Pagination.Item active >{1}</Pagination.Item>
      
        <Pagination.Next />
        <Pagination.Last />
      </Pagination> */}

      <Pagination>
          {prev_next?.previous != null ? (
            <Pagination.First
              onClick={() => handlePreviousClick(currentPage - 1)}
            />
          ) : (
            <Pagination.First disabled />
          )}
          {/* <Pagination.Prev /> */}
          {prev_next?.previous != null && (
            <Pagination.Prev
              onClick={() => handlePreviousClick(currentPage - 1)}
            />
          )}
          <Pagination.Item id={currentPage} active={true}>
            {currentPage}
          </Pagination.Item>
          {prev_next?.next != null && (
            <Pagination.Next
              onClick={() => handleNexteClick(currentPage + 1)}
            />
          )}

          {prev_next?.next != null ? (
            <Pagination.Last
              onClick={() => handleNexteClick(currentPage + 1)}
            />
          ) : (
            <Pagination.Last disabled />
          )}
        </Pagination>
      </div>

     
    </div>
  );
};

export default UserPagination;
