import * as Types from "../types/Types";
const initialState = {
  categoryInput: {
    name: "",
    is_active: true,
  },
  billingInput: [
    // {
    //   id: "",
    //   is_active: false,
    // },
  ],
  categoryList: null,
  billingTypeList: null,
  iscategorydeleted: null,
  isLoadCategory: false,
  afterCategoryData: false,
  isSuccessBilling: false,
};
const CategoryReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_CATEGORY_INPUT:
      const categoryInput = { ...state.categoryInput };
      categoryInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        categoryInput: categoryInput,
      };
    case Types.IS_LOAD_CATEGORY:
      return {
        ...state,
        isLoadCategory: action.payload,
      };
    case Types.AFTER_CATEGORY_DATA:
      return {
        ...state,
        afterCategoryData: action.payload,
      };
    case Types.GET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case Types.GET_BILLING_TYPE_LIST:
      return {
        ...state,
        billingTypeList: action.payload,
      };
    case Types.SET_CATEGORY_FALSE:
      return {
        ...state,
        afterCategoryData: action.payload,
        categoryInput: initialState.categoryInput,
        billingInput: initialState.billingInput,
      };
    case Types.IS_CATEGORY_DELETED:
      return {
        ...state,
        iscategorydeleted: action.payload,
      };
    case Types.ADD_NEW_BILLING_INPUT:
      const billingInput = [...state.billingInput];
      billingInput[action.payload.index] = {
        ...state.billingInput[action.payload.index],
        [action.payload.name]: action.payload.value,
      };

      return {
        ...state,
        billingInput: [...billingInput],
      };
    case Types.SET_NEW_BILLING_INPUT:
      const newBillingInput = [...state.billingInput];

      return {
        ...state,

        billingInput: [
          ...newBillingInput,
          {
            id: "1",
            is_active: false,
          },
        ],
        // },
      };

      case Types.DELETE_DOC:
        const removeBillingType = { ...state.billingInput };
        const category_billing = state.billingInput.filter((item, index) => {
          return index != action.payload;
        });
  
        return {
          ...state,
          ...removeBillingType,
          billingInput: [...category_billing],
        };
      case Types.SUCCESS_DOC:
        return {
          ...state,
          isSuccessBilling: action.payload,
          billingInput: initialState.billingInput,
        };

    default:
      break;
  }
  return newState;
};

export default CategoryReducer;
