import { Search } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../Const";
import { discount_list } from "../../Common/Dropdown";
import { GetClientList } from "../all_clients/_redux/action/ClientAction";
import { GetClientCategoryList } from "../category/_redux/action/ClientCategoryAction";
import { GetClientProductList } from "../products/_redux/action/ClientProductAction";
import ClientOffersList from "./ClientOffersList";
import { useDispatch } from "react-redux";

const ClientOffers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [selectedName, setSelectedName] = useState(undefined);
  const handleSelectChange = (e) => {
    setSelectedName(e.target.value);
  };
  useEffect(() => {
    dispatch(GetClientList(`${BASE_URL}api/v1/restaurant/admin/clients/`));
    dispatch(
      GetClientCategoryList(`${BASE_URL}api/v1/inventory/admin/category/`)
    );
    dispatch(
      GetClientProductList(`${BASE_URL}api/v1/inventory/admin/product/`)
    );
  }, []);
  return (
    <div className="parent text-capitalize">
      <div className="main-div">
        <div className="new-user mt-4">
          <div className="d-flex gap-3 align-items-center">
            <p>Add Offer</p>
            <button
              onClick={() => history.push("/add-client-offer")}
              className="orange-btn"
            >
              Add New
            </button>
          </div>
        </div>

        <div className="filter_search d-flex align-items-center justify-content-between mt-4">
          <div className=" mb-3 d-flex align-items-center">
            <span className="me-2" style={{ fontSize: "14px", width: "70%" }}>
              Filter by :{" "}
            </span>
            <select
              name=""
              id=""
              className="me-2"
              onChange={handleSelectChange}
            >
              <option value="">--</option>
              {discount_list?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className=" mb-3 ">
            <span className="search">
              <Search className="search_icon" />
              <input
                type="search"
                name=""
                id=""
                placeholder="Search comments, users.."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </span>
          </div>
        </div>

        <div className="table_div mt-4">
          <ClientOffersList search={search} selectedName={selectedName} />
        </div>
      </div>
    </div>
  );
};

export default ClientOffers;
