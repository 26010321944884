import { Search } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { NotificationPublishTyps } from "../../Common/Dropdown";
import MenuBookNotificationList from "./MenuBookNotificationList";

const MenuBookNotification = () => {
    const history = useHistory()
    const [search, setSearch] = useState("");
  const [selectedName, setSelectedName] = useState(undefined);
  const handleSelectChange = (e) => {
    console.log(e.target.value);
    setSelectedName(e.target.value);
  };
  return (
    <div className="parent text-capitalize">
      <div className="main-div">
        <div className="new-user mt-4">
          <div className="d-flex gap-3 align-items-center">
            <p>Notification</p>
            <button className="orange-btn" onClick={()=>history.push('/add-notification')}>Add New</button>
          </div>
        </div>
        <div className="filter_search d-flex align-items-center justify-content-between mt-4">
           
            
            <div className=" mb-3 d-flex align-items-center">
              <span className="me-2" style={{ fontSize: "14px" , width:"70%"}}>
                Filter by :{" "}
              </span>
              <select name="" id="" className="me-2"  onChange={handleSelectChange}>
              <option value="">--</option>
              {NotificationPublishTyps?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
            </div>
            <div className=" mb-3 ">
              <span className="search">
                <Search className="search_icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search comments, users.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </span>
            </div>
          </div>
        <div className="table_div mt-4">
            <MenuBookNotificationList search={search} selectedName={selectedName}/>
        </div>
      </div>
    </div>
  );
};

export default MenuBookNotification;
