import React, { useEffect, useRef, useState } from "react";
import { GoogleApiWrapper, Marker } from "google-maps-react";
import { Map } from "google-maps-react";
import { useDispatch, useSelector } from "react-redux";

import { GetClientInput } from "../../_redux/action/ClientAction";
const Location = (props) => {
  console.log("props", props.lat, props.lon);
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();

  const clientInput = useSelector((state) => state.clientInfo.clientInput);

  const { google, onPlaceSelected, lat, lon, placeObject } = props;

  console.log("placeObject", lat, lon, placeObject.name);
  const mapDivRef = useRef();

  useEffect(() => {
    refreshMap();
    var input = document.getElementById("pac-input");
    input.value = clientInput.location;
    if (input) {
        input.value = clientInput.location;
      }
   
  }, [clientInput.location]);

  function getReverseGeocodingData(map, marker, infowindow) {
    var latlng = new google.maps.LatLng(lat, lon);

    var infowindowContent = document.getElementById("infowindow-content");
    infowindow.setContent(infowindowContent);

    marker.setPosition(latlng);
    marker.setVisible(true);

    var address = "";
    if (placeObject?.address_components) {
      address = [
        (placeObject.address_components[0] &&
          placeObject.address_components[0].short_name) ||
          "",
        (placeObject.address_components[1] &&
          placeObject.address_components[1].short_name) ||
          "",
        (placeObject.address_components[2] &&
          placeObject.address_components[2].short_name) ||
          "",
      ].join(" ");
    }

    var input = document.getElementById("pac-input");

    if (
      infowindowContent.children["place-icon"] &&
      infowindowContent.children["place-name"] &&
      infowindowContent.children["place-address"]
    ) {
      infowindowContent.children["place-icon"].src = placeObject.icon;
      infowindowContent.children["place-name"].textContent = placeObject.name;
      infowindowContent.children["place-address"].textContent = address;
      infowindow.open(map, marker);
    }

    if (input) {
      input.value = placeObject.name + " - " + address;
    }
  }

  function refreshMap() {
    var map = new google.maps.Map(document.getElementById("mapDiv"), {
      center: {
        lat: lat || clientInput.latitude,
        lng: lon || clientInput.longitude,
      },
      zoom: 13,
    });

    var input = document.getElementById("pac-input");

    var options = {
      //   bounds: dubaiBounds,
      types: ["establishment"],
      componentRestrictions: {
        // country: 'AE',
      },
      strictBounds: false,
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    /// show on map
    autocomplete.bindTo("bounds", map);

    // Set the data fields to return when the user selects a place.
    autocomplete.setFields(["address_components", "geometry", "icon", "name"]);

    var infowindow = new google.maps.InfoWindow();
    var infowindowContent = document.getElementById("infowindow-content");
    infowindow.setContent(infowindowContent);
    var marker = new google.maps.Marker({
      map: map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    autocomplete.addListener("place_changed", function () {
      infowindow.close();
      marker.setVisible(false);
      var place = autocomplete.getPlace();
      onPlaceSelected(place);
      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
      // clientInput.address = place.name
      var address = "";
      if (place.address_components) {
        address = [
          (place.address_components[0] &&
            place.address_components[0].short_name) ||
            "",
          (place.address_components[1] &&
            place.address_components[1].short_name) ||
            "",
          (place.address_components[2] &&
            place.address_components[2].short_name) ||
            "",
        ].join(" ");
      }

      infowindowContent.children["place-icon"].src = place.icon;
      infowindowContent.children["place-name"].textContent = place.name;
      infowindowContent.children["place-address"].textContent = address;
      infowindow.open(map, marker);
      console.log("fields", place.name + address);
      // dispatch()
      // clientInput.address = place.name
    });

    if (placeObject && Object.keys(placeObject).length > 0) {
      setTimeout(() => getReverseGeocodingData(map, marker, infowindow), 2000);
    }
  }
  // -----------------new code---------------------
  const [searchQuery, setSearchQuery] = useState("");
  const [markers, setMarkers] = useState([]);
  const [markerPosition, setMarkerPosition] = useState({
    lat: lat || 23.8103,
    lng: lon || 90.4125,
  });

  useEffect(() => {
    if (lat && lon) {
      setMarkerPosition({
        lat: lat,
        lng: lon,
      });
      dispatch(GetClientInput("latitude", lat));
      dispatch(GetClientInput("longitude", lon));
      dispatch(GetClientInput("location", placeObject.name));
    }
  }, [lat, lon,placeObject]);

 
  const handleMarkerDrag = (t, marker) => {
    console.log(t, marker, "handleMarkerDrag");
    const newPosition = {
      lat: marker.getPosition().lat(),
      lng: marker.getPosition().lng(),
    };
  
    const geocoder = new window.google.maps.Geocoder();

    const getLocName = (newPosition) => {
      console.log("newPosition :", newPosition);
      geocoder.geocode({ latLng: newPosition }, function (results, status) {
        console.log("results :", results);
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            // let adrs_comp = results[0].address_components, loc_name, area_name;
            var input = document.getElementById("pac-input");
            if (input) {
              input.value = results[0].formatted_address;
              setAddress(results[0].formatted_address);
              dispatch(GetClientInput("latitude", newPosition.lat));
              dispatch(GetClientInput("longitude", newPosition.lng));
              dispatch(
                GetClientInput("location", results[0].formatted_address)
              );
            }
            // for(let  i = 0; i < adrs_comp.length; i++) {
            //     if(adrs_comp[i].types[0] === "locality" ) {
            //         loc_name = adrs_comp[i].long_name;
            //         console.log('loc name :',loc_name )
            //     }
            //     if(adrs_comp[i].types[0] === "administrative_area_level_1" ) {
            //         area_name = adrs_comp[i].long_name;
            //         console.log('area_name :',area_name )
            //     }
            // }
          }
        }
      });
    };

    getLocName(newPosition);
    setMarkerPosition(newPosition);
  };
  const onMapClick = (mapProps, map, event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const newMarker = { lat, lng };
    setMarkers([markers, newMarker]);
    console.log("coord", markers);
  };
  const onSearchChange = (e) => {
    setSearchQuery(e);
  };

  return (
    <>
      {/* {!props?.hideSearch && ( */}
      <input
        id="pac-input"
        // value={clientInput.location}
        type="text"
        placeholder="Enter a location"
        className="ant-input map-input"
        style={{ marginBottom: "20px", width: "100%" }}
      />

      <div
        className="map"
        id="mapDiv"
        ref={mapDivRef}
        style={{ display: "none" }}
      ></div>
     
      <div className="" style={{ position: "relative", height: "500px" }}>
        <Map
          google={google}
          zoom={14}
          //   center={markerPosition}
          center={{
            lat: clientInput.latitude,
            lng: clientInput.longitude,
          }}
          dragable={true}
         
          onClick={onMapClick}
        >
          <Marker
            draggable={true}
          
            onDragend={handleMarkerDrag}
            // position={markerPosition}
            position={{
              lat: clientInput.latitude,
              lng: clientInput.longitude,
            }}
          />
         
        </Map>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCueZ-BzQugLmj0J1h3ODLAtoeAlLUuogk",
})(Location);
