import { Divider } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetBillingTypeList } from "../Seetings/category_redux/action/CategoryAcion";
import { GetClientInput } from "./all_clients/_redux/action/ClientAction";
const Details4 = ({ value }) => {
  const dispatch = useDispatch();
  const clientInput = useSelector((state) => state.clientInfo.clientInput);
  const isLoadClient = useSelector((state) => state.clientInfo.isLoadClient);
  const handleChangeInput = (name, value) => {
    dispatch(GetClientInput(name, value));
  };
  const billingTypeList = useSelector(
    (state) => state.categoryInfo.billingTypeList
  );

  useEffect(() => {
    dispatch(GetBillingTypeList());
  }, []);
  return (
    <div className="parent">
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Add New Client /
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>
            Client Name
          </span>
        </div>

        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Facobook </p>
            <input
              type="text"
              placeholder="https://www.facebook.com"
              name="facebook"
              value={clientInput.facebook}
              onChange={(e) => handleChangeInput("facebook", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Instagram</p>
            <input
              type="text"
              placeholder="https://www.instagram.com"
              name="instagram"
              value={clientInput.instagram}
              onChange={(e) => handleChangeInput("instagram", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Twitter</p>
            <input
              type="text"
              placeholder="https://www.twitter.com"
              name="twitter"
              value={clientInput.twitter}
              onChange={(e) => handleChangeInput("twitter", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Whatsapp</p>
            <input
              type="text"
              placeholder="https://www.whatsapp.com"
              name="whatsapp"
              value={clientInput.whatsapp}
              onChange={(e) => handleChangeInput("whatsapp", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "25px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Linkedin</p>
            <input
              type="text"
              placeholder="https://www.linkedin.com/"
              name="linkedin"
              value={clientInput.linkedin}
              onChange={(e) => handleChangeInput("linkedin", e.target.value)}
            />
          </div>
        </div>
       
        <div className=" justify-content-start" style={{ marginTop: "25px" }}>
          <button onClick={() => value("2")} className="back-btn me-3">
            Back
          </button>
          <button onClick={() => value("4")} className="next-btn ">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Details4;
