import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";
import * as Types from "../types/Types";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

export const GetNotificationInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_NOTIFICATION_INPUT, payload: formData });
};
export const SetNotificationlStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_NOTIFICATION_FALSE, payload: false });
};
export const UpdateNotificationdData = (data) => async (dispatch) => {
  data.push_notification_description = draftToHtml(
    convertToRaw(data.push_notification_description.getCurrentContent())
  );
  if (data.server_key === "") {
    showToast("error", "Server key shouldn't be empty !");
    return 0;
  }
  const url = `${BASE_URL}api/v1/promotions/admin/notification/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });

  try {
    await Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
          showToast("success", "Notification Updated successfully");

          dispatch({ type: Types.AFTER_NOTIFICATION_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const SubmitNotificationdData = (data) => async (dispatch) => {
  data.push_notification_description = draftToHtml(
    convertToRaw(data.push_notification_description.getCurrentContent())
  );
  if (data.server_key === "") {
    showToast("error", "Server key shouldn't be empty !");
    return 0;
  }
  const url = `${BASE_URL}api/v1/promotions/admin/notification/`;
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
          showToast("success", "Notification published successfully");

          dispatch({ type: Types.AFTER_NOTIFICATION_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const GetNotificationList = (api) => (dispatch) => {
 
  const url = api;
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_NOTIFICATION_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });
};

export const NextnotificationList = (nextUrl) => (dispatch) => {
  const url = nextUrl;
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_NOTIFICATION_LIST,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
  }
};

export const PreviousnotificationList = (prevUrl) => (dispatch) => {
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_NOTIFICATION_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });
};


export const SetNotificationUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/promotions/admin/notification/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.SET_NOTIFICATION_UPDATED,
          payload: res.data.data,
        });
      }
    });
  } catch (error) {}
};

export const pubLishedNotification = (id, data) => async (dispatch) => {
  data.id = id;

  const url = `${BASE_URL}api/v1/promotions/admin/notification/send/`;
  dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
          showToast("success", "Notification published successfully");
          dispatch(GetNotificationList(`${BASE_URL}api/v1/promotions/admin/notification/?limit=20`));
          dispatch({ type: Types.AFTER_NOTIFICATION_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_NOTIFICATION, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
