import React, { useState, useEffect } from "react";
import "./SideNav.css";

import { NavLink, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/logo/logo.png";

import { GiMoneyStack } from "react-icons/gi";
import { FaUserTie } from "react-icons/fa";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { RiAdvertisementLine } from "react-icons/ri";
import {
  MdOutlineRateReview,
  MdOutlineProductionQuantityLimits,
  MdOutlineCelebration,
} from "react-icons/md";
import { FiUsers, FiSettings, FiUser } from "react-icons/fi";
import devss_logo from "../../../assets/logo/devss_logo.png";
import { BsChatSquareQuote } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { DashboardOutlined } from "@material-ui/icons";

import { Logout } from "@mui/icons-material";
import { GetClientDetails } from "../../Clients/all_clients/_redux/action/ClientAction";
import AddNewAdvertisement from "../../Clients/ClientAdvertisement/AddNewAdvertisement";
import { GetUserNotification } from "../../User/component/verifieduser/_redux/action/VerifiedUserAction";

const SideNav = ({ menuCollapse }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [menu, setMenu] = useState(menuCollapse);
  const [activeNav, setActiveNave] = useState(location.pathname);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const clientInput = useSelector((state) => state.clientInfo.clientInput);
  const clientDetails = useSelector((state) => state.clientInfo.clientDetails);
  const userNotification = useSelector(
    (state) => state.verifiedUserInfo.userNotification
  );

  useEffect(() => {
    setMenu(menuCollapse);
  }, [menuCollapse]);

  useEffect(() => {
    setActiveNave(location.pathname);
  }, [location.pathname]);

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    // if (userData.id) {
    //   dispatch(GetClientDetails(userData.id));
    // }
    dispatch(GetUserNotification());
  }, []);

  // ----------------logout-------------
  const handleLogOut = () => {
    let response = {
      status: false,
      message: "",
      isLoading: true,
    };

    try {
      localStorage.removeItem("is_menubook_cient_logged_in");
      localStorage.removeItem("access_token");
      localStorage.removeItem("userData");

      // toast.success('Logged out successfully !');
      if (typeof window !== "undefined") {
        window.loction.href = "/";
      }
    } catch (error) {
      response.message = "Something Went Wrong !";
      // toast.error(error);
    }
    response.isLoading = false;
  };

  return (
    <>
      <div className="sidebar">
        <ProSidebar collapsed={menu}>
        <SidebarHeader>
            <div className="logo ">
              {menu ? (
                <img src={logo} alt="" />
              ) : (
                <img src={devss_logo} alt="" />
              )}
            </div>
          </SidebarHeader>
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <Menu iconShape="square">
                {/* ------------------------all Dashboard---------------------- */}

                <SubMenu
                  onClick={() => {
                    setIsOpenDropdown(!isOpenDropdown);
                  }}
                  active={activeNav === `/alluser` ? true : false}
                  icon={
                    <DashboardOutlined
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                      }}
                    />
                  }
                  title="Dashboard"
                  // open={isOpenDropdown}
                >
                  <MenuItem
                    active={activeNav === `/clients-dashboard` ? true : false}
                    onClick={() => setActiveNave("/clients-dashboard")}
                  >
                    <NavLink to="/clients-dashboard">My Business</NavLink>
                  </MenuItem>
                  
                </SubMenu>
                <hr />
                {/* -------------------------------------------Clients---------------------------- */}
              
                <MenuItem
                  icon={
                    <FiUser
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  active={activeNav === `/summary` ? true : false}
                  onClick={() => setActiveNave("/summary")}
                >
                  <NavLink to="/summary">Summary</NavLink>
                </MenuItem>
                <hr />
                <MenuItem
                  icon={
                    <AiOutlineDashboard
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  active={activeNav === `/category` ? true : false}
                  onClick={() => setActiveNave("/category")}
                >
                  <NavLink to="/category">Category</NavLink>
                </MenuItem>
                <hr />
                <MenuItem
                  icon={
                    <MdOutlineRateReview
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  active={activeNav === `/clinet-reviews` ? true : false}
                  onClick={() => setActiveNave("/clinet-reviews")}
                >
                  <NavLink to="/clinet-reviews">
                    Reviews{" "}
                    {userNotification?.review > 0 && (
                      <span className="userNotificaton2">
                        {userNotification?.review}
                      </span>
                    )}
                  </NavLink>
                </MenuItem>
                <hr />
                <MenuItem
                  icon={
                    <FiUsers
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  active={activeNav === `/clinet-users` ? true : false}
                  onClick={() => setActiveNave("/clinet-users")}
                >
                  <NavLink to="/clinet-users">Users</NavLink>
                </MenuItem>
                <hr />
                <MenuItem
                  icon={
                    <MdOutlineProductionQuantityLimits
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  active={activeNav === `/products` ? true : false}
                  onClick={() => setActiveNave("/products")}
                >
                  <NavLink to="/products">Products</NavLink>
                </MenuItem>
                <hr />
                <MenuItem
                  active={activeNav === `/client-offer` ? true : false}
                  icon={
                    <MdOutlineCelebration
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  onClick={() => setActiveNave("/client-offer")}
                >
                  <NavLink to="/client-offer">Offers</NavLink>
                </MenuItem>
                <hr />
                <MenuItem
                  active={activeNav === "/client-advertisement" ? true : false}
                  icon={
                    <RiAdvertisementLine
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  onClick={() => {
                    // setModalShow(true);
                    setActiveNave("/client-advertisement");
                  }}
                >
                  <NavLink to="/client-advertisement">Advertisement</NavLink>
                </MenuItem>
                {/* </SubMenu> */}

                {/* </SubMenu> */}

                <hr />
                {/* ==add transectiont======== */}
                <SubMenu
                  active={activeNav === `/all-invoice` ? true : false}
                  icon={
                    <GiMoneyStack
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                      }}
                    />
                  }
                  title={
                    userNotification?.invoice > 0 ? (
                      <>
                        Transaction{" "}
                        <span className="userNotificaton2">
                          {userNotification?.invoice}
                        </span>
                      </>
                    ) : (
                      "Transaction"
                    )
                  }
                >
                  <MenuItem
                    active={activeNav === `/all-invoice` ? true : false}
                    onClick={() => setActiveNave("/all-invoice")}
                  >
                    <NavLink to="/all-invoice">All Invoices</NavLink>
                  </MenuItem>
                </SubMenu>
                {/* ====notification====================             */}
                <hr />
                {/* <SubMenu
                  active={activeNav === `/notification` ? true : false}
                  icon={
                    <IoMdNotificationsOutline
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  title={
                    userNotification?.notification > 0 ? (
                      <>
                        Notification{" "}
                        <span className="userNotificaton2">
                        {userNotification?.notification}
                        </span>
                      </>
                    ) : (
                      "Notification"
                    )
                  }
                > */}
                  <MenuItem
                   icon={
                    <IoMdNotificationsOutline
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                    active={activeNav === `/notification` ? true : false}
                    onClick={() => setActiveNave("/notification")}
                  >
                    <NavLink to="/notification">Notification
                    {userNotification?.notification > 0 && (
                        <span className="userNotificaton">
                          {userNotification?.notification}
                        </span>
                      )}</NavLink>
                  </MenuItem>
                {/* </SubMenu> */}
                <hr />
                <MenuItem
                icon={
                  <BiSupport
                  style={{
                    height: "24px",
                    width: "24px",
                    fontWeight: "500",
                    color: "white",
                  }}
                />
                }
                    active={activeNav === `/contact` ? true : false}
                    onClick={() => setActiveNave("/contact")}
                  >
                    <NavLink to="/contact">
                      Contact
                      {/* {userNotification?.notification > 0 && (
                        <span className="userNotificaton">
                          {userNotification?.notification}
                        </span>
                      )} */}
                    </NavLink>
                  </MenuItem>
                  <hr />
                <MenuItem
                  style={{ color: "#e0e0e0" }}
                  onClick={handleLogOut}
                  icon={
                    <Logout
                      style={{
                        height: "24px",
                        width: "24px",
                        fontWeight: "500",
                        color: "white",
                      }}
                    />
                  }
                  active={activeNav === `/` ? true : false}
                >
                  <NavLink to="/" style={{ color: "#e0e0e0 !importat" }}>
                    Logout
                  </NavLink>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideNav;
