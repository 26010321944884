import React,{useState} from "react";
import "./user.css";
import { Search } from "@material-ui/icons";
import VerifiedUserList from "./VerifiedUserList";
import { useHistory } from "react-router-dom";
const VerifiedUser = () => {
  const history = useHistory()
  const [search, setSearch] = useState("");
  const [selectedName, setSelectedName] = useState(undefined);
  const handleSelectChange = (e) => {
    console.log(e.target.value);
    setSelectedName(e.target.value);
    
  };
  return (
    <>
      <div className="parent text-capitalize">
        <div className="main-div">
          <div className="fw-bold">
            <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
            <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
              Client Name /
            </span>{" "}
            <span
              style={{ fontSize: "14px", color: "#818181", fontWeight: "400" }}
            >
              Users
            </span>
          </div>

          <div className="new-user mt-4">
            <div className="d-flex gap-3 align-items-center">
              <p>User</p>
              <button className="orange-btn" onClick={()=>history.push('/add_verified_user')}>Add New</button>
            </div>
          </div>
         

          <div className="filter_search row mt-4 ">
            <div className="col-lg-3 col-md-6 mb-3 d-flex align-items-center">
              {/* <span className="me-2" style={{ fontSize: "14px" }}>
                Business Type :{" "}
              </span> */}
              <select name="" id="" className="me-2">
                <option value="bulk_action">Bulk action</option>
                <option value="Food">Action</option>
                <option value="None">Action</option>
              </select>
              <span className="me-2">
                <button className="orange-btn" style={{ padding: "5px 20px" }}>
                  Apply
                </button>
              </span>
            </div>
            
            <div className="col-lg-4 col-md-6 mb-3 d-flex align-items-center">
              <span className="me-2" style={{ fontSize: "14px", width: "70%" }}>
                Filter by :{" "}
              </span>
              <select name="" id="" className="me-2" onChange={handleSelectChange}>
                <option value="">--</option>
                <option value="is_app_user">All User</option>
                <option value="is_verified">Verified User</option>
                <option value="is_requested">Verification Request</option>

               
              </select>
            </div>
            <div className="col-lg-5 col-md-6 mb-3 ">
              <span className="search">
                <Search className="search_icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search comments, users.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </span>
            </div>
          </div>

          <div className="table_div mt-4">
            <VerifiedUserList search={search} selectedName={selectedName}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifiedUser;
