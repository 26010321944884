import { Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProductList from "./ProductList";
import { useDispatch, useSelector } from "react-redux";
import { GetCategoryList } from "../Seetings/category_redux/action/CategoryAcion";
import { BASE_URL } from "../../Const";
import { GetClientCategoryList } from "./category/_redux/action/ClientCategoryAction";
const ClientProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // const api = `${BASE_URL}api/v1/inventory/admin/category/`;
  const [search, setSearch] = useState("");
  const [selectedName, setSelectedName] = useState(undefined);

  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );
  useEffect(() => {
    dispatch(
      GetClientCategoryList(`${BASE_URL}api/v1/inventory/admin/category/`)
    );
  }, []);

  const handleSelectChange = (e) => {
    setSelectedName(e.target.value);
  };
  return (
    <div className="parent min-vh-100">
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>Products</span>
        </div>

        <div className="new-user mt-4">
          <div className="d-flex gap-3 align-items-center">
            <p>Products</p>
            <button
              className="orange-btn"
              onClick={() => history.push("/add-products")}
            >
              Add New
            </button>
            <button
              className="orange-btn"
              onClick={() => history.push(`/import-products`)}
            >
              Import
            </button>
            <button
              className="orange-btn"
              onClick={() => history.push(`/export-products`)}
            >
              Export
            </button>
          </div>
        </div>
        <div className="filter_search d-flex align-items-center justify-content-between mt-4">
          <div className=" mb-3 d-flex align-items-center">
            <span className="me-2" style={{ fontSize: "14px", width: "70%" }}>
              Filter by :{" "}
            </span>
            <select
              name=""
              id=""
              className="me-2"
              onChange={handleSelectChange}
            >
              <option value="">--</option>

              {ClientCategoryList?.results?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className=" mb-3 ">
            <span className="search">
              <Search className="search_icon" />
              <input
                type="search"
                name=""
                id=""
                placeholder="Search "
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </span>
          </div>
        </div>

        <div className="table_div mt-4">
          <ProductList search={search} selectedName={selectedName} />
        </div>
      </div>
    </div>
  );
};

export default ClientProducts;
