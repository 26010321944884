import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPublishedWithChanges } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Checkbox from "@mui/material/Checkbox";
import user from "../../../assets/img/slide_home_2.jpg";
import { useHistory } from "react-router-dom";

import { BsImage } from "react-icons/bs";

import UserPagination from "../../Common/UserPagination";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Lottie from "react-lottie";
import noData2 from "../../../assets/jsonFile/noData2.json";
import {
  GetNotificationList,
  NextnotificationList,
  PreviousnotificationList,
  pubLishedNotification,
  SetNotificationUpdatedData,
} from "../_redux/action/NotificationAction";

import { NotiRowMenu } from "./NotiRowMenu";
import { NotificationTrafficType } from "../../Common/Dropdown";
import { BASE_URL } from "../../../Const";
import { GetUserNotification } from "../../User/component/verifieduser/_redux/action/VerifiedUserAction";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
const MenuBookNotificationList = ({ search, selectedName }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notification_List = useSelector(
    (state) => state.notificationInfo.notificationList
  );
  const isLoadNotification = useSelector(
    (state) => state.notificationInfo.isLoadNotification
  );
  const sendNotification = useSelector(
    (state) => state.notificationInfo.sendNotification
  );

  const [selected, setSelected] = React.useState([]);
  const options = ["None", "Atria", "Callisto"];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // ========checkbox---------------

  // const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      GetNotificationList(
        `${BASE_URL}api/v1/promotions/admin/notification/?limit=20&&search=${search}`
      )
    );
    dispatch(GetUserNotification());
    // setUsers(notification_List?.results);
  }, []);
  React.useEffect(() => {
    if (selectedName) {
      dispatch(
        GetNotificationList(
          `${BASE_URL}api/v1/promotions/admin/notification/?status=${selectedName}&&limit=20&&search=${search}`
        )
      );
    } else {
      dispatch(
        GetNotificationList(
          `${BASE_URL}api/v1/promotions/admin/notification/?limit=20&&search=${search}`
        )
      );
    }
  }, [selectedName, search]);

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextnotificationList(notification_List?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousnotificationList(notification_List?.previous));
  };

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(notification_List.results);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  const handleProfileEdit = (id) => {
    dispatch(SetNotificationUpdatedData(id));
    history.push(`edit_notification/${id}`);
  };
  const handlePublished = (id) => {
    console.log("id", id);
    dispatch(pubLishedNotification(id, sendNotification));
    // history.push(`/edit_clients/${id}`);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleDeleteAll = () => {
    if (selectedUser) {
      selectedUser.map((item) => {
        return dispatch(pubLishedNotification(item.id, sendNotification));
      });
    }
  };

  // =====================image preview================
  const [profile, setprofile] = React.useState(null);

  const handleClick = (event, row) => {
    console.log(row, event);
    setAnchorEl(event.currentTarget);
    setprofile(row);
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Card sx={{ maxWidth: 355 }} onClick={handleClose}>
          <CardMedia
            sx={{ height: 140, minWidth: 350 }}
            image={profile?.image_url ? profile?.image_url : user}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {profile?.notification_name}
            </Typography>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <Typography>
                <h6 size="small" className="mb-0">
                  Views : {profile?.views}{" "}
                </h6>
              </Typography>
              <Typography>
                <h6 size="small" className="mb-0">
                  Clicks : {profile?.clicks}{" "}
                </h6>
              </Typography>
            </div>
          </CardContent>
        </Card>
        {/* <div className="d-flex align-items-center justify-content-betwen">
          <img src={profile?.reviewed_by_image} alt="" />
          <h4>{profile?.reviewed_by_image}</h4>
        </div> */}
      </Menu>
      {isLoadNotification && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!isLoadNotification &&
        notification_List?.results &&
        notification_List?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}
      {notification_List?.results?.length > 0 && (
        <div className="d-flex justify-content-end">
          <Button onClick={() => handleDeleteAll()} variant="outline-danger">
            <MdPublishedWithChanges /> Published All
          </Button>{" "}
        </div>
      )}
      <div>
        {!isLoadNotification &&
          notification_List?.results &&
          notification_List?.results !== null &&
          notification_List?.results.length > 0 && (
            <TableContainer
            component={Paper}
            className="text-capitalize"
            sx={{ maxHeight: 670 }}
          >
            <Table stickyHeader aria-label="sticky table">
                {/* <caption>A basic table example with a caption</caption> */}
                <TableHead style={{ background: "white" }}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // className="form-check-input"
                        name="allSelect"
                        checked={
                          selectedUser?.length ===
                          notification_List.results?.length
                        }
                        onChange={(e) =>
                          handleChange(e, notification_List.results)
                        }
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "24px" }}>
                      <BsImage />
                    </TableCell>

                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">View</TableCell>
                    <TableCell align="left">Click</TableCell>
                    <TableCell align="center">Traffic </TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Action</TableCell> */}
                    <TableCell align="left">
                      {" "}
                      <IconButton aria-label="more">
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ background: "white" }}>
                  {notification_List?.results?.map((row) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.name}
                            checked={selectedUser?.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange(e, row)}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className="image-td image-container"
                          style={{ cursor: "pointer" }}
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          // onClick={handleClick}
                          onClick={(e) => handleClick(e, row)}
                        >
                          <img src={row.image_url} alt="" className="image" />
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          <h6>{row.notification_name}</h6>
                          <p>
                            {" "}
                            {/* {new Date(row.created_at).toISOString().slice(0, 10)} */}
                          </p>
                        </TableCell>

                        <TableCell align="left" className="">
                          <h6>{row.views}</h6>
                        </TableCell>
                        <TableCell align="left">{row.clicks}</TableCell>
                        <TableCell align="center" className="placement_td">
                          {" "}
                          <select name="" id="" className="me-2">
                            {NotificationTrafficType.map((item) => {
                              return (
                                item.value === row.traffic && (
                                  <option value="Landing page">
                                    {item.label}
                                  </option>
                                )
                              );
                            })}
                          </select>
                        </TableCell>
                        <TableCell align="center">
                          {row.status === 1 ? (
                            <button className="yellow-btn">Pending</button>
                          ) : row.status === 2 ? (
                            <button className="green-btn">Published</button>
                          ) : (
                            <button className="green-btn">Deactive</button>
                          )}
                        </TableCell>

                        {/* <TableCell align="center" className="table_icon">
                      <span style={{ cursor: "pointer" }}>
                        <FaEye className="me-2" style={{ fontSize: "22px" }} />
                        view
                      </span>
                      <br />
                      <span style={{ cursor: "pointer" }}>
                        <FaEdit className="me-2" style={{ fontSize: "22px" }} />
                        Edit
                      </span>
                    </TableCell> */}

                        <TableCell align="left">
                          {" "}
                          <NotiRowMenu
                            row={row}
                            handleeEdit={handleProfileEdit}
                            handlePublished={handlePublished}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
          <p>Total : {notification_List?.count}</p>
          <UserPagination
            prev_next={notification_List}
            handleNexteClick={handleNexteClick}
            handlePreviousClick={handlePreviousClick}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
};

export default MenuBookNotificationList;
