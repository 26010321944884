import React from "react";
import "./Client.css";
import CreatableSelect from "react-select/creatable";
import { Divider } from "@material-ui/core";
import { useState, useEffect } from "react";

import ArrowSvg from "../Common/ArrowSvg";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {
  GetClientInput,
  GetClientList,
  GetClientOption,
} from "./all_clients/_redux/action/ClientAction";
import {
  GetCategoryList,
  GetCategoryOption,
} from "../Seetings/category_redux/action/CategoryAcion";

import AddressSearch from "./all_clients/pages/mapSearch/AddressSearch";

import { BASE_URL } from "../../Const";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import CurrentLocation from "../Common/CurrentLocation";

const Details1 = ({ value }) => {
  const dispatch = useDispatch();

  const clientInput = useSelector((state) => state.clientInfo.clientInput);
  const [rValue, setRValue] = useState([]);
  const [restaurant_types, setRestaurant_types] = useState([]);
  const isLoadclient = useSelector((state) => state.clientInfo.isLoadclient);
  const clientList = useSelector((state) => state.clientInfo.clientList);
  const clientOption = GetClientOption(
    useSelector((state) => state.clientInfo.clientList?.results)
  );
  const categoryOption = GetCategoryOption(
    useSelector((state) => state.categoryInfo.categoryList)
  );
  const categoryList = useSelector((state) => state.categoryInfo.categoryList);

  const handleChangeInput = (name, value) => {
    dispatch(GetClientInput(name, value));
    // dispatch(GetClientInput("branch", selectedValue));
  };

  const handleChangeTextInput = (name, value) => {
    if (name === "restaurant_types") {
      value = Array.from(value, (option) => option.value);
      setRestaurant_types(value);
    }

    dispatch(GetClientInput(name, value));
  };

  const [first, setFirst] = useState("");

  useEffect(() => {
    const options = clientInput.restaurant_types.map((item) => ({
      value: item,
      label: item,
    }));
    setFirst(options[0]); // Set the first option as the initial value of "first"
    setRValue(options); // Set all options as the initial value of "rValue"
  }, [clientInput.restaurant_types]);

  const api = `${BASE_URL}api/v1/restaurant/admin/clients/`;
  useEffect(() => {
    dispatch(GetClientList(api));
    dispatch(GetCategoryList());
  }, []);

  // -----multiselect branch ----------
  const [branches, setBranch] = useState([]);
  const handleChangebranch = (name, value) => {
    if (name === "branch") {
      value = Array.from(value, (option) => option.id);
      // setRestaurant_types(value);
    }

    dispatch(GetClientInput(name, value));
  };
  useEffect(() => {
    const branchValue = clientOption.filter(({ id }) => {
      return clientInput?.branch.find((id1) => id1 === id) !== undefined;
    });

    setBranch((branch) => {
      return branchValue;
    });
  }, [clientInput.branch]);

  // -----multiselect category----------
  const [category, setCategory] = useState([]);

  const handleChangeCategory = (name, value) => {
    if (name === "company_category") {
      value = Array.from(value, (option) => option.id);
    }

    dispatch(GetClientInput(name, value));
  };

  useEffect(() => {
    const categoryValue = categoryOption?.filter(({ id }) => {
      return (
        clientInput?.company_category.find((id1) => id1 === id) !== undefined
      );
    });

    setCategory((category) => {
      return categoryValue;
    });
  }, [clientInput.company_category]);

  return (
    <>
      {isLoadclient && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="parent">
        <div className="main-div">
          <div className="fw-bold">
            <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
            <span style={{ fontSize: "14px", color: "#818181" }}>
              Add New Client
            </span>
          </div>

          <div className="row input-title" style={{ marginTop: "40px" }}>
            <div className="col-11 col-md-8 col-lg-7 input-head">
              <p>Your company's name*</p>
              <input
                type="text"
                placeholder="Menubook BD"
                name="company_name"
                value={clientInput.company_name}
                onChange={(e) =>
                  handleChangeInput("company_name", e.target.value)
                }
              />
            </div>
          </div>

          <div className="row input-title" style={{ marginTop: "25px" }}>
            <div className="col-11 col-md-8 col-lg-7 input-head ">
              <div className="d-flex gap-2 align-items-center justify-content-between">
                <p style={{ marginBottom: 0 }}>
                  Your company Address ( Google Map Location )*
                </p>
                <CurrentLocation />
              </div>

              <p className="fw-bold" style={{ color: "black" }}></p>
              <div className="my-4">
                <p>Visible Company Address</p>
                <input
                  type="text"
                  placeholder="www.menubookBD.com"
                  name="location_manual"
                  value={clientInput.location_manual}
                  onChange={(e) =>
                    handleChangeInput("location_manual", e.target.value)
                  }
                />
              </div>
              <div
                style={{ width: "100%", height: "50vh", marginBottom: "100px" }}
              >
                <AddressSearch />
              </div>
            </div>
          </div>

          <div className="row input-title" style={{ marginTop: "55px" }}>
            <div className="col-11 col-md-8 col-lg-7 input-head">
              <p>Your company Website</p>
              <input
                type="text"
                placeholder="www.menubookBD.com"
                name="company_website"
                value={clientInput.company_website}
                onChange={(e) =>
                  handleChangeInput("company_website", e.target.value)
                }
              />
            </div>
          </div>
          <div className="row input-title" style={{ marginTop: "25px" }}>
            <div className="col-11 col-md-8 col-lg-7 input-head">
              <p>Your Service Type*</p>
              <div style={{ position: "relative" }}>
                <select
                  id="inputState"
                  className="form-select"
                  name="company_service_type"
                  value={clientInput.company_service_type}
                  onChange={(e) =>
                    handleChangeInput("company_service_type", e.target.value)
                  }
                >
                  <option selected value={""}>
                    --
                  </option>
                  <option selected value={0}>
                    Restaurant
                  </option>
                  <option value={1}>Others</option>
                  <option value={2}>Both</option>
                </select>
                <div className="arrow-icon">
                  <ArrowSvg />
                </div>
              </div>
            </div>
          </div>
          <div className="row input-title" style={{ marginTop: "25px" }}>
            <div className="col-11 col-md-8 col-lg-7 input-head2">
              <p>Your company Category*</p>
              {/* <div style={{ position: "relative" }}>
                <select
                  id="inputState"
                  className="form-select "
                  name="company_category"
                  value={clientInput.company_category}
                  onChange={(e) =>
                    handleChangeInput("company_category", e.target.value)
                  }
                >
                  <option selected value={""}>
                    --
                  </option>
                  {categoryList?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <div className="arrow-icon">
                  <ArrowSvg />
                </div>
              </div> */}

              <Select
                isMulti
                closeMenuOnSelect={false}
                options={categoryOption}
                className=" mb-3"
                name="company_category"
                value={category}
                onChange={(e) => handleChangeCategory("company_category", e)}
              />
            </div>
          </div>
          {parseInt(clientInput.company_service_type) === 0 ? (
            <div className="row input-title" style={{ marginTop: "25px" }}>
              <div className="col-11 col-md-8 col-lg-7 input-head2">
                <p>Your Restaurant / Home Made Type*</p>
                <CreatableSelect
                  isClearable={true}
                  isMulti
                  name="restaurant_types"
                  value={rValue}
                  onChange={(newValue) => {
                    // handleChangeResInput("restaurant_types", newValue);
                    handleChangeTextInput("restaurant_types", newValue);
                    setRValue(newValue);
                  }}
                  // onCreateOption={handleCreateRes}
                />
              </div>
            </div>
          ) : parseInt(clientInput.company_service_type) === 1 ? (
            <div className="row input-title" style={{ marginTop: "25px" }}>
              <div className="col-11 col-md-8 col-lg-7 input-head2">
                <p>Your Facilities*</p>

                <Editor
                  wrapperClassName="wrapper"
                  editorClassName="editor"
                  toolbarClassName="toolbar"
                  name="facilities"
                  editorState={clientInput.facilities}
                  onEditorStateChange={(e) =>
                    handleChangeInput("facilities", e)
                  }
                  // onEditorStateChange={(e) => Test(e)}
                />
              </div>
            </div>
          ) : (
            parseInt(clientInput.company_service_type) === 2 && (
              <>
                <div className="row input-title" style={{ marginTop: "25px" }}>
                  <div className="col-11 col-md-8 col-lg-7 input-head2">
                    <p>Your Restaurant / Home Made Type*</p>
                    <CreatableSelect
                      isClearable={true}
                      isMulti
                      name="restaurant_types"
                      value={rValue}
                      onChange={(newValue) => {
                        // handleChangeResInput("restaurant_types", newValue);
                        handleChangeTextInput("restaurant_types", newValue);
                        setRValue(newValue);
                      }}
                      // onCreateOption={handleCreateRes}
                    />
                  </div>
                </div>
                <div className="row input-title" style={{ marginTop: "25px" }}>
                  <div className="col-11 col-md-8 col-lg-7 input-head2">
                    <p>Your Facilities*</p>

                    <Editor
                      wrapperClassName="wrapper"
                      editorClassName="editor"
                      toolbarClassName="toolbar"
                      name="facilities"
                      editorState={clientInput.facilities}
                      onEditorStateChange={(e) =>
                        handleChangeInput("facilities", e)
                      }
                      // onEditorStateChange={(e) => Test(e)}
                    />
                  </div>
                </div>
              </>
            )
          )}

          <div
            className="d-flex align-item-center  input-title"
            style={{ marginTop: "25px" }}
          >
            <p>Is your company active?</p>
            <input
              className="mx-2"
              type="checkbox"
              name="is_active"
              value={clientInput.is_active}
              checked={clientInput.is_active}
              onChange={(e) => handleChangeInput("is_active", e.target.checked)}
            />
            <p>Yes</p>
          </div>
          <div
            className="d-flex align-item-center  input-title"
            style={{ marginTop: "25px" }}
          >
            <p>Do you have multiple branch?</p>
            <input
              className="mx-2"
              type="checkbox"
              name="has_multiple_branch"
              value={clientInput.has_multiple_branch}
              checked={clientInput.has_multiple_branch}
              onChange={(e) =>
                handleChangeInput("has_multiple_branch", e.target.checked)
              }
            />
            <p>Yes</p>
          </div>
          {clientInput.has_multiple_branch && (
            <div className="row " style={{ marginTop: "25px" }}>
              <div className="row input-title" style={{ marginTop: "25px" }}>
                <div className="col-11 col-md-8 col-lg-7 input-head2">
                  <p>Add this client to multiple branches:</p>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    options={clientOption}
                    className=" mb-3"
                    name="branch"
                    value={branches}
                    onChange={(e) => handleChangebranch("branch", e)}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="row input-title" style={{ marginTop: "15px" }}>
            <div className="col-12 col-md-8 col-lg-7 row input-head2">
              <div className="col-12">
                <p>Opening Time:*</p>
              </div>
              <div
                className="col-12 col-md-9 justify-md-between ms-2 bg-white"
                style={{ border: "1px solid  #C2C2C2", borderRadius: "12px" }}
              >
                <div className="d-flex time justify-content-center align-items-center">
                  <input
                    type="time"
                    placeholder="12:10 PM"
                    name="open_time"
                    value={clientInput.open_time}
                    onChange={(e) =>
                      handleChangeInput("open_time", e.target.value)
                    }
                  />
                  <Divider
                    color="dark"
                    bgcolor="dark"
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <input
                    type="time"
                    placeholder="12:20 PM"
                    name="close_time"
                    value={clientInput.close_time}
                    onChange={(e) =>
                      handleChangeInput("close_time", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-2 ms-0 ms-md-4 mt-sm-2 mt-md-0">
                <button onClick={() => value("2")} className="next-btn ">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddressSearch/> */}
    </>
  );
};

export default Details1;
