import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BsQuestionCircleFill } from "react-icons/bs";
export function ReportRowMenu({ row, handleeReport }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalShow, setModalShow] = useState(false);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id={`actions-${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={(e) => handleeEdit(row.id, e)}>Edit</MenuItem> */}
        <MenuItem
          style={{ color: "#7A7A7A", fontSize: "13px" }}
          onClick={(e) => handleeReport(row.id, e)}
        >
          Report <BsQuestionCircleFill />
        </MenuItem>
      </Menu>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        // className={classes.menuButton}
        // endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        <MoreVertIcon />
      </IconButton>
      {/* <AddNewAdvertisement
        show={modalShow}
        onHide={() => setModalShow(false)}
        add_id={row.id}
      /> */}
    </>
  );
}
