import React from 'react';

const Save = () => {
    return (
        <div>
            <button className="save-btn">Save</button>
        </div>
    );
};

export default Save;