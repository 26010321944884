import * as Types from "../types/Types";
const initialState = {
 

  supportList: null,

  SupportDetails: null,
  isVerifiedUserDeleted: null,
  
  isLoadSupport: false,
 
};
const SupportReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
   
    case Types.IS_LOAD_SUPPORT:
      return {
        ...state,
        isLoadSupport: action.payload,
      };
   
    case Types.GET_SUPPORT_LIST:
      return {
        ...state,
        supportList: action.payload,
      };
      
   
      
      case Types.GET_SUPPORT_DETAILS:
      return {
        ...state,
        SupportDetails: action.payload,
      };
   
  
    


    default:
      break;
  }
  return newState;
};

export default SupportReducer;
