import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Edit, Star, ViewDayOutlined } from "@material-ui/icons";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import Avatar from "@mui/material/Avatar";
import noData2 from "../../../assets/jsonFile/noData2.json";
import Checkbox from "@mui/material/Checkbox";

import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { BsImage } from "react-icons/bs";

import user from "../../../assets/img/slide_home_2.jpg";
import UserPagination from "../../Common/UserPagination";
import { useSelector, useDispatch } from "react-redux";
import {
  GetClientofferdelete,
  GetClientOfferList,
  SetClientofferUpdatedData,
} from "./_redux/action/ClientOfferAction";
import { GetClientProductList } from "../products/_redux/action/ClientProductAction";
import ProductList from "../ProductList";
import { GetClientCategoryList } from "../category/_redux/action/ClientCategoryAction";
import { status_list } from "../../Common/Dropdown";
import { GetClientList } from "../all_clients/_redux/action/ClientAction";
import { RowMenu } from "../../Common/RowMenu";
import Lottie from "react-lottie";
import { BASE_URL } from "../../../Const";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "react-bootstrap/Button";
import {
  Getofferdelete,
  GetOfferList,
  NextOfferListList,
  PreviousOfferList,
} from "../../add_request/manage_offers/_redux/action/OfferAction";
import { GetUserNotification } from "../../User/component/verifieduser/_redux/action/VerifiedUserAction";

const ClientOffersList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ClientProductList = useSelector(
    (state) => state.clientProductInfo.ClientProductList
  );
  const [selected, setSelected] = React.useState([]);
  const options = ["None", "Atria", "Callisto"];

  const offer_List = useSelector((state) => state.offerInfo.OfferList);
  const isLoadOffer = useSelector((state) => state.offerInfo.isLoadOffer);
  const ClientCategoryList = useSelector(
    (state) => state.clientCategoryInfo.ClientCategoryList
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  React.useEffect(() => {
    dispatch(
      GetOfferList(
        `${BASE_URL}api/v1/promotions/admin/offer/?client=${userData.client_id}&&limit=20&&search=${search}`
      )
    );
    dispatch(
      GetClientProductList(`${BASE_URL}api/v1/inventory/admin/product/`)
    );
    dispatch(
      GetClientCategoryList(`${BASE_URL}api/v1/inventory/admin/category/`)
    );
    dispatch(GetUserNotification());
    setUsers(offer_List?.results);
  }, []);

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  const handleDeleteAll = () => {
    if (selectedUser) {
      selectedUser.map((item) => {
        return dispatch(
          Getofferdelete(
            item.id,
            `${BASE_URL}api/v1/promotions/admin/offer/?limit=20&&client=${userData.client_id}&&search=${search}`
          )
        );
      });
    }
  };
  const handleeEdit = (id) => {
    dispatch(SetClientofferUpdatedData(id));
    history.push(`/edit-client-offer/${id}`);
  };
  const handleDelete = (id) => {
    dispatch(
      Getofferdelete(
        id,
        `${BASE_URL}api/v1/promotions/admin/offer/?limit=20&&client=${userData.client_id}&&search=${search}`
      )
    );
    // handleClose()
    setAnchorEl(null);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // ====================filter===========
  React.useEffect(() => {
    if (selectedName) {
      dispatch(
        GetOfferList(
          `${BASE_URL}api/v1/promotions/admin/offer/?discount_type=${selectedName}&&limit=20&&client=${userData.client_id}&&search=${search}`
        )
      );
    } else {
      dispatch(
        GetOfferList(
          `${BASE_URL}api/v1/promotions/admin/offer/?limit=20&&client=${userData.client_id}&&search=${search}`
        )
      );
    }
  }, [selectedName, search]);

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextOfferListList(offer_List?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousOfferList(offer_List?.previous));
  };

  const [profile, setprofile] = React.useState(null);

  // const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    console.log(row, event);
    setAnchorEl(event.currentTarget);
    setprofile(row);
  };
  return (
    <div>
       <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Card sx={{ maxWidth: 355, padding:0 }} onClick={handleClose}>
          <CardMedia
            sx={{ height: 140, minWidth: 350 }}
            image={profile?.banner_url ? profile?.banner_url : user}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {profile?.name}
            </Typography>
            <div className="d-flex align-items-center justify-content-between">
              <Typography>
                <h4 size="small" className="mb-0">
                  {profile?.client_name}{" "}
                </h4>
              </Typography>
              <Typography className="rating_td">
                <span className="d-flex align-items-center">
                  <Star /> {profile?.client_rating}
                </span>
              </Typography>
            </div>
          </CardContent>
        </Card>
        {/* <div className="d-flex align-items-center justify-content-betwen">
          <img src={profile?.reviewed_by_image} alt="" />
          <h4>{profile?.reviewed_by_image}</h4>
        </div> */}
      </Menu>
      {offer_List?.results?.length > 0 && (
        <div className="d-flex justify-content-end mb-3">
            <Button onClick={() => handleDeleteAll()} variant="outline-danger">
            <DeleteIcon /> Multiple Delete
          </Button>{" "}
        </div>
      )}
      {isLoadOffer && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadOffer &&
        offer_List?.results &&
        offer_List?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}

      {!isLoadOffer &&
        offer_List?.results &&
        offer_List?.results !== null &&
        offer_List?.results?.length > 0 && (
          <TableContainer
          component={Paper}
          className="text-capitalize"
          sx={{ maxHeight: 670 }}
        >
          <Table stickyHeader aria-label="sticky table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableHead style={{ background: "white" }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      // className="form-check-input"
                      name="allSelect"
                      checked={selectedUser?.length === users?.length}
                      onChange={(e) => handleChange(e, users)}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "24px" }}>
                    <BsImage />
                  </TableCell>
                  <TableCell align="left">Offer Name</TableCell>
                  <TableCell align="left">Foods</TableCell>
                  {/* <TableCell align="left"> Category</TableCell> */}
                  <TableCell align="left">Discount Type</TableCell>
                  {/* <TableCell align="left">Categories</TableCell> */}

                  <TableCell align="center">Action</TableCell>
                  <TableCell align="left">
                    {" "}
                    <IconButton aria-label="more">
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody style={{ background: "white" }}>
                {offer_List?.results
                  ?.filter((val) => {
                    if (search == "") {
                      return val;
                    } else if (
                      val?.client_category
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val?.client_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((row) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            name={row.name}
                            checked={selectedUser.some(
                              (item) => item?.id === row.id
                            )}
                            onChange={(e) => handleChange(e, row)}
                          />
                        </TableCell>
                        <TableCell   align="center"
                          className="image-td"
                          style={{ cursor: "pointer" }}
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          // onClick={handleClick}
                          onClick={(e) => handleClick(e, row)}>
                          <img src={row.banner_url} alt="" />
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          <h6 style={{ color: "#7A7A7A" }}>{row.name}</h6>
                          <p>
                            {" "}
                            {new Date(row.start_date)
                              .toISOString()
                              .slice(0, 10)}
                          </p>
                        </TableCell>
                        <TableCell align="left" className="client_td">
                          <div className="d-flex gap-2 mb-3">
                            {row.product.map((item) => {
                              return ClientProductList?.results?.map(
                                (product) => {
                                  return (
                                    product.id === item && (
                                      <div className="menu_name">
                                        {product.name}{" "}
                                      </div>
                                    )
                                  );
                                }
                              );
                            })}
                          </div>
                        </TableCell>
                        {/* <TableCell align="left" className="">
                          {row.category.map((item) => {
                            return ClientCategoryList?.results?.map((cat) => {
                              return (
                                cat.id === item && (
                                  <h6 style={{ color: "#ef5b0c" }}>
                                    {cat.name}
                                  </h6>
                                )
                              );
                            });
                          })}
                        </TableCell> */}
                        <TableCell align="left" className="">
                          <h6 style={{ color: "#7A7A7A" }}>
                            {row.discount_value}% Off
                          </h6>
                        </TableCell>
                        {/* <TableCell align="left">
                          {" "}
                          {row.category.map((item) => {
                            return ClientCategoryList?.results?.map((cat) => {
                              return (
                                cat.id === item && (
                                  <h6 style={{ color: "#7A7A7A" }}>
                                    {cat.name}
                                  </h6>
                                )
                              );
                            });
                          })}
                        
                        </TableCell> */}

                        <TableCell align="center">
                          {status_list?.map((item) => {
                            return (
                              item.value === row.status && (
                                <button className="green-btn">
                                  {item.label}
                                </button>
                              )
                            );
                          })}
                          {/* {row.status === "Scheduled" ? (
                      <button className="yellow-btn">Scheduled</button>
                    ) : (
                      <button className="green-btn">Published</button>
                    )} */}
                        </TableCell>

                        <TableCell align="left">
                          <RowMenu
                            row={row}
                            handleeEdit={handleeEdit}
                            handleDelete={handleDelete}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {offer_List?.count}</p>
        <UserPagination
          prev_next={offer_List}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default ClientOffersList;
