import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React,{useEffect} from "react";
import user1 from "../../assets/img/user1.jpg";
import ModalImageUpload from "./ModalImageUpload";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {
  GetTestimoniallInput,
  SetTestimonialStatusFalse,
  SubmitTestimonialldData,
  UpdateTestimonialldData,
} from "./testimonial_redux/action/TestimonialAction";
import { SubmitImage } from "./_redux/action/SeetingsAction";
const AddTestiminial = (props) => {
  console.log('props', props)
  const history = useHistory();
  const dispatch = useDispatch();
 
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);
  const testimonialInput = useSelector(
    (state) => state.testimonialInfo.testimonialInput
  );
  const isLoadTestimonial = useSelector(
    (state) => state.testimonialInfo.isLoadTestimonial
  );
  const afterTestimonialData = useSelector(
    (state) => state.testimonialInfo.afterTestimonialData
  );

  const handleChangeInput = (name, value) => {
    dispatch(GetTestimoniallInput(name, value));
  };
  const handleSubmitTestimonial = (data) => {
    dispatch(SubmitTestimonialldData(data));
  };
  const handleUpdateTestimonial = (data) => {
    dispatch(UpdateTestimonialldData(data));
  };

  useEffect(() => {
    if(afterTestimonialData){
      props.onHide()
      localStorage.removeItem("testimonial_id");
    }
    dispatch(SetTestimonialStatusFalse())
  }, [afterTestimonialData])
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="mx-2 mx-md-3 mx-lg-4">
        <div>
          <h4 className="text-center" style={{ color: "#515151" }}>
            Add Testimonial
          </h4>
        </div>

        <div>
          <div className="inputs-modal my-3">
            <p>Name</p>
            <input
              type="text"
              name="name"
              value={testimonialInput.name}
              onChange={(e) => handleChangeInput("name", e.target.value)}
            />
          </div>
          <div className="inputs-modal my-3">
            <p>Designation</p>
            <input
              type="text"
              name="designation"
              value={testimonialInput.designation}
              onChange={(e) => handleChangeInput("designation", e.target.value)}
            />
          </div>
          <div className="inputs-modal my-3">
            <p>Testimonials</p>
            <input
              type="textarea"
              placeholder="Your meta description goes here."
              name="speech"
              value={testimonialInput.speech}
              onChange={(e) => handleChangeInput("speech", e.target.value)}
            />
          </div>
          {/* Choose a photo: */}
          <ModalImageUpload
            title="Choose a photo:"
            onImageChange={(e) => {
              imageInput.owner = userData.id;
              imageInput.doc_type = 0;
              imageInput.document = e.target.files[0];
              dispatch(SubmitImage(imageInput, "testimonial"));
              handleChangeInput("fileName1", e.target.files[0].name);
              handleChangeInput("size1", e.target.files[0].size);
              let reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);
              reader.onloadend = (e) => {
                // setPdfFile(e.target.result);
                handleChangeInput("image_url", e.target.result);
              };
            }}
            name="image"
            url={testimonialInput?.image_url}
            file_name={testimonialInput.fileName1}
            size={testimonialInput.size1 / 1000}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="mx-2 mx-md-3 mx-lg-4">
        {props.id?  <Button
          className="w-100 save-btn "
          style={{ color: "white", background: "#EF5B0C" }}
          onClick={() => handleUpdateTestimonial(testimonialInput)}
          // onClick={props.onHide}
        >
          {" "}
          Update
        </Button> :  <Button
          className="w-100 save-btn "
          style={{ color: "white", background: "#EF5B0C" }}
          onClick={() => handleSubmitTestimonial(testimonialInput)}
          // onClick={props.onHide}
        >
          {" "}
          Add
        </Button>}
        {/* <Button
          className="w-100 save-btn "
          style={{ color: "white", background: "#EF5B0C" }}
          onClick={() => handleSubmitTestimonial(testimonialInput)}
          // onClick={props.onHide}
        >
          {" "}
          Add
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default AddTestiminial;
