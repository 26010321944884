import { Search } from "@material-ui/icons";
import React from "react";
import { useState, useEffect } from "react";
import AddNewAdvertisement from "./AddNewAdvertisement";
import ClientAdvertisementList from "./ClientAdvertisementList";
import { useDispatch, useSelector } from "react-redux";
import { status_list } from "../../Common/Dropdown";

const ClientAdvertisement = () => {
  const [modalShow, setModalShow] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedName, setSelectedName] = useState(undefined);
  const handleSelectChange = (e) => {
    console.log(e.target.value);
    setSelectedName(e.target.value);
  };
  return (
    <div className="parent text-capitalize">
      <AddNewAdvertisement
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>
            Advertisement
          </span>
        </div>
        <div className="new-user mt-4">
          <div className="d-flex gap-3 align-items-center">
            <p>Advertisement</p>
            <button className="orange-btn" onClick={() => setModalShow(true)}>
              Add New
            </button>
          </div>
        </div>
        <div className="filter_search d-flex align-items-center justify-content-between mt-4">
         

          <div className="mb-3 d-flex align-items-center">
            <span
              className="me-2 my-auto"
              style={{ fontSize: "14px", minWidth: "60px" }}
            >
              Sort by :{" "}
            </span>
            <select name="" id="" className="me-2"  onChange={handleSelectChange}>
              <option value="">--</option>
              {status_list?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>

          <div className=" mb-3">
            <span className="search">
              <Search className="search_icon" />
              <input
                type="search"
                name=""
                id=""
                placeholder="Search comments, users.."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </span>
          </div>
        </div>
        <div className="table_div mt-4">
          <ClientAdvertisementList  show={modalShow}
        onHide={() => setModalShow(false)} search={search} selectedName={selectedName}/>
        </div>
      </div>
    </div>
  );
};

export default ClientAdvertisement;
