import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../Const";
import swal from "sweetalert";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { EditorState } from 'draft-js';
export const GetClientList = (api) => (dispatch) => {
  // const url = `${BASE_URL}api/v1/restaurant/admin/clients/`;
  const url = api;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
};


export const NextClientListList = (nextUrl) => (dispatch) => {
  const url = nextUrl;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_LIST,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
  }
};

export const PreviousClientList = (prevUrl) => (dispatch) => {
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
};

export const GetClientDetails = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/restaurant/admin/clients/${id}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_DETAILS,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
};

export const SetClientUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/restaurant/admin/clients/${id}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_UODATED,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });
};

export const GetClientInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_CLIENT_INPUT, payload: formData });
  // dispatch({ type: Types.ADD_NEW_DOC, payload: {name:'restaurant_types', value:, } });
};
export const GetClientInputImage = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_CLIENT_INPUT, payload: formData });
  // dispatch({ type: Types.ADD_NEW_DOC, payload: {name:'restaurant_types', value:, } });
};
export const SetClientStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_CLIENT_FALSE, payload: false });
};
export const SubmitClientdData = (data) => async (dispatch) => {
    if(data.facilities){
    data.facilities = draftToHtml(
      convertToRaw(data.facilities.getCurrentContent())
    );
  }
 if(data.about){
  data.about = draftToHtml(
    convertToRaw(data.about.getCurrentContent())
  );
 }

  if (data.company_name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  } else if (data.Location === "") {
    showToast("error", "Adress shouldn't be empty !");
    return 0;
  } else if (data.company_service_type === "") {
    showToast("error", "Service type shouldn't be empty !");
    return 0;
  } else if (data.company_category === "") {
    showToast("error", "Company category shouldn't be empty !");
    return 0;
  } else if (data.email === "") {
    showToast("error", "Email shouldn't be empty !");
    return 0;
  } else if (data.company_category === 2 && data.restaurant_types === "") {
    showToast("error", "Restaurent types shouldn't be empty !");
    return 0;
  } else if (data.company_category === 2 && data.facilities_types === "") {
    showToast("error", "Facilities shouldn't be empty !");
    return 0;
  } else if (data.company_category === 2 && data.homemade_types === "") {
    showToast("error", "Home made type shouldn't be empty !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/restaurant/admin/clients/`;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
          showToast("success", "Client added successfully");

          dispatch({ type: Types.AFTER_CLIENT_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const UpdateClientdData = (data) => async (dispatch) => {
  if (!data.password) delete data.password;

    if(data.facilities){
    data.facilities = draftToHtml(
      convertToRaw(data.facilities.getCurrentContent())
    );
  }
 if(data.about){
  data.about = draftToHtml(
    convertToRaw(data.about.getCurrentContent())
  );
 }

  if (data.company_name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  } else if (data.Location === "") {
    showToast("error", "Adress shouldn't be empty !");
    return 0;
  } else if (data.company_service_type === "") {
    showToast("error", "Service type shouldn't be empty !");
    return 0;
  } else if (data.company_category === "") {
    showToast("error", "Company category shouldn't be empty !");
    return 0;
  } else if (data.email === "") {
    showToast("error", "Email shouldn't be empty !");
    return 0;
  } else if (data.company_category === 2 && data.restaurant_types === "") {
    showToast("error", "Restaurent types shouldn't be empty !");
    return 0;
  } else if (data.company_category === 2 && data.facilities_types === "") {
    showToast("error", "Facilities shouldn't be empty !");
    return 0;
  } else if (data.company_category === 2 && data.homemade_types === "") {
    showToast("error", "Home made type shouldn't be empty !");
    return 0;
  } else if (data.cover?.length > 5) {
    showToast("error", "Please upload maximum 5 photos !");
    return 0;
  }

  const url = `${BASE_URL}api/v1/restaurant/admin/clients/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });

  try {
    await Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
          showToast("success", "Client updated successfully");

          dispatch({ type: Types.AFTER_CLIENT_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
export const UpdateClientStatus = (data, value, id) => async (dispatch) => {
  // if (data.name === "") {
  //   showToast("error", "Name shouldn't be empty !");
  //   return 0;
  // } else if (data.address === "") {
  //   showToast("error", "Adress shouldn't be empty !");
  //   return 0;
  // } else if (data.email === "") {
  //   showToast("error", "Email shouldn't be empty !");
  //   return 0;
  // }

  data.is_active = value;
  const url = `${BASE_URL}api/v1/restaurant/admin/clients/${id}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT, payload: true });

  try {
    await Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
          showToast("success", "Client updated successfully");

          dispatch({ type: Types.AFTER_CLIENT_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CLIENT, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const GetClientdelete = (id, api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/restaurant/admin/clients/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("error", res.data.message);
        dispatch({ type: Types.IS_CLIENT_DELETED, payload: true });
        dispatch(GetClientList(api));
      }
    });
  } catch (error) {}
};
export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_CLIENT_DELETED, payload: false });
};

export const GetClientOption = (data) => {
  console.log(data,'data')
  const option = [];
  if (data) {
    data.forEach((item) => {
      const obj = {
        label: item.company_name,
        value: item.id,
        id: item.id,
      };
      //  item.id
      option.push(obj);
    });
  }
  return option;
};
