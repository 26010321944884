import * as Types from "../types/Types";
const initialState = {
  verifiedUserInput: {
    password: "124",
    first_name: "",
    last_name: "string",
    username: "",
    email: "",
    mobile: "",
    dob: null,
    gender: 0,
    address1: "",
    address2: "",
    district: "",
    wallet: 0,
    membership_level: 0,
    role: 0,
    posts: 0,
    followers: 0,
    is_app_user: false,
    is_verified: true,
    is_requested: false,
    is_approved: true,
    is_active: false,
    is_staff: false,
    is_superuser: false,
    bio: "",
    image: 0,
    country: 0,
  },
  userNotification: null,
  verifieduserList: [],
  userList: { app_user_arr: [], userList: [] },
  verifiedUserDetails: null,
  isVerifiedUserDeleted: null,

  isLoadVerifiedUser: false,
  afterVerifiedUserData: false,
};
const VerifiedUsrReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_VERIFIED_USER_INPUT:
      const verifiedUserInput = { ...state.verifiedUserInput };
      verifiedUserInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        verifiedUserInput: verifiedUserInput,
      };
    case Types.IS_LOAD_VERIFIED_USER:
      return {
        ...state,
        isLoadVerifiedUser: action.payload,
      };
    case Types.AFTER_VERIFIED_USER_DATA:
      return {
        ...state,
        afterVerifiedUserData: action.payload,
      };
    case Types.GET_VERIFIED_USER_LIST:
      return {
        ...state,
        verifieduserList: action.payload,
      };
    case Types.GET_USER_NOTIFICATION:
      return {
        ...state,
        userNotification: action.payload,
      };

    case Types.GET_VERIFIED_USER_DETAILS:
      return {
        ...state,
        verifiedUserDetails: action.payload,
      };
    case Types.SET_VERIFIED_USER_FALSE:
      return {
        ...state,
        afterVerifiedUserData: action.payload,
        verifiedUserInput: initialState.verifiedUserInput,
      };
    case Types.IS_VERIFIED_USER_DELETED:
      return {
        ...state,
        isVerifiedUserDeleted: action.payload,
      };

    case Types.GET_VERIFIED_USER_UODATED:
      const setverifiedUserInput = { ...state.verifiedUserInput };
      setverifiedUserInput.id = action.payload.id;
      setverifiedUserInput.first_name = action.payload.first_name;
      setverifiedUserInput.last_name = action.payload.last_name;
      setverifiedUserInput.username = action.payload.username;
      setverifiedUserInput.email = action.payload.email;
      setverifiedUserInput.dob = action.payload.dob;
      setverifiedUserInput.gender = action.payload.gender;
      setverifiedUserInput.address1 = action.payload.address1;
      setverifiedUserInput.address2 = action.payload.address2;
      setverifiedUserInput.district = action.payload.district;
      setverifiedUserInput.wallet = action.payload.wallet;
      setverifiedUserInput.membership_level = action.payload.membership_level;
      setverifiedUserInput.role = action.payload.role;
      setverifiedUserInput.posts = action.payload.posts;
      setverifiedUserInput.followers = action.payload.followers;
      setverifiedUserInput.is_app_user = action.payload.is_app_user;
      setverifiedUserInput.is_verified = action.payload.is_verified;
      setverifiedUserInput.is_approved = action.payload.is_approved;
      setverifiedUserInput.role = action.payload.role;
      setverifiedUserInput.is_active = action.payload.is_active;
      setverifiedUserInput.is_staff = action.payload.is_staff;
      setverifiedUserInput.is_superuser = action.payload.is_superuser;
      setverifiedUserInput.bio = action.payload.bio;
      setverifiedUserInput.country = action.payload.country;
      setverifiedUserInput.image = action.payload.image;
      setverifiedUserInput.image_url = action.payload.image_url;
      setverifiedUserInput.mobile = action.payload.mobile;
      // setverifiedUserInput.password = action.payload.password;
      return {
        ...state,
        verifiedUserInput: setverifiedUserInput,
      };

    default:
      break;
  }
  return newState;
};

export default VerifiedUsrReducer;
