import React from "react";
import { useState, useEffect } from "react";
import { showToast } from "../../utils/ToastHelper";
import "./Client.css";
import swal from "sweetalert";
import axios from "axios";
import { BASE_URL } from "../../Const";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import bulk from '../../../src/assets/bulk.xlsx'
import {
  GetBulkInput,
  SetBulkStatusFalse,
  SubmitBulkData,
} from "../image_upload/_redux/action/Imageaction";
import { Link, useHistory } from "react-router-dom";
const ImportCSV = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bulkInput = useSelector((state) => state.imageInfo.bulkInput);
  const afterBulkData = useSelector((state) => state.imageInfo.afterBulkData);
  const isLoadImage = useSelector((state) => state.imageInfo.isLoadImage);
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);
  useEffect(() => {
    if (afterBulkData) {
      dispatch(SetBulkStatusFalse());
    }
  }, [afterBulkData]);
  // const [fileName, setFileName] = useState(null);
  // const [name, setName] = useState(null);
  // const fileReader = new FileReader();
  // const client_id = localStorage.getItem("client_id");
  // const [isLoading, SetisLoading] = useState(false);
  // const fileHandle = (e) => {
  //   setFileName(e.target.files[0]);
  //   setName(e.target.files[0].name);

  // };
  // const url = `${BASE_URL}api/v1/inventory/admin/product/bulk-import/`;

  // const dataSubmit = () => {
  //   SetisLoading(true);
  //   const from = new FormData();
  //   const file = fileName;
  //   console.log(file);
  //   const reader = new FileReader();
  //   reader.onload = function (event) {
  //     const csvOutput = event.target.result;
  //     console.log(csvOutput);
  //     setFileName(csvOutput);
  //   };
  //   fileReader.readAsText(file);
  //   from.append("file", fileName);
  //   from.append("client", client_id);
  //   console.log(from);
  //   axios
  //     .post(url, from)
  //     .then((res) => {
  //       if (res.data.status) {
  //         showToast("success", "Product Added.");
  //         SetisLoading(false);
  //         // history.push(path.customer_list);
  //       }
  //     })
  //     .catch((err) => {
  //       //   const message = JSON.parse(err.request.response).message;
  //       //   const errorMsg = JSON.parse(err.request.response).errors;
  //       //   for (let value of Object.values(errorMsg)) {
  //       //     showToast("error", value[0]);
  //       //   }
  //       //   showToast("error", message);
  //       // });
  //       const message = JSON.parse(err.request.response).message;
  //       const errorMsg = JSON.parse(err.request.response).errors;
  //       const errorMsgForuser = errorMsg?.msg;
  //       console.log("errorMsg", errorMsg);
  //       var errors = "Please,Try again,after Solving this errors: \n \n ";
  //       for (let key in errorMsg) {
  //         errors += errorMsg[key];
  //         errors += "\n";
  //         errors += "\n";
  //       }
  //       SetisLoading(false);

  //       swal({
  //         icon: "error",
  //         // text: `${errorMsg[key] === 'File is not a zip file' ? 'Only Exel file supported!' : errorMsg[key]}`
  //         text: errors,
  //       });
  //       // showToast("error", message);
  //     });
  // };

  const handleChangeInput = (name, value) => {
    dispatch(GetBulkInput(name, value));
    console.log(value, "value");
  };
  const handleSubmit = (data) => {
    dispatch(SubmitBulkData(data));
  };
  return (
    <div className="parent min-vh-100">
      {isLoadImage && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>Products</span>
        </div>
        <div className="my-3 my-md-4">
          <h5 className="product-heading">Import Products</h5>
        </div>
        <div className=" border bg-white">
          <div className="px-4 py-3">
            <div className="my-3">
              <h2 className="main-title">Import products as a CSV file</h2>
            </div>
            <div>
              <p className="sub-title">
                This tool allows you to export product data to your store from a
                CSV or TXT file.
              </p>
            </div>
          </div>
          {/* upper */}
          <div className="row px-2 px-md-4 mb-4">
            <div className="col-md-8 col-12 row">
              <div className="col-12 col-md-7 select-text">
                <p>
                  Choose a CSV file from your <br /> computer:
                </p>
              </div>
              <div className="col-12 col-md-5 ">
                <div className="csv">
                  <div className="d-flex align-items-center">
                    <div className="logo-file">
                      {/* <div className=""> */}
                      <p>Choose File</p>
                      <input
                        type="file"
                        accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="images"
                        id=""
                        onChange={(e) => {
                          handleChangeInput("file", e.target.files[0]);
                          handleChangeInput("name", e.target.files[0].name);
                        }}
                        // onChange={fileHandle}
                      />
                    </div>

                    {/* <span className="ms-3">No file chosen</span> */}
                    <span className="ms-3">
                      {bulkInput.name ? bulkInput.name : "No file chosen"}
                    </span>
                  </div>
                  <p className="mt-3">Maximum Size: 20 MB</p>
                </div>
              </div>
            </div>
          </div>
          {/* upper */}
          {/* middle */}
          <div className="row px-2 px-md-4 mb-4">
            <div className="col-md-8 col-12 row">
              <div className="col-12 col-md-7 select-text">
                <p>Open Demo CSV file</p>
              </div>
              <div className="col-12 col-md-5 import-csv">
           
                <a href="https://docs.google.com/spreadsheets/d/1t6IhmSt-sWvN2f9njpb_6jkqP90DWRQ8/edit#gid=2133856913" target="_blank" rel="noopener noreferrer">Click Here</a>
              </div>
              <Link to="../../../src/assets/bulk.xlsx" target="_blank" download>Download</Link>
            </div>
          </div>
         
          {/* <div className="row px-2 px-md-4 mb-4">
            <div className="col-md-8 col-12 row">
              <div className="col-12 col-md-7 select-text">
                <p>
                  Import all/selected <br /> branches:
                </p>
              </div>
              <div className="col-12 col-md-5 ">
                <div className="csv">
                  <select id="inputState" className="form-select ">
                    <option selected value={1}>
                      Dhanmondi 7/A
                    </option>
                    <option value={2}>Mirur 7/A</option>
                    <option value={3}>Gulshan 7/A</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
          {/* select */}
          <hr />
          <div className="row m-4">
            <div className="col my-2 my-md-4 d-flex justify-content-md-end justify-content-center">
              <button
                className="export-btn"
                //  onClick={dataSubmit}
                onClick={() => handleSubmit(bulkInput)}
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportCSV;
