import { Divider } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SingleBrowseFile from "../../Clients/SingleBrowseFile";
import ArrowSvg from "../../Common/ArrowSvg";
import { NotificationTrafficType } from "../../Common/Dropdown";
import { SubmitImage } from "../../Seetings/_redux/action/SeetingsAction";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {
  GetNotificationInput,
  SubmitNotificationdData,
  SetNotificationlStatusFalse,
  UpdateNotificationdData,
} from "../_redux/action/NotificationAction";
import { Editor } from "react-draft-wysiwyg";
import "./Notification.css";
import Multiselect from "multiselect-react-dropdown";
import { GetClientList } from "../../Clients/all_clients/_redux/action/ClientAction";
import { BASE_URL } from "../../../Const";
import NotificationAddres from "./mapSearch/NotificationAddres";
import { showToast } from "../../../utils/ToastHelper";
import NotificationAddressSearch from "./notificationArea/NotificationAddressSearch";
// import NotificationAddres from "../../Clients/all_clients/pages/mapSearch/NotificationAddres";
const PushNotification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, SetisLoading] = React.useState(false);
  const notificationInput = useSelector(
    (state) => state.notificationInfo.notificationInput
  );
  const [selectedValue, setSelectedValue] = React.useState(
    notificationInput.branch
  );
  const clientList = useSelector((state) => state.clientInfo.clientList);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);

  const afterNotificationData = useSelector(
    (state) => state.notificationInfo.afterNotificationData
  );
  const handleChangeInput = (name, value) => {
    dispatch(GetNotificationInput(name, value));
  };
  const handleSubmitNotification = (data) => {
    if (notificationInput.server_key === "") {
      showToast("error", "Server key shouldn't be empty !");
      return 0;
    }
    SetisLoading(true);
    dispatch(SubmitNotificationdData(data));
  };
  const handleUpdateNotification = (data) => {
    if (notificationInput.server_key === "") {
      showToast("error", "Server key shouldn't be empty !");
      return 0;
    }
    SetisLoading(true);
    dispatch(UpdateNotificationdData(data));
  };

  useEffect(() => {
    if (afterNotificationData) {
      SetisLoading(false);
      history.push(`/notification`);
      dispatch(SetNotificationlStatusFalse());
    }

  }, [afterNotificationData]);
  useEffect(() => {
    dispatch(GetClientList(`${BASE_URL}api/v1/restaurant/admin/clients/`));
  }, []);
  // const handleChangeBranch = (e) => {
  //   setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
  //   dispatch(GetNotificationInput("clients", selectedValue));
  // };


  // ---select clients-------------
  const [client, setClient] = React.useState([]);
  const handleChangeBranch = (name, value) => {
    console.log('removedItem2',value)
    value = Array.from(value, (option) => option.id);
    // setSelectedValue(Array.isArray(e) ? e.map((x) => x.id) : []);
    dispatch(GetNotificationInput("clients", value));
  };
  const onRemove = (selectedList, removedItem) => {
    setClient(selectedList);
  
    const value =Array.from(selectedList, (option) => option.id);
     
      dispatch(GetNotificationInput("clients", value));
  };


  useEffect(() => {
    const clientValue = clientList?.results?.filter(({ id }) => {
      
      return notificationInput?.clients?.find((id1) => id1 === id) !== undefined;
    });

    setClient((client) => {
      return clientValue;
    });
  }, [notificationInput.clients]);
  console.log(client)


  if (isLoading) {
    return (
      <Backdrop
        open
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div className="parent">
      <div className="main-div">
        <div className="row input-title" style={{ marginTop: "20px" }}>
          <div className="col-11  input-head">
            <p className="server-title">Server Key:</p>
          </div>
          ``
          <div className="col-11 col-md-6 input-head">
            <input
              type="text"
              placeholder="Menubook BD"
              name="server_key"
              value={notificationInput.server_key}
              onChange={(e) => handleChangeInput("server_key", e.target.value)}
            />
          </div>
          <div className="col-11 col-md-4 my-2 my-md-0 server">
            <button className="btn-save">Save</button>
          </div>
        </div>

        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p className="server-title">Notification Name:</p>
            <input
              className="mt-4"
              type="text"
              placeholder="Menubook BD"
              name="notification_name"
              value={notificationInput.notification_name}
              onChange={(e) =>
                handleChangeInput("notification_name", e.target.value)
              }
            />
          </div>
        </div>

        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <p className="server-title mb-3">Main settings</p>
            <span style={{ fontSize: "15px", color: "#515151" }}>
              Select Traffic:
            </span>{" "}
            <span
              className="ms-2"
              style={{ fontSize: "13px", color: "#A1A1A1" }}
            >
              Select the resource to which you want to send a push notification.
            </span>
            <div className="mt-3" style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="traffic"
                value={notificationInput.traffic}
                onChange={(e) => handleChangeInput("traffic", e.target.value)}
              >
                <option value="">--</option>
                {NotificationTrafficType?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        {parseInt(notificationInput.traffic) === 1 ? (
          <>
            <div
              className="d-flex align-item-center  input-title"
              style={{ marginTop: "25px" }}
            >
              <p>Is Select All Client?</p>
              <input
                className="mx-2"
                type="checkbox"
                name="is_all_client"
                value={notificationInput.is_all_client}
                checked={notificationInput.is_all_client}
                onChange={(e) =>
                  handleChangeInput("is_all_client", e.target.checked)
                }
              />
              <p>Yes</p>
            </div>
            <div className="col-11 col-md-6 food-cat f2">
              <p> Multiple Clients:</p>
              <div style={{ position: "relative" }}>
              <Multiselect
                  displayValue="company_name"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={onRemove}
                  onSearch={function noRefCheck() {}}
                  name="clients"
                  selectedValues={client}
                  onSelect={(e) => handleChangeBranch("clients", e)}
                  // onSelect={handleChangeBranch}
                  className="my-2"
                  options={clientList?.results}
                  showCheckbox
                  placeholder="Select"
                  closeIcon={true}
                  // onChange={}
                />
                <div className="arrow-icon" style={{ marginTop: "-5px" }}>
                  <ArrowSvg />
                </div>
              </div>
            </div>
          </>
        ) : (
          parseInt(notificationInput.traffic) === 2 && (
            <>
              <div
                className="d-flex align-item-center  input-title col-11 col-md-6"
                style={{ marginTop: "25px" }}
              >
                <p>Is Select All User?</p>
                <input
                  className="mx-2"
                  type="checkbox"
                  name="is_all_user"
                  value={notificationInput.is_all_user}
                  checked={notificationInput.is_all_user}
                  onChange={(e) =>
                    handleChangeInput("is_all_user", e.target.checked)
                  }
                />
                <p>Yes</p>
              </div>

              <div className="  input-title col-11 col-md-6">
                <NotificationAddressSearch />
              </div>
              <div className="col-11 col-md-6 input-head">
                <p
                  className="server-title"
                  style={{ fontSize: "18px", fontWeight: "500" }}
                >
                  {" "}
                  Area Radius:
                </p>

                <input
                  type="number"
                  placeholder="50"
                  name="radius"
                  value={notificationInput.radius}
                  onChange={(e) => handleChangeInput("radius", e.target.value)}
                />
              </div>
            </>
          )
        )}

        <div className="row input-title" style={{ marginTop: "30px" }}>
          <div className="col-11 col-md-6 input-head">
            <p className="server-title">Push Notification</p>
            <span
              className="mt-4 mb-3"
              style={{ fontSize: "15px", color: "#515151" }}
            >
              Title:
            </span>
            <input
              className="mt-4"
              type="text"
              placeholder="Menubook BD"
              name="push_notification_name"
              value={notificationInput.push_notification_name}
              onChange={(e) =>
                handleChangeInput("push_notification_name", e.target.value)
              }
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "20px" }}>
          <div className="col-11 col-md-6 input-head">
            <span
              className="mt-4 mb-3"
              style={{ fontSize: "15px", color: "#515151" }}
            >
              Description:
            </span>
            {/* <textarea
              className="mt-4"
              rows={3}
              type="textarea"
              placeholder="Menubook BD"
              name="push_notification_description"
              value={notificationInput.push_notification_description}
              onChange={(e) =>
                handleChangeInput(
                  "push_notification_description",
                  e.target.value
                )
              }
            /> */}
            <Editor
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarClassName="toolbar"
              name="push_notification_description"
              editorState={notificationInput?.push_notification_description}
              onEditorStateChange={(e) =>
                handleChangeInput("push_notification_description", e)
              }
              // onEditorStateChange={(e) => Test(e)}
            />
          </div>
        </div>
        {/* image */}
        <SingleBrowseFile
          title="Push Image"
          onImageChange={(e) => {
            imageInput.owner = userData.id;
            imageInput.doc_type = 0;
            imageInput.document = e.target.files[0];
            dispatch(SubmitImage(imageInput, "noti_image"));
            handleChangeInput("fileName6", e.target.files[0].name);
            handleChangeInput("size3", e.target.files[0].size);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
              // setPdfFile(e.target.result);
              handleChangeInput("thumb_url", e.target.result);
            };
          }}
          name="image"
          url={notificationInput?.thumb_url}
          file_name={notificationInput.fileName6}
          size={notificationInput.size3 / 1000}
        />

        <div className="mt-4 ">
          <button
            className="done-btn"
            onClick={() => {
              notificationInput.id
                ? handleUpdateNotification(notificationInput)
                : handleSubmitNotification(notificationInput);
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default PushNotification;
