import * as Types from "../types/Types";
import * as Type from "../../../Seetings/testimonial_redux/types/Types";
import * as appUser from "../../../User/component/appUser/_redux/types/Types";
import * as verification from "../../../User/component/verifieduser/_redux/types/Types";
import * as clientImg from "../../../Clients/all_clients/_redux/types/Types";
import * as category from "../../../Clients/category/_redux/types/Types";
import * as product from "../../../Clients/products/_redux/types/Types";
import * as addvertisement from "../../../Clients/ClientAdvertisement/_redux/types/Types";
import * as offer from "../../../Clients/ClientOffers/_redux/types/Types";
import * as noti from "../../../notification/_redux/types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";
import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";
import { SetClientUpdatedData } from "../../../Clients/all_clients/_redux/action/ClientAction";

// --------------------general---------------
export const GetGeneralInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };

  dispatch({ type: Types.GET_GENERAL_INPUT, payload: formData });
  // if (name === "logo") {
  //   let reader = new FileReader();
  //   const file = e.target.files[0];
  //   reader.onloadend = () => {
  //     formData.name = "logo";
  //     formData.value = reader.result;
  //     dispatch({ type: Types.GET_GENERAL_INPUT, payload: formData });
  //     // dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });
  //   };
  //   reader.readAsDataURL(file);
  // }
  return;
};
export const SetGeneralStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_GENERAL_FALSE, payload: false });
};
export const SubmitGeneraldData = (data) => async (dispatch) => {
  if (data.name === "") {
    showToast("error", "Name shouldn't be empty !");
    return 0;
  } else if (data.address === "") {
    showToast("error", "Adress shouldn't be empty !");
    return 0;
  } else if (data.email === "") {
    showToast("error", "Email shouldn't be empty !");
    return 0;
  }
  const formData = new FormData();
  formData.append("logo", data.logo);
  // formData.append("doc_type", data.doc_type);
  // formData.append("owner", data.owner);
  const url = `${BASE_URL}api/v1/settings/admin/general/`;
  dispatch({ type: Types.IS_LOAD_SEETINGS, payload: true });

  try {
    // await Axios.post(url, data)
    Axios.post(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })

      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_SEETINGS, payload: false });
          showToast("success", "General data added successfully");

          dispatch({ type: Types.AFTER_GENERAL_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_SEETINGS, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_SEETINGS, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_SEETINGS, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const GetImageInput = (name, value, e) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });

  if (name === "doc_type") {
    let reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      formData.name = "doc_url";
      formData.value = reader.result;
      dispatch({ type: Types.GET_IMAGE_INPUT, payload: formData });
    };
    reader.readAsDataURL(file);
  }
  //  }
};
export const SubmitImage = (data, name, clientId) => (dispatch) => {
  if(data.document.length> 5 && name === "cover"){
    showToast("error", "Select maximum 5 cover photo")
    return 0
  }
  const urlImg = `${BASE_URL}api/v1/auth/admin/documents/upload/`;

  // formData.append("document", data.document);
  // formData.append("doc_type", data.doc_type);
  //   formData.append("owner", data.owner);

  data.document = data.document?.length ? data.document : [data.document];
console.log("data.document",data.document.length)
  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });

  if (
    clientId === "" &&
    (name === "gallery" || name === "menu" || name === "cover")
  ) {
    dispatch({
      type: clientImg.GET_CLIENT_INPUT,
      payload: { name: name, value: null },
    });
  }
  Promise.all(
  
    Array.from(data.document).map((file) => {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("doc_type", data.doc_type);
      return new Promise((resolve, reject) => {
        try {
          Axios.post(urlImg, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((resImg) => {
              if (resImg.data.status) {
                showToast("success", "Image Uploaded successfully");
                if (name === "logo") {
                  dispatch({
                    type: Types.GET_GENERAL_INPUT,
                    payload: { name: "logo", value: resImg.data.data.id },
                  });
                } else if (name === "fav_icon") {
                  dispatch({
                    type: Types.GET_GENERAL_INPUT,
                    payload: { name: "fav_icon", value: resImg.data.data.id },
                  });
                } else if (name === "image") {
                  dispatch({
                    type: appUser.GET_APP_USER_INPUT,
                    payload: { name: "image", value: resImg.data.data.id },
                  });
                } else if (name === "req_image") {
                  dispatch({
                    type: appUser.GET_REQUESTED_USER_INPUT,
                    payload: { name: "image", value: resImg.data.data.id },
                  });
                } else if (name === "Ver_image") {
                  dispatch({
                    type: verification.GET_VERIFIED_USER_INPUT,
                    payload: { name: "image", value: resImg.data.data.id },
                  });
                } else if (name === "menu") {
                  dispatch({
                    type: clientImg.GET_CLIENT_INPUT,
                    payload: {
                      name: "menu",
                      value: resImg.data.data.document,
                      id: resImg.data.data.id,
                    },
                  });
                } else if (name === "cover") {
                  dispatch({
                    type: clientImg.GET_CLIENT_INPUT,
                    // payload: { name: "cover", value: resImg.data.data.id },
                    payload: {
                      name: "cover",
                      value: resImg.data.data.document,
                      id: resImg.data.data.id,
                    },
                  });
                } else if (name === "gallery") {
                  dispatch({
                    type: clientImg.GET_CLIENT_INPUT,
                    payload: {
                      name: "gallery",
                      value: resImg.data.data.document,
                      id: resImg.data.data.id,
                    },
                  });
                  
                } else if (name === "client_logo") {
                  dispatch({
                    type: clientImg.GET_CLIENT_INPUT,
                    payload: { name: "logo", value: resImg.data.data.id },
                  });
                } else if (name === "testimonial") {
                  dispatch({
                    type: Type.GET_TESTIMONIAL_INPUT,
                    payload: { name: "image", value: resImg.data.data.id },
                  });
                } else if (name === "thumb") {
                  dispatch({
                    type: category.GET_CLIENT_CATEGORY_INPUT,
                    payload: { name: "thumb", value: resImg.data.data.id },
                  });
                } else if (name === "product_thumb") {
                  dispatch({
                    type: product.GET_CLIENT_PRODUCT_INPUT,
                    payload: { name: "thumb", value: resImg.data.data.id },
                  });
                } else if (name === "banner") {
                  dispatch({
                    type: addvertisement.GET_ADDVERTISEMENT_INPUT,
                    payload: { name: "banner", value: resImg.data.data.id },
                  });
                } else if (name === "offer_banner") {
                  dispatch({
                    type: offer.GET_CLIENT_OFFER_INPUT,
                    payload: { name: "banner", value: resImg.data.data.id },
                  });
                } else if (name === "noti_image") {
                  dispatch({
                    type: noti.GET_NOTIFICATION_INPUT,
                    payload: { name: "image", value: resImg.data.data.id },
                  });
                }

                //   dispatch({
                //     type: Types.GET_IMAGE_INPUT,
                //     payload: {
                //       name: "file_url",
                //       value: resImg.data.data.document_url,
                //       index: i,
                //     },
                //   });

                dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
                resolve();
              } else {
                showToast("error", resImg.data.message);
                dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
              }
            })
            .catch((err) => {
              dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
              const message = JSON.parse(err.request.response).message;
              showToast("error", message);
              reject();
            });
        } catch (error) {
          showToast("error", "Something went wrong");
          reject();
        }
      });
    })
  ).then(() => {
    // if(clientId){
    //   dispatch(SetClientUpdatedData(clientId))
    // }
  });
  // Array.from(data.document).map((file) => {
  //   console.log("fileeeee", file);
  //   const formData = new FormData();
  //   formData.append("document", file);
  //   formData.append("doc_type", data.doc_type);

  //   try {
  //     Axios.post(urlImg, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })
  //       .then((resImg) => {
  //         if (resImg.data.status) {
  //           showToast("success", "Image Uploaded successfully");
  //           if (name === "logo") {
  //             dispatch({
  //               type: Types.GET_GENERAL_INPUT,
  //               payload: { name: "logo", value: resImg.data.data.id },
  //             });
  //           } else if (name === "fav_icon") {
  //             dispatch({
  //               type: Types.GET_GENERAL_INPUT,
  //               payload: { name: "fav_icon", value: resImg.data.data.id },
  //             });
  //           } else if (name === "image") {
  //             dispatch({
  //               type: appUser.GET_APP_USER_INPUT,
  //               payload: { name: "image", value: resImg.data.data.id },
  //             });
  //           } else if (name === "req_image") {
  //             dispatch({
  //               type: appUser.GET_REQUESTED_USER_INPUT,
  //               payload: { name: "image", value: resImg.data.data.id },
  //             });
  //           } else if (name === "Ver_image") {
  //             dispatch({
  //               type: verification.GET_VERIFIED_USER_INPUT,
  //               payload: { name: "image", value: resImg.data.data.id },
  //             });
  //           } else if (name === "menu") {
  //             dispatch({
  //               type: clientImg.GET_CLIENT_INPUT,
  //               payload: { name: "menu", value: resImg.data.data.id },
  //             });
  //           } else if (name === "cover") {
  //             dispatch({
  //               type: clientImg.GET_CLIENT_INPUT,
  //               payload: { name: "cover", value: [resImg.data.data.id] },
  //             });
  //           } else if (name === "gallery") {
  //             dispatch({
  //               type: clientImg.GET_CLIENT_INPUT,
  //               payload: { name: "gallery", value: resImg.data.data.id },
  //             });
  //           } else if (name === "client_logo") {
  //             dispatch({
  //               type: clientImg.GET_CLIENT_INPUT,
  //               payload: { name: "logo", value: resImg.data.data.id },
  //             });
  //           } else if (name === "testimonial") {
  //             dispatch({
  //               type: Type.GET_TESTIMONIAL_INPUT,
  //               payload: { name: "image", value: resImg.data.data.id },
  //             });
  //           } else if (name === "thumb") {
  //             dispatch({
  //               type: category.GET_CLIENT_CATEGORY_INPUT,
  //               payload: { name: "thumb", value: resImg.data.data.id },
  //             });
  //           } else if (name === "product_thumb") {
  //             dispatch({
  //               type: product.GET_CLIENT_PRODUCT_INPUT,
  //               payload: { name: "thumb", value: resImg.data.data.id },
  //             });
  //           } else if (name === "banner") {
  //             dispatch({
  //               type: addvertisement.GET_ADDVERTISEMENT_INPUT,
  //               payload: { name: "banner", value: resImg.data.data.id },
  //             });
  //           } else if (name === "offer_banner") {
  //             dispatch({
  //               type: offer.GET_CLIENT_OFFER_INPUT,
  //               payload: { name: "banner", value: resImg.data.data.id },
  //             });
  //           } else if (name === "noti_image") {
  //             dispatch({
  //               type: noti.GET_NOTIFICATION_INPUT,
  //               payload: { name: "image", value: resImg.data.data.id },
  //             });
  //           }

  //           //   dispatch({
  //           //     type: Types.GET_IMAGE_INPUT,
  //           //     payload: {
  //           //       name: "file_url",
  //           //       value: resImg.data.data.document_url,
  //           //       index: i,
  //           //     },
  //           //   });

  //           dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
  //         } else {
  //           showToast("error", resImg.data.message);
  //           dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
  //         }
  //       })
  //       .catch((err) => {
  //         dispatch({ type: Types.IS_LOAD_IMAGE, payload: false });
  //         const message = JSON.parse(err.request.response).message;
  //         showToast("error", message);
  //       });
  //   } catch (error) {
  //     showToast("error", "Something went wrong");
  //   }
  // });

  dispatch({ type: Types.IS_LOAD_IMAGE, payload: true });
};
