import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../Const";
import swal from "sweetalert";


export const GetRevieList = (api) => (dispatch) => {
    const url = api;
    dispatch({ type: Types.IS_LOAD_REVIEW, payload: true });
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_REVIEW_LIST,
            payload: res.data.data,
          });
  
          dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
    }
    dispatch({ type: Types.IS_LOAD_REVIEW, payload: true });
  };

  export const NextreviewListList = (nextUrl) => (dispatch) => {
    const url = nextUrl;
    dispatch({ type: Types.IS_LOAD_REVIEW, payload: true });
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_REVIEW_LIST,
            payload: res.data.data,
          });
          dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
    }
  };
  
  export const PreviousreviewList = (prevUrl) => (dispatch) => {
    const url = prevUrl;
    dispatch({ type: Types.IS_LOAD_REVIEW, payload: true });
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_REVIEW_LIST,
            payload: res.data.data,
          });
  
          dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
        }
      });
    } catch (error) {
      dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
    }
    dispatch({ type: Types.IS_LOAD_REVIEW, payload: true });
  };
  

  export const GetReviewlInput = (name, value) => (dispatch) => {
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.GET_REVIEW_INPUT, payload: formData });
  };
  
  export const SetReviewStatusFalse = () => (dispatch) => {
    dispatch({ type: Types.SET_REVIEW_FALSE, payload: false });
  };
  export const SubmitReviewldData = (data, api) => async (dispatch) => {
    if (data.status === "") {
      showToast("error", "Status shouldn't be empty !");
      return 0;
    }
  
  
    const url =  `${BASE_URL}api/v1/feeds/admin/review/${data.id}/`;
    dispatch({ type: Types.IS_LOAD_REVIEW, payload: true });
  
    try {
      await Axios.patch(url, data)
        .then((res) => {
          if (res.data.status) {
            dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
            showToast("success", "Report  send successfully");
            dispatch(GetRevieList(api));
            dispatch({ type: Types.AFTER_REVIEW_DATA, payload: true });
          } else {
            dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
          }
        })
        .catch((err) => {
          dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
  
          const erroeMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(erroeMsg)) {
            // showToast("error", value[0]);
            swal(value[0], {
              icon: "error",
            });
          }
          dispatch({ type: Types.IS_LOAD_REVIEW, payload: false });
          swal(erroeMsg, {
            icon: "error",
          });
        });
    } catch (error) {}
  };
  export const SetReviewUpdatedData = (id) => (dispatch) => {
    const url =  `${BASE_URL}api/v1/feeds/admin/review/${id}/`;
    try {
      Axios.get(url).then((res) => {
        if (res.data.status) {
          dispatch({
            type: Types.GET_REVIEW_UODATED,
            payload: res.data.data,
          });
        }
      });
    } catch (error) {}
  };