import * as Types from "../types/Types";
const initialState = {
  imageInput: {
    document: "",
    doc_type: 0,
  },
  bulkInput: {
    file: "",
    client: 44,
    name:""
  },
  ClientbulkInput: {
    file: "",
  

  },
  isLoadImage: false,
  afterBulkData: false,
};
const ImageReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_IMAGE_INPUT:
      const imageInput = { ...state.imageInput };
      imageInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        imageInput: imageInput,
      };
    case Types.GET_BULK_INPUT:
      const bulkInput = { ...state.bulkInput };
      bulkInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        bulkInput: bulkInput,
      };
    case Types.GET_CLIENT_BULK_INPUT:
      const ClientbulkInput = { ...state.ClientbulkInput };
      ClientbulkInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        ClientbulkInput: ClientbulkInput,
      };
    case Types.IS_LOAD_IMAGE:
      return {
        ...state,
        isLoadImage: action.payload,
      };
    case Types.AFTER_BULK_DATA:
      return {
        ...state,
        afterBulkData: action.payload,
      };
      case Types.SET_BULK_FALSE:
    return {
      ...state,
      afterBulkData: action.payload,
      bulkInput: initialState.bulkInput,
    };

    default:
      break;
  }
  return newState;
};

export default ImageReducer;
