import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Star } from "@material-ui/icons";
import Lottie from "react-lottie";
import noData2 from "../../assets/jsonFile/noData2.json";
import user from "../../assets/img/slide_home_2.jpg";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";

import { useHistory } from "react-router-dom";

import { BsImage } from "react-icons/bs";

import UserPagination from "../Common/UserPagination";
import { useSelector, useDispatch } from "react-redux";

import { RowMenu } from "../Common/RowMenu";
import {
  GetClientProductdelete,
  GetClientProductInput,
  GetClientProductList,
  NextProductListList,
  PreviousProductList,
  SetClientproductUpdatedData,
  UpdateProductTodaysSpecialdData,
} from "./products/_redux/action/ClientProductAction";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "react-bootstrap/Button";
import { BASE_URL } from "../../Const";
import { GetUserNotification } from "../User/component/verifieduser/_redux/action/VerifiedUserAction";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

const ProductList = ({ search, selectedName }) => {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch();
  const ClientProductList = useSelector(
    (state) => state.clientProductInfo.ClientProductList
  );
  const isLoadProduct = useSelector(
    (state) => state.clientProductInfo.isLoadProduct
  );
  const ClientProductInput = useSelector(
    (state) => state.clientProductInfo.ClientProductInput
  );
  const handleChangeInput = (name, value, id) => {
    dispatch(GetClientProductInput(name, value));

    dispatch(UpdateProductTodaysSpecialdData(ClientProductInput, id));
  };
  const [selected, setSelected] = React.useState([]);
  // const options = ["Edit", "Delete"];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      GetClientProductList(
        `${BASE_URL}api/v1/inventory/admin/product/?limit=20&&client=${userData.client_id}&&search=${search}`
      )
    );
    setUsers(ClientProductList?.results);
  }, []);
  React.useEffect(() => {
    if (selectedName) {
      dispatch(
        GetClientProductList(
          `${BASE_URL}api/v1/inventory/admin/product/?category=${selectedName}&&limit=20&&client=${userData.client_id}&&search=${search}`
        )
      );
    } else {
      dispatch(
        GetClientProductList(
          `${BASE_URL}api/v1/inventory/admin/product/?limit=20&&client=${userData.client_id}&&search=${search}`
        )
      );
    }
  }, [selectedName, search]);
  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(ClientProductList?.results);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  const handleDeleteAll = () => {
    if (selectedUser) {
      selectedUser.map((item) => {
        return dispatch(
          GetClientProductdelete(
            item.id,
            `${BASE_URL}api/v1/inventory/admin/product/?limit=20&&client=${userData.client_id}`
          )
        );
      });
    }
  };
  const handleeEdit = (id) => {
    dispatch(SetClientproductUpdatedData(id));
    history.push(`/edit-products/${id}`);
  };
  const handleDelete = (id) => {
    dispatch(
      GetClientProductdelete(
        id,
        `${BASE_URL}api/v1/inventory/admin/product/?limit=20&&client=${userData.client_id}`
      )
    );
    // handleClose()
    setAnchorEl(null);
  };

  ///////// -rederect login page--------------

  React.useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
    dispatch(GetUserNotification());
  }, []);

  // --------------pagination----------------
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextProductListList(ClientProductList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousProductList(ClientProductList?.previous));
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // ==================stich tHead=========
  const [navbar, setNavbar] = React.useState(false);
  const [color, setColor] = React.useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
      setColor("black");
    } else {
      setNavbar(false);
      setColor("white");
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", changeBackground, true);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  // =====================image preview================
  const [profile, setprofile] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    console.log(row, event);
    setAnchorEl(event.currentTarget);
    setprofile(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Card sx={{ maxWidth: 355 }} onClick={handleClose}>
          <CardMedia
            sx={{ height: 140, minWidth: 350 }}
            image={profile?.thumb_url ? profile?.thumb_url : user}
            title="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {profile?.name}
            </Typography>
            <div className="d-flex align-items-center justify-content-between">
              <Typography>
                <h4 size="small" className="mb-0">
                  ৳ {profile?.regular_price}{" "}
                </h4>
              </Typography>
              <Typography className="rating_td">
                <span className="d-flex align-items-center">
                  <Star /> {profile?.rating}
                </span>
              </Typography>
            </div>
          </CardContent>
        </Card>
        {/* <div className="d-flex align-items-center justify-content-betwen">
          <img src={profile?.reviewed_by_image} alt="" />
          <h4>{profile?.reviewed_by_image}</h4>
        </div> */}
      </Menu>
      {isLoadProduct && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadProduct &&
        ClientProductList?.results &&
        ClientProductList?.results?.length === 0 && (
          <div
            style={{ display: "flex" }}
            className="d-flex, justify-content-between"
          >
            <Lottie options={defaultOptions} height="200" width="200" />
          </div>
        )}
      {ClientProductList?.results?.length > 0 && (
        <div className="d-flex justify-content-end mb-3">
          <Button onClick={() => handleDeleteAll()} variant="outline-danger">
            <DeleteIcon /> Multiple Delete
          </Button>{" "}
        </div>
      )}
      <div>
        {!isLoadProduct &&
          ClientProductList?.results &&
          ClientProductList?.results !== null &&
          ClientProductList?.results?.length > 0 && (
            <TableContainer
              component={Paper}
              className="text-capitalize"
              sx={{ maxHeight: 670 }}
            >
              <Table stickyHeader aria-label="sticky table">
                {/* <caption>A basic table example with a caption</caption> */}
                <TableHead style={{ background: "white" }}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // className="form-check-input"
                        name="allSelect"
                        checked={
                          selectedUser?.length ===
                          ClientProductList?.results?.length
                        }
                        onChange={(e) =>
                          handleChange(e, ClientProductList?.results)
                        }
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "24px" }}>
                      <BsImage />
                    </TableCell>
                    <TableCell align="left"> Name</TableCell>

                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Sale Price</TableCell>
                    <TableCell align="left">Categories</TableCell>
                    <TableCell align="left">Branch</TableCell>
                    <TableCell align="center">Ratings </TableCell>
                    <TableCell align="center">Today's Speacial </TableCell>
                    <TableCell align="center">Status</TableCell>

                    <TableCell align="left">
                      {" "}
                      <IconButton aria-label="more">
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ background: "white" }}>
                  {ClientProductList?.results
                    ?.filter((val) => {
                      if (search == "") {
                        return val;
                      } else if (
                        val?.name.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val?.restaurant
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                    })
                    .map((row) => {
                      const isItemSelected = isSelected(row.name);
                      const productId = row.id;

                      return (
                        <TableRow key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              name={row.name}
                              checked={
                                selectedUser &&
                                selectedUser?.some(
                                  (item) => item?.id === row.id
                                )
                              }
                              onChange={(e) => handleChange(e, row)}
                            />
                          </TableCell>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            align="center"
                            className="image-td"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            // onClick={handleClick}
                            onClick={(e) => handleClick(e, row)}
                          >
                            <img src={row.thumb_url} alt="" />
                          </TableCell>
                          <TableCell align="left" className="client_td">
                            <h6>{row.name}</h6>
                          </TableCell>

                          <TableCell align="left" className="">
                            <h6>{row.regular_price}</h6>
                          </TableCell>
                          <TableCell align="left">{row.sale_price}</TableCell>
                          <TableCell align="left">
                            {row.category_name}
                          </TableCell>
                          <TableCell align="left">{row.restaurant}</TableCell>
                          <TableCell align="left" className="rating_td">
                            <span className="d-flex align-items-center">
                              <Star /> {row.rating}
                            </span>
                          </TableCell>
                          <TableCell align="center" padding="checkbox">
                            <Checkbox
                              style={{ pointerEvents: "none" }}
                              color="primary"
                              value={row.is_today_special}
                              checked={row.is_today_special}
                              // onChange={(e) =>
                              //   handleChangeInput(
                              //     "is_today_special",
                              //     e.target.checked, row.id
                              //   )
                              // }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.status === "Draft" ? (
                              <button className="yellow-btn">Draft</button>
                            ) : (
                              <button className="green-btn">Published</button>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <RowMenu
                              row={row}
                              handleeEdit={handleeEdit}
                              handleDelete={handleDelete}
                            />
                            {/* <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </Button> */}{" "}
                            {/* <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                         id={`actions-${row.id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={() => handleeEdit(productId)}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(productId)}>
                          Delete
                        </MenuItem>
                      </Menu> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
          <p>Total : {ClientProductList?.count}</p>
          <UserPagination
            prev_next={ClientProductList}
            handleNexteClick={handleNexteClick}
            handlePreviousClick={handlePreviousClick}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
};

export default ProductList;
