import * as Types from "../types/Types";
const initialState = {
  OfferList: [],

  //   clientDetails: null,
  OfferDeleted: null,

  isLoadOffer: false,
  afterClientOfferData: false,
};
const OfferReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_LOAD_OFFER:
      return {
        ...state,
        isLoadOffer: action.payload,
      };

    case Types.GET_OFFER_LIST:
      return {
        ...state,
        OfferList: action.payload,
      };

    case Types.IS_OFFER_DELETED:
      return {
        ...state,
        OfferDeleted: action.payload,
      };

    default:
      break;
  }
  return newState;
};

export default OfferReducer;
