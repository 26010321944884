export const GET_BILLING_TYPE_LIST ="GET_BILLING_TYPE_LIST"
export const GET_CATEGORY_LIST ="GET_CATEGORY_LIST"
export const GET_CATEGORY_INPUT ="GET_CATEGORY_INPUT"
export const AFTER_CATEGORY_DATA ="AFTER_CATEGORY_DATA"
export const IS_LOAD_CATEGORY ="IS_LOAD_CATEGORY"
export const SET_CATEGORY_FALSE ="SET_CATEGORY_FALSE"
export const IS_CATEGORY_DELETED ="IS_CATEGORY_DELETED"
export const SET_NEW_BILLING_INPUT ="SET_NEW_BILLING_INPUT"
export const ADD_NEW_BILLING_INPUT ="ADD_NEW_BILLING_INPUT"
export const DELETE_DOC ="DELETE_DOC"
export const SUCCESS_DOC ="SUCCESS_DOC"