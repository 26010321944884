import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../Const";
import swal from "sweetalert";

export const GetInvoiceList = (api) => (dispatch) => {
  const url = api;
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_INVOICE_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });
};

export const NextInvoiceList = (nextUrl) => (dispatch) => {
  const url = nextUrl;
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_INVOICE_LIST,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
  }
};

export const PreviousInvoiceList = (prevUrl) => (dispatch) => {
  const url = prevUrl;
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_INVOICE_LIST,
          payload: res.data.data,
        });

        dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });
};

export const GetInvoicelInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_INVOICE_INPUT, payload: formData });
};
export const SetInvoiceStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.INVOICE_STATUS_STATUS_FALSE, payload: false });
};
export const SetInvoiceUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/transactions/admin/invoice/${id}/`;
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_INVOICE_UPDATE_DATA,
          payload: res.data.data,
        });
        dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });
};

export const UpdateInvoiceldData = (data, api) => async (dispatch) => {
  console.log("data",api)
 
console.log(data)
  const url = `${BASE_URL}api/v1/transactions/admin/invoice/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_INVOICE, payload: true });

  try {
    await Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
          dispatch({ type: Types.AFTER_INVOICE_DATA, payload: true });
          showToast("success", " Invoice updated successfully");
          dispatch(GetInvoiceList(api));
      
        } else {
          dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_INVOICE, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};
