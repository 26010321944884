import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { FaEdit, FaEye } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import Avatar from "@mui/material/Avatar";
import UserPagination from "../../../Common/UserPagination";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {
  GetVerifiedUserList,
  SetVerifiedUpdatedData,
  GetVerifiedDetails,
  NextUserListList,
  PreviousUserList,
  GetUserNotification,
} from "./_redux/action/VerifiedUserAction";
import { BASE_URL } from "../../../../Const";

import { GetAppUserDetails } from "../appUser/_redux/action/AppUserAction";
const VerifiedUserList = ({ search, selectedName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const api = `${BASE_URL}api/v1/users/admin/?is_verified=true&&limit=20`;
  const verifieduserList = useSelector(
    (state) => state.verifiedUserInfo.verifieduserList
  );

  const isLoadVerifiedUser = useSelector((state) => state.verifiedUserInfo.isLoadVerifiedUser);
  const [selected, setSelected] = React.useState([]);
  const options = ["None", "Atria", "Callisto"];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // ========checkbox---------------

  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    setUsers(verifieduserList?.results);
    dispatch(GetVerifiedUserList(api));
    dispatch(GetUserNotification());
  }, []);

  React.useEffect(() => {
    if (selectedName === "is_app_user") {
      dispatch(GetVerifiedUserList(`${BASE_URL}api/v1/users/admin/?limit=20`));
    } else if (selectedName === "is_verified") {
      dispatch(
        GetVerifiedUserList(
          `${BASE_URL}api/v1/users/admin/?is_verified=true&&limit=20`
        )
      );
    } else if (selectedName === "is_requested") {
      dispatch(
        GetVerifiedUserList(
          `${BASE_URL}api/v1/users/admin/?is_requested=true&&limit=20`
        )
      );
    }
  }, [selectedName]);

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    if (checked) {
      // if cheked and selectall checkbox add all fileds to selectedList
      if (name === "allSelect") {
        setSelectedUser(users);
      } else {
        // if cheked and specific checkbox add specific field to selectedList
        setSelectedUser([...selectedUser, data]);
      }
    } else {
      // if uncheked and selectall checkbox add remove all fileds from selectedList
      if (name === "allSelect") {
        setSelectedUser([]);
      } else {
        // if uncheked and specific checkbox remove specific field from selectedList
        let tempuser = selectedUser.filter((item) => item.id !== data.id);
        setSelectedUser(tempuser);
      }
    }
  };
  // ----------------------api calll--------------------
  const handleEditUser = (id) => {
    dispatch(SetVerifiedUpdatedData(id));
    history.push(`/edit_verified_user/${id}`);
  };
  const handleViewUser = (id) => {
    dispatch(GetAppUserDetails(id));
    history.push(`/profile/${id}`);
  };
  React.useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
  }, []);

  // --------------pagination----------------

  const [currentPage, setCurrentPage] = React.useState(1);

  const handleNexteClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(NextUserListList(verifieduserList?.next));
  };
  const handlePreviousClick = (pageNo) => {
    setCurrentPage(pageNo);
    dispatch(PreviousUserList(verifieduserList?.previous));
  };
  return (
    <>
     {isLoadVerifiedUser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!isLoadVerifiedUser &&
        verifieduserList?.results &&
        verifieduserList?.results.length === 0 && (
          <>
            <div class="alert alert-success text-center mt-150" role="alert">
              Sorry ! No order found.
            </div>
          </>
        )}
        {!isLoadVerifiedUser &&
        verifieduserList?.results &&
        verifieduserList?.results !== null &&
        verifieduserList?.results.length > 0 && ( 
      <TableContainer component={Paper} className="text-capitalize">
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          {/* <caption>A basic table example with a caption</caption> */}
          <TableHead style={{ background: "white" }}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  // className="form-check-input"
                  name="allSelect"
                  checked={selectedUser?.length === users?.length}
                  onChange={(e) => handleChange(e, users)}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell align="left">User</TableCell>
              <TableCell align="center">User Role</TableCell>
              <TableCell align="center">Contact Info</TableCell>
              <TableCell align="center">Review Count</TableCell>
              <TableCell align="center">Followers</TableCell>
              <TableCell align="center">Membership Level</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: "white" }}>
            {verifieduserList?.results
              ?.filter((val) => {
                if (search === "") {
                  return val;
                } else if (
                  val.mobile?.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                } else if (
                  val.username?.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                } else if (
                  val.email?.toLowerCase().includes(search.toLowerCase())
                ) {
                  return val;
                }
              })
              .map((row) => {
                const isItemSelected = isSelected(row.name);
                return (
                  <TableRow key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        name={row.name}
                        // checked when selectedUser contains checked object/filed/row
                        checked={selectedUser.some(
                          (item) => item?.id === row.id
                        )}
                        onChange={(e) => handleChange(e, row)}
                        //  checked={isItemSelected}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <div className="d-flex  gap-2">
                        <Avatar alt="User1" src="/static/images/avatar/1.jpg" />{" "}
                        <div style={{ textAlign: "left", lineHeight: "20px" }}>
                          <p
                            style={{
                              color: "#2D2D2D",
                              fontSize: "14px",
                              fontWeight: "500",
                              marginBottom: "0",
                            }}
                          >
                            {row.first_name} {" "}    {row.last_name}
                          </p>
                          <p
                            style={{
                              color: "#2D2D2D",
                              fontSize: "12px",
                              margin: "0",
                            }}
                          >
                       
                          </p>
                          <p
                            style={{
                              color: "#7A7A7A",
                              fontSize: "12px",
                              margin: "0",
                            }}
                          >
                            Joined:{" "}
                            {new Date(row.created_at)
                              .toISOString()
                              .slice(0, 10)}
                          </p>
                          <p
                            onClick={() => history.push(`/review`)}
                            style={{
                              color: "#EF5B0C",
                              fontSize: "12px",
                              margin: "0",
                              cursor: "pointer",
                            }}
                          >
                            View All Reviews
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#EF5B0C", fontWeight: "bold" }}
                    >
                      <p>
                        {" "}
                        {
                          row.role === 0 && "Food Blogger"
                          // : row.role === 1
                          // ? "Client"
                          // : "User"
                        }
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ textAlign: "center", lineHeight: "20px" }}>
                        <p
                          style={{
                            color: "#7A7A7A",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          {row.email}
                        </p>{" "}
                        <p
                          style={{
                            color: "#7A7A7A",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}
                        >
                          {row.mobile}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#7A7A7A",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginBottom: "0",
                      }}
                    >
                      {row.posts}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#7A7A7A",
                        fontSize: "14px",
                        fontWeight: "500",
                        marginBottom: "0",
                      }}
                    >
                      {row.followers}
                    </TableCell>
                    <TableCell align="center" className="me-2">
                      <p
                        style={{
                          color: "#7A7A7A",
                          fontSize: "14px",
                          fontWeight: "500",
                          marginBottom: "0",
                        }}
                      >
                        {row.membership_level === 1
                          ? "Verified"
                          : row.membership_level === 0 && "Not Verified"}
                        {row.membership_level === 1 && (
                          <MdVerified
                            style={{ color: "#EF5B0C", fontSize: "20px" }}
                          />
                        )}
                      </p>
                    </TableCell>
                    <TableCell align="center" className="table_icon">
                      <span
                        className="d-flex justify-content-center"
                        onClick={() => handleViewUser(row.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <FaEye className="me-2" style={{ fontSize: "20px" }} />
                        view
                      </span>
                      <br />
                      <span
                        className="d-flex justify-content-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditUser(row.id)}
                      >
                        <FaEdit className="me-2" style={{ fontSize: "20px" }} />
                        Edit
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
 )}
      <div className="mt-4 d-flex pagination justify-content-end align-items-center gap-3">
        <p>Total : {verifieduserList?.count}</p>
        <UserPagination
          prev_next={verifieduserList}
          handleNexteClick={handleNexteClick}
          handlePreviousClick={handlePreviousClick}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default VerifiedUserList;
