import { Box } from "@material-ui/core";
import { TabContext, TabPanel } from "@mui/lab";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetUserNotification } from "../User/component/verifieduser/_redux/action/VerifiedUserAction";
import "./Client.css";
import Details1 from "./Details1";
import Details2 from "./Details2";
import Details3 from "./Details3";
import Details4 from "./Details4";

export const AddClients = () => {
  const history =useHistory()
  const dispatch =useDispatch()
  const step = ["1", "2", "3"];
  const [value, setValue] = React.useState("1");
  // const [activeStep, setActiveStep] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }
    dispatch(GetUserNotification());
  }, []);
  return (
    <Box sx={{ width: "100%", typography: "body1" }} className="mt-2">
      
      <TabContext value={value}>
        <TabPanel value="1">
          <Details1 value={setValue} />
        </TabPanel>
        <TabPanel value="2">
          <Details2 value={setValue} />
        </TabPanel>
        <TabPanel value="3">
          <Details4 value={setValue} />
        </TabPanel>
        <TabPanel value="4">
          <Details3 value={setValue} />
        </TabPanel>
        
      </TabContext>
      
    </Box>
  );
};
