import * as Types from "../types/Types";
const initialState = {
  myBusinessDetails: [],

  menuInput: {
    name: "",
    is_active: false,
    is_custom: false,
    user: 0,
  },
  menuDeleted: null,
  menuList: [],
  isLoadDashboard: false,
  afterMenuData: false,
};
const MyBusinessReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.GET_MENU_INPUT:
      const menuInput = { ...state.menuInput };
      menuInput[action.payload.name] = action.payload.value;
      return {
        ...state,
        menuInput: menuInput,
      };
    case Types.AFTER_MENU_DATA:
      return {
        ...state,
        afterMenuData: action.payload,
      };
    case Types.SET_MENU_FALSE:
      return {
        ...state,
        afterMenuData: action.payload,
        menuInput: initialState.menuInput,
      };
    case Types.IS_LOAD_MY_BUSINESS:
      return {
        ...state,
        isLoadDashboard: action.payload,
      };

    case Types.GET_MY_BUSINESS_DETAILS:
      return {
        ...state,
        myBusinessDetails: action.payload,
      };
    case Types.GET_MENU_LIST:
      return {
        ...state,
        menuList: action.payload,
      };

    case Types.IS_MENU_DELETED:
      return {
        ...state,
        menuDeleted: action.payload,
      };

    default:
      break;
  }
  return newState;
};

export default MyBusinessReducer;
