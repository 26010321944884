import * as Types from "../types/Types";
import Axios from "axios";
import { showToast } from "../../../../../utils/ToastHelper";

import { BASE_URL } from "../../../../../Const";
import swal from "sweetalert";

export const GetClientOfferList = (api) => (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const url = `${BASE_URL}api/v1/promotions/admin/offer/?client=${userData.client_id}`;
  dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: true });
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_OFFER_LIST,
          payload: res.data.data.results,
        });

        dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
      }
    });
  } catch (error) {
    dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
  }
  dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: true });
};

export const GetClientOfferInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.GET_CLIENT_OFFER_INPUT, payload: formData });
  // dispatch({ type: Types.ADD_NEW_DOC, payload: {name:'restaurant_types', value:, } });
};
export const SetClientOfferStatusFalse = () => (dispatch) => {
  dispatch({ type: Types.SET_CLIENT_OFFER_FALSE, payload: false });
};
export const SubmitClientOfferdData = (data) => async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData.client_id) {
    data.client = userData.client_id;
  }
  if (data.discount_type === 0 && data.discount_value > 100) {
    showToast("error", "Percentage no more than 100");
    return 0;
  }
  const url = `${BASE_URL}api/v1/promotions/admin/offer/`;
  dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: true });

  try {
    await Axios.post(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
          showToast("success", "Offer added successfully");

          dispatch({ type: Types.AFTER_CLIENT_OFFER_DATA, payload: true });
          dispatch(GetClientOfferList());
        } else {
          dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const SetClientofferUpdatedData = (id) => (dispatch) => {
  const url = `${BASE_URL}api/v1/promotions/admin/offer/${id}/`;
  try {
    Axios.get(url).then((res) => {
      if (res.data.status) {
        dispatch({
          type: Types.GET_CLIENT_OFFER_UPDATED,
          payload: res.data.data,
        });
      }
    });
  } catch (error) {}
};

export const UpdateClientofferdData = (data) => async (dispatch) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  // if (data.name === "") {
  //   showToast("error", "Name shouldn't be empty !");
  //   return 0;
  // } else if (data.address === "") {
  //   showToast("error", "Adress shouldn't be empty !");
  //   return 0;
  // } else if (data.email === "") {
  //   showToast("error", "Email shouldn't be empty !");
  //   return 0;
  // }
  if (data.discount_type === 0 && data.discount_value > 100) {
    showToast("error", "Discount amount no more than 100%");
    return 0;
  }
  const url = `${BASE_URL}api/v1/promotions/admin/offer/${data.id}/`;
  dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: true });

  try {
    await Axios.patch(url, data)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
          showToast("success", "Offer updated successfully");

          dispatch({ type: Types.AFTER_CLIENT_OFFER_DATA, payload: true });
        } else {
          dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });

        const erroeMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(erroeMsg)) {
          // showToast("error", value[0]);
          swal(value[0], {
            icon: "error",
          });
        }
        dispatch({ type: Types.IS_LOAD_CLIENT_OFFER, payload: false });
        swal(erroeMsg, {
          icon: "error",
        });
      });
  } catch (error) {}
};

export const GetClientofferdelete = (id, api) => (dispatch) => {
  const url = `${BASE_URL}api/v1/promotions/admin/offer/${id}/`;

  try {
    Axios.delete(url).then((res) => {
      if (res.data.status) {
        showToast("error", res.data.message);
        dispatch({ type: Types.IS_CLIENT_OFFER_DELETED, payload: true });
        dispatch(GetClientOfferList(api));
      }
    });
  } catch (error) {}
};
export const SetFalseObjectDelete = () => (dispatch) => {
  dispatch({ type: Types.IS_CLIENT_OFFER_DELETED, payload: false });
};
