import React from "react";
import { AiFillExclamationCircle } from "react-icons/ai";

import ArrowSvg from "../../../../Common/ArrowSvg";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";


import { SubmitImage } from "../../../../Seetings/_redux/action/SeetingsAction";
import { SubmitVerifiedUserldData } from "../../verifieduser/_redux/action/VerifiedUserAction";
import { UpdateAppUserldData,SetAppUserStatusFalse, GetCountryList, GetRequestedduserlInput, SubmitAppUserldData } from "../../appUser/_redux/action/AppUserAction";
import { user_role, verification_status } from "../../../../Common/Dropdown";
import SingleBrowseFile from "../../../../Clients/SingleBrowseFile";

const AddRequestedUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const requestedUserInput = useSelector((state) => state.appUserInfo.requestedUserInput);
  const countryList = useSelector((state) => state.appUserInfo.countryList);
  const isLoadAppUser = useSelector((state) => state.appUserInfo.isLoadAppUser);
  const afterAppUserData = useSelector(
    (state) => state.appUserInfo.afterAppUserData
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const imageInput = useSelector((state) => state.imageInfo.imageInput);

  const handleChangeInput = (name, value) => {
    dispatch(GetRequestedduserlInput(name, value));
  
    if(parseInt(requestedUserInput.membership_level) === 1 ){
      console.log("true")
      dispatch(GetRequestedduserlInput("is_verified", true));
      dispatch(GetRequestedduserlInput("is_requested", false));
    
    }
    console.log("tru4e", requestedUserInput.membership_level , requestedUserInput.is_verified,requestedUserInput.is_requested)
  };
  const handleSubmitAppUser = (data) => {
    dispatch(SubmitAppUserldData(data));
  };
  const handleUpdateAppUser = (data) => {
    dispatch(UpdateAppUserldData(data));
  };
  useEffect(() => {
    if (afterAppUserData) 
    {
      history.push(`/verification_r`)
    }
    dispatch(SetAppUserStatusFalse());
  }, [afterAppUserData]);

  const testimonial_id = localStorage.getItem("testimonial_id");
  useEffect(() => {
    const is_menubook_cient_logged_in =
      localStorage.getItem("is_menubook_cient_logged_in") || "false";

    if (is_menubook_cient_logged_in === "false") {
      history.push("/");
    }

    dispatch(GetCountryList());
  }, []);
  return (
    <div className="parent">
      {/* {isLoadAppUser && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
      <div className="main-div">
        <div className="fw-bold">
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}> Home / </span>{" "}
          <span style={{ fontSize: "14px", color: "#EF5B0C" }}>
            {" "}
            Client Name /{" "}
          </span>{" "}
          <span style={{ fontSize: "14px", color: "#818181" }}>
            {requestedUserInput?.id ? "Edit User" : "Add User"}
          </span>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>First name</p>
            <input
              type="text"
              placeholder="user@123"
              name="first_name"
              value={requestedUserInput.first_name}
              onChange={(e) => handleChangeInput("first_name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Last name</p>
            <input
              type="text"
              placeholder="user@123"
              name="last_name"
              value={requestedUserInput.last_name}
              onChange={(e) => handleChangeInput("last_name", e.target.value)}
            />
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Site Role</p>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="role"
                value={requestedUserInput.role}
                onChange={(e) => handleChangeInput("role", e.target.value)}
              >
                 <option value={""}>--</option>
                {user_role.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "35px" }}>
          <div className="col-11 col-md-6 input-head">
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              style={{ flexWrap: "wrap " }}
            >
              <p className="my-2 my-md-0">Membership level </p>
              <div style={{ color: "#FF0000" }}>
                <span>
                  <AiFillExclamationCircle />
                </span>
                <span className="ms-2 fw-bold">Requested for verification</span>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <select
                id="inputState"
                className="form-select "
                name="membership_level"
                value={requestedUserInput.membership_level}
                onChange={(e) =>
                  handleChangeInput("membership_level", e.target.value)
                }
              >
                <option value={""}>--</option>
                {verification_status.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="arrow-icon">
                <ArrowSvg />
              </div>
            </div>
          </div>
        </div>
      
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Phone</p>
            <input type="text" placeholder="123456"   name="mobile"
                value={requestedUserInput.mobile}
                onChange={(e) => handleChangeInput("mobile", e.target.value)}/>
          </div>
        </div>
        <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Email</p>
            <input type="text" placeholder="example@gmail.com"   name="email"
                value={requestedUserInput.email}
                onChange={(e) => handleChangeInput("email", e.target.value)}/>
          </div>
        </div>
        {/* <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head">
            <p>Password</p>
            <input type="password" placeholder="example@gmail.com"   name="password"
                value={requestedUserInput.password}
                onChange={(e) => handleChangeInput("password", e.target.value)}/>
          </div>
        </div> */}
        {/* <div className="row input-title" style={{ marginTop: "40px" }}>
          <div className="col-11 col-md-6 input-head d-flex align-items-center">
            <p className="my-auto me-3">Password:</p>
            <button
              className="reset-btn px-4 py-2 rounded-2 "
              style={{ color: "#EF5B0C", border: "1px solid #EF5B0C" }}
            >
              Sent Reset Link
            </button>
          </div>
        </div> */}
        <div className="my-4">
          {requestedUserInput?.id ? (
            <button className="save-btn" onClick={()=>handleUpdateAppUser(requestedUserInput)}>Update User</button>
          ) : (
            <button className="save-btn" onClick={()=>handleSubmitAppUser(requestedUserInput)}>Add User</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddRequestedUser;
