import React from 'react'
import PushNotification from '../notification/component/PushNotification'

const SeetingsHome = () => {
  return (
    <div>
      Home Page
    </div>
  )
}

export default SeetingsHome