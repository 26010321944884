
export const GET_APP_USER_LIST ="GET_APP_USER_LIST"
export const GET_USER_LIST ="GET_USER_LIST"
export const GET_APP_USER_INPUT ="GET_APP_USER_INPUT"
export const AFTER_APP_USER_DATA ="AFTER_APP_USER_DATA"
export const IS_LOAD_APP_USER ="IS_LOAD_APP_USER"
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const SET_APP_USER_FALSE ="SET_APP_USER_FALSE"
export const IS_APP_USER_DELETED ="IS_APP_USER_DELETED"
export const GET_APP_USER_UODATED ="GET_APP_USER_UODATED"
export const GET_APP_USER_DETAILS ="GET_APP_USER_DETAILS"
export const GET_USER_PAGINATION ="GET_USER_PAGINATION"


export const GET_REQUESTED_USER_LIST ="GET_REQUESTED_USER_LIST"
export const GET_REQUESTED_USER_INPUT ="GET_REQUESTED_USER_INPUT"
export const SET_REQUESTED_USER_FALSE ="SET_REQUESTED_USER_FALSE"
export const GET_REQUESTED_USER_UODATED ="GET_REQUESTED_USER_UODATED"